import { Col, Container, Row } from "react-bootstrap";
import {
  ArrowBox,
  ArrowBoxText,
  ArrowWithTipBox,
  ContainerTitle,
  FilledLine,
  ParagraphText,
} from "../../Styles/StyleComponent";
import blueWave from "../../Assets/blueVector.png";
import yellowWave from "../../Assets/yellowVector.png";
import { strings } from "../../Api/Localization";
import { Fade } from "react-reveal";
import { CircleIcon, LeftArrow } from "./VectorIcon";

export const ProductOddItems = ({ products }: { products: any }) => {
  const mapProductsList = (product: string[]) =>
    product
      .reduce((accumulator: any[], currentValue, currentIndex, array) => {
        if (currentIndex % 2 === 0) {
          accumulator.push(array.slice(currentIndex, currentIndex + 2));
          return accumulator;
        } else {
          return accumulator;
        }
      }, [])
      .map((p, i) => (
        <ArrowBoxLeft firstText={p[0]} secondText={p[1]} key={i} />
      ));

  return (
    <div className="position-relative">
      <Container className="my-4 ">
        <Row className=" m-0  g-3 ">
          <Col lg={12}>
            <div className="d-flex flex-wrap" style={{ marginLeft: "25px" }}>
              {mapProductsList(products?.features)}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const ProductEvenItems = ({ products }: { products: any }) => {
  const mapProductsListWithArrow = (product: string[]) =>
    product.map((item: string, index) => (
      <ArrowTipBox
        key={index}
        color={index % 2 === 0 ? "#F9A51B" : "#007EC5"}
        text={item}
      />
    ));
  return (
    <div className="position-relative">
      {/* <BlueWave /> */}
      <Container className="my-4 ">
        <Row className=" m-0  g-3 ">
          <Col lg={12} className="order-lg-1 order-2">
            {/* <ProductItemsHeader products={products} /> */}
            <div className="d-flex flex-wrap justify-content-md-start justify-content-center  position-relative ">
              {mapProductsListWithArrow(products?.features)}
            </div>
          </Col>
          {/* <Col lg={12} className="order-lg-2 order-1">
            <div
              className=" d-flex  justify-content-end"
              style={{ height: "300px" }}
            >
              <img
                src={products.image}
                alt=""
                width="290px"
                style={{ objectFit: "contain" }}
              />
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};
export const ProductItemsHeader = ({ products }: { products: any }) => (
  <div
    style={{
      marginBottom: "24px",
    }}
  >
    <ContainerTitle>{strings.getString(`${products?.title}`)}</ContainerTitle>
    <Fade up delay={100}>
      <ParagraphText color="#000">
        {strings.getString(`${products?.description}`)}
      </ParagraphText>
    </Fade>
  </div>
);

const YellowWave = () => (
  <img
    src={yellowWave}
    alt=""
    height="300px"
    style={{
      position: "absolute",
      left: "0px",
      top: "0px",
      zIndex: "-1",
    }}
  />
);

const BlueWave = () => (
  <img
    src={blueWave}
    alt=""
    height="300px"
    style={{
      position: "absolute",
      right: "0px",
      top: "15px",
      zIndex: "-1",
    }}
  />
);

export const ArrowTipBox = ({
  color,
  text,
}: {
  color: string;
  text: string;
}) => (
  <>
    <div
      style={{
        height: "130px",
        position: "relative",
        marginTop: "12px",
      }}
    >
      <Fade up delay={100}>
        <ArrowWithTipBox background={color}>
          <LeftArrow color={color !== "#F9A51B" ? "#0057A5" : "#FF9700"} />
          <ArrowBoxText transform="rotate(0deg)">
            {strings.getString(`${text}`)}
          </ArrowBoxText>
        </ArrowWithTipBox>
      </Fade>

      <div>
        <CircleIcon
          color={color === "#F9A51B" ? "#0057A5" : "#FF9700"}
        ></CircleIcon>
        <FilledLine />
      </div>
    </div>
  </>
);

export const ArrowBoxLeft = ({ firstText, secondText }: any) => (
  <div style={{ marginLeft: "-15px" }}>
    <Fade down delay={100}>
      <ArrowBox>
        <ArrowBoxText>{strings.getString(`${firstText}`)}</ArrowBoxText>
      </ArrowBox>
    </Fade>
    <Fade up delay={100}>
      {secondText && (
        <ArrowBox
          border="3px solid #007EC5"
          background="#F9A51B"
          transform="rotate(45deg)"
          margin=" -15px 0px 0px 75px"
        >
          <ArrowBoxText transform="rotate(-45deg)">
            {strings.getString(`${secondText}`)}
          </ArrowBoxText>
        </ArrowBox>
      )}
    </Fade>
  </div>
);
