export const Twitter = ({ color }: { color?: any }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.5"
    height="11.777"
    viewBox="0 0 14.5 11.777"
  >
    <path
      id="Icon_awesome-twitter"
      data-name="Icon awesome-twitter"
      d="M13.01,6.316c.009.129.009.258.009.386a8.4,8.4,0,0,1-8.455,8.455A8.4,8.4,0,0,1,0,13.823a6.148,6.148,0,0,0,.718.037,5.952,5.952,0,0,0,3.689-1.27A2.977,2.977,0,0,1,1.628,10.53a3.748,3.748,0,0,0,.561.046,3.143,3.143,0,0,0,.782-.1A2.972,2.972,0,0,1,.589,7.558V7.521A2.993,2.993,0,0,0,1.932,7.9a2.976,2.976,0,0,1-.92-3.975A8.447,8.447,0,0,0,7.14,7.033a3.355,3.355,0,0,1-.074-.681,2.975,2.975,0,0,1,5.143-2.033A5.851,5.851,0,0,0,14.1,3.6a2.964,2.964,0,0,1-1.306,1.638,5.958,5.958,0,0,0,1.711-.46,6.388,6.388,0,0,1-1.49,1.536Z"
      transform="translate(0 -3.381)"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export const Facebook = ({ color }: { color?: any }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.199"
    height="17.176"
    viewBox="0 0 9.199 17.176"
  >
    <path
      id="Icon_awesome-facebook-f"
      data-name="Icon awesome-facebook-f"
      d="M10.206,9.661l.477-3.108H7.7V4.536A1.554,1.554,0,0,1,9.453,2.856h1.356V.21A16.536,16.536,0,0,0,8.4,0C5.945,0,4.34,1.489,4.34,4.184V6.553H1.609V9.661H4.34v7.514H7.7V9.661Z"
      transform="translate(-1.609)"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export const Instagram = ({ color }: { color?: any }) => (
  <svg
    id="Icon_ionic-logo-instagram"
    data-name="Icon ionic-logo-instagram"
    xmlns="http://www.w3.org/2000/svg"
    width="14.083"
    height="14.083"
    viewBox="0 0 14.083 14.083"
  >
    <path
      id="Path_3964"
      data-name="Path 3964"
      d="M14.475,5.674a2.943,2.943,0,0,1,2.934,2.934v5.868a2.943,2.943,0,0,1-2.934,2.934H8.607a2.943,2.943,0,0,1-2.934-2.934V8.607A2.943,2.943,0,0,1,8.607,5.674h5.868m0-1.174H8.607A4.12,4.12,0,0,0,4.5,8.607v5.868a4.12,4.12,0,0,0,4.107,4.107h5.868a4.12,4.12,0,0,0,4.107-4.107V8.607A4.12,4.12,0,0,0,14.475,4.5Z"
      transform="translate(-4.5 -4.5)"
      fill={color ? color : "#fff"}
    />
    <path
      id="Path_3965"
      data-name="Path 3965"
      d="M24.505,10.76a.88.88,0,1,1,.88-.88A.878.878,0,0,1,24.505,10.76Z"
      transform="translate(-13.65 -6.653)"
      fill={color ? color : "#fff"}
    />
    <path
      id="Path_3966"
      data-name="Path 3966"
      d="M14.771,12.424a2.347,2.347,0,1,1-2.347,2.347,2.349,2.349,0,0,1,2.347-2.347m0-1.174a3.521,3.521,0,1,0,3.521,3.521,3.522,3.522,0,0,0-3.521-3.521Z"
      transform="translate(-7.729 -7.729)"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export const Linkedin = ({ color }: { color?: any }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.498"
    height="13.473"
    viewBox="0 0 13.498 13.473"
  >
    <path
      id="Icon_awesome-linkedin"
      data-name="Icon awesome-linkedin"
      d="M6.858,19.58h-2.8v-9h2.8v9ZM5.46,9.349A1.621,1.621,0,1,1,7.081,7.728,1.622,1.622,0,0,1,5.46,9.349ZM17.337,19.58h-2.8V15.2c0-1.044-.021-2.387-1.453-2.387-1.457,0-1.68,1.137-1.68,2.311V19.58h-2.8v-9H11.3v1.229h.038a2.945,2.945,0,0,1,2.648-1.453c2.829,0,3.356,1.865,3.356,4.29Z"
      transform="translate(-3.839 -6.107)"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export const LocationIcon = ({ color }: { color?: any }) => (
  <svg
    width="21"
    height="27"
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M10.473 0.77124C7.69641 0.774397 5.03446 1.83878 3.0711 3.73089C1.10774 5.62301 0.00328679 8.18836 1.11422e-05 10.8642C-0.00331441 13.0509 0.737864 15.1783 2.10985 16.92C2.10985 16.92 2.39547 17.2824 2.44213 17.3347L10.473 26.4625L18.5077 17.3302C18.5496 17.2815 18.8362 16.92 18.8362 16.92L18.8372 16.9173C20.2085 15.1763 20.9493 13.0499 20.946 10.8642C20.9428 8.18836 19.8383 5.62301 17.8749 3.73089C15.9116 1.83878 13.2496 0.774397 10.473 0.77124ZM14.4813 11.5083L10.4759 15.4519L6.46472 11.5083C5.98309 11.0291 5.71379 10.3881 5.71379 9.72096C5.71379 9.05381 5.98309 8.41282 6.46472 7.93359C6.70089 7.69964 6.98333 7.51368 7.29535 7.3867C7.60736 7.25973 7.94262 7.1943 8.28131 7.1943C8.62 7.1943 8.95526 7.25973 9.26727 7.3867C9.57928 7.51368 9.86172 7.69964 10.0979 7.93359L10.4759 8.30519L10.8501 7.93359C11.0862 7.69964 11.3687 7.51368 11.6807 7.3867C11.9927 7.25973 12.328 7.1943 12.6666 7.1943C13.0053 7.1943 13.3406 7.25973 13.6526 7.3867C13.9646 7.51368 14.2471 7.69964 14.4832 7.93359C14.9649 8.41282 15.2342 9.05381 15.2342 9.72096C15.2342 10.3881 14.9649 11.0291 14.4832 11.5083H14.4813Z"
      fill={color ? color : "white"}
    />
  </svg>
);
export const FooterLocationIcon = () => (
  <svg
    width="20px"
    height="100%"
    viewBox="0 0 21 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "16px" }}
  >
    <path
      d="M10.473 0.77124C7.69641 0.774397 5.03446 1.83878 3.0711 3.73089C1.10774 5.62301 0.00328679 8.18836 1.11422e-05 10.8642C-0.00331441 13.0509 0.737864 15.1783 2.10985 16.92C2.10985 16.92 2.39547 17.2824 2.44213 17.3347L10.473 26.4625L18.5077 17.3302C18.5496 17.2815 18.8362 16.92 18.8362 16.92L18.8372 16.9173C20.2085 15.1763 20.9493 13.0499 20.946 10.8642C20.9428 8.18836 19.8383 5.62301 17.8749 3.73089C15.9116 1.83878 13.2496 0.774397 10.473 0.77124ZM14.4813 11.5083L10.4759 15.4519L6.46472 11.5083C5.98309 11.0291 5.71379 10.3881 5.71379 9.72096C5.71379 9.05381 5.98309 8.41282 6.46472 7.93359C6.70089 7.69964 6.98333 7.51368 7.29535 7.3867C7.60736 7.25973 7.94262 7.1943 8.28131 7.1943C8.62 7.1943 8.95526 7.25973 9.26727 7.3867C9.57928 7.51368 9.86172 7.69964 10.0979 7.93359L10.4759 8.30519L10.8501 7.93359C11.0862 7.69964 11.3687 7.51368 11.6807 7.3867C11.9927 7.25973 12.328 7.1943 12.6666 7.1943C13.0053 7.1943 13.3406 7.25973 13.6526 7.3867C13.9646 7.51368 14.2471 7.69964 14.4832 7.93359C14.9649 8.41282 15.2342 9.05381 15.2342 9.72096C15.2342 10.3881 14.9649 11.0291 14.4832 11.5083H14.4813Z"
      fill={"white"}
    />
  </svg>
);

export const WhitePhone = () => (
  <svg
    width="25px"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "16px" }}
  >
    <path
      d="M20.714 11.5851H23.1509C23.1509 5.33438 18.4318 0.620117 12.1725 0.620117V3.05706C17.1219 3.05706 20.714 6.64302 20.714 11.5851Z"
      fill="white"
    />
    <path
      d="M12.185 7.93101C14.7475 7.93101 15.8404 9.02398 15.8404 11.5864H18.2774C18.2774 7.65686 16.1146 5.49407 12.185 5.49407V7.93101ZM16.3546 14.5631C16.1205 14.3503 15.8128 14.2369 15.4966 14.2466C15.1804 14.2564 14.8803 14.3887 14.6597 14.6155L11.7439 17.6142C11.0421 17.4802 9.63112 17.0403 8.17871 15.5915C6.72629 14.1379 6.28643 12.7233 6.15605 12.0263L9.15226 9.10927C9.3794 8.88889 9.51188 8.58877 9.52166 8.27244C9.53144 7.9561 9.41775 7.64838 9.20466 7.41438L4.70242 2.46374C4.48924 2.22901 4.19295 2.08662 3.87648 2.06683C3.56001 2.04704 3.24829 2.15139 3.00753 2.35773L0.363448 4.6253C0.152788 4.83673 0.0270519 5.11811 0.0100913 5.41609C-0.00818572 5.72071 -0.356668 12.9365 5.23854 18.5341C10.1197 23.4141 16.234 23.7711 17.9179 23.7711C18.1641 23.7711 18.3152 23.7638 18.3554 23.7614C18.6533 23.7447 18.9345 23.6184 19.1449 23.4068L21.4113 20.7615C21.6178 20.5209 21.7223 20.2093 21.7028 19.8928C21.6832 19.5763 21.541 19.28 21.3065 19.0666L16.3546 14.5631Z"
      fill="white"
    />
  </svg>
);

export const WhiteMail = () => (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "6px" }}
  >
    <path
      d="M3.64582 1.2981H24.8123C26.2675 1.2981 27.4582 2.47631 27.4582 3.91635V19.6259C27.4582 21.0659 26.2675 22.2441 24.8123 22.2441H3.64582C2.19062 22.2441 1 21.0659 1 19.6259V3.91635C1 2.47631 2.19062 1.2981 3.64582 1.2981Z"
      fill="white"
      stroke="#0082C6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M27.4582 3.91626L14.2291 13.0802L1 3.91626" fill="white" />
    <path
      d="M27.4582 3.91626L14.2291 13.0802L1 3.91626"
      stroke="#0082C6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FilledVerifiedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    viewBox="0 0 62 62"
  >
    <path
      id="_5402431_done_success_tick_valid_approve_icon"
      data-name="5402431_done_success_tick_valid_approve_icon"
      d="M33,2A31,31,0,1,0,64,33,31,31,0,0,0,33,2ZM47.6,25.9,32.1,41.4a3.1,3.1,0,0,1-4.4,0l-6.2-6.2a3.113,3.113,0,0,1,4.4-4.4l4,4.03L43.2,21.5a3.113,3.113,0,1,1,4.4,4.4Z"
      transform="translate(-2 -2)"
      fill="#333"
    />
  </svg>
);

export const HappyFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    viewBox="0 0 62 62"
  >
    <path
      id="Icon_awesome-smile"
      data-name="Icon awesome-smile"
      d="M31,.563a31,31,0,1,0,31,31A30.995,30.995,0,0,0,31,.563Zm10,21a4,4,0,1,1-4,4A4,4,0,0,1,41,21.563Zm-20,0a4,4,0,1,1-4,4A4,4,0,0,1,21,21.563ZM45.35,42.837a18.673,18.673,0,0,1-28.7,0,2,2,0,0,1,3.075-2.562,14.687,14.687,0,0,0,22.55,0A2,2,0,0,1,45.35,42.837Z"
      transform="translate(0 -0.563)"
      fill="#333"
    />
  </svg>
);

export const AwardFilled = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="66.429"
    viewBox="0 0 62 66.429"
  >
    <g id="Interface-Solid" transform="translate(0 0)">
      <g id="interface-solid-award-trophie-1">
        <path
          id="Path_3961"
          data-name="Path 3961"
          d="M30.043,12.542,24.2,12.3a1.15,1.15,0,0,1-1.029-.781L21.147,5.782a1.129,1.129,0,0,0-2.148,0l-2.026,5.736a1.15,1.15,0,0,1-1.028.781l-5.842.243a1.208,1.208,0,0,0-.664,2.136l4.589,3.787a1.229,1.229,0,0,1,.393,1.264l-1.584,5.886a1.158,1.158,0,0,0,1.738,1.321l4.863-3.395a1.1,1.1,0,0,1,1.271,0l4.863,3.395a1.158,1.158,0,0,0,1.738-1.321l-1.584-5.886a1.229,1.229,0,0,1,.393-1.264l4.589-3.787a1.208,1.208,0,0,0-.664-2.136Z"
          transform="translate(10.927 6.071)"
          fill="none"
        />
        <path
          id="Path_3962"
          data-name="Path 3962"
          d="M53.143,11.3V2.214A2.213,2.213,0,0,0,50.929,0H11.071A2.213,2.213,0,0,0,8.857,2.214V11.3A11.09,11.09,0,0,0,0,22.143V35.429a2.214,2.214,0,1,0,4.429,0V22.143a6.631,6.631,0,0,1,4.429-6.235V26.571A22.173,22.173,0,0,0,28.786,48.6V62H17.714a2.214,2.214,0,0,0,0,4.429H44.286a2.214,2.214,0,0,0,0-4.429H33.214V48.6a22.173,22.173,0,0,0,19.929-22.03V15.908a6.632,6.632,0,0,1,4.429,6.235V35.429a2.214,2.214,0,1,0,4.429,0V22.143A11.09,11.09,0,0,0,53.143,11.3ZM41.634,20.75l-4.589,3.787a1.229,1.229,0,0,0-.393,1.264l1.584,5.886A1.158,1.158,0,0,1,36.5,33.008l-4.863-3.394a1.1,1.1,0,0,0-1.271,0L25.5,33.008a1.158,1.158,0,0,1-1.738-1.321L25.348,25.8a1.229,1.229,0,0,0-.393-1.264L20.366,20.75a1.208,1.208,0,0,1,.664-2.136l5.842-.243a1.15,1.15,0,0,0,1.029-.781l2.026-5.736a1.129,1.129,0,0,1,2.148,0L34.1,17.589a1.15,1.15,0,0,0,1.028.781l5.842.243a1.208,1.208,0,0,1,.664,2.136Z"
          transform="translate(0 0)"
          fill="#333"
        />
      </g>
    </g>
  </svg>
);

export const ServiceFilled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62.717"
    height="62.733"
    viewBox="0 0 62.717 62.733"
  >
    <path
      id="Icon_ionic-ios-settings"
      data-name="Icon ionic-ios-settings"
      d="M62.039,35.858a8.071,8.071,0,0,1,5.177-7.529A31.989,31.989,0,0,0,63.346,19a8.179,8.179,0,0,1-3.283.7A8.053,8.053,0,0,1,52.7,8.371,31.893,31.893,0,0,0,43.388,4.5a8.063,8.063,0,0,1-15.059,0A31.99,31.99,0,0,0,19,8.371a8.053,8.053,0,0,1-7.366,11.335A7.912,7.912,0,0,1,8.354,19,32.7,32.7,0,0,0,4.5,28.345a8.061,8.061,0,0,1,5.177,7.529A8.08,8.08,0,0,1,4.516,43.4,31.989,31.989,0,0,0,8.387,52.73,8.056,8.056,0,0,1,19.02,63.362a32.176,32.176,0,0,0,9.326,3.871,8.049,8.049,0,0,1,15.026,0A31.99,31.99,0,0,0,52.7,63.362,8.064,8.064,0,0,1,63.33,52.73,32.177,32.177,0,0,0,67.2,43.4,8.109,8.109,0,0,1,62.039,35.858ZM36.005,48.908A13.066,13.066,0,1,1,49.071,35.842,13.062,13.062,0,0,1,36.005,48.908Z"
      transform="translate(-4.5 -4.5)"
      fill="#333"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    baseProfile="tiny"
    height="24px"
    version="1.2"
    viewBox="0 0 24 24"
    width="24px"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Layer_1">
      <path d="M19,6.184V6c0-1.657-1.343-3-3-3s-3,1.343-3,3h-2c0-1.657-1.343-3-3-3S5,4.343,5,6v0.184C3.839,6.599,3,7.698,3,9v9   c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V9C21,7.698,20.161,6.599,19,6.184z M15,6c0-0.552,0.447-1,1-1s1,0.448,1,1v2   c0,0.552-0.447,1-1,1s-1-0.448-1-1V6z M7,6c0-0.552,0.447-1,1-1s1,0.448,1,1v2c0,0.552-0.447,1-1,1S7,8.552,7,8V6z M19,18   c0,0.551-0.448,1-1,1H6c-0.552,0-1-0.449-1-1v-6h14V18z M19,11H5V9c0-0.551,0.448-1,1-1c0,1.104,0.896,2,2,2s2-0.896,2-2h4   c0,1.104,0.896,2,2,2s2-0.896,2-2c0.552,0,1,0.449,1,1V11z" />
    </g>
  </svg>
);

export const GlobeIcon = () => (
  <svg
    height="20px"
    version="1.1"
    viewBox="0 0 20 20"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title />
    <desc />
    <defs />
    <g
      fill="none"
      fillRule="evenodd"
      id="Page-1"
      stroke="none"
      stroke-width="1"
    >
      <g
        fill="#000000"
        id="Core"
        transform="translate(-296.000000, -296.000000)"
      >
        <g id="language" transform="translate(296.000000, 296.000000)">
          <path
            d="M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M16.9,6 L14,6 C13.7,4.7 13.2,3.6 12.6,2.4 C14.4,3.1 16,4.3 16.9,6 L16.9,6 Z M10,2 C10.8,3.2 11.5,4.5 11.9,6 L8.1,6 C8.5,4.6 9.2,3.2 10,2 L10,2 Z M2.3,12 C2.1,11.4 2,10.7 2,10 C2,9.3 2.1,8.6 2.3,8 L5.7,8 C5.6,8.7 5.6,9.3 5.6,10 C5.6,10.7 5.7,11.3 5.7,12 L2.3,12 L2.3,12 Z M3.1,14 L6,14 C6.3,15.3 6.8,16.4 7.4,17.6 C5.6,16.9 4,15.7 3.1,14 L3.1,14 Z M6,6 L3.1,6 C4.1,4.3 5.6,3.1 7.4,2.4 C6.8,3.6 6.3,4.7 6,6 L6,6 Z M10,18 C9.2,16.8 8.5,15.5 8.1,14 L11.9,14 C11.5,15.4 10.8,16.8 10,18 L10,18 Z M12.3,12 L7.7,12 C7.6,11.3 7.5,10.7 7.5,10 C7.5,9.3 7.6,8.7 7.7,8 L12.4,8 C12.5,8.7 12.6,9.3 12.6,10 C12.6,10.7 12.4,11.3 12.3,12 L12.3,12 Z M12.6,17.6 C13.2,16.5 13.7,15.3 14,14 L16.9,14 C16,15.7 14.4,16.9 12.6,17.6 L12.6,17.6 Z M14.4,12 C14.5,11.3 14.5,10.7 14.5,10 C14.5,9.3 14.4,8.7 14.4,8 L17.8,8 C18,8.6 18.1,9.3 18.1,10 C18.1,10.7 18,11.4 17.8,12 L14.4,12 L14.4,12 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Calendar = () => (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "12px" }}
  >
    <path
      d="M24.3303 5.16488V4.89409C24.3303 2.45549 22.3538 0.479004 19.9152 0.479004C17.4766 0.479004 15.5002 2.45549 15.5002 4.89409H12.5568C12.5568 2.45549 10.5803 0.479004 8.14168 0.479004C5.70308 0.479004 3.72659 2.45549 3.72659 4.89409V5.16488C2.01796 5.77563 0.783203 7.39303 0.783203 9.30917V22.5544C0.783203 24.9886 2.7641 26.9695 5.19829 26.9695H22.8586C25.2928 26.9695 27.2737 24.9886 27.2737 22.5544V9.30917C27.2737 7.39303 26.039 5.77563 24.3303 5.16488ZM18.4435 4.89409C18.4435 4.08171 19.1014 3.42239 19.9152 3.42239C20.7291 3.42239 21.3869 4.08171 21.3869 4.89409V7.83748C21.3869 8.64985 20.7291 9.30917 19.9152 9.30917C19.1014 9.30917 18.4435 8.64985 18.4435 7.83748V4.89409ZM6.66998 4.89409C6.66998 4.08171 7.32783 3.42239 8.14168 3.42239C8.95553 3.42239 9.61337 4.08171 9.61337 4.89409V7.83748C9.61337 8.64985 8.95553 9.30917 8.14168 9.30917C7.32783 9.30917 6.66998 8.64985 6.66998 7.83748V4.89409ZM24.3303 22.5544C24.3303 23.3653 23.671 24.0261 22.8586 24.0261H5.19829C4.38591 24.0261 3.72659 23.3653 3.72659 22.5544V13.7243H24.3303V22.5544Z"
      fill="#0D97D6"
    />
  </svg>
);

export const UserIcon = () => (
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "12px" }}
  >
    <path
      d="M21.1173 7.6302C21.1173 9.58179 20.3421 11.4534 18.9621 12.8334C17.5821 14.2134 15.7105 14.9887 13.7589 14.9887C11.8073 14.9887 9.93562 14.2134 8.55564 12.8334C7.17566 11.4534 6.40039 9.58179 6.40039 7.6302C6.40039 5.67861 7.17566 3.80696 8.55564 2.42698C9.93562 1.04699 11.8073 0.271729 13.7589 0.271729C15.7105 0.271729 17.5821 1.04699 18.9621 2.42698C20.3421 3.80696 21.1173 5.67861 21.1173 7.6302V7.6302Z"
      fill="#0D97D6"
    />
    <path
      d="M13.7587 16.4604C11.8794 16.4604 10.0879 16.8563 8.46976 17.5642C4.22304 19.4215 1.14352 23.4466 0.605469 28.234C0.605469 29.8602 1.83124 31.1774 3.45688 31.1774H24.0606C25.6868 31.1774 26.9127 29.8602 26.9127 28.234C26.3741 23.4466 23.2939 19.4215 19.048 17.5642C17.4291 16.8563 15.6381 16.4604 13.7587 16.4604V16.4604Z"
      fill="#0D97D6"
    />
  </svg>
);

export const SendIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.4346 1.5512C25.4918 1.40817 25.5059 1.25149 25.4749 1.10057C25.4439 0.94965 25.3694 0.811137 25.2604 0.7022C25.1515 0.593263 25.013 0.518693 24.8621 0.487734C24.7112 0.456776 24.5545 0.470791 24.4114 0.528042L1.51319 9.68766H1.51162L0.800131 9.97099C0.665374 10.0247 0.548097 10.1147 0.461255 10.2309C0.374413 10.3471 0.321385 10.4851 0.30803 10.6296C0.294674 10.774 0.321511 10.9194 0.385575 11.0496C0.449639 11.1797 0.548437 11.2897 0.671056 11.3672L1.31643 11.7765L1.31801 11.7796L9.18057 16.7821L14.183 24.6446L14.1862 24.6478L14.5954 25.2932C14.6732 25.4153 14.7832 25.5136 14.9133 25.5773C15.0434 25.6409 15.1885 25.6675 15.3327 25.654C15.4769 25.6405 15.6145 25.5874 15.7305 25.5007C15.8465 25.414 15.9363 25.297 15.9901 25.1625L25.4346 1.5512ZM22.5493 4.52622L10.7531 16.3224L10.4147 15.7904C10.3526 15.6928 10.2699 15.61 10.1722 15.548L9.64021 15.2096L21.4364 3.41334L23.2907 2.67195L22.5509 4.52622H22.5493Z"
      fill="white"
    />
  </svg>
);
