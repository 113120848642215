import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { strings } from "../../Api/Localization";
import {
  AccountingErp,
  BulkSms,
  CollegeErp,
  HospitalErp,
  HrErp,
} from "../../Assets/ProductsIcon";
import {
  EllipsisText,
  ProductBox,
  SubTitle,
} from "../../Styles/StyleComponent";
import "swiper/css";
import "swiper/css/pagination";

export const ProductSliders = () => {
  const navigateTo = useNavigate();
  const [activeHover, setActiveHover] = useState<string | null>();
  const products = [
    {
      id: "1",
      svg: <CollegeErp activeHover={activeHover} />,
      title: strings.getString("school_college_erp"),
      description: strings.getString("school_college_erp_description"),
      path: "products/academic-erp",
    },
    {
      id: "2",
      svg: <AccountingErp activeHover={activeHover} />,
      title: strings.getString("accounting_erp"),
      description: strings.getString("accounting_erp_description"),
      path: "products/accounting-solution",
    },
    {
      id: "3",
      svg: <HospitalErp activeHover={activeHover} />,
      title: strings.getString("hospital_erp"),
      description: strings.getString("hospital_erp_description"),
      path: "products/hospital-erp",
    },
    {
      id: "4",
      svg: <HrErp activeHover={activeHover} />,
      title: strings.getString("hr_and_payroll"),
      description: strings.getString("hr_and_payroll_decription"),
      path: "products/hr-and-payroll-management",
    },
    {
      id: "5",
      svg: <BulkSms activeHover={activeHover} />,
      title: strings.getString("sms_system"),
      description: strings.getString("sms_system_description"),
      path: "products/web-sms",
    },
  ];

  return (
    <>
      <Row className="icon-wraper-1">
        <Col  md={12}>
          <div className="section-head style-3 text-center mb-md-5 mb-3">
            <Fade up delay={100}>
              <SubTitle>{strings.getString("products")}</SubTitle>
            </Fade>
            <Fade up delay={200}>
              <h2 className="title m-t10">
              Our Comprehensive Offerings to  {" "}
                <span style={{ color: "#007EC5" }}>
                 Benefit{" "}
                </span>
                You
              </h2>
            </Fade>
          </div>
        </Col>



        
        {products.slice(0,3).map((product: any, i: number) => (
          <Col key={i} lg={4} md={6} className={`mx-auto ${i<=2 ? 'px-4' : 'px-0'}`}>
            <ProductBox
              onClick={() => navigateTo(`/${product.path}`)}
              style={{ cursor: "pointer"}}
              onMouseEnter={() => setActiveHover(product.id)}
              onMouseLeave={() => setActiveHover("")}
            >
              <div className="icon-bx-sm radius bgl-secondary-lt">{product.svg}</div>
              <div className="wraper-effect"></div>
              <div className="icon-content">
                <h4 className="dlab-title m-b15">{product.title}</h4>
                <EllipsisText lineClamp="6">{product.description}</EllipsisText>
              </div>
            </ProductBox>
          </Col>
        ))}
      </Row>

      <Row className="d-flex justify-content-center">
      {products.slice(3,5).map((product: any, i: number) => (
          <Col key={i} lg={4} md={6} className={` ${i<=2 ? 'px-4' : 'px-0'}`}>
            <ProductBox
              onClick={() => navigateTo(`/${product.path}`)}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => setActiveHover(product.id)}
              onMouseLeave={() => setActiveHover("")}
            >
              <div className="icon-bx-sm radius bgl-secondary-lt">{product.svg}</div>
              <div className="wraper-effect"></div>
              <div className="icon-content">
                <h4 className="dlab-title m-b15">{product.title}</h4>
                <EllipsisText lineClamp="6">{product.description}</EllipsisText>
              </div>
            </ProductBox>
          </Col>
        ))}
      </Row>
    </>
  );
};
