import { SubTitle, Title, TitleText } from "../../Styles/StyleComponent";
import blog from "../../Assets/coverImage/blog.png";
import { Fade } from "react-reveal";
import { Card, Col, Container, Row } from "react-bootstrap";
import { CoverImage } from "../helper/CoverImage";
import { strings } from "../../Api/Localization";

import { BlogCards } from "./blogCard";
// import { blogLists } from "./Blog";

import thumbnail1 from '../../Assets/youtube/youtube-1.jpg'
import avatar from "../../Assets/logoFav.png";
import { Calendar, UserIcon } from "../../Assets/SocialMediaIcon";
import { PrimaryText } from "./styled";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { dateConvert } from "../../utils/dateConvert";

export const blogLists = [
  {
    id: 1,
    title: "Tutorial - Class Setup",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/zzM5UODixhk" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "16", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
  {
    id: 2,
    title: "Tutorial- Product Creation",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/Rw-7kT-DZwU" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "15", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
  {
    id: 3,
    title: "Tutorial-Ledger Voucher",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/xFYNzJzb93A" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "14", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
  {
    id: 4,
    title: "Tutorial - Product Rate Change",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/1nMDbcwMxqI" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "14", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
  {
    id: 5,
    title: "Tutorial- Detailed Ledger Creation With Contact Info",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/Gu1Kmg2h1Bc" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "14", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
  {
    id: 6,
    title: "Tutorial-Ledger Creation with basic info",
    embeed : <iframe width="100%" height="250" src="https://www.youtube.com/embed/VD4cpdnrG-Y" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    date: { day: "14", month: "Mar", year: "2023" },
    image: thumbnail1,
    author: { name: "Dynamic Technosoft", avatar: avatar, position: "Admin" },
    category: "erp",
    description: "",
  },
];

export function BlogsPage() {


  let {
    mediaCollection
    } = useContext(GlobalData);
  return (
    <>

      <BackgroundContainer title={'Dynamic blog'} description={'Our Latest Blog'} > 

      <Container className="my-5">
       

        <Row className="blogDetails g-4 py-4">


      <Col xs={12}>
        <Row>
        <Col lg={5}>
          <Fade up delay={200}>
             <Title > <span style={{color:"#127DC2"}}>Tutorials</span></Title>
        </Fade>
        </Col>
        </Row>
      </Col>

          {mediaCollection?.sort((a:any, b:any) => a.orderAt - b.orderAt)?.map((item: any, i: number) => {
            return (
              <Col sm={6} lg={4} key={item.id}>
                <Fade top delay={i * 50}>
                  <Card>
                    <Card.Body>
                    <iframe width="100%" height="250" src={item.video_url} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
                    </Card.Body>
                    <Card.Footer>
                    <Col sm={12} className="">
                      <p className="text-black" style={{fontSize : '18px'}}>{item.title}</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex  py-1">
                  <UserIcon />
                  <PrimaryText fontSize="14px" color="#0d97d6">
                  Dynamic Technosoft
                  </PrimaryText>
                </div>
                <div className="d-flex  py-1">
                  <Calendar />
                  <PrimaryText fontSize="14px" color="#0d97d6">
                    {dateConvert(item.createdAt)}
                  </PrimaryText>
                </div>
              </div>
            </Col>
                    </Card.Footer>
                  </Card>
                </Fade>
              </Col>
            );
          })}
        </Row>
      </Container>

      </BackgroundContainer>
    </>
  );
}
