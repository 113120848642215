import styled from "styled-components";

interface CustomProps {
  readonly lineClamp?: string;
  readonly fontSize?: string;
  readonly fontWeight?: string;
  readonly color?: string;
  readonly textTransform?: string;
  readonly image?: any;
  readonly justifyContent?: string;
  readonly background?: string;
  readonly transform?: string;
  readonly margin?: string;
  readonly alignItems?: string;
  readonly padding?: string;
  readonly minWidth?: string;
  readonly url?: string;
  readonly bottom?: string;
  readonly top?: string;
  readonly left?: string;
  readonly border?: string;
  readonly lineHeight?: string;
  readonly boxShadow?: string;
  readonly backgroundImage?: string;
  readonly transformHeight?: string;
  readonly transformDistance?: string;
  readonly transformFactor?: string;
}

export const DateBox = styled.span`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 4px solid;
  font-weight: bold;
  padding: 5px 10px;
  opacity: 0;
  transform: scale(0.5);
  transition: 300ms ease-in-out;
`;

export const CardBackgroundImage = styled.div`
  height: 100%;
  position: relative;
  transition: 300ms ease-in-out;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const CardHeader = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    ${DateBox} {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
export const Poligon = styled.div`
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
  position: relative;
  overflow: hidden;
`;
export const Layer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Card = styled.article`
  &:hover {
    ${CardBackgroundImage} {
      transform: scale(1.2) rotate(5deg);
    }
  }
`;

export const PrimaryText = styled.h3<CustomProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.fontSize ?? "25px"};
  color: ${(props) => props.color ?? " #74b750"};
  line-height: 28px;
`;

export const SecondaryText = styled.p<CustomProps>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight ?? "400"};
  font-size: ${(props) => props.fontSize ?? "14px"};
  line-height: 18px;
  margin: 0px;
  color: ${(props) => props.color ?? "#000"};
`;
