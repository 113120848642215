import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academicErp from "../../Assets/coverImage/academicErp.png";
import accounts from "../../Assets/products/accounts.png";
import inventary from "../../Assets/products/inventary.png";
import sms from "../../Assets/products/sms.png";

import {
  ContainerTitle,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { Fade } from "react-reveal";
import {
  ArrowBoxFirst,
  ArrowBoxLeft,
  ArrowTipBox,
  BlueWave,
  YellowWave,
} from "./AcademicErp";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { ProductEvenItems, ProductOddItems } from "./ProductHelper";

export const HospitalErp = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);

    const academicProducts = {
      accountManagement: {
        features: [
       


          "streamlined_record_keeping",
          "invoice_automation",
          "expense_management",
          "bBudget_control",
          "robust_financial_reporting",
          "tax_Compliance_Made_Easy",
          "detailed_Audit_Trails"


        ],
      },
      inventoryManagement: {
        features: [
          "product_group_or_product_category",
          "purchase_or_sales_quotation",
          "stock_transfer_or_stock_journal",
          "purchase_anlysis",
          
        ],
      },
      smsManagement: {
        features: [
          "improve_the_level_of_professionalism",
          "scheduling_customized_sms",
          "customer_support"
          
        ],
      }
    }
    const newModules = [
      {
        id: 0,
        icon : accounts,
        title : strings.getString("account_management"),
        para : "Dynamic Hospital ERP brings automation to accounting processes in the hospital sector, offering significant time savings and reducing the risk of calculation errors. This system includes vital accounting functions such as:",
        products: academicProducts.accountManagement
      },
      {
        id: 1,
        icon : inventary,
        title : strings.getString("inventory_management"),
        para : strings.getString("inventory_management_description"),
        products: academicProducts.inventoryManagement
      },
      {
        id: 2,
        icon : sms,
        title : strings.getString("sms_management_hospital"),
        para : strings.getString("sms_management_hospital_description"),
        products: academicProducts.smsManagement
      },

    ]


  return (
    <>
      {/* <CoverImage image={academicErp} path="hospital_erp" type="products" /> */}

      <BackgroundContainer title={allpagesCollection.product_2_title} description={''} > 

      <Container className="my-5 ">
      <Row className="py-4">

      <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Enhancing Healthcare Functions</span></Title>
           <p>With the assistance of our Hospital Enterprise Resource Planning solution, enter a new chapter in healthcare management history.</p>
      </Fade>
      </Col>
      </Row>
    </Col>



          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.product_2_title}</span></Title40>
      </Fade>

          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: allpagesCollection.product_2_description || '' }} ></div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col lg={4} className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2" >
            <img src={allpagesCollection.product_2_image} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
      </Container>

      <Container  className="mb-5">
        <Row>
        {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} style={{height : '30px' , width : '30px'}} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
                <ProductOddItems products={val.products} />
               {/* {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />} */}
              </div>
            </Col>


            </>)
          }) }
        </Row>
      </Container>

      {/* <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={accounts}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("account_management")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("account_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxLeft
                  firstText="receipt_and_payment"
                  secondText="multiple_ledgers"
                />
                <ArrowBoxLeft
                  firstText="trial_balance"
                  secondText="profit_and_loss"
                />
                <ArrowBoxLeft
                  firstText="balance_sheet"
                  secondText="cash_flow_and_fund_flow"
                />
                <ArrowBoxLeft
                  firstText="ratio_analysis"
                  secondText="bank_reconciliation"
                />
                <ArrowBoxLeft
                  firstText="interest_calculation"
                  secondText="cheque_book"
                />
                <ArrowBoxLeft firstText="day_book_and_pending_voucher" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <BlueWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={8} className="order-lg-1 order-2">
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("inventory_management")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("inventory_management_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap position-relative justify-content-md-start justify-content-center">
                <ArrowTipBox
                  color="#F9A51B"
                  text="product_group_or_product_category"
                />
                <ArrowTipBox color="#007EC5" text="go_down" />
                <ArrowTipBox
                  color="#F9A51B"
                  text="purchase_or_sales_quotation"
                />
                <ArrowTipBox color="#007EC5" text="purchase_or_sales_invoice" />
                <ArrowTipBox
                  color="#F9A51B"
                  text="stock_transfer_or_stock_journal"
                />
                <ArrowTipBox color="#007EC5" text="purchase_anlysis" />
              </div>
            </Col>
            <Col lg={4} className="order-lg-2 order-1">
              <div
                className=" d-flex  justify-content-end"
                style={{ height: "300px" }}
              >
                <img
                  src={inventary}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className=" d-flex " style={{ height: "300px" }}>
                <img
                  src={sms}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("sms_management_hospital")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString("sms_management_hospital_description")}
                  </ParagraphText>
                </Fade>
              </div>
              <div className="d-flex flex-wrap " style={{ marginLeft: "25px" }}>
                <ArrowBoxFirst
                  firstText="flexible_system"
                  secondText="improve_the_level_of_professionalism"
                />
                <ArrowBoxLeft
                  firstText="high_roi_and_low_cost"
                  secondText="scheduling_customized_sms"
                />
                <ArrowBoxLeft firstText="customer_support" />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      </BackgroundContainer>
    </>
  );
};
