import development from "../../Assets/coverImage/development.png";
import { BR, ParagraphText } from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";

import sms from "../../Assets/products/sms.png";
import { ServiceContainer } from "./ServiceContainer";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const BulkSmsServices = () => {
    let {
    allpagesCollection
    } = useContext(GlobalData);

  const bulkSms = {
    title: allpagesCollection.service_4_title,
    image: allpagesCollection.service_4_image,
    description: (
      <ParagraphText color="#000">
         <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_4_description }} ></p>

      </ParagraphText>
    ),
    type: "Our Bulk SMS service provides",
    feature_title : "Critical Features of Our Bulk SMS Services for Enhanced Marketing",

    features:
      "Affordability and Efficiency,High Delivery Rates,Comprehensive Analytics,Seamless Integration,Dedicated Customer Support",
  };
  return (
    <>
      {/* <CoverImage image={development} path="bulk_sms_service" type="services" /> */}
      <BackgroundContainer title={''} description={''} > 

      <ServiceContainer services={bulkSms} title={'Bulk SMS Service'} subdescription={`Use our Bulk SMS Service to rapidly reach your target audience. For transactional updates, notifications, or promotional messages, we offer a dependable and affordable way to keep your customers informed and involved.`} />
      </BackgroundContainer>
    </>
  );
};
