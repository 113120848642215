export const SkyBlueWave = () => (
  <svg
    id="Group_1977"
    data-name="Group 1977"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 412.547 386"
    style={{
      position: "absolute",
      right: "-2rem",
      top: "0px",
    }}
  >
    <path
      id="Path_4319"
      data-name="Path 4319"
      d="M783.117,3998.175V3629.627H387.034s-30.756,48.745-8.2,112.663,80.977,61.5,80.977,61.5,134.592,6.18,121.432,112.711,150.4,99.012,150.4,99.012h51.473Z"
      transform="translate(-370.569 -3629.627)"
      fill="#007ec5"
      opacity="0.28"
    />
    <path
      id="Path_4318"
      data-name="Path 4318"
      d="M767.911,3984.592V3629.627H386.427s-29.622,46.948-7.894,108.51,77.992,59.233,77.992,59.233,129.631,5.952,116.957,108.556,144.854,95.362,144.854,95.362h49.576Z"
      transform="translate(-355.364 -3629.627)"
      fill="#007ec5"
      opacity="0.39"
    />
  </svg>
);

export const YellowWave = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 406.85 410"
    style={{
      position: "absolute",
      left: "-4rem",
      top: "0px",
    }}
  >
    <g
      id="Group_2071"
      data-name="Group 2071"
      transform="translate(19.285 -1112)"
    >
      <path
        id="Path_4320"
        data-name="Path 4320"
        d="M125.34,3628.517H523.832s31.219,83.61-145.807,111.12c-42.017,6.529-68.579,41.2-87.026,105.563-7.769,27.106-1.585,80.744-39.635,128.812s-118.584,64.505-118.584,64.505Z"
        transform="translate(-138.389 -2516.517)"
        fill="#f9a51b"
        opacity="0.15"
      />
      <path
        id="Path_4321"
        data-name="Path 4321"
        d="M135.183,3628.517H525.555s31.219,83.61-145.807,111.12c-42.017,6.529-68.578,41.2-87.026,105.563-7.769,27.106-1.585,80.744-39.635,128.812S134.5,4038.517,134.5,4038.517Z"
        transform="translate(-153.788 -2516.517)"
        fill="#f9a51b"
        opacity="0.12"
      />
    </g>
  </svg>
);

export const LeftArrow = ({ color }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.873"
    height="24.946"
    viewBox="0 0 12.873 24.946"
    style={{
      position: "absolute",
      left: "0px",
      bottom: "-15.5px",
      zIndex: "-1",
    }}
  >
    <path
      id="Path_4438"
      data-name="Path 4438"
      d="M-525.852,297.923l12.873,15.359V288.335Z"
      transform="translate(525.852 -288.335)"
      fill={color}
    />
  </svg>
);

export const CircleIcon = ({ color }: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute", bottom: "0px", left: "5px" }}
  >
    <circle cx="10" cy="10" r="10" fill="#9D9D9C" />
    <circle cx="10" cy="10" r="6" fill={color} />
  </svg>
);
