import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academicErp from "../../Assets/coverImage/academicErp.png";
import { Fade } from "react-reveal";
import accounts from "../../Assets/products/accounts.png";
import {
  ContainerTitle,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";

import { ArrowBoxLeft, YellowWave } from "./AcademicErp";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";
import { ProductOddItems } from "./ProductHelper";

export const AccountingSolution = () => {
  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);

    const academicProducts = {
      account: {
        features: [
          "multiple_ledgers",
          "bank_reconciliation",
          "payment_and_receipt",
          "cost_center",
          "profit_and_loss",
          "cash_flow_and_fund_flow",
          "balance_sheet",
          "journal_and_contra",
          "day_book",
          "cash_or_bank_books"
        ],
      }
    }
    const newModules = [
      {
        id: 0,
        icon : accounts,
        title : strings.getString("account_management"),
        para : strings.getString("account_management_description"),
        products: academicProducts.account
      }

    ]


  return (
    <>
      {/* <CoverImage
        image={academicErp}
        path="accounting_solution"
        type="products"
      /> */}

<BackgroundContainer title={allpagesCollection.product_3_title || 'Accounting Solution'} description={''} > 

      <Container className="my-5 ">
      <Row className="py-4">

      <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Accuracy in Money Management </span></Title>
           <p>Use our cutting-edge accounting solution to gain control and transparency over your finances.</p>
      </Fade>
      </Col>
      </Row>
    </Col>

 
          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.product_3_title}</span></Title40>
      </Fade>
          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: allpagesCollection.product_3_description || '' }} ></div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col
            lg={4}
            className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2"
          >
            <img src={allpagesCollection.product_3_image} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
        {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} style={{height : '30px' , width : '30px'}} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
                <ProductOddItems products={val.products} />
               {/* {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />} */}
              </div>
            </Col>


            </>)
          }) }
        </Row>
      </Container>

      {/* <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={accounts}
                  alt=""
                  width="340px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("account_management")}
                </ContainerTitle>
                <Fade up delay={0}>
                  <ParagraphText color="#000">
                    {strings.getString(
                      "account_management_accounting_description"
                    )}
                  </ParagraphText>
                </Fade>
              </div>
              <div
                className="d-flex flex-wrap  "
                style={{ marginLeft: "25px" }}
              >
                <ArrowBoxLeft
                  firstText="multiple_ledgers"
                  secondText="bank_reconciliation"
                />
                <ArrowBoxLeft
                  firstText="payment_and_receipt"
                  secondText="cost_center"
                />
                <ArrowBoxLeft
                  firstText="profit_and_loss"
                  secondText="cash_flow_and_fund_flow"
                />
                <ArrowBoxLeft
                  firstText="balance_sheet"
                  secondText="journal_and_contra"
                />
                <ArrowBoxLeft
                  firstText="day_book"
                  secondText="cash_or_bank_books"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      </BackgroundContainer>
    </>
  );
};
