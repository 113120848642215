import SliderImg1 from '../Assets/new/slider1.png'
export const WaveVector = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="363.1"
    viewBox="0 0 1366 363.1"
  >
    <path
      id="Path_3537"
      data-name="Path 3537"
      d="M-4281,2040.962V1830.615s129.278-98.043,429.6,74.87S-3438.87,2023.3-3438.87,2023.3s355.149-263.99,427.7-256.86S-2915,1834.18-2915,1834.18V2129.4H-4281Z"
      transform="translate(4281 -1766.3)"
      fill="#f9a51b"
      opacity="0.25"
    />
  </svg>
);

export const ManWithMail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="294"
    viewBox="0 0 351.905 294"
  >
    <g
      id="Group_2402"
      data-name="Group 2402"
      transform="translate(-923.979 -6708.81)"
    >
      <g
        id="Group_2184"
        data-name="Group 2184"
        transform="translate(923.979 6752.523)"
      >
        <path
          id="Path_4461"
          data-name="Path 4461"
          d="M-381.646,216.366c-17.758-13.491-48.128-46.969-8.134-99.523,49.458-64.99-9.773-91.653-66.546-88.87C-502,30.212-553.405-27.21-604.683-16.947-672.744-3.325-628.552,55.286-674.7,86.863c-39.356,26.93-22.459,108.985,28.762,129.5Z"
          transform="translate(699.106 20.604)"
          fill="#dcedf9"
        />
        <path
          id="Path_4462"
          data-name="Path 4462"
          d="M-258.978,126.645c-.274-9.637-9.252-13.375-15.244-6.4a30.072,30.072,0,0,0-7.328,17.422c-1.372,17.182,13.66,12.2,19.277,3A26.279,26.279,0,0,0-258.978,126.645Z"
          transform="translate(608.488 -8.853)"
          fill="#dcedf9"
        />
        <path
          id="Path_4463"
          data-name="Path 4463"
          d="M-467.574-21.027c-9.473-1.793-15.043,6.178-9.51,13.523A30.087,30.087,0,0,0-461.632,3.379c16.492,5.013,14.842-10.736,7.056-18.19A26.275,26.275,0,0,0-467.574-21.027Z"
          transform="translate(651.669 21.283)"
          fill="#dcedf9"
        />
        <path
          id="Path_4464"
          data-name="Path 4464"
          d="M-672.335,88.322c3.79-8.866-2.794-16.022-11.158-12.2a30.077,30.077,0,0,0-13.955,12.746c-8.447,15.025,7.288,16.805,16.243,10.807A26.278,26.278,0,0,0-672.335,88.322Z"
          transform="translate(699.862 0.232)"
          fill="#dcedf9"
        />
      </g>
      <ellipse
        id="Ellipse_268"
        data-name="Ellipse 268"
        cx="162.369"
        cy="10.689"
        rx="162.369"
        ry="10.689"
        transform="translate(940.091 6981.433)"
        fill="#dcedf9"
      />
      <g
        id="Group_2195"
        data-name="Group 2195"
        transform="translate(932.725 6760.085)"
      >
        <g
          id="Group_2194"
          data-name="Group 2194"
          transform="translate(6.505 8.869)"
        >
          <g
            id="Group_2191"
            data-name="Group 2191"
            transform="translate(16.943 0)"
          >
            <path
              id="Path_4469"
              data-name="Path 4469"
              d="M-655.588,21.688c5.344,13-5.213,9.506-1.375,19.242,3.5,8.874,15.119,6.511,8.078,21.677-1.54,3.318-.13,6.845,2.778,9.564a14.22,14.22,0,0,0,16.176,2.389q.612-.294,1.2-.65a14.48,14.48,0,0,0,7.077-12.4c.052-4.5-1.124-9.448-5.381-13-9.989-8.334,2.721-20.867-6.7-25.582-11.355-5.687-9.983-10.15-15.867-19.4C-655.836-6.272-662.831,4.068-655.588,21.688Z"
              transform="translate(658.668 0.259)"
              fill="#2e3052"
            />
            <g
              id="Group_2190"
              data-name="Group 2190"
              transform="translate(9.239 17.744)"
            >
              <path
                id="Path_4470"
                data-name="Path 4470"
                d="M-646.8,23.355c.146.371,12.258,31.185,19.451,56.165.408-.2.808-.413,1.2-.65-7.229-25.009-19.264-55.632-19.411-56a.664.664,0,0,0-.863-.374A.664.664,0,0,0-646.8,23.355Z"
                transform="translate(646.846 -22.446)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_2400"
            data-name="Group 2400"
            transform="translate(0 19.545)"
          >
            <path
              id="Path_4469-2"
              data-name="Path 4469"
              d="M-655.588,21.688c5.344,13-5.213,9.506-1.375,19.242,3.5,8.874,15.119,6.511,8.078,21.677-1.54,3.318-.13,6.845,2.778,9.564a14.22,14.22,0,0,0,16.176,2.389q.612-.294,1.2-.65a14.48,14.48,0,0,0,7.077-12.4c.052-4.5-1.124-9.448-5.381-13-9.989-8.334,2.721-20.867-6.7-25.582-11.355-5.687-9.983-10.15-15.867-19.4C-655.836-6.272-662.831,4.068-655.588,21.688Z"
              transform="translate(658.668 0.259)"
              fill="#007ec5"
            />
            <g
              id="Group_2190-2"
              data-name="Group 2190"
              transform="translate(9.239 17.744)"
            >
              <path
                id="Path_4470-2"
                data-name="Path 4470"
                d="M-646.8,23.355c.146.371,12.258,31.185,19.451,56.165.408-.2.808-.413,1.2-.65-7.229-25.009-19.264-55.632-19.411-56a.664.664,0,0,0-.863-.374A.664.664,0,0,0-646.8,23.355Z"
                transform="translate(646.846 -22.446)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_2401"
          data-name="Group 2401"
          transform="matrix(0.891, 0.454, -0.454, 0.891, 303.958, 111.043)"
        >
          <g
            id="Group_2191-2"
            data-name="Group 2191"
            transform="translate(0 0)"
          >
            <path
              id="Path_4469-3"
              data-name="Path 4469"
              d="M33.933,21.947c-5.344,13,5.213,9.506,1.375,19.242-3.5,8.874-15.118,6.511-8.078,21.677,1.54,3.318.13,6.845-2.778,9.564A14.22,14.22,0,0,1,8.276,74.819c-.408-.2-.808-.413-1.2-.65A14.48,14.48,0,0,1,0,61.766c-.052-4.5,1.124-9.448,5.382-13,9.989-8.334-2.721-20.867,6.7-25.582,11.355-5.687,9.983-10.15,15.867-19.4C34.181-6.013,41.176,4.327,33.933,21.947Z"
              fill="#2e3052"
            />
            <g
              id="Group_2190-3"
              data-name="Group 2190"
              transform="translate(7.079 17.744)"
            >
              <path
                id="Path_4470-3"
                data-name="Path 4470"
                d="M20.648.909C20.5,1.28,8.39,32.094,1.2,57.074c-.408-.2-.808-.413-1.2-.65C7.229,31.415,19.264.792,19.411.42a.665.665,0,0,1,1.237.488Z"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_2400-2"
            data-name="Group 2400"
            transform="translate(16.943 19.545)"
          >
            <path
              id="Path_4469-4"
              data-name="Path 4469"
              d="M33.933,21.947c-5.344,13,5.213,9.506,1.375,19.242-3.5,8.874-15.118,6.511-8.078,21.677,1.54,3.318.13,6.845-2.778,9.564A14.22,14.22,0,0,1,8.276,74.819c-.408-.2-.808-.413-1.2-.65A14.48,14.48,0,0,1,0,61.766c-.052-4.5,1.124-9.448,5.382-13,9.989-8.334-2.721-20.867,6.7-25.582,11.355-5.687,9.983-10.15,15.867-19.4C34.181-6.013,41.176,4.327,33.933,21.947Z"
              fill="#007ec5"
            />
            <g
              id="Group_2190-4"
              data-name="Group 2190"
              transform="translate(7.079 17.744)"
            >
              <path
                id="Path_4470-4"
                data-name="Path 4470"
                d="M20.648.909C20.5,1.28,8.39,32.094,1.2,57.074c-.408-.2-.808-.413-1.2-.65C7.229,31.415,19.264.792,19.411.42a.665.665,0,0,1,1.237.488Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
        <path
          id="Path_4473"
          data-name="Path 4473"
          d="M-578.864,40.869c4.541-3.109,15.912,4.56,21.858,13.872l-5.274-14.777a32.363,32.363,0,0,0-10.475-5.831c-8.122-2.878-16.264-7.388-11.715-10.5,4.262-2.919,14.546,3.662,20.708,12.184l-6.773-18.977a21.358,21.358,0,0,0-5.919-3.118c-5.119-1.814-10.25-4.656-7.383-6.619,2.488-1.7,8.229,1.727,12.149,6.509l-2.2-6.151A30.977,30.977,0,0,0-578.568.788c-5.214-5.4-9.158-11.465-5.135-12.345s10.614,9.693,11.651,19.246l1.129,3.165c-.877-6.948,1.621-15.36,5.1-15.659,3.461-.3,1.29,5.153-1.523,9.8a19.407,19.407,0,0,0-2.891,7.795l5.336,14.949c-1.358-11.008,2.6-24.281,8.111-24.752,5.493-.47,2.048,8.175-2.416,15.548a30.867,30.867,0,0,0-4.583,12.32l6.4,17.926C-558.52,37.9-554.628,24.7-549.2,24.659c4.184-.033,2.9,5.874-2.094,14.213a35.452,35.452,0,0,0-4.83,13.435l3.014,8.444a2.779,2.779,0,0,1-1.551.554l-1.2-3.361a30.991,30.991,0,0,0-11.287-6.573C-575.271,48.493-583.413,43.984-578.864,40.869Z"
          transform="translate(666.191 11.608)"
          fill="#f88800"
        />
        <path
          id="Path_4474"
          data-name="Path 4474"
          d="M-641.294,208.453c-4.658,2.93-2.141,16.413,4.04,25.571l-11.463-10.715a32.373,32.373,0,0,1-1.186-11.929c.587-8.6-.315-17.861-4.981-14.925-4.373,2.751-2.422,14.8,2.948,23.845l-14.72-13.759a21.328,21.328,0,0,1-.509-6.67c.37-5.418-.2-11.256-3.139-9.406-2.552,1.606-1.687,8.238,1.143,13.736l-4.771-4.46a30.989,30.989,0,0,1-4.258-6.948c-2.878-6.931-6.88-12.961-9.286-9.62s4.675,13.593,13.028,18.341l2.456,2.3c-6.027-3.567-14.739-4.62-16.4-1.541-1.648,3.059,4.215,3.232,9.6,2.5a19.381,19.381,0,0,1,8.3.445l11.6,10.839c-9.561-5.621-23.313-7.268-25.937-2.4-2.615,4.854,6.688,5.129,15.226,3.965a30.868,30.868,0,0,1,13.127.692l13.9,13c-9.529-5.365-23.189-7.043-25.385-2.08-1.694,3.826,4.236,5,13.874,3.729a35.446,35.446,0,0,1,14.248.91l6.55,6.122a2.779,2.779,0,0,0,1.125-1.2l-2.606-2.437a30.976,30.976,0,0,1-1.545-12.969C-635.725,214.78-636.627,205.518-641.294,208.453Z"
          transform="translate(688.671 -30.741)"
          fill="#007ec5"
        />
      </g>
      <g
        id="Group_2199"
        data-name="Group 2199"
        transform="translate(966.567 6816.984)"
      >
        <rect
          id="Rectangle_6534"
          data-name="Rectangle 6534"
          width="248.596"
          height="171.722"
          transform="translate(0.007 0.001)"
          fill="#f9a51b"
        />
        <path
          id="Path_4475"
          data-name="Path 4475"
          d="M-396.771,61.208V72.217l-124.29,110.162-124.3-110.162V61.208l124.29,110.54Z"
          transform="translate(645.367 -61.2)"
          fill="#f88800"
        />
        <g
          id="Group_2196"
          data-name="Group 2196"
          transform="translate(0.007 82.854)"
        >
          <path
            id="Path_4476"
            data-name="Path 4476"
            d="M-545.011,173.231l-93.149,82.853h-7.2v-4.378l94.994-84.492Z"
            transform="translate(645.36 -167.214)"
            fill="#f88800"
          />
        </g>
        <g
          id="Group_2198"
          data-name="Group 2198"
          transform="translate(147.603 82.854)"
        >
          <g id="Group_2197" data-name="Group 2197">
            <path
              id="Path_4477"
              data-name="Path 4477"
              d="M-355.5,252.287v3.8h-7.852L-456.5,173.231l5.355-6.017Z"
              transform="translate(456.502 -167.214)"
              fill="#f88800"
            />
          </g>
        </g>
        <path
          id="Path_4478"
          data-name="Path 4478"
          d="M-645.369,61.2l124.3,110.548L-396.772,61.2Z"
          transform="translate(645.369 -61.198)"
          fill="#fdcc50"
        />
      </g>
      <g
        id="Group_2262"
        data-name="Group 2262"
        transform="translate(1052.225 6708.81)"
      >
        <g
          id="Group_2213"
          data-name="Group 2213"
          transform="translate(41.704 63.196)"
        >
          <g
            id="Group_2206"
            data-name="Group 2206"
            transform="translate(46.535 29.513)"
          >
            <path
              id="Path_4479"
              data-name="Path 4479"
              d="M-416.736,41.41l3.172,6.788-6.706.1-2.587-6.177Z"
              transform="translate(422.856 -41.41)"
              fill="#f9af8b"
            />
            <g
              id="Group_2205"
              data-name="Group 2205"
              transform="translate(0.559 4.59)"
            >
              <g id="Group_2204" data-name="Group 2204">
                <path
                  id="Path_4480"
                  data-name="Path 4480"
                  d="M-415.518,49.056s1.377-1.851,2.471-1.771,9.531,6.787,12.422,7.342,2.624,3.5,1.413,3.5c-3.484,0-22.711.032-22.711.032s-.581-6.877.155-8.929S-417.779,49.542-415.518,49.056Z"
                  transform="translate(422.141 -47.283)"
                  fill="#008eff"
                />
                <g
                  id="Group_2203"
                  data-name="Group 2203"
                  transform="translate(9.558 2.198)"
                >
                  <g
                    id="Group_2200"
                    data-name="Group 2200"
                    transform="translate(1.207 1.141)"
                  >
                    <path
                      id="Path_4481"
                      data-name="Path 4481"
                      d="M-407.845,54.671a.4.4,0,0,1-.317-.036.406.406,0,0,1-.152-.553,6.232,6.232,0,0,1,2.109-2.2,2.791,2.791,0,0,1,2.865.134.4.4,0,0,1,.084.567.4.4,0,0,1-.567.084,1.99,1.99,0,0,0-2.018-.061,5.516,5.516,0,0,0-1.769,1.876A.4.4,0,0,1-407.845,54.671Z"
                      transform="translate(408.366 -51.555)"
                      fill="#fff"
                    />
                  </g>
                  <g id="Group_2201" data-name="Group 2201">
                    <path
                      id="Path_4482"
                      data-name="Path 4482"
                      d="M-409.39,53.212a.4.4,0,0,1-.317-.036.406.406,0,0,1-.152-.553,6.247,6.247,0,0,1,2.109-2.2,2.791,2.791,0,0,1,2.866.133.405.405,0,0,1,.083.567.4.4,0,0,1-.567.084,1.991,1.991,0,0,0-2.018-.06,5.481,5.481,0,0,0-1.769,1.875A.405.405,0,0,1-409.39,53.212Z"
                      transform="translate(409.91 -50.095)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_2202"
                    data-name="Group 2202"
                    transform="translate(2.843 2.066)"
                  >
                    <path
                      id="Path_4483"
                      data-name="Path 4483"
                      d="M-405.752,55.855a.4.4,0,0,1-.317-.036.406.406,0,0,1-.152-.553,6.239,6.239,0,0,1,2.109-2.2,2.788,2.788,0,0,1,2.866.134.4.4,0,0,1,.083.566.4.4,0,0,1-.566.084,2,2,0,0,0-2.019-.06,5.481,5.481,0,0,0-1.769,1.875A.4.4,0,0,1-405.752,55.855Z"
                      transform="translate(406.272 -52.738)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_2212" data-name="Group 2212" transform="translate(0 0)">
            <g id="Group_2210" data-name="Group 2210">
              <g id="Group_2209" data-name="Group 2209">
                <g id="Group_2208" data-name="Group 2208">
                  <g id="Group_2207" data-name="Group 2207">
                    <path
                      id="Path_4484"
                      data-name="Path 4484"
                      d="M-427.835,35.372l-2.971.969-1.651.1-2.838.166c-3.751-2.967-13.867-11.892-15.838-17.156,0,0-13.018,30.271-31.015,30.271-1.168,0,1.918-15.062,4.852-18.195,0,0,16.588-30.916,27.342-27.635C-445.137,5.361-440.265,12.372-427.835,35.372Z"
                      transform="translate(482.401 -3.646)"
                      fill="#2e3052"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Group_2211"
              data-name="Group 2211"
              transform="translate(6.107 11.547)"
            >
              <path
                id="Path_4485"
                data-name="Path 4485"
                d="M-429.1,39.569l-1.651.1c-11.04-8.359-16.154-15.438-18.509-19.973a.345.345,0,0,0-.3-.206.181.181,0,0,0-.168.134c-5.287,12.443-11.631,19.912-16.022,23.989a31.677,31.677,0,0,1-8.432,5.966l-.2-.494-.2-.492a31.308,31.308,0,0,0,8.163-5.809c4.3-4,10.518-11.34,15.715-23.578a1.237,1.237,0,0,1,1.108-.782,1.405,1.405,0,0,1,1.29.779C-445.938,23.775-440.679,31-429.1,39.569Z"
                transform="translate(474.587 -18.422)"
                fill="#001030"
              />
            </g>
            <path
              id="Path_4486"
              data-name="Path 4486"
              d="M-425.935,42.574a9.6,9.6,0,0,0,2.06,2.735s6.357-.294,9.449-1.847l-1.387-3.232A47.642,47.642,0,0,1-425.935,42.574Z"
              transform="translate(470.064 -11.639)"
              fill="#524e84"
            />
          </g>
        </g>
        <g
          id="Group_2227"
          data-name="Group 2227"
          transform="translate(45.98 33.139)"
        >
          <g id="Group_2214" data-name="Group 2214" transform="translate(0 0)">
            <path
              id="Path_4487"
              data-name="Path 4487"
              d="M-428.756-17.217a88.251,88.251,0,0,1-22.414,6.3.026.026,0,0,0-.013,0c-.079,0-1.526.12-2.083.1a16.182,16.182,0,0,1-6.171-1.493,29.313,29.313,0,0,1-6.347-5.037c-2.995-2.889-6.152-6.356-8.336-8.833-1.7-1.924-2.81-3.251-2.81-3.251,1.508-2.243,4.846-4.283,7.752-5.374,0,0,6.063,10.1,15.249,15.054q.75.409,1.528.758c2.093-.339,7.756-.6,10.211-.772A98.758,98.758,0,0,0-430.1-21.4Z"
              transform="translate(476.93 34.814)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2226"
            data-name="Group 2226"
            transform="translate(44.304 5.055)"
          >
            <g id="Group_2225" data-name="Group 2225">
              <g
                id="Group_2215"
                data-name="Group 2215"
                transform="translate(2.294 0.615)"
              >
                <path
                  id="Path_4488"
                  data-name="Path 4488"
                  d="M-417.3-20.15s1.259-3.18,2.971-4.087,6.586-4.1,7.626-3.146.825,5.547-3.543,9.384a8.734,8.734,0,0,1-5.588,2.341Z"
                  transform="translate(417.305 27.56)"
                  fill="#f9af8b"
                />
              </g>
              <g id="Group_2222" data-name="Group 2222">
                <g
                  id="Group_2216"
                  data-name="Group 2216"
                  transform="translate(0.017 2.45)"
                >
                  <path
                    id="Path_4489"
                    data-name="Path 4489"
                    d="M-407.032-23.95l-7.194,6.721a1.045,1.045,0,0,1-1.092.166l-4.409-2.132a.5.5,0,0,1-.265-.271L-420-19.5l-.216-.737,1.17-.443,11.319-4.3.614-.231.23.779.017.053a.022.022,0,0,1,0,.02A.447.447,0,0,1-407.032-23.95Z"
                    transform="translate(420.219 25.212)"
                    fill="#001030"
                  />
                </g>
                <g
                  id="Group_2217"
                  data-name="Group 2217"
                  transform="translate(1.187 0.748)"
                >
                  <path
                    id="Path_4490"
                    data-name="Path 4490"
                    d="M-407.4-25.456l-11.319,4.3,6.4-5.982a1.05,1.05,0,0,1,1.09-.166Z"
                    transform="translate(418.722 27.39)"
                    fill="#1f2232"
                  />
                </g>
                <g id="Group_2219" data-name="Group 2219">
                  <g id="Group_2218" data-name="Group 2218">
                    <path
                      id="Path_4491"
                      data-name="Path 4491"
                      d="M-415.542-18.5l-4.411-2.133a.427.427,0,0,1-.114-.748l7.195-6.72a1.049,1.049,0,0,1,1.09-.166l4.411,2.134a.427.427,0,0,1,.114.747l-7.195,6.721A1.051,1.051,0,0,1-415.542-18.5Z"
                      transform="translate(420.241 28.346)"
                      fill="#2e3052"
                    />
                  </g>
                </g>
                <g
                  id="Group_2220"
                  data-name="Group 2220"
                  transform="translate(1.529 0.77)"
                  style={{ mixBlendMode: "screen", isolation: "isolate" }}
                >
                  <path
                    id="Path_4492"
                    data-name="Path 4492"
                    d="M-414.58-19.484-418-21.137a.427.427,0,0,1-.114-.747l5.6-5.227a1.048,1.048,0,0,1,1.09-.165l3.418,1.652a.427.427,0,0,1,.113.747l-5.595,5.228A1.051,1.051,0,0,1-414.58-19.484Z"
                    transform="translate(418.284 27.361)"
                    fill="#2e3052"
                  />
                </g>
                <g
                  id="Group_2221"
                  data-name="Group 2221"
                  transform="translate(13.366 3.282)"
                >
                  <path
                    id="Path_4493"
                    data-name="Path 4493"
                    d="M-403.133-24.129l0,0a.022.022,0,0,0,0-.02Z"
                    transform="translate(403.138 24.147)"
                    fill="#1f2232"
                  />
                </g>
              </g>
              <g
                id="Group_2223"
                data-name="Group 2223"
                transform="translate(3.44 2.306)"
              >
                <path
                  id="Path_4494"
                  data-name="Path 4494"
                  d="M-414.137-25.372s1.025.159,1.129,1.041-.849.531-1.461.337a1.5,1.5,0,0,0-1.25.1C-415.987-23.579-415.941-25.633-414.137-25.372Z"
                  transform="translate(415.839 25.395)"
                  fill="#f9af8b"
                />
              </g>
              <g
                id="Group_2224"
                data-name="Group 2224"
                transform="translate(9.723 2.704)"
              >
                <path
                  id="Path_4495"
                  data-name="Path 4495"
                  d="M-407.8-20.477a40.493,40.493,0,0,1,2.841-4.087c.821-.961,1.24.434.678,1.406S-405.95-20.2-407.8-20.477Z"
                  transform="translate(407.799 24.887)"
                  fill="#f9af8b"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2234"
          data-name="Group 2234"
          transform="translate(21.313 30.599)"
        >
          <g id="Group_2230" data-name="Group 2230">
            <g id="Group_2229" data-name="Group 2229">
              <g id="Group_2228" data-name="Group 2228">
                <path
                  id="Path_4496"
                  data-name="Path 4496"
                  d="M-504.717,24.29l-3.564-22.974-.212-1.361.025-1.026.794-32.547,9.03-3.052.484-.164,2.028-.687,1.607-.542,9.534,1.312,1.552.213.547.075,6.821.939c.063,21.576-2.008,26.237,1.979,43.789C-472.085,14.885-479.449,38.33-504.717,24.29Z"
                  transform="translate(508.493 38.064)"
                  fill="#008eff"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_2231"
            data-name="Group 2231"
            transform="translate(8.401 37.46)"
          >
            <path
              id="Path_4497"
              data-name="Path 4497"
              d="M-497.293,28.885h0c7.14-1.994,12.349-6.921,15.461-10.7a40.665,40.665,0,0,0,5.093-7.807.357.357,0,0,0-.175-.473.356.356,0,0,0-.473.174,39.878,39.878,0,0,1-5.009,7.667c-3.046,3.7-8.135,8.514-15.089,10.455a.356.356,0,0,0-.248.439A.357.357,0,0,0-497.293,28.885Z"
              transform="translate(497.744 -9.868)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2232"
            data-name="Group 2232"
            transform="translate(14.982 49.078)"
          >
            <path
              id="Path_4498"
              data-name="Path 4498"
              d="M-487.336,32.516c6.2-1.7,11.418-6.935,11.655-7.175a.357.357,0,0,0,0-.5.357.357,0,0,0-.5,0c-.062.062-6.2,6.216-12.836,7.32a.354.354,0,0,0-.293.41.357.357,0,0,0,.41.294A15.71,15.71,0,0,0-487.336,32.516Z"
              transform="translate(489.323 -24.734)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2233"
            data-name="Group 2233"
            transform="translate(10.591 3.156)"
          >
            <path
              id="Path_4499"
              data-name="Path 4499"
              d="M-492.954-26.215c6.2-1.719,11.4-6.964,11.637-7.2a.356.356,0,0,0,0-.5.357.357,0,0,0-.5,0c-.061.062-6.183,6.232-12.817,7.352a.354.354,0,0,0-.292.411.357.357,0,0,0,.411.293A15.818,15.818,0,0,0-492.954-26.215Z"
              transform="translate(494.941 34.026)"
              fill="#0077eb"
            />
          </g>
        </g>
        <g
          id="Group_2248"
          data-name="Group 2248"
          transform="translate(23.571 92.289)"
        >
          <g
            id="Group_2241"
            data-name="Group 2241"
            transform="translate(59.115 60.124)"
          >
            <path
              id="Path_4500"
              data-name="Path 4500"
              d="M-424.363,117.805l2.5,5.557-5.119,2.2-2.977-5.74Z"
              transform="translate(429.963 -117.805)"
              fill="#f9af8b"
            />
            <g
              id="Group_2240"
              data-name="Group 2240"
              transform="translate(1.314 2.795)"
            >
              <g id="Group_2239" data-name="Group 2239">
                <path
                  id="Path_4501"
                  data-name="Path 4501"
                  d="M-422.617,124.026s.524-2.246,1.558-2.611,11.45,2.416,14.321,1.77,3.207,1.529,3.156,2.738-21.306,8.834-21.306,8.834-3.246-5.9-3.39-8.075S-424.5,125.373-422.617,124.026Z"
                  transform="translate(428.281 -121.382)"
                  fill="#008eff"
                />
                <g
                  id="Group_2238"
                  data-name="Group 2238"
                  transform="translate(9.581 0.234)"
                >
                  <g
                    id="Group_2235"
                    data-name="Group 2235"
                    transform="translate(1.562 0.564)"
                  >
                    <path
                      id="Path_4502"
                      data-name="Path 4502"
                      d="M-413.355,126.678a.4.4,0,0,1-.3.093.4.4,0,0,1-.36-.445,6.224,6.224,0,0,1,1.056-2.856,2.79,2.79,0,0,1,2.68-1.022.4.4,0,0,1,.3.486.4.4,0,0,1-.486.3,1.99,1.99,0,0,0-1.874.751,5.5,5.5,0,0,0-.873,2.425A.4.4,0,0,1-413.355,126.678Z"
                      transform="translate(414.022 -122.403)"
                      fill="#fff"
                    />
                  </g>
                  <g id="Group_2236" data-name="Group 2236">
                    <path
                      id="Path_4503"
                      data-name="Path 4503"
                      d="M-415.354,125.957a.407.407,0,0,1-.3.094.406.406,0,0,1-.359-.446,6.249,6.249,0,0,1,1.056-2.856,2.789,2.789,0,0,1,2.68-1.021.4.4,0,0,1,.3.486.405.405,0,0,1-.486.3,1.99,1.99,0,0,0-1.874.75,5.486,5.486,0,0,0-.873,2.426A.4.4,0,0,1-415.354,125.957Z"
                      transform="translate(416.021 -121.682)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_2237"
                    data-name="Group 2237"
                    transform="translate(3.432 0.758)"
                  >
                    <path
                      id="Path_4504"
                      data-name="Path 4504"
                      d="M-410.963,126.928a.4.4,0,0,1-.3.093.406.406,0,0,1-.36-.445,6.249,6.249,0,0,1,1.056-2.856,2.788,2.788,0,0,1,2.68-1.022.4.4,0,0,1,.3.486.4.4,0,0,1-.486.3,1.992,1.992,0,0,0-1.874.751,5.476,5.476,0,0,0-.873,2.425A.406.406,0,0,1-410.963,126.928Z"
                      transform="translate(411.63 -122.652)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_2247" data-name="Group 2247" transform="translate(0 0)">
            <g id="Group_2246" data-name="Group 2246">
              <g id="Group_2244" data-name="Group 2244">
                <g id="Group_2243" data-name="Group 2243">
                  <g id="Group_2242" data-name="Group 2242">
                    <path
                      id="Path_4505"
                      data-name="Path 4505"
                      d="M-439.6,102.075a14.611,14.611,0,0,1-3.955,2.316c-.324.147-.656.288-.984.424-.58.239-1.155.456-1.7.642,0,0-12.143-22.383-16.8-38.613,0,0-41.1-5.076-42.527-19.472a7.785,7.785,0,0,1-.006-1.479,10.56,10.56,0,0,1,1.485-4.355c.938-1.615,15.006-.04,22.606,2.093,0,0,20.606,8.027,28.223,16.3C-449.4,64.111-439.6,102.075-439.6,102.075Z"
                      transform="translate(505.604 -40.873)"
                      fill="#2e3052"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group_2245"
                data-name="Group 2245"
                transform="translate(0 5.02)"
              >
                <path
                  id="Path_4506"
                  data-name="Path 4506"
                  d="M-443.555,105.794c-.324.147-.656.288-.984.424-6.141-10.164-14.669-33.91-16.57-39.61a1.126,1.126,0,0,0-.863-.75c-19.97-3.754-31.476-8.992-37.613-12.725a39.9,39.9,0,0,1-5.982-4.357,7.785,7.785,0,0,1-.006-1.479,36.449,36.449,0,0,0,6.663,5c6.058,3.663,17.417,8.808,37.136,12.516a2.2,2.2,0,0,1,1.676,1.46C-458.2,71.976-449.633,95.828-443.555,105.794Z"
                  transform="translate(505.604 -47.296)"
                  fill="#001030"
                />
              </g>
            </g>
            <path
              id="Path_4507"
              data-name="Path 4507"
              d="M-432.443,119.446l1.938,3.1a16.8,16.8,0,0,0,8.1-4.137l-1.207-2.77A87.273,87.273,0,0,1-432.443,119.446Z"
              transform="translate(489.62 -57.208)"
              fill="#524e84"
            />
          </g>
        </g>
        <g
          id="Group_2249"
          data-name="Group 2249"
          transform="translate(0 35.045)"
        >
          <path
            id="Path_4508"
            data-name="Path 4508"
            d="M-520.436,52.4s2.8,3.793,2.566,5.755-2.764,5.661-4.788,5.84,2.327-4.752,1.3-4.594c-1.243.193-9.632,7.076-11.4,7.076s2.084-3.2,3.584-4.008c0,0-3.969,1.776-4.892,2.7s-1.594-1.057,3.024-3.867c0,0-4.237,2.4-4.525,2.443-.942.125,1.746-3.027,4.061-4.166,0,0-3.4,1.654-3.741,1.661-.477.01.547-1.1,3.068-2.5,2.267-1.257,5.982-7.027,5.982-7.027Z"
            transform="translate(535.764 14.003)"
            fill="#f9af8b"
          />
          <path
            id="Path_4509"
            data-name="Path 4509"
            d="M-510.96-32.375s-9.414,18.084-11.559,37l-1.01,28.721s.968,2.047,5.765.686l4.64-28.718,3.556-13.14S-507.951-24-510.96-32.375Z"
            transform="translate(533.091 32.375)"
            fill="#0077eb"
          />
        </g>
        <g
          id="Group_2261"
          data-name="Group 2261"
          transform="translate(27.876 0)"
        >
          <circle
            id="Ellipse_269"
            data-name="Ellipse 269"
            cx="10.403"
            cy="10.403"
            r="10.403"
            transform="matrix(0.284, -0.959, 0.959, 0.284, 0, 20.705)"
            fill="#2e3052"
          />
          <path
            id="Path_4510"
            data-name="Path 4510"
            d="M-476.01-63.591s3.042-.288,3.158-3.171c.1-2.454-2.992-1.4-2.413-3.227s1.429-3.435-.91-4.331c-2.047-.785-2.339.662-4.749-1.937-2.848-3.071-6.393,2.248-9.358,1.9-4.373-.51-5.352,1.841-6.123,3.724a3.714,3.714,0,0,0,.651,3.55Z"
            transform="translate(499.339 77.217)"
            fill="#2e3052"
          />
          <g
            id="Group_2253"
            data-name="Group 2253"
            transform="translate(19.363 17.257)"
          >
            <path
              id="Path_4511"
              data-name="Path 4511"
              d="M-473.738-54.338s2.005-1.876,2.9.126-1.749,6.985-4.476,5.173Z"
              transform="translate(475.318 55.136)"
              fill="#fc8664"
            />
            <g
              id="Group_2250"
              data-name="Group 2250"
              transform="translate(0.725 2.018)"
            >
              <path
                id="Path_4512"
                data-name="Path 4512"
                d="M-474.181-49.994a.254.254,0,0,0,.273-.14,3.921,3.921,0,0,1,2.571-1.916.254.254,0,0,0,.2-.3.254.254,0,0,0-.3-.2,4.368,4.368,0,0,0-2.934,2.2.254.254,0,0,0,.119.338A.248.248,0,0,0-474.181-49.994Z"
                transform="translate(474.391 52.554)"
                fill="#fd664d"
              />
            </g>
            <g
              id="Group_2252"
              data-name="Group 2252"
              transform="translate(1.864 2.745)"
            >
              <g id="Group_2251" data-name="Group 2251">
                <path
                  id="Path_4513"
                  data-name="Path 4513"
                  d="M-472.012-50.311a.254.254,0,0,0,.141-.016.252.252,0,0,0,.136-.331,2.309,2.309,0,0,0-.8-.921.253.253,0,0,0-.352.068.253.253,0,0,0,.067.352,1.838,1.838,0,0,1,.621.7A.255.255,0,0,0-472.012-50.311Z"
                  transform="translate(472.933 51.624)"
                  fill="#fd664d"
                />
              </g>
            </g>
          </g>
          <path
            id="Path_4514"
            data-name="Path 4514"
            d="M-490.852-44.121l-.929,5.255s1.751,2.7,3.838,3.255,3.818-1.729,3.818-1.729l.893-5.053Z"
            transform="translate(498.278 69.986)"
            fill="#f9af8b"
          />
          <path
            id="Path_4515"
            data-name="Path 4515"
            d="M-490.707-44.121l-.413,2.331c1.143,1.83,3.6,2.67,7.434,2.779l.6-3.382Z"
            transform="translate(498.134 69.986)"
            fill="#fc8664"
          />
          <path
            id="Path_4516"
            data-name="Path 4516"
            d="M-494.767-64.073a51.889,51.889,0,0,0-.492,6.759c.007.527.032,1.025.079,1.476.421,4.068,2.013,11.2,10.247,9.8,3.636-.616,5.894-4.145,7.286-7.895a38.059,38.059,0,0,0,1.787-7.1,5.571,5.571,0,0,0-1.958-5.16c-4.112-3.4-9.107-3.285-12.5-2.626A5.552,5.552,0,0,0-494.767-64.073Z"
            transform="translate(499.038 75.462)"
            fill="#f9af8b"
          />
          <path
            id="Path_4517"
            data-name="Path 4517"
            d="M-478.439-54.372c-.1.561.109,1.067.467,1.13s.726-.341.825-.9-.11-1.067-.467-1.13S-478.34-54.933-478.439-54.372Z"
            transform="translate(495.369 72.424)"
            fill="#2e3052"
          />
          <path
            id="Path_4518"
            data-name="Path 4518"
            d="M-488.115-56.082c-.1.561.11,1.067.467,1.129s.726-.34.825-.9-.109-1.067-.466-1.13S-488.016-56.643-488.115-56.082Z"
            transform="translate(497.483 72.798)"
            fill="#2e3052"
          />
          <path
            id="Path_4519"
            data-name="Path 4519"
            d="M-475.535-57.8s-2.5-1.537-4.727-1.009l0,.991a8.864,8.864,0,0,1,4.38,1.1C-475.664-57.135-475.44-57.315-475.535-57.8Z"
            transform="translate(495.761 73.219)"
            fill="#2e3052"
          />
          <path
            id="Path_4520"
            data-name="Path 4520"
            d="M-489.992-60.308s2.876-.587,4.788.673l-.342.93a8.863,8.863,0,0,0-4.492-.466C-490.1-59.638-490.247-59.884-489.992-60.308Z"
            transform="translate(497.919 73.552)"
            fill="#2e3052"
          />
          <g
            id="Group_2255"
            data-name="Group 2255"
            transform="translate(11.658 16.618)"
          >
            <g id="Group_2254" data-name="Group 2254">
              <path
                id="Path_4521"
                data-name="Path 4521"
                d="M-484.189-51.154a2.731,2.731,0,0,1-.871-.35.254.254,0,0,1-.079-.349.253.253,0,0,1,.349-.08c.209.131.725.37.985.272a.278.278,0,0,0,.164-.211,38.385,38.385,0,0,0,.525-3.85.252.252,0,0,1,.276-.229.253.253,0,0,1,.229.276,37.592,37.592,0,0,1-.542,3.942.775.775,0,0,1-.471.546A1.071,1.071,0,0,1-484.189-51.154Z"
                transform="translate(485.178 55.952)"
                fill="#fc8664"
              />
            </g>
          </g>
          <path
            id="Path_4522"
            data-name="Path 4522"
            d="M-482.007-47.46a3.264,3.264,0,0,1-3.683,2.355c-2.29-.4-2.627-2.244-2.638-3.472Z"
            transform="translate(497.523 70.96)"
            fill="#fff"
          />
          <g
            id="Group_2256"
            data-name="Group 2256"
            transform="translate(10.392 26.163)"
          >
            <path
              id="Path_4523"
              data-name="Path 4523"
              d="M-485.555-42.74a2.644,2.644,0,0,1-1.157-.556.253.253,0,0,1-.023-.357.253.253,0,0,1,.357-.024,2.165,2.165,0,0,0,.911.438,2.045,2.045,0,0,0,.956-.107.253.253,0,0,1,.329.142.253.253,0,0,1-.141.329A2.535,2.535,0,0,1-485.555-42.74Z"
              transform="translate(486.798 43.74)"
              fill="#fc8664"
            />
          </g>
          <path
            id="Path_4524"
            data-name="Path 4524"
            d="M-478.126-55.7a10.625,10.625,0,0,0,.777-3c-.03-1.248-.9-2.134-.365-3.237s-.468-2.574-1.338-2.941-3.06-.293-4.966-1.649c-1.819-1.295-5.391-1.23-6.179-.055-.953,1.422-.053,1.733-2.206,3.072-.857.535-.284,2.417-.358,3.563a7.621,7.621,0,0,1-.19,1.332l-.057.281-2.224-.393c.063-1.786.263-3.732.47-5.353a5.553,5.553,0,0,1,4.452-4.745c3.392-.66,8.384-.774,12.5,2.627a5.568,5.568,0,0,1,1.956,5.16,40.41,40.41,0,0,1-1.253,5.515Z"
            transform="translate(499.032 75.462)"
            fill="#2e3052"
          />
          <g
            id="Group_2260"
            data-name="Group 2260"
            transform="translate(1.167 13.777)"
          >
            <path
              id="Path_4525"
              data-name="Path 4525"
              d="M-495.152-55.489a9.369,9.369,0,0,0,.171-1.76,2.12,2.12,0,0,0-.846-2.026,1.389,1.389,0,0,0-1.9.137c-1.523,1.576-1.163,7.381,2.019,6.613Z"
              transform="translate(498.601 59.588)"
              fill="#f9af8b"
            />
            <g
              id="Group_2258"
              data-name="Group 2258"
              transform="translate(1.161 1.663)"
            >
              <g id="Group_2257" data-name="Group 2257">
                <path
                  id="Path_4526"
                  data-name="Path 4526"
                  d="M-495.672-53.783a.253.253,0,0,1-.159-.262,3.92,3.92,0,0,0-1.2-2.974.253.253,0,0,1-.018-.358.253.253,0,0,1,.357-.019,4.364,4.364,0,0,1,1.365,3.4.253.253,0,0,1-.277.227A.271.271,0,0,1-495.672-53.783Z"
                  transform="translate(497.115 57.46)"
                  fill="#fc8664"
                />
              </g>
            </g>
            <g
              id="Group_2259"
              data-name="Group 2259"
              transform="translate(1.132 3.123)"
            >
              <path
                id="Path_4527"
                data-name="Path 4527"
                d="M-496.991-54.782a.249.249,0,0,1-.112-.088.254.254,0,0,1,.056-.354,2.318,2.318,0,0,1,1.165-.368.253.253,0,0,1,.266.241.253.253,0,0,1-.241.265,1.834,1.834,0,0,0-.893.274A.254.254,0,0,1-496.991-54.782Z"
                transform="translate(497.152 55.593)"
                fill="#fc8664"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2304"
        data-name="Group 2304"
        transform="translate(1156.026 6788.721)"
      >
        <g
          id="Group_2263"
          data-name="Group 2263"
          transform="translate(0 35.96)"
        >
          <path
            id="Path_4528"
            data-name="Path 4528"
            d="M-353.846,94.5a90.086,90.086,0,0,1-7.456,11.641,30.562,30.562,0,0,1-3.654,4c-1.983,1.754-3.915,2.67-5.489,1.893-5.84-2.885-18.857-25.836-18.857-25.836l3.206-3.725s5.794,6.555,10.031,10.478c3.908,3.618,6.913,6.322,6.913,6.322.541-.807,1.117-1.69,1.716-2.626.637-.993,1.3-2.044,1.971-3.125,4.879-7.848,10.754-16.432,10.754-16.432Z"
            transform="translate(399.964 -72.366)"
            fill="#f9af8b"
          />
          <path
            id="Path_4529"
            data-name="Path 4529"
            d="M-388.227,83.4a6.186,6.186,0,0,0,.81-4.113c-.511-1.943-1.007-6.335-3.131-6.215-.775.043.357,1.878-.163,3.578a28.587,28.587,0,0,1-3.794-2.22c-1.339-.989-4.11-2.595-5.4-3.328a.4.4,0,0,0-.557.531,9.018,9.018,0,0,0,3.008,2.567s-4.643-2.6-5.421-2.029,5.088,3.717,5.088,3.717-4.736-1.914-5.124-1.213c-.236.426,4.409,3.155,4.409,3.155s-4.613-1.425-3.757-.344c.249.315,9.014,4.281,10.84,8.887C-391.417,86.373-388.863,84.974-388.227,83.4Z"
            transform="translate(402.944 -71.047)"
            fill="#f9af8b"
          />
          <path
            id="Path_4530"
            data-name="Path 4530"
            d="M-355.189,110.73a30.562,30.562,0,0,1-3.654,4l-2.481-13.5c.637-.993,1.3-2.044,1.971-3.125Z"
            transform="translate(393.851 -76.96)"
            fill="#fc8664"
          />
          <path
            id="Path_4531"
            data-name="Path 4531"
            d="M-348.266,76.308l-12.34,17.8,3.927,15.859L-347,94.518S-345.183,84.183-348.266,76.308Z"
            transform="translate(393.694 -72.197)"
            fill="#008eff"
          />
        </g>
        <g
          id="Group_2273"
          data-name="Group 2273"
          transform="translate(39.055 110.003)"
        >
          <path
            id="Path_4532"
            data-name="Path 4532"
            d="M-336.712,271.063v5.618l5.245-.083.342-5.954Z"
            transform="translate(349.418 -188.698)"
            fill="#f9af8b"
          />
          <g
            id="Group_2264"
            data-name="Group 2264"
            transform="translate(7.546 0.047)"
          >
            <path
              id="Path_4533"
              data-name="Path 4533"
              d="M-324.837,166.069c-.037.667-.217,3.874-.493,8.345-.739,12.282-2.122,34.109-2.933,39.013-.833,5.031-.456,21.529-.188,30.165a5.164,5.164,0,0,1-1.485,3.782,5.536,5.536,0,0,1-1.217.93,5.424,5.424,0,0,1-2.636.681h-4a5.282,5.282,0,0,1-5.338-5.392c.224-7.3.5-20.62-.022-30.91-.5-9.792.225-27.12.782-37.921h.008c.224-4.314.42-7.578.5-8.912Z"
              transform="translate(343.314 -165.849)"
              fill="#2e3052"
            />
          </g>
          <path
            id="Path_4534"
            data-name="Path 4534"
            d="M-341.59,165.849l17.021.22c-.037.667-.217,3.874-.493,8.345a73.3,73.3,0,0,1-17.027.347C-341.865,170.447-341.669,167.183-341.59,165.849Z"
            transform="translate(350.593 -165.802)"
            fill="#001030"
          />
          <g
            id="Group_2265"
            data-name="Group 2265"
            transform="translate(19.511)"
          >
            <path
              id="Path_4535"
              data-name="Path 4535"
              d="M-326.656,228.178c.148,5.523.3,11.233.2,15.836-.028,1.292-.074,2.4-.135,3.345a5.193,5.193,0,0,1-1.221.931c.1-1.1.173-2.506.214-4.3.1-4.577-.055-10.272-.2-15.782-.184-6.733-.356-13.094.019-15.8.817-5.925,2.643-38.044,3.122-46.618l1.145.05c-.479,8.569-2.311,40.753-3.134,46.718C-327.009,215.174-326.837,221.492-326.656,228.178Z"
              transform="translate(328.005 -165.789)"
              fill="#001030"
            />
          </g>
          <g
            id="Group_2272"
            data-name="Group 2272"
            transform="translate(0 86.32)"
          >
            <g id="Group_2271" data-name="Group 2271">
              <g id="Group_2270" data-name="Group 2270">
                <path
                  id="Path_4536"
                  data-name="Path 4536"
                  d="M-338.919,277.695s-1.086-1.5-1.976-1.453-7.867,5.237-10.226,5.627-1.941,2.1-1.529,2.971,18.746.077,18.746.077.622-5.328.061-6.974S-337.091,278.12-338.919,277.695Z"
                  transform="translate(352.97 -276.241)"
                  fill="#008eff"
                />
                <g
                  id="Group_2269"
                  data-name="Group 2269"
                  transform="translate(5.079 1.679)"
                >
                  <g
                    id="Group_2266"
                    data-name="Group 2266"
                    transform="translate(1.346 0.887)"
                  >
                    <path
                      id="Path_4537"
                      data-name="Path 4537"
                      d="M-341,282.057a.335.335,0,0,0,.258-.023.319.319,0,0,0,.133-.437,5,5,0,0,0-1.674-1.787,2.307,2.307,0,0,0-2.33.056.318.318,0,0,0-.078.449.333.333,0,0,0,.459.077,1.646,1.646,0,0,1,1.641-.013,4.4,4.4,0,0,1,1.4,1.524A.326.326,0,0,0-341,282.057Z"
                      transform="translate(344.749 -279.524)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_2267"
                    data-name="Group 2267"
                    transform="translate(2.347)"
                  >
                    <path
                      id="Path_4538"
                      data-name="Path 4538"
                      d="M-339.716,280.923a.335.335,0,0,0,.258-.023.318.318,0,0,0,.133-.437A5,5,0,0,0-341,278.676a2.308,2.308,0,0,0-2.331.056.318.318,0,0,0-.078.449.333.333,0,0,0,.46.077,1.644,1.644,0,0,1,1.64-.013,4.379,4.379,0,0,1,1.4,1.524A.33.33,0,0,0-339.716,280.923Z"
                      transform="translate(343.468 -278.389)"
                      fill="#fff"
                    />
                  </g>
                  <g
                    id="Group_2268"
                    data-name="Group 2268"
                    transform="translate(0 1.595)"
                  >
                    <path
                      id="Path_4539"
                      data-name="Path 4539"
                      d="M-342.719,282.963a.332.332,0,0,0,.258-.023.319.319,0,0,0,.133-.437A5,5,0,0,0-344,280.717a2.3,2.3,0,0,0-2.331.056.318.318,0,0,0-.078.449.333.333,0,0,0,.459.077,1.648,1.648,0,0,1,1.641-.013,4.4,4.4,0,0,1,1.4,1.524A.325.325,0,0,0-342.719,282.963Z"
                      transform="translate(346.472 -280.429)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2281"
          data-name="Group 2281"
          transform="translate(68.635 109.995)"
        >
          <path
            id="Path_4540"
            data-name="Path 4540"
            d="M-304.022,271.063l.565,5.61,4.681-.074.342-5.954Z"
            transform="translate(312.696 -188.69)"
            fill="#f9af8b"
          />
          <g
            id="Group_2274"
            data-name="Group 2274"
            transform="translate(0 0.317)"
          >
            <path
              id="Path_4541"
              data-name="Path 4541"
              d="M-296.812,212.756c-.521,10.289-.246,23.608-.022,30.91a5.286,5.286,0,0,1-5.338,5.393h-4a5.458,5.458,0,0,1-2.456-.581,5.144,5.144,0,0,1-1.21-.852,5.135,5.135,0,0,1-1.673-3.959c.268-8.636.645-25.134-.188-30.165-.826-4.988-2.245-27.482-2.977-39.637-.239-4.059-.406-6.962-.449-7.678l17.05.22c.051.866.13,2.2.224,3.889C-297.3,180.016-296.232,201.424-296.812,212.756Z"
              transform="translate(315.121 -166.185)"
              fill="#2e3052"
            />
          </g>
          <path
            id="Path_4542"
            data-name="Path 4542"
            d="M-297.847,170.294a168.609,168.609,0,0,1-16.825,3.569c-.239-4.059-.406-6.962-.449-7.678l17.05.22C-298.021,167.271-297.941,168.605-297.847,170.294Z"
            transform="translate(315.121 -165.868)"
            fill="#001030"
          />
          <g
            id="Group_2275"
            data-name="Group 2275"
            transform="translate(2.197)"
          >
            <path
              id="Path_4543"
              data-name="Path 4543"
              d="M-308.227,243.987c.04,1.849.12,3.285.219,4.4a5.228,5.228,0,0,1-1.214-.845c-.069-.987-.12-2.152-.151-3.528-.1-4.6.056-10.313.2-15.836.181-6.686.351-13-.01-15.621-.822-5.966-2.654-38.168-3.132-46.725l1.143-.051c.479,8.563,2.305,40.7,3.124,46.625.374,2.707.2,9.068.02,15.8C-308.173,233.715-308.327,239.41-308.227,243.987Z"
              transform="translate(312.31 -165.779)"
              fill="#001030"
            />
          </g>
          <g
            id="Group_2280"
            data-name="Group 2280"
            transform="translate(8.354 86.508)"
          >
            <path
              id="Path_4544"
              data-name="Path 4544"
              d="M-301.722,277.083a22.135,22.135,0,0,1,3.047-.611c.66.013,5.081,4.856,6.569,5.142,2.7.517,2.7,4.648,1.06,4.852-4.752.594-12.88-1.373-12.88-1.373s-.75-5.3-.424-6.963S-303.05,277.572-301.722,277.083Z"
              transform="translate(304.431 -276.472)"
              fill="#008eff"
            />
            <g
              id="Group_2279"
              data-name="Group 2279"
              transform="translate(5.333 1.853)"
            >
              <g
                id="Group_2276"
                data-name="Group 2276"
                transform="translate(0.91 0.977)"
              >
                <path
                  id="Path_4545"
                  data-name="Path 4545"
                  d="M-296.042,282.4a.334.334,0,0,1-.255-.048.318.318,0,0,1-.09-.447,5.034,5.034,0,0,1,1.84-1.622,2.307,2.307,0,0,1,2.315.274.318.318,0,0,1,.034.455.333.333,0,0,1-.464.033,1.643,1.643,0,0,0-1.632-.166,4.451,4.451,0,0,0-1.547,1.385A.328.328,0,0,1-296.042,282.4Z"
                  transform="translate(296.442 -280.092)"
                  fill="#fff"
                />
              </g>
              <g id="Group_2277" data-name="Group 2277">
                <path
                  id="Path_4546"
                  data-name="Path 4546"
                  d="M-297.207,281.148a.331.331,0,0,1-.255-.047.318.318,0,0,1-.089-.447,5.015,5.015,0,0,1,1.84-1.622,2.3,2.3,0,0,1,2.315.274.316.316,0,0,1,.034.454.333.333,0,0,1-.464.034,1.644,1.644,0,0,0-1.632-.166A4.412,4.412,0,0,0-297,281.014.33.33,0,0,1-297.207,281.148Z"
                  transform="translate(297.607 -278.842)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_2278"
                data-name="Group 2278"
                transform="translate(2.181 1.807)"
              >
                <path
                  id="Path_4547"
                  data-name="Path 4547"
                  d="M-294.416,283.461a.337.337,0,0,1-.255-.047.32.32,0,0,1-.09-.448,5.042,5.042,0,0,1,1.84-1.622,2.3,2.3,0,0,1,2.315.274.318.318,0,0,1,.034.455.334.334,0,0,1-.464.033,1.645,1.645,0,0,0-1.632-.166,4.42,4.42,0,0,0-1.546,1.386A.328.328,0,0,1-294.416,283.461Z"
                  transform="translate(294.816 -281.154)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2287"
          data-name="Group 2287"
          transform="translate(43.346 29.432)"
        >
          <g id="Group_2282" data-name="Group 2282">
            <path
              id="Path_4548"
              data-name="Path 4548"
              d="M-301.272,145.254a94.754,94.754,0,0,1-46.144,0c-.081-5.624-.162-28.113.281-46.572q.012-.544.027-1.084c0-.178.009-.356.014-.531v-.029c.006-.231.011-.457.019-.685.328-12.173.9-22.114,1.883-23.442l0,0c2.294-3.086,13.816-8.016,17-10.217.266.419,1.834,2.747,3.89,2.919,2.255.2,4.261-2.406,4.261-2.406v-.492c3.2,2.2,13.641,6.762,15.931,9.839C-301.6,75.927-301.165,135.672-301.272,145.254Z"
              transform="translate(347.48 -62.694)"
              fill="#008eff"
            />
          </g>
          <g
            id="Group_2283"
            data-name="Group 2283"
            transform="translate(0.345 26.768)"
          >
            <path
              id="Path_4549"
              data-name="Path 4549"
              d="M-342.344,97.5a56.239,56.239,0,0,0-4.12,7.553c-.118.252-.216.463-.291.624-.1.21-.152.332-.157.342a.338.338,0,0,1-.126.148q.012-.544.027-1.084c0-.178.009-.356.014-.531v-.029c.177-.374.387-.813.622-1.294a45.3,45.3,0,0,1,3.457-6.141.362.362,0,0,1,.5-.073A.345.345,0,0,1-342.344,97.5Z"
              transform="translate(347.038 -96.945)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2284"
            data-name="Group 2284"
            transform="translate(15.872 3.959)"
          >
            <path
              id="Path_4550"
              data-name="Path 4550"
              d="M-308.518,77.539h-.011A25.053,25.053,0,0,1-322.252,72.8a23.98,23.98,0,0,1-4.848-4.477.344.344,0,0,1,.073-.488.362.362,0,0,1,.5.071c.065.086,6.667,8.586,18.022,8.94a.352.352,0,0,1,.345.36A.353.353,0,0,1-308.518,77.539Z"
              transform="translate(327.171 -67.76)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2285"
            data-name="Group 2285"
            transform="translate(13.554 51.373)"
          >
            <path
              id="Path_4551"
              data-name="Path 4551"
              d="M-329.78,147.882a.354.354,0,0,1-.356-.326.352.352,0,0,1,.333-.372,26.006,26.006,0,0,0,19.283-9.7,26.8,26.8,0,0,0,4.726-8.8.356.356,0,0,1,.435-.25.348.348,0,0,1,.256.425,26.984,26.984,0,0,1-4.837,9.03,26.7,26.7,0,0,1-19.816,9.985Z"
              transform="translate(330.136 -128.428)"
              fill="#0077eb"
            />
          </g>
          <g
            id="Group_2286"
            data-name="Group 2286"
            transform="translate(18.944 54.098)"
          >
            <path
              id="Path_4552"
              data-name="Path 4552"
              d="M-322.883,142.4a.356.356,0,0,1-.344-.259.348.348,0,0,1,.251-.428c8.724-2.311,10.408-9.449,10.424-9.52a.357.357,0,0,1,.424-.267.349.349,0,0,1,.273.415,12.352,12.352,0,0,1-2,4.119,15.607,15.607,0,0,1-8.934,5.928A.376.376,0,0,1-322.883,142.4Z"
              transform="translate(323.239 -131.916)"
              fill="#0077eb"
            />
          </g>
        </g>
        <g
          id="Group_2288"
          data-name="Group 2288"
          transform="translate(87.019 39.983)"
        >
          <path
            id="Path_4553"
            data-name="Path 4553"
            d="M-277.3,152.324c-.219,3.059-2.093,5.33-2.1,7.344s-2.384.7-1.285-3.282-.531-7.826-1.4-7.791.277,4.484-.638,5.961-1.6-1.625-2.406-3.543c-.832-1.991-.367-6.122.388-9.03s-2.946-21.356-2.462-26.553l-1.336-6.744-.781-3.934-.146-4.106,7.132-2.769c.27,1.318.544,2.685.817,4.059.12.606.238,1.214.355,1.819.954,4.917,1.811,9.757,2.16,12.863.964,8.59-.618,26.108-.618,26.108S-277.09,149.456-277.3,152.324Z"
            transform="translate(291.132 -80.932)"
            fill="#f9af8b"
          />
          <path
            id="Path_4554"
            data-name="Path 4554"
            d="M-281.161,103.756a15.841,15.841,0,0,1-7.378,4.931l-.781-3.934-.146-4.106,7.132-2.769c.27,1.318.544,2.685.817,4.059C-281.4,102.542-281.277,103.15-281.161,103.756Z"
            transform="translate(291.132 -80.932)"
            fill="#fc8664"
          />
          <path
            id="Path_4555"
            data-name="Path 4555"
            d="M-279.646,95.125a15.091,15.091,0,0,1-2.336,2.073,13.58,13.58,0,0,1-7.795,2.817c-.394-11.38-.975-21-1.821-23.82C-290.093,78.015-282.865,86.978-279.646,95.125Z"
            transform="translate(291.598 -76.195)"
            fill="#008eff"
          />
        </g>
        <g
          id="Group_2289"
          data-name="Group 2289"
          transform="translate(83.875 55.056)"
        >
          <path
            id="Path_4556"
            data-name="Path 4556"
            d="M-295.551,96.038a56.137,56.137,0,0,1,4.119,7.553c.118.252.216.463.291.624.1.209.154.332.157.341a.355.355,0,0,0,.327.208h.007c0-.178-.009-.356-.014-.533.1,0,.21,0,.312,0-.048-.1-.164-.355-.33-.71-.209-.445-.5-1.057-.839-1.752a45.085,45.085,0,0,0-3.456-6.141.361.361,0,0,0-.5-.073A.345.345,0,0,0-295.551,96.038Z"
            transform="translate(295.621 -95.482)"
            fill="#0077eb"
          />
        </g>
        <g
          id="Group_2303"
          data-name="Group 2303"
          transform="translate(54.598 0)"
        >
          <path
            id="Path_4557"
            data-name="Path 4557"
            d="M-309.609,36.188c0,6.165-3.874,10.413-10.166,10.413a10.324,10.324,0,0,1-10.677-10.732c0-6.165,3.726-10.835,10.018-10.835C-312.887,25.034-309.609,30.024-309.609,36.188Z"
            transform="translate(332.507 -25.034)"
            fill="#2e3052"
          />
          <g
            id="Group_2296"
            data-name="Group 2296"
            transform="translate(20.813 12.666)"
          >
            <path
              id="Path_4558"
              data-name="Path 4558"
              d="M-305.779,42.383s1.748-2.284,3.048-.4-.538,7.466-3.72,6.1Z"
              transform="translate(306.451 -41.24)"
              fill="#fc8664"
            />
            <g
              id="Group_2292"
              data-name="Group 2292"
              transform="translate(0.426 1.999)"
            >
              <g id="Group_2291" data-name="Group 2291">
                <g id="Group_2290" data-name="Group 2290">
                  <path
                    id="Path_4559"
                    data-name="Path 4559"
                    d="M-305.637,46.927a.268.268,0,0,0,.259-.192,4.121,4.121,0,0,1,2.333-2.427.263.263,0,0,0,.156-.34.27.27,0,0,0-.346-.153,4.588,4.588,0,0,0-2.66,2.778.263.263,0,0,0,.186.325A.29.29,0,0,0-305.637,46.927Z"
                    transform="translate(305.906 -43.798)"
                    fill="#fd664d"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_2295"
              data-name="Group 2295"
              transform="translate(1.371 3.034)"
            >
              <g id="Group_2294" data-name="Group 2294">
                <g id="Group_2293" data-name="Group 2293">
                  <path
                    id="Path_4560"
                    data-name="Path 4560"
                    d="M-303.535,46.349a.274.274,0,0,0,.145-.041.26.26,0,0,0,.08-.364,2.437,2.437,0,0,0-1.01-.8.27.27,0,0,0-.355.133.261.261,0,0,0,.135.348,1.928,1.928,0,0,1,.777.6A.271.271,0,0,0-303.535,46.349Z"
                    transform="translate(304.697 -45.123)"
                    fill="#fd664d"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group_2301"
            data-name="Group 2301"
            transform="translate(0 12.666)"
          >
            <path
              id="Path_4561"
              data-name="Path 4561"
              d="M-329.613,42.383s-1.748-2.284-3.048-.4.538,7.466,3.721,6.1Z"
              transform="translate(333.082 -41.24)"
              fill="#fc8664"
            />
            <g
              id="Group_2297"
              data-name="Group 2297"
              transform="translate(0.682 1.999)"
            >
              <path
                id="Path_4562"
                data-name="Path 4562"
                d="M-329.445,46.927a.268.268,0,0,1-.259-.192,4.123,4.123,0,0,0-2.332-2.427.263.263,0,0,1-.156-.34.271.271,0,0,1,.347-.153,4.592,4.592,0,0,1,2.66,2.778.264.264,0,0,1-.187.325A.29.29,0,0,1-329.445,46.927Z"
                transform="translate(332.209 -43.798)"
                fill="#fd664d"
              />
            </g>
            <g
              id="Group_2300"
              data-name="Group 2300"
              transform="translate(1.34 3.034)"
            >
              <g id="Group_2299" data-name="Group 2299">
                <g id="Group_2298" data-name="Group 2298">
                  <path
                    id="Path_4563"
                    data-name="Path 4563"
                    d="M-331.1,46.349a.272.272,0,0,1-.145-.041.26.26,0,0,1-.08-.364,2.438,2.438,0,0,1,1.009-.8.271.271,0,0,1,.356.133.261.261,0,0,1-.136.348,1.929,1.929,0,0,0-.777.6A.269.269,0,0,1-331.1,46.349Z"
                    transform="translate(331.367 -45.123)"
                    fill="#fd664d"
                  />
                </g>
              </g>
            </g>
          </g>
          <path
            id="Path_4564"
            data-name="Path 4564"
            d="M-322.623,56.061v5.546s1.688,2.824,3.972,3.017,4.308-2.432,4.308-2.432V56.451Z"
            transform="translate(330.797 -31.813)"
            fill="#f9af8b"
          />
          <path
            id="Path_4565"
            data-name="Path 4565"
            d="M-322.623,56.061v2.461c1.532,1.665,4.253,2.081,8.279,1.5v-3.57Z"
            transform="translate(330.797 -31.813)"
            fill="#fc8664"
          />
          <path
            id="Path_4566"
            data-name="Path 4566"
            d="M-310.047,39.515a32.864,32.864,0,0,1-.46,3.652c-.759,4.088-2.469,8.109-6.153,9.4-8.341,2.918-11.323-4.091-12.514-8.177-.13-.453-.248-.958-.352-1.5-.19-.974-.337-2.06-.452-3.154-.136-1.327-.227-2.67-.283-3.851a5.768,5.768,0,0,1,3.772-5.664c3.419-1.288,8.616-2.305,13.54.43a5.768,5.768,0,0,1,3,4.926C-309.927,36.691-309.943,38.05-310.047,39.515Z"
            transform="translate(332.467 -25.883)"
            fill="#f9af8b"
          />
          <path
            id="Path_4567"
            data-name="Path 4567"
            d="M-311,42.7c-.281.5-1.844,5.227-2.279,5.89-.986-2.126-5.744-.581-5.744-.581a3.059,3.059,0,0,1-.922.144,3.064,3.064,0,0,1-.895-.136l-.024-.008s-4.814-1.479-5.763.749c-.441-.66-1.8-5.295-2.051-6.057H-329.9c.115,1.094.262,2.18.452,3.154.1.539.222,1.044.352,1.5,1.192,4.086,4.173,11.1,12.514,8.177,3.684-1.29,5.394-5.311,6.153-9.4a32.857,32.857,0,0,0,.46-3.652Zm-4.486,8.64v0h0a5.287,5.287,0,0,1-1.12.832c-2.113,1.121-2.343.95-3.177.987a6.4,6.4,0,0,1-3.208-.856,9.075,9.075,0,0,1-1.526-1.139s-.858-2.871,4.587-2.152C-314.473,48.1-315.4,50.78-315.486,51.34Z"
            transform="translate(332.386 -28.845)"
            fill="#2e3052"
          />
          <path
            id="Path_4568"
            data-name="Path 4568"
            d="M-312.54,43.329c0,.592.312,1.072.7,1.072s.7-.48.7-1.072-.312-1.072-.7-1.072S-312.54,42.737-312.54,43.329Z"
            transform="translate(328.594 -28.797)"
            fill="#2e3052"
          />
          <path
            id="Path_4569"
            data-name="Path 4569"
            d="M-323.031,43.3c0,.592.311,1.071.7,1.071s.7-.48.7-1.071-.312-1.072-.7-1.072S-323.031,42.709-323.031,43.3Z"
            transform="translate(330.886 -28.791)"
            fill="#2e3052"
          />
          <path
            id="Path_4570"
            data-name="Path 4570"
            d="M-309.438,39.612s-2.665-1.032-4.717-.163l.206,1.118a13.422,13.422,0,0,1,4.529.352A1.9,1.9,0,0,0-309.438,39.612Z"
            transform="translate(328.947 -28.107)"
            fill="#2e3052"
          />
          <path
            id="Path_4571"
            data-name="Path 4571"
            d="M-325.114,39.612s2.665-1.032,4.716-.163l-.2,1.118a13.426,13.426,0,0,0-4.53.352A1.9,1.9,0,0,1-325.114,39.612Z"
            transform="translate(331.369 -28.107)"
            fill="#2e3052"
          />
          <g
            id="Group_2302"
            data-name="Group 2302"
            transform="translate(11.243 13.527)"
          >
            <path
              id="Path_4572"
              data-name="Path 4572"
              d="M-317.555,47.5a2.909,2.909,0,0,1-.974-.2.261.261,0,0,1-.147-.343.27.27,0,0,1,.35-.145,1.65,1.65,0,0,0,1.078.1.286.286,0,0,0,.133-.245,39.242,39.242,0,0,0-.163-4.036.264.264,0,0,1,.245-.284.267.267,0,0,1,.291.241,38.414,38.414,0,0,1,.161,4.133.8.8,0,0,1-.391.643A1.155,1.155,0,0,1-317.555,47.5Z"
              transform="translate(318.696 -42.343)"
              fill="#fc8664"
            />
          </g>
          <path
            id="Path_4573"
            data-name="Path 4573"
            d="M-314.443,51.409a3.43,3.43,0,0,1-3.411,3.077,3.3,3.3,0,0,1-3.4-3.077Z"
            transform="translate(330.497 -30.796)"
            fill="#fff"
          />
          <path
            id="Path_4574"
            data-name="Path 4574"
            d="M-329.977,39.738h1.218c-.134-1.2-.327-3.311.678-3.613,3.176-.955-.94-3.154.494-4.25s5-.628,7.483-.707a54.366,54.366,0,0,1,7.482.177c.976.219.441,1.807.084,3.038s1.2,2.588,1.458,3.86a5.479,5.479,0,0,1,0,1.5h1.016c.121-1.548.139-2.991.117-4.159a5.775,5.775,0,0,0-3-4.927c-4.924-2.735-10.121-1.715-13.541-.427a5.769,5.769,0,0,0-3.773,5.662C-330.2,37.068-330.115,38.411-329.977,39.738Z"
            transform="translate(332.467 -25.883)"
            fill="#2e3052"
          />
        </g>
      </g>
      <g
        id="Group_2337"
        data-name="Group 2337"
        transform="translate(967.055 6825.316)"
      >
        <g id="Group_2336" data-name="Group 2336" transform="translate(0 0)">
          <g
            id="Group_2307"
            data-name="Group 2307"
            transform="translate(5.476 101.536)"
          >
            <g id="Group_2305" data-name="Group 2305">
              <path
                id="Path_4575"
                data-name="Path 4575"
                d="M-575.868,212.479h-61.868c1.022-6.207,5.945-10.716,11.684-10.7l19.25.057,19.25-.057C-581.814,201.763-576.89,206.272-575.868,212.479Z"
                transform="translate(637.736 -201.781)"
                fill="#fff"
              />
            </g>
            <g
              id="Group_2306"
              data-name="Group 2306"
              transform="translate(4.866 9.81)"
            >
              <rect
                id="Rectangle_6535"
                data-name="Rectangle 6535"
                width="3.371"
                height="57.684"
                fill="#fff"
              />
              <rect
                id="Rectangle_6536"
                data-name="Rectangle 6536"
                width="3.371"
                height="57.684"
                transform="translate(48.765)"
                fill="#fff"
              />
              <rect
                id="Rectangle_6537"
                data-name="Rectangle 6537"
                width="45.394"
                height="3.251"
                transform="translate(3.371 16.163)"
                fill="#dcedf9"
              />
            </g>
          </g>
          <g id="Group_2335" data-name="Group 2335">
            <path
              id="Path_4576"
              data-name="Path 4576"
              d="M-620.824,116.111s-10.332,13.78-11.707,25.8c-.971,8.488,11.707,21.171,11.707,21.171l4.367-2.173-4.353-9.817c-1.55-4.781-3.457-6.67-2.048-11.494l3.153-6.8Z"
              transform="translate(642.087 -81.528)"
              fill="#0077eb"
            />
            <path
              id="Path_4577"
              data-name="Path 4577"
              d="M-577.08,115.845s10.2,14.693,11.043,26.76c.728,10.4-11.756,20.416-11.756,20.416l-3.605-1.794,4.993-9.918c1.549-4.781,2.285-7.664.174-12.865l-2.681-5.7Z"
              transform="translate(630.904 -81.47)"
              fill="#0077eb"
            />
            <g
              id="Group_2309"
              data-name="Group 2309"
              transform="translate(16.519 30.848)"
            >
              <path
                id="Path_4578"
                data-name="Path 4578"
                d="M-616.757,146.449l-6.849,21.788s29.652,3.782,38.721,1.335l-4.606-23.73,3.189-30.983a44.164,44.164,0,0,0-13.946-3.412c-7.013-.506-11.443.65-18.614,3.62Z"
                transform="translate(623.606 -111.332)"
                fill="#008eff"
              />
              <g
                id="Group_2308"
                data-name="Group 2308"
                transform="translate(12.247 4.18)"
              >
                <path
                  id="Path_4579"
                  data-name="Path 4579"
                  d="M-605.92,122.8c6.542-.767,12.629-5.26,12.9-5.467a.365.365,0,0,0,.074-.511.365.365,0,0,0-.511-.074c-.071.053-7.234,5.337-14.122,5.431a.363.363,0,0,0-.36.37.365.365,0,0,0,.37.36A16.315,16.315,0,0,0-605.92,122.8Z"
                  transform="translate(607.935 -116.68)"
                  fill="#0077eb"
                />
              </g>
            </g>
            <g
              id="Group_2311"
              data-name="Group 2311"
              transform="translate(14.571 81.552)"
            >
              <path
                id="Path_4580"
                data-name="Path 4580"
                d="M-598.613,273.882l.535,4.811,5.117.117.429-3.992Z"
                transform="translate(620.094 -197.55)"
                fill="#f9af8b"
              />
              <g id="Group_2310" data-name="Group 2310">
                <path
                  id="Path_4581"
                  data-name="Path 4581"
                  d="M-605.421,176.269s-13.249-.728-17.737,2.944-3.586,14.209.986,17.086,12.365,8.53,12.365,8.53l4.957,49.283,6.551.223s6.036-49.506,2.976-59.094S-605.421,176.269-605.421,176.269Z"
                  transform="translate(626.099 -176.21)"
                  fill="#001030"
                />
                <path
                  id="Path_4582"
                  data-name="Path 4582"
                  d="M-591.975,273.018a5.478,5.478,0,0,1-.17,3.375l-7.537-.167a8.667,8.667,0,0,1-.146-3.306Z"
                  transform="translate(620.38 -197.339)"
                  fill="#2e3052"
                />
              </g>
            </g>
            <g
              id="Group_2312"
              data-name="Group 2312"
              transform="translate(15.978 59.335)"
            >
              <path
                id="Path_4583"
                data-name="Path 4583"
                d="M-586.277,169.686h-34.77l-3.213-18.791a2.663,2.663,0,0,1,2.626-3.113h35.944a2.664,2.664,0,0,1,2.626,3.113Z"
                transform="translate(624.299 -147.782)"
                fill="#fff"
              />
              <path
                id="Rectangle_6538"
                data-name="Rectangle 6538"
                d="M0,0H34.8a0,0,0,0,1,0,0V.465a3.621,3.621,0,0,1-3.621,3.621H3.621A3.621,3.621,0,0,1,0,.465V0A0,0,0,0,1,0,0Z"
                transform="translate(3.252 21.903)"
                fill="#dcedf9"
              />
              <path
                id="Path_4584"
                data-name="Path 4584"
                d="M-595.618,161.943a3.02,3.02,0,0,1-3.02,3.02,3.02,3.02,0,0,1-3.021-3.02,3.02,3.02,0,0,1,3.021-3.02A3.02,3.02,0,0,1-595.618,161.943Z"
                transform="translate(619.352 -150.216)"
                fill="#dcedf9"
              />
            </g>
            <g
              id="Group_2314"
              data-name="Group 2314"
              transform="translate(12.723 80.067)"
            >
              <path
                id="Path_4585"
                data-name="Path 4585"
                d="M-626.738,256.569l-1.134,4.761,4.591.907,1.87-4.251Z"
                transform="translate(628.334 -192.282)"
                fill="#f9af8b"
              />
              <g id="Group_2313" data-name="Group 2313">
                <path
                  id="Path_4586"
                  data-name="Path 4586"
                  d="M-598.85,175.037s-8.874-3.06-14.076,3.366-13.912,60.669-13.912,60.669l5.971,2.465,19.2-48.174s15.632,8.92,17.526-3.761C-582.648,179.567-598.85,175.037-598.85,175.037Z"
                  transform="translate(628.108 -174.309)"
                  fill="#2e3052"
                />
                <path
                  id="Path_4587"
                  data-name="Path 4587"
                  d="M-619.869,256.414a5.657,5.657,0,0,1-1.255,3.257l-7.339-2.6a8.946,8.946,0,0,1,.923-3.29Z"
                  transform="translate(628.463 -191.673)"
                  fill="#524e84"
                />
              </g>
            </g>
            <g
              id="Group_2319"
              data-name="Group 2319"
              transform="translate(0 146.755)"
            >
              <path
                id="Path_4588"
                data-name="Path 4588"
                d="M-628.054,261.218a27.879,27.879,0,0,0-3.6-1.573c-.819-.162-7.584,4.638-9.5,4.591-3.475-.085-4.588,5.019-2.613,5.713,5.714,2.009,16.29,1.76,16.29,1.76s2.348-6.349,2.393-8.492S-626.545,262.179-628.054,261.218Z"
                transform="translate(644.743 -259.641)"
                fill="#008eff"
              />
              <g
                id="Group_2318"
                data-name="Group 2318"
                transform="translate(4.386 1.58)"
              >
                <g
                  id="Group_2315"
                  data-name="Group 2315"
                  transform="translate(1.794 0.963)"
                >
                  <path
                    id="Path_4589"
                    data-name="Path 4589"
                    d="M-632.4,266.464a.422.422,0,0,0,.327.01.4.4,0,0,0,.231-.529,6.359,6.359,0,0,0-1.84-2.5,2.917,2.917,0,0,0-2.935-.283.4.4,0,0,0-.164.553.422.422,0,0,0,.565.165,2.083,2.083,0,0,1,2.062.233,5.609,5.609,0,0,1,1.539,2.127A.418.418,0,0,0-632.4,266.464Z"
                    transform="translate(636.836 -262.895)"
                    fill="#fff"
                  />
                </g>
                <g
                  id="Group_2316"
                  data-name="Group 2316"
                  transform="translate(3.181)"
                >
                  <path
                    id="Path_4590"
                    data-name="Path 4590"
                    d="M-630.629,265.232a.422.422,0,0,0,.327.01.4.4,0,0,0,.231-.529,6.359,6.359,0,0,0-1.84-2.5,2.912,2.912,0,0,0-2.935-.283.4.4,0,0,0-.164.553.422.422,0,0,0,.565.165,2.084,2.084,0,0,1,2.062.233,5.6,5.6,0,0,1,1.539,2.127A.41.41,0,0,0-630.629,265.232Z"
                    transform="translate(635.061 -261.663)"
                    fill="#fff"
                  />
                </g>
                <g
                  id="Group_2317"
                  data-name="Group 2317"
                  transform="translate(0 1.648)"
                >
                  <path
                    id="Path_4591"
                    data-name="Path 4591"
                    d="M-634.7,267.342a.42.42,0,0,0,.327.009.4.4,0,0,0,.231-.529,6.365,6.365,0,0,0-1.84-2.5,2.912,2.912,0,0,0-2.935-.283.4.4,0,0,0-.164.553.422.422,0,0,0,.565.165,2.084,2.084,0,0,1,2.062.233,5.6,5.6,0,0,1,1.539,2.127A.415.415,0,0,0-634.7,267.342Z"
                    transform="translate(639.131 -263.772)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_2324"
              data-name="Group 2324"
              transform="translate(35.388 161.614)"
            >
              <path
                id="Path_4592"
                data-name="Path 4592"
                d="M-596.29,279.534a26.654,26.654,0,0,1,3.644-.88c.8-.016,6.354,5.615,8.161,5.89,3.279.5,3.478,5.475,1.507,5.8-5.7.938-15.592-1.051-15.592-1.051s-1.152-6.353-.839-8.373S-597.868,280.186-596.29,279.534Z"
                transform="translate(599.462 -278.654)"
                fill="#008eff"
              />
              <g
                id="Group_2323"
                data-name="Group 2323"
                transform="translate(6.487 2.117)"
              >
                <g
                  id="Group_2320"
                  data-name="Group 2320"
                  transform="translate(1.143 1.135)"
                >
                  <path
                    id="Path_4593"
                    data-name="Path 4593"
                    d="M-589.2,285.711a.4.4,0,0,1-.309-.045.385.385,0,0,1-.13-.535,6.073,6.073,0,0,1,2.143-2.041,2.781,2.781,0,0,1,2.8.221.384.384,0,0,1,.062.547.4.4,0,0,1-.558.062,1.984,1.984,0,0,0-1.976-.124,5.369,5.369,0,0,0-1.8,1.743A.394.394,0,0,1-589.2,285.711Z"
                    transform="translate(589.699 -282.815)"
                    fill="#fff"
                  />
                </g>
                <g id="Group_2321" data-name="Group 2321">
                  <path
                    id="Path_4594"
                    data-name="Path 4594"
                    d="M-590.664,284.26a.4.4,0,0,1-.309-.045.384.384,0,0,1-.129-.535,6.053,6.053,0,0,1,2.142-2.041,2.777,2.777,0,0,1,2.8.222.383.383,0,0,1,.062.546.4.4,0,0,1-.558.062,1.982,1.982,0,0,0-1.975-.124,5.341,5.341,0,0,0-1.8,1.742A.393.393,0,0,1-590.664,284.26Z"
                    transform="translate(591.161 -281.363)"
                    fill="#fff"
                  />
                </g>
                <g
                  id="Group_2322"
                  data-name="Group 2322"
                  transform="translate(2.714 2.076)"
                >
                  <path
                    id="Path_4595"
                    data-name="Path 4595"
                    d="M-587.191,286.917a.4.4,0,0,1-.309-.045.384.384,0,0,1-.129-.535,6.067,6.067,0,0,1,2.142-2.041,2.777,2.777,0,0,1,2.8.222.383.383,0,0,1,.063.546.4.4,0,0,1-.559.062,1.984,1.984,0,0,0-1.975-.123,5.34,5.34,0,0,0-1.8,1.743A.4.4,0,0,1-587.191,286.917Z"
                    transform="translate(587.688 -284.019)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g
              id="Group_2334"
              data-name="Group 2334"
              transform="translate(23.299)"
            >
              <path
                id="Path_4596"
                data-name="Path 4596"
                d="M-591.775,76.346s4.216,6.532,3.754,13.335.621,14.656-5.466,14.311-16.946,2.441-20.4-2.858S-591.775,76.346-591.775,76.346Z"
                transform="translate(614.772 -72.84)"
                fill="#2e3052"
              />
              <path
                id="Path_4597"
                data-name="Path 4597"
                d="M-586.763,91.682s1.094,9.861-3.585,12.576a6.905,6.905,0,0,1-8.065-.317S-588.55,90.2-586.763,91.682Z"
                transform="translate(611.322 -76.166)"
                fill="#000b29"
              />
              <g
                id="Group_2333"
                data-name="Group 2333"
                transform="translate(1.788 4.904)"
              >
                <g
                  id="Group_2327"
                  data-name="Group 2327"
                  transform="translate(0 9.445)"
                >
                  <path
                    id="Path_4598"
                    data-name="Path 4598"
                    d="M-609.308,91.341s-1.68-2.24-2.93-.388.517,7.324,3.576,5.989Z"
                    transform="translate(612.643 -90.219)"
                    fill="#fc8664"
                  />
                  <g
                    id="Group_2325"
                    data-name="Group 2325"
                    transform="translate(0.656 1.961)"
                  >
                    <path
                      id="Path_4599"
                      data-name="Path 4599"
                      d="M-609.147,95.8a.258.258,0,0,1-.249-.189,4,4,0,0,0-2.242-2.381.258.258,0,0,1-.149-.333.257.257,0,0,1,.332-.15,4.451,4.451,0,0,1,2.557,2.724.259.259,0,0,1-.18.319A.27.27,0,0,1-609.147,95.8Z"
                      transform="translate(611.804 -92.729)"
                      fill="#fd664d"
                    />
                  </g>
                  <g
                    id="Group_2326"
                    data-name="Group 2326"
                    transform="translate(1.288 2.977)"
                  >
                    <path
                      id="Path_4600"
                      data-name="Path 4600"
                      d="M-610.736,95.232a.26.26,0,0,1-.14-.041.259.259,0,0,1-.077-.358,2.357,2.357,0,0,1,.97-.782.258.258,0,0,1,.342.131.257.257,0,0,1-.131.341,1.864,1.864,0,0,0-.746.591A.258.258,0,0,1-610.736,95.232Z"
                      transform="translate(610.995 -94.029)"
                      fill="#fd664d"
                    />
                  </g>
                </g>
                <g
                  id="Group_2330"
                  data-name="Group 2330"
                  transform="translate(20.004 9.445)"
                >
                  <path
                    id="Path_4601"
                    data-name="Path 4601"
                    d="M-586.4,91.341s1.679-2.24,2.929-.388-.517,7.324-3.576,5.989Z"
                    transform="translate(587.047 -90.219)"
                    fill="#fc8664"
                  />
                  <g
                    id="Group_2328"
                    data-name="Group 2328"
                    transform="translate(0.409 1.961)"
                  >
                    <path
                      id="Path_4602"
                      data-name="Path 4602"
                      d="M-586.265,95.8a.259.259,0,0,0,.249-.189,4,4,0,0,1,2.241-2.381.259.259,0,0,0,.15-.333.258.258,0,0,0-.333-.15,4.448,4.448,0,0,0-2.556,2.724.258.258,0,0,0,.179.319A.271.271,0,0,0-586.265,95.8Z"
                      transform="translate(586.524 -92.729)"
                      fill="#fd664d"
                    />
                  </g>
                  <g
                    id="Group_2329"
                    data-name="Group 2329"
                    transform="translate(1.317 2.977)"
                  >
                    <path
                      id="Path_4603"
                      data-name="Path 4603"
                      d="M-584.244,95.232a.259.259,0,0,0,.139-.041.259.259,0,0,0,.077-.358,2.357,2.357,0,0,0-.97-.782.258.258,0,0,0-.342.131.258.258,0,0,0,.131.341,1.864,1.864,0,0,1,.746.591A.259.259,0,0,0-584.244,95.232Z"
                      transform="translate(585.362 -94.029)"
                      fill="#fd664d"
                    />
                  </g>
                </g>
                <path
                  id="Path_4604"
                  data-name="Path 4604"
                  d="M-594.231,104.759V110.2s-1.623,2.771-3.817,2.96-4.141-2.386-4.141-2.386v-5.632Z"
                  transform="translate(610.359 -83.951)"
                  fill="#f9af8b"
                />
                <path
                  id="Path_4605"
                  data-name="Path 4605"
                  d="M-594.231,104.759v2.414c-1.472,1.634-4.087,2.213-7.957,1.642v-3.674Z"
                  transform="translate(610.359 -83.951)"
                  fill="#fc8664"
                />
                <path
                  id="Path_4606"
                  data-name="Path 4606"
                  d="M-590.126,84.968a52.953,52.953,0,0,1-.706,6.873c-.1.528-.214,1.024-.341,1.468-1.146,4.009-4.009,10.885-12.028,8.021-3.542-1.264-5.183-5.209-5.914-9.22a38.817,38.817,0,0,1-.535-7.445,5.681,5.681,0,0,1,2.882-4.834c4.733-2.683,9.728-1.683,13.015-.419A5.663,5.663,0,0,1-590.126,84.968Z"
                  transform="translate(611.991 -78.134)"
                  fill="#f9af8b"
                />
                <path
                  id="Path_4607"
                  data-name="Path 4607"
                  d="M-602.191,92.435c0,.581-.3,1.052-.668,1.052s-.669-.471-.669-1.052.3-1.051.669-1.051S-602.191,91.854-602.191,92.435Z"
                  transform="translate(610.652 -81.029)"
                  fill="#2e3052"
                />
                <path
                  id="Path_4608"
                  data-name="Path 4608"
                  d="M-591.626,92.435c0,.581-.3,1.052-.668,1.052s-.669-.471-.669-1.052.3-1.051.669-1.051S-591.626,91.854-591.626,92.435Z"
                  transform="translate(608.344 -81.029)"
                  fill="#2e3052"
                />
                <path
                  id="Path_4609"
                  data-name="Path 4609"
                  d="M-604.668,88.132a11.176,11.176,0,0,1,2.86-.429.5.5,0,0,1,.495.582l0,.008a.494.494,0,0,1-.511.406,12.561,12.561,0,0,0-3.916.48.747.747,0,0,1,.227-.531A1.722,1.722,0,0,1-604.668,88.132Z"
                  transform="translate(611.136 -80.225)"
                  fill="#2e3052"
                />
                <path
                  id="Path_4610"
                  data-name="Path 4610"
                  d="M-591.484,88.159a11.135,11.135,0,0,0-2.86-.428.5.5,0,0,0-.495.582v.008a.5.5,0,0,0,.511.406,12.562,12.562,0,0,1,3.917.48.749.749,0,0,0-.227-.532A1.721,1.721,0,0,0-591.484,88.159Z"
                  transform="translate(608.755 -80.231)"
                  fill="#2e3052"
                />
                <g
                  id="Group_2331"
                  data-name="Group 2331"
                  transform="translate(11.121 10.627)"
                >
                  <path
                    id="Path_4611"
                    data-name="Path 4611"
                    d="M-597.452,96.789a1.091,1.091,0,0,1-.563-.134.792.792,0,0,1-.376-.631,38.5,38.5,0,0,1,.155-4.055.259.259,0,0,1,.279-.237.258.258,0,0,1,.236.279,39.306,39.306,0,0,0-.156,3.958.281.281,0,0,0,.127.241,1.554,1.554,0,0,0,1.037-.1.259.259,0,0,1,.337.141.258.258,0,0,1-.141.337A2.73,2.73,0,0,1-597.452,96.789Z"
                    transform="translate(598.414 -91.732)"
                    fill="#fc8664"
                  />
                </g>
                <path
                  id="Path_4612"
                  data-name="Path 4612"
                  d="M-601.384,100.291a3.078,3.078,0,0,0,3.033,2.792,2.964,2.964,0,0,0,3.02-2.792Z"
                  transform="translate(610.183 -82.975)"
                  fill="#fff"
                />
                <g
                  id="Group_2332"
                  data-name="Group 2332"
                  transform="translate(10.466 20.784)"
                >
                  <path
                    id="Path_4613"
                    data-name="Path 4613"
                    d="M-597.916,105.561a2.585,2.585,0,0,1-1.213-.355.259.259,0,0,1-.084-.356.259.259,0,0,1,.356-.084h0a2.077,2.077,0,0,0,.941.277,2.207,2.207,0,0,0,.993-.278.258.258,0,0,1,.355.088.258.258,0,0,1-.088.354A2.7,2.7,0,0,1-597.916,105.561Z"
                    transform="translate(599.251 -104.728)"
                    fill="#fc8664"
                  />
                </g>
              </g>
              <path
                id="Path_4614"
                data-name="Path 4614"
                d="M-589.265,83.176a.853.853,0,0,1-.622.477,16.387,16.387,0,0,1-.094,1.913c-.319,3.736-1.261,4.392-1.475,3.468a43.14,43.14,0,0,1-.064-5.279,12.131,12.131,0,0,1-1.369-.172l-.867-3.627-.3,3.582c-1.3.452-4.333-.273-4.333-.273l-1.017-3.575-.629,3.439a7.237,7.237,0,0,1-4.387.317l-.56-3.71-1.293,3.71s-4.159,1.492-4.2,0a28,28,0,0,1,1.146-5.207,7.834,7.834,0,0,1,3.778-4.8,13.769,13.769,0,0,1,12.486-.179C-589.1,75.635-589.265,83.176-589.265,83.176Z"
                transform="translate(613.959 -71.86)"
                fill="#2e3052"
              />
              <path
                id="Path_4615"
                data-name="Path 4615"
                d="M-609.162,76.7a24.623,24.623,0,0,0-4.55,11.345c-1.127,7.274-2.686,12.647,1.7,15.05,4.743,2.6,4.333-2.732,4.264-4.181s-4.1-5.409-1.411-16.564A9.7,9.7,0,0,0-609.162,76.7Z"
                transform="translate(614.931 -72.917)"
                fill="#2e3052"
              />
              <path
                id="Path_4616"
                data-name="Path 4616"
                d="M-608.574,95.932v.009c0,.054.005.343.027.8.01.281.027.622.053,1.01.162,2.45.638,6.682,2.038,8.15a4.678,4.678,0,0,0,2.874.99v-1.926a7.623,7.623,0,0,1-3.153-2.927,18.014,18.014,0,0,1-1.744-5.376Z"
                transform="translate(613.542 -77.119)"
                fill="#000b29"
              />
              <path
                id="Path_4617"
                data-name="Path 4617"
                d="M-584.926,98.809a.606.606,0,0,1-.606.606.606.606,0,0,1-.606-.606.606.606,0,0,1,.606-.606A.606.606,0,0,1-584.926,98.809Z"
                transform="translate(608.64 -77.615)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_2399"
        data-name="Group 2399"
        transform="translate(932.955 6729.046)"
      >
        <g
          id="Group_2341"
          data-name="Group 2341"
          transform="translate(246.667 46.325)"
        >
          <g id="Group_2339" data-name="Group 2339" transform="translate(0 0)">
            <g id="Group_2338" data-name="Group 2338">
              <rect
                id="Rectangle_6539"
                data-name="Rectangle 6539"
                width="18.984"
                height="18.984"
                rx="2.642"
                fill="#ffaa01"
              />
            </g>
          </g>
          <g
            id="Group_2340"
            data-name="Group 2340"
            transform="translate(3.028 3.028)"
          >
            <path
              id="Path_4618"
              data-name="Path 4618"
              d="M-355.949,18.291a6.436,6.436,0,0,1-1.48,4.116,6.45,6.45,0,0,1-4.984,2.348,6.449,6.449,0,0,1-4.984-2.348,6.439,6.439,0,0,1-1.48-4.117,6.464,6.464,0,0,1,6.464-6.464A6.464,6.464,0,0,1-355.949,18.291Z"
              transform="translate(368.877 -11.827)"
              fill="#fdcc50"
            />
            <path
              id="Path_4619"
              data-name="Path 4619"
              d="M-357.016,22.955A6.45,6.45,0,0,1-362,25.3a6.449,6.449,0,0,1-4.984-2.348l.087-.172a2.267,2.267,0,0,1,.566-.714,2.261,2.261,0,0,1,.8-.435,4,4,0,0,0,2.577-1.586,2.79,2.79,0,0,1-1.3-1.383c-.732.156-1.257-1.2-.866-1.556a.577.577,0,0,1,.431-.166A2.829,2.829,0,0,1-362,14.336a2.829,2.829,0,0,1,2.685,2.606.577.577,0,0,1,.431.166c.392.359-.134,1.712-.866,1.556a2.452,2.452,0,0,1-1.2,1.3,3.9,3.9,0,0,0,2.483,1.672,2.258,2.258,0,0,1,.8.435,2.278,2.278,0,0,1,.566.714Z"
              transform="translate(368.463 -12.375)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Group_2344"
          data-name="Group 2344"
          transform="translate(0 147.274)"
        >
          <g id="Group_2343" data-name="Group 2343" transform="translate(0 0)">
            <g id="Group_2342" data-name="Group 2342">
              <rect
                id="Rectangle_6540"
                data-name="Rectangle 6540"
                width="18.984"
                height="18.984"
                rx="2.642"
                fill="#ffaa01"
              />
            </g>
          </g>
          <path
            id="Path_4620"
            data-name="Path 4620"
            d="M-677.108,141.212a4.157,4.157,0,0,0-4.441,4.145,4.131,4.131,0,0,0,.581,2.119c.55.927,1.117,1.843,1.64,2.785l1.969,3.548,3-5.417c.176-.317.367-.624.549-.937a4.105,4.105,0,0,0,.569-2.2A4.175,4.175,0,0,0-677.108,141.212Zm-.285,5.4a1.754,1.754,0,0,1-1.754-1.755,1.754,1.754,0,0,1,1.754-1.754,1.753,1.753,0,0,1,1.754,1.754A1.753,1.753,0,0,1-677.394,146.615Z"
            transform="translate(686.885 -138.015)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_2366"
          data-name="Group 2366"
          transform="translate(303.584 37.793)"
        >
          <g
            id="Group_2345"
            data-name="Group 2345"
            transform="translate(21.033 33.769)"
          >
            <path
              id="Path_4621"
              data-name="Path 4621"
              d="M-268.772,40.245a4.242,4.242,0,0,1,4.237,4.237,4.243,4.243,0,0,1-4.237,4.238,4.243,4.243,0,0,1-4.238-4.238A4.243,4.243,0,0,1-268.772,40.245Zm0,7.146a2.912,2.912,0,0,0,2.908-2.909,2.911,2.911,0,0,0-2.908-2.908,2.912,2.912,0,0,0-2.909,2.908A2.912,2.912,0,0,0-268.772,47.391Z"
              transform="translate(273.01 -40.245)"
              fill="#008eff"
            />
          </g>
          <g
            id="Group_2346"
            data-name="Group 2346"
            transform="translate(5.778 18.901)"
          >
            <path
              id="Path_4622"
              data-name="Path 4622"
              d="M-288.293,21.221a4.243,4.243,0,0,1,4.238,4.237,4.243,4.243,0,0,1-4.238,4.238,4.243,4.243,0,0,1-4.237-4.238A4.242,4.242,0,0,1-288.293,21.221Zm0,7.146a2.911,2.911,0,0,0,2.908-2.909,2.912,2.912,0,0,0-2.908-2.908,2.912,2.912,0,0,0-2.908,2.908A2.911,2.911,0,0,0-288.293,28.367Z"
              transform="translate(292.53 -21.221)"
              fill="#ffaa01"
            />
          </g>
          <g id="Group_2353" data-name="Group 2353" transform="translate(0 0)">
            <g
              id="Group_2349"
              data-name="Group 2349"
              transform="translate(4.115)"
            >
              <g
                id="Group_2347"
                data-name="Group 2347"
                transform="translate(0 5.13)"
              >
                <path
                  id="Path_4623"
                  data-name="Path 4623"
                  d="M-293.994,3.6a.665.665,0,0,1,.665.665V7.2a.664.664,0,0,1-.665.664.664.664,0,0,1-.664-.664V4.265A.665.665,0,0,1-293.994,3.6Z"
                  transform="translate(294.658 -3.6)"
                  fill="#008eff"
                />
              </g>
              <g id="Group_2348" data-name="Group 2348">
                <path
                  id="Path_4624"
                  data-name="Path 4624"
                  d="M-293.994-2.964a.665.665,0,0,1,.665.664V.632a.665.665,0,0,1-.665.665.665.665,0,0,1-.664-.665V-2.3A.665.665,0,0,1-293.994-2.964Z"
                  transform="translate(294.658 2.964)"
                  fill="#008eff"
                />
              </g>
            </g>
            <g
              id="Group_2352"
              data-name="Group 2352"
              transform="translate(0 4.03)"
            >
              <g id="Group_2350" data-name="Group 2350">
                <path
                  id="Path_4625"
                  data-name="Path 4625"
                  d="M-299.259,2.193h2.634a.664.664,0,0,1,.664.665.664.664,0,0,1-.664.665h-2.634a.665.665,0,0,1-.664-.665A.665.665,0,0,1-299.259,2.193Z"
                  transform="translate(299.923 -2.193)"
                  fill="#008eff"
                />
              </g>
              <g
                id="Group_2351"
                data-name="Group 2351"
                transform="translate(5.596)"
              >
                <path
                  id="Path_4626"
                  data-name="Path 4626"
                  d="M-292.1,2.193h2.634a.665.665,0,0,1,.664.665.665.665,0,0,1-.664.665H-292.1a.665.665,0,0,1-.665-.665A.665.665,0,0,1-292.1,2.193Z"
                  transform="translate(292.763 -2.193)"
                  fill="#008eff"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_2365"
            data-name="Group 2365"
            transform="translate(23.399 16.427)"
          >
            <g
              id="Group_2364"
              data-name="Group 2364"
              transform="translate(0 0)"
            >
              <g
                id="Group_2358"
                data-name="Group 2358"
                transform="translate(4.115)"
              >
                <g
                  id="Group_2355"
                  data-name="Group 2355"
                  transform="translate(0 5.13)"
                >
                  <g id="Group_2354" data-name="Group 2354">
                    <path
                      id="Path_4627"
                      data-name="Path 4627"
                      d="M-264.052,24.619a.665.665,0,0,1,.665.664v2.932a.665.665,0,0,1-.665.664.665.665,0,0,1-.664-.664V25.283A.665.665,0,0,1-264.052,24.619Z"
                      transform="translate(264.716 -24.619)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
                <g id="Group_2357" data-name="Group 2357">
                  <g id="Group_2356" data-name="Group 2356">
                    <path
                      id="Path_4628"
                      data-name="Path 4628"
                      d="M-264.052,18.055a.664.664,0,0,1,.665.664v2.931a.665.665,0,0,1-.665.665.665.665,0,0,1-.664-.665V18.719A.664.664,0,0,1-264.052,18.055Z"
                      transform="translate(264.716 -18.055)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group_2363"
                data-name="Group 2363"
                transform="translate(0 4.03)"
              >
                <g id="Group_2360" data-name="Group 2360">
                  <g id="Group_2359" data-name="Group 2359">
                    <path
                      id="Path_4629"
                      data-name="Path 4629"
                      d="M-269.317,23.212h2.634a.665.665,0,0,1,.664.665.664.664,0,0,1-.664.664h-2.634a.664.664,0,0,1-.665-.664A.665.665,0,0,1-269.317,23.212Z"
                      transform="translate(269.982 -23.212)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
                <g
                  id="Group_2362"
                  data-name="Group 2362"
                  transform="translate(5.596)"
                >
                  <g id="Group_2361" data-name="Group 2361">
                    <path
                      id="Path_4630"
                      data-name="Path 4630"
                      d="M-262.156,23.212h2.634a.665.665,0,0,1,.664.665.664.664,0,0,1-.664.664h-2.634a.664.664,0,0,1-.665-.664A.665.665,0,0,1-262.156,23.212Z"
                      transform="translate(262.821 -23.212)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2392"
          data-name="Group 2392"
          transform="translate(40.258 0)"
        >
          <g
            id="Group_2367"
            data-name="Group 2367"
            transform="translate(21.917 17.448)"
          >
            <path
              id="Path_4631"
              data-name="Path 4631"
              d="M-604.582-29a4.243,4.243,0,0,1,4.238,4.238,4.243,4.243,0,0,1-4.238,4.238,4.243,4.243,0,0,1-4.238-4.238A4.243,4.243,0,0,1-604.582-29Zm0,1.33a2.911,2.911,0,0,0-2.908,2.908,2.911,2.911,0,0,0,2.908,2.908,2.911,2.911,0,0,0,2.908-2.908A2.911,2.911,0,0,0-604.582-27.667Z"
              transform="translate(608.82 28.997)"
              fill="#008eff"
            />
          </g>
          <g
            id="Group_2371"
            data-name="Group 2371"
            transform="translate(6.483 3.45)"
          >
            <g id="Group_2370" data-name="Group 2370">
              <g id="Group_2369" data-name="Group 2369">
                <g id="Group_2368" data-name="Group 2368">
                  <path
                    id="Path_4632"
                    data-name="Path 4632"
                    d="M-624.331-46.908a4.242,4.242,0,0,1,4.237,4.237,4.243,4.243,0,0,1-4.237,4.238,4.243,4.243,0,0,1-4.238-4.238A4.243,4.243,0,0,1-624.331-46.908Zm0,1.329a2.912,2.912,0,0,0-2.909,2.908,2.912,2.912,0,0,0,2.909,2.909,2.912,2.912,0,0,0,2.908-2.909A2.911,2.911,0,0,0-624.331-45.579Z"
                    transform="translate(628.569 46.908)"
                    fill="#ffaa01"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group_2378"
            data-name="Group 2378"
            transform="translate(0 22.895)"
          >
            <g
              id="Group_2374"
              data-name="Group 2374"
              transform="translate(0 4.115)"
            >
              <g
                id="Group_2372"
                data-name="Group 2372"
                transform="translate(5.13)"
              >
                <path
                  id="Path_4633"
                  data-name="Path 4633"
                  d="M-630.3-16.1a.665.665,0,0,0,.665.665h2.931a.665.665,0,0,0,.665-.665.665.665,0,0,0-.665-.664h-2.931A.665.665,0,0,0-630.3-16.1Z"
                  transform="translate(630.3 16.763)"
                  fill="#008eff"
                />
              </g>
              <g id="Group_2373" data-name="Group 2373">
                <path
                  id="Path_4634"
                  data-name="Path 4634"
                  d="M-636.864-16.1a.665.665,0,0,0,.665.665h2.931a.665.665,0,0,0,.664-.665.665.665,0,0,0-.664-.664H-636.2A.665.665,0,0,0-636.864-16.1Z"
                  transform="translate(636.864 16.763)"
                  fill="#008eff"
                />
              </g>
            </g>
            <g
              id="Group_2377"
              data-name="Group 2377"
              transform="translate(4.031)"
            >
              <g id="Group_2375" data-name="Group 2375">
                <path
                  id="Path_4635"
                  data-name="Path 4635"
                  d="M-631.706-21.364v2.634a.664.664,0,0,0,.664.665.665.665,0,0,0,.665-.665v-2.634a.665.665,0,0,0-.665-.664A.664.664,0,0,0-631.706-21.364Z"
                  transform="translate(631.706 22.028)"
                  fill="#008eff"
                />
              </g>
              <g
                id="Group_2376"
                data-name="Group 2376"
                transform="translate(0 5.596)"
              >
                <path
                  id="Path_4636"
                  data-name="Path 4636"
                  d="M-631.706-14.2v2.634a.664.664,0,0,0,.664.664.665.665,0,0,0,.665-.664V-14.2a.665.665,0,0,0-.665-.665A.664.664,0,0,0-631.706-14.2Z"
                  transform="translate(631.706 14.868)"
                  fill="#008eff"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_2391"
            data-name="Group 2391"
            transform="translate(31.043)"
          >
            <g id="Group_2390" data-name="Group 2390">
              <g
                id="Group_2384"
                data-name="Group 2384"
                transform="translate(0 4.115)"
              >
                <g
                  id="Group_2380"
                  data-name="Group 2380"
                  transform="translate(5.131)"
                >
                  <g id="Group_2379" data-name="Group 2379">
                    <path
                      id="Path_4637"
                      data-name="Path 4637"
                      d="M-590.578-45.393a.665.665,0,0,0,.664.665h2.932a.665.665,0,0,0,.665-.665.665.665,0,0,0-.665-.665h-2.932A.665.665,0,0,0-590.578-45.393Z"
                      transform="translate(590.578 46.058)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
                <g id="Group_2383" data-name="Group 2383">
                  <g id="Group_2382" data-name="Group 2382">
                    <g id="Group_2381" data-name="Group 2381">
                      <path
                        id="Path_4638"
                        data-name="Path 4638"
                        d="M-597.143-45.393a.665.665,0,0,0,.665.665h2.931a.665.665,0,0,0,.665-.665.665.665,0,0,0-.665-.665h-2.931A.665.665,0,0,0-597.143-45.393Z"
                        transform="translate(597.143 46.058)"
                        fill="#ffaa01"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g
                id="Group_2389"
                data-name="Group 2389"
                transform="translate(4.031)"
              >
                <g id="Group_2386" data-name="Group 2386">
                  <g id="Group_2385" data-name="Group 2385">
                    <path
                      id="Path_4639"
                      data-name="Path 4639"
                      d="M-591.985-50.658v2.633a.665.665,0,0,0,.665.665.665.665,0,0,0,.664-.665v-2.633a.665.665,0,0,0-.664-.665A.665.665,0,0,0-591.985-50.658Z"
                      transform="translate(591.985 51.323)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
                <g
                  id="Group_2388"
                  data-name="Group 2388"
                  transform="translate(0 5.596)"
                >
                  <g id="Group_2387" data-name="Group 2387">
                    <path
                      id="Path_4640"
                      data-name="Path 4640"
                      d="M-591.985-43.5v2.634a.665.665,0,0,0,.665.665.665.665,0,0,0,.664-.665V-43.5a.665.665,0,0,0-.664-.664A.665.665,0,0,0-591.985-43.5Z"
                      transform="translate(591.985 44.162)"
                      fill="#ffaa01"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_2398"
          data-name="Group 2398"
          transform="translate(212.578 12.902)"
        >
          <g
            id="Group_2395"
            data-name="Group 2395"
            transform="translate(0 10.943)"
          >
            <g
              id="Group_2393"
              data-name="Group 2393"
              transform="translate(40.138)"
            >
              <path
                id="Path_4641"
                data-name="Path 4641"
                d="M-364.587-19.419a.6.6,0,0,0,.28,0c1.007-.248,1.589-.348,1.6-.349a.529.529,0,0,0,.452-.611.56.56,0,0,0-.652-.424c-.025,0-.626.107-1.68.366a.525.525,0,0,0-.4.643A.544.544,0,0,0-364.587-19.419Z"
                transform="translate(365.011 20.813)"
                fill="#dcedf9"
              />
            </g>
            <g
              id="Group_2394"
              data-name="Group 2394"
              transform="translate(0 1.265)"
            >
              <path
                id="Path_4642"
                data-name="Path 4642"
                d="M-406.863,11.621c-.523.894-1.026,1.821-1.5,2.755a.572.572,0,0,1-.644.286.545.545,0,0,1-.1-.034.513.513,0,0,1-.268-.7c.48-.953.993-1.9,1.526-2.812a.58.58,0,0,1,.761-.209A.511.511,0,0,1-406.863,11.621Z"
                transform="translate(414.853 12.632)"
                fill="#dcedf9"
              />
              <path
                id="Path_4643"
                data-name="Path 4643"
                d="M-410.371,18.79c-.4.945-.789,1.922-1.143,2.9a.568.568,0,0,1-.668.342l-.043-.012a.52.52,0,0,1-.352-.667c.361-1,.753-2,1.164-2.958a.575.575,0,0,1,.73-.292A.516.516,0,0,1-410.371,18.79Z"
                transform="translate(415.547 11.053)"
                fill="#dcedf9"
              />
              <path
                id="Path_4644"
                data-name="Path 4644"
                d="M-415.948,37.567a.53.53,0,0,1-.417-.578c.143-1.047.312-2.1.5-3.114a.558.558,0,0,1,.649-.427.528.528,0,0,1,.456.609c-.187,1-.353,2.036-.495,3.067a.555.555,0,0,1-.628.455A.67.67,0,0,1-415.948,37.567Z"
                transform="translate(416.37 7.695)"
                fill="#dcedf9"
              />
              <path
                id="Path_4645"
                data-name="Path 4645"
                d="M-413.374,25.657a.523.523,0,0,1,.388.649c-.137.463-.27.93-.394,1.391-.143.531-.281,1.075-.411,1.615a.562.562,0,0,1-.67.4l-.015,0a.526.526,0,0,1-.41-.626c.132-.549.273-1.1.418-1.642.127-.469.262-.945.4-1.415a.566.566,0,0,1,.678-.369Z"
                transform="translate(416.048 9.399)"
                fill="#dcedf9"
              />
              <path
                id="Path_4646"
                data-name="Path 4646"
                d="M-404.3,7.481a.576.576,0,0,1-.611.229.566.566,0,0,1-.162-.065.508.508,0,0,1-.175-.727c.6-.888,1.232-1.767,1.887-2.614a.584.584,0,0,1,.784-.117.505.505,0,0,1,.124.735C-403.09,5.751-403.711,6.612-404.3,7.481Z"
                transform="translate(413.958 14.108)"
                fill="#dcedf9"
              />
              <path
                id="Path_4647"
                data-name="Path 4647"
                d="M-369.217-18.17c-1.023.329-2.061.7-3.085,1.093a.59.59,0,0,1-.351.024.551.551,0,0,1-.381-.309.516.516,0,0,1,.3-.688c1.046-.406,2.106-.781,3.152-1.116a.571.571,0,0,1,.712.327A.519.519,0,0,1-369.217-18.17Z"
                transform="translate(406.911 19.194)"
                fill="#dcedf9"
              />
              <path
                id="Path_4648"
                data-name="Path 4648"
                d="M-379.917-13.715a.591.591,0,0,1-.411.05.554.554,0,0,1-.352-.253.511.511,0,0,1,.216-.717c.975-.51,1.98-1,2.985-1.458a.577.577,0,0,1,.749.245.513.513,0,0,1-.26.7C-377.976-14.7-378.961-14.216-379.917-13.715Z"
                transform="translate(408.588 18.528)"
                fill="#dcedf9"
              />
              <path
                id="Path_4649"
                data-name="Path 4649"
                d="M-393.555-4.566a.587.587,0,0,1-.521.127.565.565,0,0,1-.271-.151.5.5,0,0,1,.026-.744c.814-.715,1.664-1.417,2.527-2.086a.587.587,0,0,1,.79.07.506.506,0,0,1-.076.742C-391.925-5.953-392.758-5.266-393.555-4.566Z"
                transform="translate(411.591 16.648)"
                fill="#dcedf9"
              />
              <path
                id="Path_4650"
                data-name="Path 4650"
                d="M-387.041-9.531a.591.591,0,0,1-.467.084.561.561,0,0,1-.316-.2.506.506,0,0,1,.123-.735c.9-.614,1.835-1.213,2.781-1.78a.582.582,0,0,1,.774.16.507.507,0,0,1-.17.727C-385.243-10.72-386.159-10.133-387.041-9.531Z"
                transform="translate(410.156 17.676)"
                fill="#dcedf9"
              />
              <path
                id="Path_4651"
                data-name="Path 4651"
                d="M-399.341,1.132a.582.582,0,0,1-.57.174.564.564,0,0,1-.22-.1.5.5,0,0,1-.074-.741c.712-.808,1.461-1.6,2.226-2.369a.587.587,0,0,1,.792-.023.5.5,0,0,1,.025.744C-397.911-.439-398.644.341-399.341,1.132Z"
                transform="translate(412.866 15.454)"
                fill="#dcedf9"
              />
            </g>
          </g>
          <g
            id="Group_2397"
            data-name="Group 2397"
            transform="translate(39.986)"
          >
            <g id="Group_2396" data-name="Group 2396">
              <path
                id="Path_4652"
                data-name="Path 4652"
                d="M-364.072-26.041l-1.134-8.773,29.725,4.263Z"
                transform="translate(365.206 34.814)"
                fill="#008eff"
              />
              <path
                id="Path_4653"
                data-name="Path 4653"
                d="M-335.253-29.361l-27.46,6.745-.073.019-1.379,1.581.32-3.834Z"
                transform="translate(364.978 33.623)"
                fill="#0077eb"
              />
              <path
                id="Path_4654"
                data-name="Path 4654"
                d="M-364.165-19.125l2.468-.031-1.088-1.551Z"
                transform="translate(364.978 31.732)"
                fill="#0059d3"
              />
              <path
                id="Path_4655"
                data-name="Path 4655"
                d="M-358.945-23.446l-3.456.849,5.621,8,21.912-14.766Z"
                transform="translate(364.593 33.623)"
                fill="#008eff"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const BookWithLadder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="327px"
    viewBox="0 0 511.454 427"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.887"
        y1="2.257"
        x2="0.359"
        y2="-0.142"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e0ecf6" />
        <stop offset="1" stopColor="#bed3e4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.165"
        y1="-0.03"
        x2="0.677"
        y2="0.805"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#72c7bb" />
        <stop offset="0.981" stopColor="#4473e4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1="0.984"
        y1="0.43"
        x2="0.136"
        y2="0.655"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#6ec2fc" />
        <stop offset="0.981" stopColor="#4473e4" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-5"
        x1="1.004"
        y1="1.137"
        x2="0.443"
        y2="0.472"
        xlinkHref="#linear-gradient-4"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="1.582"
        y1="-1.99"
        x2="0.319"
        y2="-1.211"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e3825e" />
        <stop offset="0.376" stopColor="#e38f4b" />
        <stop offset="1" stopColor="#e3a231" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-7"
        x1="0.446"
        y1="-3.489"
        x2="1.324"
        y2="-3.593"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-8"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2f456b" />
        <stop offset="1" stopColor="#40689e" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-9"
        x1="0.5"
        y1="1.071"
        x2="0.5"
        y2="0.231"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-10"
        x1="0.515"
        y1="0.5"
        x2="-0.012"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.367" stopColor="#fbfcfd" />
        <stop offset="0.709" stopColor="#f0f5fa" />
        <stop offset="1" stopColor="#e0ecf6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-11"
        x1="0"
        y1="0.5"
        x2="1"
        y2="0.5"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-17"
        x1="0.656"
        y1="0.5"
        x2="0.984"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.452" stopColor="#fcfdfe" />
        <stop offset="0.717" stopColor="#f4f8fb" />
        <stop offset="0.932" stopColor="#e6eff7" />
        <stop offset="1" stopColor="#e0ecf6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-25"
        x1="0.382"
        y1="-0.193"
        x2="0.606"
        y2="1.137"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-26"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.854" stopColor="#e0ecf6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-37"
        x1="0"
        y1="0.5"
        x2="1"
        y2="0.5"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-38"
        x1="0"
        y1="0.5"
        x2="1"
        y2="0.5"
        xlinkHref="#linear-gradient-3"
      />
      <linearGradient
        id="linear-gradient-55"
        x1="0"
        y1="0.5"
        x2="1"
        y2="0.5"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-56"
        x1="-2.093"
        y1="3.271"
        x2="-2.683"
        y2="3.901"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-57"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e3825e" />
        <stop offset="0.563" stopColor="#d3595f" />
        <stop offset="1" stopColor="#c93f60" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-74"
        x1="1.066"
        y1="0.059"
        x2="0.176"
        y2="0.753"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-76"
        x1="0.5"
        y1="1.226"
        x2="0.5"
        y2="-2.063"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e0ecf6" />
        <stop offset="1" stopColor="#80939c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-81"
        x1="0.5"
        y1="0.277"
        x2="0.5"
        y2="0.942"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-82"
        x1="0.5"
        y1="0.93"
        x2="0.5"
        y2="0.093"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-83"
        x1="-0.917"
        y1="0.5"
        x2="0.812"
        y2="0.5"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-84"
        x1="-0.384"
        y1="0.5"
        x2="0.867"
        y2="0.5"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-85"
        x1="0.926"
        y1="0.5"
        x2="0.072"
        y2="0.5"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-86"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ebb340" />
        <stop offset="1" stopColor="#f3d646" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-87"
        x1="0.5"
        y1="-0.017"
        x2="0.5"
        y2="0.783"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-88"
        x1="0.5"
        y1="0.718"
        x2="0.5"
        y2="-0.331"
        xlinkHref="#linear-gradient-86"
      />
      <linearGradient
        id="linear-gradient-89"
        x1="0.5"
        y1="0.155"
        x2="0.5"
        y2="0.83"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ebae8e" />
        <stop offset="1" stopColor="#f0c894" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-90"
        x1="0.223"
        y1="0.5"
        x2="1.085"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#302254" />
        <stop offset="0.997" stopColor="#452a52" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-91"
        x1="0.5"
        y1="0.082"
        x2="0.5"
        y2="0.655"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-92"
        x1="0"
        y1="0.5"
        x2="1"
        y2="0.5"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-93"
        x1="0.5"
        y1="0.45"
        x2="0.5"
        y2="1.027"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-94"
        x1="0.5"
        y1="0.928"
        x2="0.5"
        y2="0.347"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-95"
        x1="0.5"
        y1="1"
        x2="0.5"
        y2="0"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-96"
        x1="0.5"
        y1="0.188"
        x2="0.5"
        y2="0.566"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#5ca1ed" />
        <stop offset="0.997" stopColor="#467cd1" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-98"
        x1="0.5"
        y1="0.212"
        x2="0.5"
        y2="1.019"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#5ca1ed" />
        <stop offset="0.997" stopColor="#4d87e2" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-99"
        x1="0.866"
        y1="0.402"
        x2="0.213"
        y2="0.606"
        xlinkHref="#linear-gradient-10"
      />
      <linearGradient
        id="linear-gradient-100"
        x1="-0.672"
        y1="0.5"
        x2="2.159"
        y2="0.5"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-101"
        x1="0.5"
        y1="1.242"
        x2="0.5"
        y2="0.439"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-102"
        x1="1.698"
        y1="0.919"
        x2="-0.297"
        y2="0.221"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-103"
        x1="-0.29"
        y1="0.195"
        x2="1.353"
        y2="0.83"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-104"
        x1="0.5"
        y1="0.998"
        x2="0.5"
        y2="0.034"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-106"
        x1="0.5"
        y1="0.161"
        x2="0.5"
        y2="1.52"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-108"
        x1="0.5"
        y1="0.117"
        x2="0.5"
        y2="1.184"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-109"
        x1="0.5"
        y1="0.205"
        x2="0.5"
        y2="0.77"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-110"
        x1="0.5"
        y1="0.084"
        x2="0.5"
        y2="0.55"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-112"
        x1="0.5"
        y1="0.687"
        x2="0.5"
        y2="-0.255"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-113"
        x1="0.423"
        y1="-0.116"
        x2="0.641"
        y2="1.108"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-114"
        x1="-2.717"
        y1="1.768"
        x2="-2.717"
        y2="0.768"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-116"
        x1="-2.492"
        y1="1.715"
        x2="-2.492"
        y2="0.715"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-117"
        x1="-0.681"
        y1="-0.089"
        x2="-0.681"
        y2="0.912"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-119"
        x1="0.5"
        y1="0.236"
        x2="0.5"
        y2="0.883"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-120"
        x1="-1.812"
        y1="2.154"
        x2="-1.812"
        y2="1.149"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-121"
        x1="-0.661"
        y1="-0.193"
        x2="-0.661"
        y2="0.964"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-122"
        x1="0.5"
        y1="1.206"
        x2="0.5"
        y2="0.63"
        xlinkHref="#linear-gradient-89"
      />
      <linearGradient
        id="linear-gradient-123"
        x1="1.189"
        y1="-3.99"
        x2="1.189"
        y2="-3.544"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-124"
        x1="1.197"
        y1="-4.862"
        x2="1.197"
        y2="-4.386"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-125"
        x1="4.387"
        y1="-20.637"
        x2="4.387"
        y2="-19.791"
        xlinkHref="#linear-gradient-10"
      />
      <linearGradient
        id="linear-gradient-126"
        x1="6.782"
        y1="-26.683"
        x2="6.782"
        y2="-25.996"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-127"
        x1="2.202"
        y1="-12.109"
        x2="2.202"
        y2="-11.669"
        xlinkHref="#linear-gradient-90"
      />
      <linearGradient
        id="linear-gradient-128"
        x1="0.5"
        y1="0.196"
        x2="0.5"
        y2="1.144"
        xlinkHref="#linear-gradient-6"
      />
      <linearGradient
        id="linear-gradient-130"
        x1="1.066"
        y1="-0.295"
        x2="0.217"
        y2="1.021"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-131"
        x1="0.895"
        y1="-0.094"
        x2="0.183"
        y2="0.982"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-132"
        x1="1.034"
        y1="-0.567"
        x2="0.039"
        y2="1.122"
        xlinkHref="#linear-gradient-8"
      />
      <linearGradient
        id="linear-gradient-133"
        x1="0.5"
        y1="0.355"
        x2="0.5"
        y2="2.906"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f5de42" />
        <stop offset="1" stopColor="#e8a32d" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-134"
        x1="0.5"
        y1="-0.109"
        x2="0.5"
        y2="2.916"
        xlinkHref="#linear-gradient-133"
      />
      <linearGradient
        id="linear-gradient-135"
        x1="0.5"
        y1="-2.892"
        x2="0.5"
        y2="2.976"
        xlinkHref="#linear-gradient-133"
      />
      <linearGradient
        id="linear-gradient-136"
        x1="0.5"
        y1="-5.177"
        x2="0.5"
        y2="0.284"
        xlinkHref="#linear-gradient-133"
      />
      <linearGradient
        id="linear-gradient-139"
        x1="0.5"
        y1="-5.177"
        x2="0.5"
        y2="0.285"
        xlinkHref="#linear-gradient-133"
      />
    </defs>
    <g
      id="Group_157"
      data-name="Group 157"
      transform="translate(877.413 17.619)"
    >
      <g
        id="Group_156"
        data-name="Group 156"
        transform="translate(-877.413 -17.619)"
      >
        <path
          id="Path_41"
          data-name="Path 41"
          d="M-633.931,345.865c0,3.59-15.543,6.5-34.715,6.5s-34.715-2.911-34.715-6.5,15.543-6.5,34.715-6.5S-633.931,342.275-633.931,345.865Z"
          transform="translate(923.006 74.633)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M-487.428,316.079c0,4.388-18.995,7.946-42.428,7.946s-42.427-3.558-42.427-7.946,18.995-7.945,42.427-7.945S-487.428,311.692-487.428,316.079Z"
          transform="translate(943.941 69.646)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_43"
          data-name="Path 43"
          d="M-530.151,125.782c-9.805-30.318-27.65-61.766-59.573-73.193-16.864-6.037-35.689-5.634-53.5-2.731s-35.064,8.188-52.739,11.729a274.566,274.566,0,0,1-67.482,4.986c-21.479-1.07-44.263-4.448-63.55,4.358-19.4,8.858-30.816,28.77-33.156,48.653s3.116,39.845,10.1,58.775,15.57,37.465,19.847,57.071a134.746,134.746,0,0,1,2.107,45.019c-.89,7.28-2.359,14.772-.2,21.823,4.777,15.635,24.454,22.27,41.635,25.695,40.038,7.982,81.517,11.14,122.074,5.846s80.216-19.327,112.046-43.155a108.5,108.5,0,0,0,19.016-17.528c13.62-16.565,18.747-37.709,18.988-58.2.195-16.609-5.513-33.14-7.019-49.74C-522.777,151.809-526,138.63-530.151,125.782Z"
          transform="translate(897.877 28.069)"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_44"
          data-name="Path 44"
          d="M-505.463,341.608c17.654.387,41.9-1,57.294-9.655,12.44-6.994,26.08-24.324,29.214-39.658,5.462-26.716,1.861-51.858-12.713-72.349-12.489-17.558-29.045-33.281-34.747-54.059-4.664-17,.6-36.617-8.475-51.734-8.707-14.5-27.426-19.287-44.321-20.167-28.195-1.471-56.444,4.278-82.951,14-56.224,20.62-105.863,59.962-136.465,111.439-16.055,27.006-26.845,56.947-43.74,83.434-5.456,8.555-11.538,16.735-16.365,25.677-1.921,3.558-5.985,9.674-5.963,13.83.029,5.309,4.408,2.513,8.074,2.061,23.126-2.856,47.518-1.776,70.81-2.38q70.809-1.837,141.652-1.495Q-544.807,340.744-505.463,341.608Z"
          transform="translate(906.822 35.407)"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M-449.585,199.062c-7.937-10.237-15.181-20.809-18.574-33.174-4.664-17,.6-36.617-8.475-51.734-8.707-14.5-27.426-19.287-44.321-20.167-28.2-1.471-56.444,4.278-82.951,14q-11.464,4.2-22.526,9.442c-.053.1-.118.212-.162.315-.038-.06-.084-.119-.123-.18-46.716,22.119-87.265,57.472-113.653,101.861-16.055,27.006-26.845,56.947-43.74,83.434-3.132,4.913-6.466,9.7-9.656,14.583l.6.676v.316c0,1.561,1.869,2.585,3.509,1.932,12.125-4.827,55.962-17.753,136.064,6.078,0,0,7.431,2.515,10.442,5.9,2.2,2.475,4.577,6.333,9.694,8.232q6.939-.038,13.877-.059c5.01-1.918,7.362-5.722,9.542-8.172,3.011-3.383,10.441-5.9,10.441-5.9,80.093-23.827,123.929-10.907,136.061-6.079,1.641.653,3.93-.056,3.514-2.247l10.436-11.763Z"
          transform="translate(908.567 35.407)"
          opacity="0.4"
          fill="url(#linear-gradient-5)"
          style={{ mixBlendMode: "multiply", isolation: "isolate" }}
        />
        <g id="Group_112" data-name="Group 112" transform="translate(335.017)">
          <path
            id="Path_46"
            data-name="Path 46"
            d="M-598.19,133.664c-.4,7.337-6.49,14.176-4.512,21.254,1.454,5.208,7.051,8.386,12.448,8.729s10.658-1.546,15.719-3.451c5.822-2.191,11.75-4.518,16.439-8.606s7.966-10.34,6.869-16.463c-1.056-5.891-5.946-11.235-4.639-17.077,1.573-7.022,11.1-9.82,13.009-16.759,2.041-7.419-5.809-14.559-4.729-22.178,1.256-8.861,13.782-13.511,13.477-22.457-.218-6.417-7.311-11.368-6.632-17.753.6-5.618,6.986-9.192,7.852-14.775,1.315-8.477-10.342-13.824-11.34-22.345-.694-5.919,3.8-12.831-.122-17.323-2.541-2.914-7.449-2.556-10.529-.219s-4.734,6.063-6.093,9.682-2.615,7.413-5.214,10.275c-4.277,4.711-11.732,6.4-14.413,12.167-2.969,6.391,1.679,13.8.972,20.817-1,9.933-12.354,16.724-12.574,26.706-.124,5.652,3.471,10.883,3.382,16.535C-589,91.6-602.91,98.607-603.829,109.752-604.509,117.988-597.738,125.413-598.19,133.664Z"
            transform="translate(603.877 17.619)"
            fill="#e38c50"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M-578.792,106.192l.013-.017A54.374,54.374,0,0,1-564.608,91.03c1.354-.983-.091-3.149-1.45-2.161a57.534,57.534,0,0,0-12.405,12.284q2.586-19.9,7.108-39.487a1.3,1.3,0,0,0,.331-.521l16.359-13.159c1.3-1.044-.143-3.214-1.451-2.161l-14.19,11.415q3.831-15.8,8.9-31.27a1.338,1.338,0,0,0,.337-.174l11.635-8.351c1.36-.976-.086-3.141-1.451-2.161l-9.146,6.564q2.534-7.449,5.356-14.8c.6-1.554-1.74-2.7-2.343-1.134q-3.129,8.148-5.9,16.422a20.367,20.367,0,0,1-4.98-6.7c-.695-1.511-3.169-.7-2.469.823A23.459,23.459,0,0,0-563.8,25q-5.24,15.947-9.163,32.27a25.673,25.673,0,0,1-3.045-10.179,1.3,1.3,0,0,0-2.6.162,28.494,28.494,0,0,0,4.786,13.625q-4.4,19.033-7,38.419a79.343,79.343,0,0,0-4.514-10.021c-.792-1.48-2.946-.015-2.16,1.45a74.769,74.769,0,0,1,5.908,14.6,412.2,412.2,0,0,0-2.671,62.5c.056,1.683,2.653,1.511,2.6-.162a409.571,409.571,0,0,1,2.5-60.822A1.258,1.258,0,0,0-578.792,106.192Z"
            transform="translate(606.467 21.257)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_113"
          data-name="Group 113"
          transform="translate(357.633 46.601)"
        >
          <path
            id="Path_48"
            data-name="Path 48"
            d="M-474.58,30.6c7.6,9.679,4.144,24.454-3.74,33.9s-19.147,15.278-29.159,22.435c-20.993,15.007-36.715,36.1-51.886,56.976-2.745,3.777-5.627,7.687-9.746,9.884s-9.859,2.116-12.859-1.462c-3.287-3.923-1.947-9.8-.3-14.652a278.937,278.937,0,0,1,54.1-93.827c6.957-7.948,14.718-15.733,24.62-19.41S-481.1,22.3-474.58,30.6Z"
            transform="translate(584.438 -22.565)"
            fill="url(#linear-gradient-6)"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M-558.7,110.438a1.138,1.138,0,0,1-.212-1.112,145.946,145.946,0,0,1,18.152-39.059c.918-1.392,3.192-.121,2.264,1.284a143.7,143.7,0,0,0-15.541,31.19c2.17-3.459,4.41-6.878,6.753-10.234,7.763-11.119,26.458-31.253,26.664-31.555q8.089-11.786,16.177-23.571c.942-1.372,3.216-.1,2.264,1.285l-11.386,16.59a202.413,202.413,0,0,1,18.555-14.172c1.4-.942,2.726,1.3,1.342,2.23a197.69,197.69,0,0,0-30.068,25.028,281.968,281.968,0,0,1,30.165-16.353c1.509-.7,2.862,1.523,1.343,2.23A281.7,281.7,0,0,0-531.07,76.193l-.022.01a203.768,203.768,0,0,0-15,19.124c-4.809,6.949-9.2,14.153-13.332,21.513a212.546,212.546,0,0,1,32.571-27.1c1.394-.943,2.725,1.294,1.342,2.23A209.455,209.455,0,0,0-563.8,125.283a1.341,1.341,0,0,1-.357.278c-6.322,12-12.08,24.325-17.816,36.587-.711,1.519-2.972.228-2.264-1.284C-576.26,143.8-568.214,126.7-558.7,110.438Z"
            transform="translate(584.376 -20.293)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_114"
          data-name="Group 114"
          transform="translate(363.262 108.711)"
        >
          <path
            id="Path_50"
            data-name="Path 50"
            d="M-558.03,98.3c-3.438,3.354-9.05,2.9-13.318,5.1-7.067,3.652-8.337,13.881-4.706,20.958s10.638,11.691,17.4,15.873c3.766,2.325,7.631,4.653,11.938,5.669s9.2.525,12.585-2.325c3.944-3.32,5.288-9.255,9.752-11.835,7.527-4.352,17.374,3.951,25.455.743,7.6-3.018,9.412-14.587,17.325-16.648,6-1.563,12.934,3.314,18.247.111,4.009-2.418,4.659-8.176,8.251-11.177,3.648-3.048,8.981-2.334,13.723-2.659s10.425-3.377,9.75-8.082c-.438-3.052-3.5-5.068-6.5-5.785s-6.164-.551-9.142-1.352c-8.537-2.3-14.97-12.214-23.66-10.6-4.151.774-7.371,4.12-11.393,5.4-8.849,2.817-18.645-4.96-27.37-1.781-6.184,2.252-9.717,9.394-16.047,11.2-4.534,1.293-9.292-.491-13.85-1.7s-10.026-1.627-13.4,1.664C-555.1,93.136-555.921,96.247-558.03,98.3Z"
            transform="translate(579.761 -76.121)"
            fill="url(#linear-gradient-7)"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M-451.849,91.961q-6.3.7-12.662,1.43l3.751-4.689c.855-1.068-.677-2.557-1.537-1.483q-2.3,2.877-4.6,5.756a1.075,1.075,0,0,0-.245.728q-5.781.691-11.582,1.466a17.991,17.991,0,0,1,5.432-5.011,1.068,1.068,0,0,0-1.1-1.829,20.193,20.193,0,0,0-7.1,7.223q-6.235.873-12.453,1.91a31.126,31.126,0,0,1,6.552-6.654c1.094-.82,0-2.653-1.1-1.829a34.067,34.067,0,0,0-8.357,8.985c-5.042.888-10.063,1.882-15.04,3.012a45.678,45.678,0,0,1,7.976-7.67c1.092-.82,0-2.653-1.1-1.828a48.79,48.79,0,0,0-10.118,10.258c-4.975,1.2-9.9,2.536-14.763,4.054a25.914,25.914,0,0,1,6.3-7.484c1.067-.871-.485-2.341-1.537-1.483a27.2,27.2,0,0,0-7.618,9.881q-6.165,2.027-12.158,4.473l3.817-7.927c.595-1.236-1.278-2.263-1.875-1.021l-4.91,10.2a139.382,139.382,0,0,0-31.2,18.408c-1.068.843.025,2.679,1.1,1.828a138.209,138.209,0,0,1,30.569-18.1l.024.006a1.13,1.13,0,0,0,.2.155,84.741,84.741,0,0,0,20.1,8.776c1.322.384,1.806-1.7.486-2.079a82.377,82.377,0,0,1-18.392-7.89q6.033-2.515,12.253-4.576a1.217,1.217,0,0,0,.276.153,1.116,1.116,0,0,0,.325.283l16.625,9.655c1.192.692,2.21-1.184,1.022-1.874l-15.62-9.072q7.005-2.227,14.187-3.978a53.767,53.767,0,0,0,16.33,10.679c1.257.526,2.29-1.343,1.022-1.875a52.17,52.17,0,0,1-14.856-9.4c5.308-1.251,10.669-2.34,16.059-3.305a1.188,1.188,0,0,0,.266.2,1.122,1.122,0,0,0,.313.292l12.852,8.106c1.165.735,2.18-1.145,1.022-1.875L-494.4,99.7q6.415-1.094,12.86-1.991a1.076,1.076,0,0,0,1.014.1,1.144,1.144,0,0,0,.121.081l10.125,5.514a1.068,1.068,0,0,0,1.021-1.875l-8.07-4.4q6.029-.8,12.045-1.5a1.117,1.117,0,0,0,.413.222l7.3,2.2c1.317.4,1.8-1.684.486-2.079l-3.086-.929q4.208-.478,8.39-.937C-450.427,93.947-450.477,91.81-451.849,91.961Z"
            transform="translate(579.523 -74.408)"
            fill="#e8e9ea"
          />
        </g>
        <g
          id="Group_127"
          data-name="Group 127"
          transform="translate(94.575 110.715)"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M-466.779,264.213l-37.73-19.005-130.849,22.127-130.85-22.127-37.729,19.005,2.006,17v.318c0,1.56,1.871,2.584,3.509,1.932,12.126-4.827,55.962-17.754,136.066,6.077,0,0,7.43,2.515,10.441,5.9s6.322,9.368,16.557,9.368,13.547-5.985,16.557-9.368,10.441-5.9,10.441-5.9c80.093-23.827,123.928-10.907,136.06-6.079,1.642.653,3.931-.056,3.515-2.248h0Z"
            transform="translate(812.368 -51.119)"
            fill="url(#linear-gradient-8)"
          />
          <path
            id="Path_53"
            data-name="Path 53"
            d="M-465.618,260.922l-37.73-19.005L-634.2,264.045l-130.85-22.128-37.729,19.005-8.43,9.055,10.436,11.763s43.942-23.941,139.575,4.511c0,0,7.943,2.644,10.441,4.626,4.933,3.913,6.322,10.419,16.557,10.419s12.013-6.625,15.817-10.055c1.815-1.635,11.181-4.99,11.181-4.99,95.633-28.452,139.575-4.511,139.575-4.511l10.435-11.763Z"
            transform="translate(811.207 -51.645)"
            fill="url(#linear-gradient-9)"
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M-658.575,305.188s107.168-43.718,177.008-9.368V97.84c-68.107-37.666-170.518-5.942-177.008,9.368Z"
            transform="translate(835.584 -77.487)"
            fill="url(#linear-gradient-10)"
          />
          <g
            id="Group_115"
            data-name="Group 115"
            transform="translate(204.007 29.35)"
          >
            <path
              id="Path_55"
              data-name="Path 55"
              d="M-589.533,106.815a456.048,456.048,0,0,0-45.762,9.412v4.9c8.871-2.395,83.919-21.909,124.517-8.683v-4.983C-529.368,101.948-555.831,101.727-589.533,106.815Z"
              transform="translate(635.295 -103.156)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_116"
            data-name="Group 116"
            transform="translate(204.007 57.94)"
          >
            <path
              id="Path_56"
              data-name="Path 56"
              d="M-589.533,131.468a456.5,456.5,0,0,0-45.762,9.411v4.9c8.871-2.395,83.919-21.909,124.517-8.683v-4.983C-529.368,126.6-555.831,126.379-589.533,131.468Z"
              transform="translate(635.295 -127.809)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_117"
            data-name="Group 117"
            transform="translate(204.007 86.529)"
          >
            <path
              id="Path_57"
              data-name="Path 57"
              d="M-589.533,156.12a456.4,456.4,0,0,0-45.762,9.412v4.9c8.871-2.395,83.919-21.908,124.517-8.682v-4.984C-529.368,151.253-555.831,151.032-589.533,156.12Z"
              transform="translate(635.295 -152.461)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_118"
            data-name="Group 118"
            transform="translate(204.007 115.119)"
          >
            <path
              id="Path_58"
              data-name="Path 58"
              d="M-589.533,180.773a456.054,456.054,0,0,0-45.762,9.412v4.9c8.871-2.395,83.919-21.909,124.517-8.683v-4.983C-529.368,175.906-555.831,175.684-589.533,180.773Z"
              transform="translate(635.295 -177.114)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_119"
            data-name="Group 119"
            transform="translate(204.007 143.709)"
          >
            <path
              id="Path_59"
              data-name="Path 59"
              d="M-589.533,205.425a456.734,456.734,0,0,0-45.762,9.412v4.9c8.871-2.395,83.919-21.909,124.517-8.683v-4.984C-529.368,200.559-555.831,200.337-589.533,205.425Z"
              transform="translate(635.295 -201.766)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_120"
            data-name="Group 120"
            transform="translate(204.007 172.299)"
          >
            <path
              id="Path_60"
              data-name="Path 60"
              d="M-589.533,230.078a456.045,456.045,0,0,0-45.762,9.412v4.9c8.871-2.395,83.919-21.909,124.517-8.683v-4.983C-529.368,225.211-555.831,224.99-589.533,230.078Z"
              transform="translate(635.295 -226.419)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <path
            id="Path_61"
            data-name="Path 61"
            d="M-634.2,305.549s-107.169-43.718-177.009-9.368V98.2c68.729-37.459,160.442-16.527,177.009,9.368Z"
            transform="translate(811.207 -77.848)"
            fill="url(#linear-gradient-17)"
          />
          <g
            id="Group_121"
            data-name="Group 121"
            transform="translate(25.493 29.35)"
          >
            <path
              id="Path_62"
              data-name="Path 62"
              d="M-710.469,106.815a456.133,456.133,0,0,1,45.762,9.412v4.9c-8.872-2.395-83.92-21.909-124.518-8.683v-4.983C-770.634,101.948-744.171,101.727-710.469,106.815Z"
              transform="translate(789.225 -103.156)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_122"
            data-name="Group 122"
            transform="translate(25.493 57.94)"
          >
            <path
              id="Path_63"
              data-name="Path 63"
              d="M-710.469,131.468a456.588,456.588,0,0,1,45.762,9.411v4.9c-8.872-2.395-83.92-21.909-124.518-8.683v-4.983C-770.634,126.6-744.171,126.379-710.469,131.468Z"
              transform="translate(789.225 -127.809)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_123"
            data-name="Group 123"
            transform="translate(25.493 86.529)"
          >
            <path
              id="Path_64"
              data-name="Path 64"
              d="M-710.469,156.12a456.483,456.483,0,0,1,45.762,9.412v4.9c-8.872-2.395-83.92-21.908-124.518-8.682v-4.984C-770.634,151.253-744.171,151.032-710.469,156.12Z"
              transform="translate(789.225 -152.461)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_124"
            data-name="Group 124"
            transform="translate(25.493 115.119)"
          >
            <path
              id="Path_65"
              data-name="Path 65"
              d="M-710.469,180.773a456.139,456.139,0,0,1,45.762,9.412v4.9c-8.872-2.395-83.92-21.909-124.518-8.683v-4.983C-770.634,175.906-744.171,175.684-710.469,180.773Z"
              transform="translate(789.225 -177.114)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_125"
            data-name="Group 125"
            transform="translate(25.493 143.709)"
          >
            <path
              id="Path_66"
              data-name="Path 66"
              d="M-710.469,205.425a456.82,456.82,0,0,1,45.762,9.412v4.9c-8.872-2.395-83.92-21.909-124.518-8.683v-4.984C-770.634,200.559-744.171,200.337-710.469,205.425Z"
              transform="translate(789.225 -201.766)"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <g
            id="Group_126"
            data-name="Group 126"
            transform="translate(25.493 172.299)"
          >
            <path
              id="Path_67"
              data-name="Path 67"
              d="M-710.469,230.078a456.13,456.13,0,0,1,45.762,9.412v4.9c-8.872-2.395-83.92-21.909-124.518-8.683v-4.983C-770.634,225.211-744.171,224.99-710.469,230.078Z"
              transform="translate(789.225 -226.419)"
              fill="url(#linear-gradient-11)"
            />
          </g>
        </g>
        <path
          id="Path_68"
          data-name="Path 68"
          d="M-624.957,229.554c-.429-.453-1.164-.079-1.645.317-2.834,2.328-3.24,2.4-5.285,5.485-1.062,1.605-2.055,3.349-3.629,4.416,0,0-11.7,9.435-15.432,8.235-4.707-1.511-10-7.055-13.512-8.789a5.377,5.377,0,0,0-.411-.431,9.518,9.518,0,0,0-6.246-2.428l-.151.03a20.949,20.949,0,0,1-.1-6.664,3.548,3.548,0,0,0,2.579.221,8.151,8.151,0,0,0,.994-3.284,8.706,8.706,0,0,1,.158-2.36c.449.248,1.478.291,1.508-.235a6.3,6.3,0,0,0-.588-1.995c-.6-1.469-.074-3.691.335-5.514a2.621,2.621,0,0,0,2.447-1.96,5.33,5.33,0,0,0-1.49-4.954,8.772,8.772,0,0,0-3.3-2.257,21.3,21.3,0,0,0-4.434-1.658c-4.165-.917-8.568.828-11.595,3.933-7.113,7.3-3.847,21.706-6.081,25.993-1.251,2.4-2.8,4.676-3.558,7.288s-.536,5.76,1.38,7.627a5.57,5.57,0,0,0,1.527,1.041,21.733,21.733,0,0,0-3.6,13.137c.561,7.637,4.326,14.421,7.3,18.689-.3,3.614-.622,6.531-.871,8.1a3.113,3.113,0,0,0,.6.233c-.057.521-.114,1.1-.168,1.7,2.788.764,5.61,1.561,8.492,2.418,0,0,7.43,2.517,10.441,5.9,2.14,2.4,4.446,6.114,9.269,8.065a146.408,146.408,0,0,0-1.988-20.425,1.554,1.554,0,0,0,.371-1.342c-.687-3.441-2.4-11.018-2.752-12.784a16.8,16.8,0,0,1-.313-3.348,29.7,29.7,0,0,1,1.262-9.117c.958-2.593,3.3-5.016,4.013-8.479a24.115,24.115,0,0,0,6.472,2.046c4.794.7,19.961-13.227,20.929-14.484,1.352-1.753,3.16-1.886,4.856-3.284a4.753,4.753,0,0,0,1.3-2.483c-.048-.927-1.227-1.545-1.408-1.823-.5-.763.956-1.738,1.988-3.065C-624.909,230.765-624.524,230.011-624.957,229.554Zm-62.206,44.4c-1.052-3.534-1.72-7.128-1.493-9.659a16.659,16.659,0,0,1,1.658-5.8C-686.795,263.126-686.907,268.674-687.163,273.95Z"
          transform="translate(924.32 53.251)"
          fill="url(#linear-gradient-11)"
          style={{ mixBlendMode: "multiply", isolation: "isolate" }}
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M-491.843,251.122l-7.5-5.437c.627-10.245,1.459-19.8.586-22.159-1.658-4.472-5.248-5.424-6.294-5.935a23.087,23.087,0,0,1-.429-3.2,2.979,2.979,0,0,0,2.462-2.681c.372-2.922,2.681-5.28,2.948-8.214s-.129-6.166-2.177-8.284a11.089,11.089,0,0,0-4.466-2.525,20.293,20.293,0,0,0-6.322-1.117,8.421,8.421,0,0,0-3.276.48,3.558,3.558,0,0,0-2.416,3.108,2.373,2.373,0,0,0,.5,1.517,2.618,2.618,0,0,0,1.825.925c-.146.761-.24,1.542-.435,2.29a8.238,8.238,0,0,1-2.308,3.89c-.539.508-1.214,1.189-.9,1.86.262.565,1.038.638,1.513,1.039a2,2,0,0,1,.365,2.142c-.241.731-.688,1.385-.893,2.127a1.852,1.852,0,0,0,.568,2.074c.852.529,2.036-.126,2.947.3.811.377,1.246,2.967,1.178,4.267a13.858,13.858,0,0,0-7.706,3.558,44.738,44.738,0,0,0-4.4,4.86l-12.968-9.4a2.33,2.33,0,0,0-3.255.518l-2.788,3.843-17.651-12.8a42.866,42.866,0,0,0-12.321-55.478,42.865,42.865,0,0,0-59.868,9.537,42.865,42.865,0,0,0,9.538,59.867,42.865,42.865,0,0,0,56.56-5.527l17.652,12.8-2.788,3.843a2.307,2.307,0,0,0-.419,1.108,4.054,4.054,0,0,0-1.138,3.023,8.451,8.451,0,0,0,1.649,4.192,9.96,9.96,0,0,0,1.73,2.063c.015.019.026.041.042.06a3.456,3.456,0,0,0,2.111,1.11c.012.07.016.139.032.21a26.7,26.7,0,0,0,9.285.753l12.5,9.063c-.683,4.17-1.36,8.552-2,13.375.652.365,1.359.709,2.1,1.034-1.291,5.568-2.729,13.893-4.1,23.1a136.61,136.61,0,0,1,28.012,4.962q.325-2.369.756-4.72c.9-4.9,2.145-9.723,2.75-14.673a42.4,42.4,0,0,0,.308-6.937,6.981,6.981,0,0,0,1.527-1.028,36.132,36.132,0,0,1-.485-3.973l8.382-11.559A2.33,2.33,0,0,0-491.843,251.122Z"
          transform="translate(932.568 43.514)"
          fill="url(#linear-gradient-25)"
          style={{ mixBlendMode: "multiply", isolation: "isolate" }}
        />
        <g
          id="Group_141"
          data-name="Group 141"
          transform="translate(0 205.262)"
        >
          <g
            id="Group_131"
            data-name="Group 131"
            transform="translate(22.925 117.892)"
          >
            <path
              id="Path_70"
              data-name="Path 70"
              d="M-721.773,291.236v46.841l23.941-27.064V264.173Z"
              transform="translate(897.14 -260.529)"
              fill="url(#linear-gradient-26)"
            />
            <g
              id="Group_128"
              data-name="Group 128"
              transform="translate(3.644 30.707)"
            >
              <path
                id="Path_71"
                data-name="Path 71"
                d="M-846.427,287.509a23.446,23.446,0,0,0-23.42,23.42,23.446,23.446,0,0,0,23.42,23.42h148.3V287.509Z"
                transform="translate(869.847 -287.509)"
                fill="url(#linear-gradient-26)"
              />
            </g>
            <path
              id="Rectangle_101"
              data-name="Rectangle 101"
              d="M1.571,0H161.582a0,0,0,0,1,0,0V3.643a0,0,0,0,1,0,0H1.571A1.571,1.571,0,0,1,0,2.073v-.5A1.571,1.571,0,0,1,1.571,0Z"
              transform="translate(13.785 52.306)"
              fill="url(#linear-gradient-11)"
            />
            <g
              id="Group_129"
              data-name="Group 129"
              transform="translate(175.367 11.638)"
            >
              <path
                id="Path_72"
                data-name="Path 72"
                d="M-721.773,313.3l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(721.773 -268.987)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_73"
                data-name="Path 73"
                d="M-721.773,319.806l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(721.773 -267.948)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_74"
                data-name="Path 74"
                d="M-721.773,326.313l23.926-25.575V297.1l-23.926,25.575Z"
                transform="translate(721.773 -266.909)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_75"
                data-name="Path 75"
                d="M-721.773,306.792l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(721.773 -270.027)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_76"
                data-name="Path 76"
                d="M-721.773,300.284l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(721.773 -271.066)"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <path
              id="Path_77"
              data-name="Path 77"
              d="M-700.022,303.27H-856.14a1.822,1.822,0,0,1-1.821-1.822h0a1.821,1.821,0,0,1,1.821-1.822h156.118Z"
              transform="translate(875.389 -254.867)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_78"
              data-name="Path 78"
              d="M-700.584,296.762H-852.622a1.821,1.821,0,0,1-1.822-1.821h0a1.821,1.821,0,0,1,1.822-1.822h152.038Z"
              transform="translate(875.951 -255.906)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_79"
              data-name="Path 79"
              d="M-700.022,312.641H-856.14a1.821,1.821,0,0,0-1.821,1.822h0a1.821,1.821,0,0,0,1.821,1.821h156.118Z"
              transform="translate(875.389 -252.788)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_80"
              data-name="Path 80"
              d="M-700.584,319.148H-852.622a1.821,1.821,0,0,0-1.822,1.822h0a1.822,1.822,0,0,0,1.822,1.822h152.038Z"
              transform="translate(875.951 -251.749)"
              fill="url(#linear-gradient-37)"
            />
            <g
              id="Group_130"
              data-name="Group 130"
              transform="translate(0 27.064)"
            >
              <path
                id="Path_81"
                data-name="Path 81"
                d="M-691.55,338.5H-845.925a27.1,27.1,0,0,1-27.064-27.064,27.094,27.094,0,0,1,27.064-27.063H-691.55v3.643H-845.925a23.446,23.446,0,0,0-23.42,23.42,23.446,23.446,0,0,0,23.42,23.42H-691.55Z"
                transform="translate(872.989 -284.368)"
                fill="url(#linear-gradient-38)"
              />
            </g>
            <path
              id="Path_82"
              data-name="Path 82"
              d="M-716.537,288.095l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(897.976 -261.031)"
              fill="url(#linear-gradient-38)"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M-716.537,331.626l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(897.976 -254.078)"
              fill="url(#linear-gradient-38)"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M-822.967,261.031a27,27,0,0,0-20.231,9.114h0l-23.63,26.719h0a27.021,27.021,0,0,1,16.8-8.584,27.412,27.412,0,0,1,3.118-.184h154.375l20.718-23.42,3.223-3.644Z"
              transform="translate(873.972 -261.031)"
              fill="url(#linear-gradient-38)"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M-697.832,304.563h6.072L-715.7,331.626h-6.072Z"
              transform="translate(897.14 -254.078)"
              fill="url(#linear-gradient-38)"
            />
          </g>
          <g
            id="Group_135"
            data-name="Group 135"
            transform="translate(0 58.946)"
          >
            <path
              id="Path_86"
              data-name="Path 86"
              d="M-741.542,240.408v46.841l23.941-27.064V213.345Z"
              transform="translate(916.908 -209.701)"
              fill="url(#linear-gradient-26)"
            />
            <g
              id="Group_132"
              data-name="Group 132"
              transform="translate(3.643 30.707)"
            >
              <path
                id="Path_87"
                data-name="Path 87"
                d="M-866.2,236.681a23.447,23.447,0,0,0-23.42,23.42,23.447,23.447,0,0,0,23.42,23.42h148.3V236.681Z"
                transform="translate(889.616 -236.681)"
                fill="url(#linear-gradient-26)"
              />
            </g>
            <path
              id="Rectangle_102"
              data-name="Rectangle 102"
              d="M1.571,0H161.582a0,0,0,0,1,0,0V3.643a0,0,0,0,1,0,0H1.571A1.571,1.571,0,0,1,0,2.073v-.5A1.571,1.571,0,0,1,1.571,0Z"
              transform="translate(13.784 52.306)"
              fill="url(#linear-gradient-11)"
            />
            <g
              id="Group_133"
              data-name="Group 133"
              transform="translate(175.366 11.638)"
            >
              <path
                id="Path_88"
                data-name="Path 88"
                d="M-741.542,262.471l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(741.542 -218.159)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_89"
                data-name="Path 89"
                d="M-741.542,268.978l23.926-25.575V239.76l-23.926,25.575Z"
                transform="translate(741.542 -217.12)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_90"
                data-name="Path 90"
                d="M-741.542,275.485l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(741.542 -216.081)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_91"
                data-name="Path 91"
                d="M-741.542,255.964l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(741.542 -219.199)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_92"
                data-name="Path 92"
                d="M-741.542,249.456l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(741.542 -220.238)"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <path
              id="Path_93"
              data-name="Path 93"
              d="M-719.791,252.442H-875.908a1.822,1.822,0,0,1-1.822-1.822h0a1.821,1.821,0,0,1,1.822-1.822h156.117Z"
              transform="translate(895.157 -204.039)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M-720.353,245.934H-872.391a1.821,1.821,0,0,1-1.821-1.821h0a1.821,1.821,0,0,1,1.821-1.822h152.038Z"
              transform="translate(895.719 -205.078)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_95"
              data-name="Path 95"
              d="M-719.791,261.813H-875.908a1.821,1.821,0,0,0-1.822,1.822h0a1.821,1.821,0,0,0,1.822,1.821h156.117Z"
              transform="translate(895.157 -201.96)"
              fill="url(#linear-gradient-11)"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M-720.353,268.32H-872.391a1.821,1.821,0,0,0-1.821,1.822h0a1.822,1.822,0,0,0,1.821,1.822h152.038Z"
              transform="translate(895.719 -200.921)"
              fill="url(#linear-gradient-11)"
            />
            <g
              id="Group_134"
              data-name="Group 134"
              transform="translate(0 27.064)"
            >
              <path
                id="Path_97"
                data-name="Path 97"
                d="M-711.319,287.667H-865.694A27.094,27.094,0,0,1-892.757,260.6a27.093,27.093,0,0,1,27.063-27.063h154.375v3.643H-865.694a23.447,23.447,0,0,0-23.421,23.42,23.447,23.447,0,0,0,23.421,23.42h154.375Z"
                transform="translate(892.757 -233.54)"
                fill="url(#linear-gradient-55)"
              />
            </g>
            <path
              id="Path_98"
              data-name="Path 98"
              d="M-736.306,237.267l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(917.744 -210.203)"
              fill="url(#linear-gradient-56)"
            />
            <path
              id="Path_99"
              data-name="Path 99"
              d="M-736.306,280.8l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(917.744 -203.25)"
              fill="url(#linear-gradient-57)"
            />
            <path
              id="Path_100"
              data-name="Path 100"
              d="M-842.736,210.2a27,27,0,0,0-20.231,9.114h0L-886.6,246.036h0a27.017,27.017,0,0,1,16.8-8.584,27.394,27.394,0,0,1,3.117-.184H-712.3l20.718-23.42,3.223-3.644Z"
              transform="translate(893.74 -210.203)"
              fill="url(#linear-gradient-55)"
            />
            <path
              id="Path_101"
              data-name="Path 101"
              d="M-717.6,253.735h6.072L-735.47,280.8h-6.072Z"
              transform="translate(916.908 -203.25)"
              fill="url(#linear-gradient-55)"
            />
          </g>
          <g id="Group_140" data-name="Group 140" transform="translate(25.252)">
            <path
              id="Path_102"
              data-name="Path 102"
              d="M-719.768,189.58v46.841l23.941-27.064V162.517Z"
              transform="translate(895.134 -158.873)"
              fill="url(#linear-gradient-26)"
            />
            <g
              id="Group_136"
              data-name="Group 136"
              transform="translate(3.643 30.707)"
            >
              <path
                id="Path_103"
                data-name="Path 103"
                d="M-844.422,185.853a23.447,23.447,0,0,0-23.42,23.42,23.447,23.447,0,0,0,23.42,23.42h148.3V185.853Z"
                transform="translate(867.842 -185.853)"
                fill="url(#linear-gradient-26)"
              />
            </g>
            <g
              id="Group_137"
              data-name="Group 137"
              transform="translate(175.366 11.638)"
            >
              <path
                id="Path_104"
                data-name="Path 104"
                d="M-719.768,211.643l23.926-25.575v-3.643L-719.768,208Z"
                transform="translate(719.768 -167.331)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_105"
                data-name="Path 105"
                d="M-719.768,218.15l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(719.768 -166.292)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_106"
                data-name="Path 106"
                d="M-719.768,224.657l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(719.768 -165.253)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_107"
                data-name="Path 107"
                d="M-719.768,205.136l23.926-25.575v-3.643l-23.926,25.575Z"
                transform="translate(719.768 -168.371)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_108"
                data-name="Path 108"
                d="M-719.768,198.628l23.926-25.575V169.41l-23.926,25.575Z"
                transform="translate(719.768 -169.41)"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <g
              id="Group_138"
              data-name="Group 138"
              transform="translate(13.784 37.213)"
            >
              <path
                id="Rectangle_103"
                data-name="Rectangle 103"
                d="M1.571,0H161.582a0,0,0,0,1,0,0V3.643a0,0,0,0,1,0,0H1.571A1.571,1.571,0,0,1,0,2.073v-.5A1.571,1.571,0,0,1,1.571,0Z"
                transform="translate(0 15.093)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_109"
                data-name="Path 109"
                d="M-698.017,201.614H-854.134a1.822,1.822,0,0,1-1.822-1.822h0a1.821,1.821,0,0,1,1.822-1.822h156.117Z"
                transform="translate(859.599 -190.424)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_110"
                data-name="Path 110"
                d="M-698.579,195.106H-850.617a1.821,1.821,0,0,1-1.821-1.821h0a1.821,1.821,0,0,1,1.821-1.822h152.038Z"
                transform="translate(860.161 -191.463)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_111"
                data-name="Path 111"
                d="M-698.017,210.985H-854.134a1.821,1.821,0,0,0-1.822,1.822h0a1.821,1.821,0,0,0,1.822,1.821h156.117Z"
                transform="translate(859.599 -188.345)"
                fill="url(#linear-gradient-11)"
              />
              <path
                id="Path_112"
                data-name="Path 112"
                d="M-698.579,217.492H-850.617a1.821,1.821,0,0,0-1.821,1.822h0a1.822,1.822,0,0,0,1.821,1.822h152.038Z"
                transform="translate(860.161 -187.306)"
                fill="url(#linear-gradient-11)"
              />
            </g>
            <g
              id="Group_139"
              data-name="Group 139"
              transform="translate(0 27.064)"
            >
              <path
                id="Path_113"
                data-name="Path 113"
                d="M-689.545,236.839H-843.92a27.094,27.094,0,0,1-27.063-27.064,27.093,27.093,0,0,1,27.063-27.063h154.375v3.643H-843.92a23.447,23.447,0,0,0-23.42,23.42,23.447,23.447,0,0,0,23.42,23.42h154.375Z"
                transform="translate(870.983 -182.712)"
                fill="url(#linear-gradient-8)"
              />
            </g>
            <path
              id="Path_114"
              data-name="Path 114"
              d="M-714.532,186.439l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(895.97 -159.375)"
              fill="url(#linear-gradient-8)"
            />
            <path
              id="Path_115"
              data-name="Path 115"
              d="M-714.532,229.97l23.941-27.064v3.643l-23.941,27.064Z"
              transform="translate(895.97 -152.422)"
              fill="url(#linear-gradient-74)"
            />
            <path
              id="Path_116"
              data-name="Path 116"
              d="M-820.962,159.375a27,27,0,0,0-20.231,9.114h0l-23.629,26.719h0a27.018,27.018,0,0,1,16.8-8.584,27.406,27.406,0,0,1,3.117-.184h154.375l20.718-23.42,3.223-3.644Z"
              transform="translate(871.966 -159.375)"
              fill="url(#linear-gradient-8)"
            />
            <ellipse
              id="Ellipse_146"
              data-name="Ellipse 146"
              cx="34.405"
              cy="8.309"
              rx="34.405"
              ry="8.309"
              transform="translate(77.752 6.99)"
              fill="url(#linear-gradient-76)"
              style={{ mixBlendMode: "multiply", isolation: "isolate" }}
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M-695.827,202.907h6.072L-713.7,229.97h-6.072Z"
              transform="translate(895.134 -152.422)"
              fill="url(#linear-gradient-8)"
            />
          </g>
        </g>
        <path
          id="Path_118"
          data-name="Path 118"
          d="M-793.042,355.625l10.477-176.661a1.834,1.834,0,0,0-1.785-1.85,1.255,1.255,0,0,0-1.3,1.2l-1.089,18.371h-29.146l1.051-17.722a1.833,1.833,0,0,0-1.784-1.85,1.255,1.255,0,0,0-1.3,1.2l-10.515,177.311h3.123l.115-1.947h29.146l-.116,1.947Zm-22.987-156.511h29.146l-1.166,19.652h-29.146Zm-1.309,22.081h29.145l-1.164,19.651H-818.5Zm-1.309,22.081H-789.5l-1.166,19.651h-29.145Zm-1.309,22.08h29.145l-1.166,19.651h-29.145Zm-1.309,22.081h29.145l-1.166,19.651h-29.145Zm-1.311,22.08h29.146l-1.165,19.652h-29.145Zm-2.475,41.732,1.166-19.651h29.146l-1.165,19.651Z"
          transform="translate(903.03 48.72)"
          fill="url(#linear-gradient-11)"
          style={{ mixBlendMode: "multiply", isolation: "isolate" }}
        />
        <path
          id="Path_119"
          data-name="Path 119"
          d="M-781.573,178.314l-4,18.371H-814.72l3.859-17.722a1.525,1.525,0,0,0-1.49-1.85h0a1.524,1.524,0,0,0-1.49,1.2l-41.273,189.474a1.525,1.525,0,0,0,1.49,1.85h0a1.527,1.527,0,0,0,1.492-1.2l3.215-14.76h29.146l-3.074,14.11a1.526,1.526,0,0,0,1.491,1.85h0a1.526,1.526,0,0,0,1.49-1.2l41.273-189.474a1.526,1.526,0,0,0-1.491-1.85h0A1.526,1.526,0,0,0-781.573,178.314Zm-4.531,20.8-4.281,19.652h-29.146l4.282-19.652Zm-52.665,107.974,4.281-19.651h29.146l-4.281,19.651Zm28.616,2.428-4.281,19.652h-29.146l4.282-19.652Zm-23.807-24.509,4.281-19.651h29.146l-4.281,19.651Zm4.811-22.08,4.28-19.653h29.145L-800,262.927Zm4.809-22.081,4.281-19.651h29.146l-4.281,19.651Zm-24.049,110.4,4.28-19.651h29.146l-4.281,19.651Z"
          transform="translate(898.763 48.72)"
          fill="url(#linear-gradient-8)"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M-513.979,242.853a1.414,1.414,0,0,1-.879-.54,16.256,16.256,0,0,1-3.677-7.049,40.086,40.086,0,0,1-1.059-7.95c-.238-4.169-.292-8.44.992-12.412,1.243-3.844.595-10.47,1.573-14.39,9.114,3.618,12.485,17.078,12.577,19.023a14.262,14.262,0,0,1-1.217,5.505,146.1,146.1,0,0,1-7.1,16.775C-513.032,242.315-513.418,242.887-513.979,242.853Z"
          transform="translate(952.334 52.457)"
          fill="url(#linear-gradient-55)"
        />
        <g
          id="Group_143"
          data-name="Group 143"
          transform="translate(174.504 24.478)"
        >
          <path
            id="Path_121"
            data-name="Path 121"
            d="M-709.937,31.267V67.791h0c0,3.953,18.846,12.541,42.094,12.541s42.1-8.588,42.1-12.541V31.267Z"
            transform="translate(747.452 0.948)"
            fill="url(#linear-gradient-81)"
          />
          <path
            id="Path_122"
            data-name="Path 122"
            d="M-663.473,3.488l-73.821,25.246,73.821,25.246,73.821-25.246Z"
            transform="translate(743.083 -3.488)"
            fill="url(#linear-gradient-82)"
          />
          <path
            id="Path_123"
            data-name="Path 123"
            d="M-599.818,25.257v3.49L-673.64,53.993V50.5Z"
            transform="translate(753.249 -0.011)"
            fill="url(#linear-gradient-83)"
          />
          <path
            id="Path_124"
            data-name="Path 124"
            d="M-737.294,25.257-663.473,50.5v3.491l-73.821-25.246Z"
            transform="translate(743.083 -0.011)"
            fill="url(#linear-gradient-84)"
          />
          <g
            id="Group_142"
            data-name="Group 142"
            transform="translate(4.884 23.639)"
          >
            <path
              id="Path_125"
              data-name="Path 125"
              d="M-737.17,57.039a.9.9,0,0,1-.9-.9V24.775a.9.9,0,0,1,.9-.9h73.821a.9.9,0,0,1,.9.9.9.9,0,0,1-.9.9h-72.917V56.135A.9.9,0,0,1-737.17,57.039Z"
              transform="translate(738.074 -23.872)"
              fill="url(#linear-gradient-85)"
            />
          </g>
          <path
            id="Path_126"
            data-name="Path 126"
            d="M-733.852,52.86h-5.71a1.412,1.412,0,0,1-1.411-1.413h0a1.412,1.412,0,0,1,1.411-1.411h5.71a1.412,1.412,0,0,1,1.413,1.411h0A1.412,1.412,0,0,1-733.852,52.86Z"
            transform="translate(742.495 3.946)"
            fill="url(#linear-gradient-86)"
          />
          <path
            id="Path_127"
            data-name="Path 127"
            d="M-733.64,52.471h-5.716l-2.93,10.114h11.576Z"
            transform="translate(742.286 4.335)"
            fill="url(#linear-gradient-87)"
          />
          <path
            id="Path_128"
            data-name="Path 128"
            d="M-667.563,24.984c0,1.337-2.391,2.42-5.342,2.42s-5.341-1.083-5.341-2.42,2.391-2.419,5.341-2.419S-667.563,23.648-667.563,24.984Z"
            transform="translate(752.514 -0.441)"
            fill="url(#linear-gradient-88)"
          />
        </g>
        <g
          id="Group_144"
          data-name="Group 144"
          transform="translate(104.964 62.953)"
        >
          <path
            id="Path_129"
            data-name="Path 129"
            d="M-765.307,164.211a.82.82,0,0,0-.253-.539.657.657,0,0,0-.442-.066l-5.441.477a7.82,7.82,0,0,1,.307,3.008,3.655,3.655,0,0,0,.9,2.768,3.155,3.155,0,0,0,2.2.507,5.493,5.493,0,0,0,1.363-.144,2.042,2.042,0,0,0,1.155-.778,3.348,3.348,0,0,0,.444-1.707A14.465,14.465,0,0,0-765.307,164.211Z"
            transform="translate(807.168 -16.392)"
            fill="url(#linear-gradient-89)"
          />
          <path
            id="Path_130"
            data-name="Path 130"
            d="M-771.85,174.419a12.686,12.686,0,0,1,.769-7.537,4.368,4.368,0,0,0,3.107,1.3,4.413,4.413,0,0,0,3.086-1.378c.334-.344.833.193,1.1.6s.412.906.675,1.317a3.222,3.222,0,0,0,2.527,1.32c.957.085,1.925-.052,2.877.085a2.818,2.818,0,0,1,1.365.528,3.243,3.243,0,0,1,1.057,2.231,10.432,10.432,0,0,1-.175,2.592c-3.433,1.364-7.253,1.031-10.839.355C-768.171,175.481-770.013,174.944-771.85,174.419Z"
            transform="translate(807.061 -15.897)"
            fill="url(#linear-gradient-90)"
          />
          <path
            id="Path_131"
            data-name="Path 131"
            d="M-782.767,162.123c.024-.2-.974.377-1.12.39-1.757.153-3.217-.893-4.974-.74.119.872-.058,3.406-.051,4.393a2.953,2.953,0,0,0,.923,2.405,3.065,3.065,0,0,0,2.127.491,5.322,5.322,0,0,0,1.321-.139,1.981,1.981,0,0,0,1.119-.754,3.24,3.24,0,0,0,.43-1.655C-782.926,165.372-782.951,163.636-782.767,162.123Z"
            transform="translate(804.378 -16.686)"
            fill="url(#linear-gradient-91)"
          />
          <path
            id="Path_132"
            data-name="Path 132"
            d="M-789.52,172.887a12.289,12.289,0,0,1,.746-7.3,4.233,4.233,0,0,0,3.009,1.259,4.277,4.277,0,0,0,2.991-1.335c.324-.334.806.187,1.066.583s2.174.325,3.1.406,1.866-.051,2.789.082a2.74,2.74,0,0,1,1.323.51,3.144,3.144,0,0,1,1.022,2.163,10.044,10.044,0,0,1-.168,2.511C-776.967,173.086-787.74,173.4-789.52,172.887Z"
            transform="translate(804.24 -16.104)"
            fill="url(#linear-gradient-92)"
          />
          <path
            id="Path_133"
            data-name="Path 133"
            d="M-793.074,101.836c-1.744,4.824-1.947,3.673-1.4,8.837s4.62,17.141,5.467,22.251a114.174,114.174,0,0,1-.331,39.017,17.314,17.314,0,0,0,10.323.088c1.025-6.8,2.009-13.67,3.034-20.472a96.2,96.2,0,0,0,1.481-15.466c-.079-6.414-1.436-12.751-1.37-19.167.042-4.1.663-8.262-.089-12.274a4.215,4.215,0,0,0-1.03-2.345c-1.181-1.1-2.933-.393-4.392.148C-784.391,103.571-789.984,102.6-793.074,101.836Z"
            transform="translate(803.447 -26.273)"
            fill="url(#linear-gradient-93)"
          />
          <path
            id="Path_134"
            data-name="Path 134"
            d="M-790.831,105.52c-.22,5.2,2.31,9.962,4.7,14.409,4.45,8.284,8.727,16.743,11.749,25.806s4.766,18.8,3.993,28.454a14.225,14.225,0,0,0,10.608-.656c2.573-18.713-1.421-37.832-4.939-56.33-1.032-5.423-2.151-11.06-5.236-15.382a16.247,16.247,0,0,1-8.6,5.32,23.778,23.778,0,0,1-9.718.266,4.238,4.238,0,0,1-1.666-.522A1.582,1.582,0,0,1-790.831,105.52Z"
            transform="translate(804.069 -26.258)"
            fill="url(#linear-gradient-94)"
          />
          <path
            id="Path_135"
            data-name="Path 135"
            d="M-791.328,64.148a11.735,11.735,0,0,0,.648-4.744c-.15-3.222-1.292-6.614-1.077-9.832a17.688,17.688,0,0,1,1.525-6.7,8.219,8.219,0,0,1,5.02-4.445c2.228-.59,5.492.193,6.628,2.2.783,1.384.455,2.369.857,3.907a6.738,6.738,0,0,0,1.93,3.745c.574.542,1.293,1.268.96,1.984-.279.6-1.105.678-1.612,1.108-.626.53.185,2.075.443,2.854s.139,2.056.358,2.847a1.479,1.479,0,0,1-.807,1.778c-.909.564-2.129.066-3.1.517-.986.458-1.155,4.631-.9,5.687s.956-.465.738.6c-.3,1.48-1.876,2.331-3.349,2.667a10.916,10.916,0,0,1-4.884-.013,4.227,4.227,0,0,1-2.438-1.324A4.478,4.478,0,0,1-791.328,64.148Z"
            transform="translate(803.919 -36.413)"
            fill="url(#linear-gradient-95)"
          />
          <path
            id="Path_136"
            data-name="Path 136"
            d="M-785.762,52.8a3.83,3.83,0,0,1-2.4-2.956,3.828,3.828,0,0,1,1.424-3.529,1.706,1.706,0,0,1,1.109-.419.888.888,0,0,1,.784.939,3.471,3.471,0,0,0,1.067,2.425.87.87,0,0,0,1.072.176l.013-.007a.842.842,0,0,0,.363-1.08,5.3,5.3,0,0,1-.576-2.853,2.6,2.6,0,0,1,.385-1.087c1.5-2.312,4.96-.219,6.616-2.3a2.534,2.534,0,0,0,.53-1.617,3.8,3.8,0,0,0-2.577-3.313,8.979,8.979,0,0,0-3.491-.513,21.666,21.666,0,0,0-6.739,1.191,11.808,11.808,0,0,0-4.761,2.691,11.588,11.588,0,0,0-2.781,8.686A20.935,20.935,0,0,0-790.2,61.157a16.975,16.975,0,0,0,2.261-.466,4.348,4.348,0,0,0,1.693-.706,1.77,1.77,0,0,0,.718-1.8c-.189-.645-.5-2.962-.158-3.519S-785.15,53.025-785.762,52.8Z"
            transform="translate(803.287 -36.665)"
          />
          <path
            id="Path_137"
            data-name="Path 137"
            d="M-791.784,106.814a1.542,1.542,0,0,0,.959-.589,17.756,17.756,0,0,0,4.015-7.7,43.749,43.749,0,0,0,1.156-8.68c.26-4.552.319-9.216-1.083-13.555-1.356-4.2-1.853-11.432-2.92-15.712-5.88,2.885-11.595,14.586-12.529,20.772-.31,2.043.615,4.072,1.328,6.012a159.556,159.556,0,0,0,7.757,18.318C-792.82,106.227-792.4,106.851-791.784,106.814Z"
            transform="translate(802.248 -32.845)"
            fill="url(#linear-gradient-96)"
          />
          <path
            id="Path_138"
            data-name="Path 138"
            d="M-747.869,54.354a63.861,63.861,0,0,0,4.37-7.172,32.133,32.133,0,0,1,4.82-6.855c1.963-2,4.986-2.706,5.359-2.094.36.588-.132,2.121-1.419,3.327-.661.618-2.086,2.446-1.578,3.2a4.943,4.943,0,0,1,.132,5.141c-.864,1.156-2.479,1.433-3.6,2.338-1.765,1.419-3.137,4.076-4.322,6.158-.915-.327-1.562-1.677-2.249-2.365A6.139,6.139,0,0,0-747.869,54.354Z"
            transform="translate(810.933 -36.448)"
            fill="url(#linear-gradient-95)"
          />
          <path
            id="Path_139"
            data-name="Path 139"
            d="M-766.651,80.185a2.389,2.389,0,0,1,1.75-.825c12.982-2.178,24.874-11.56,29.753-23.786a6.525,6.525,0,0,0-4.972-4.247c-3.817,4.531-13.4,11.37-14.779,11.955a38.07,38.07,0,0,1-6.558,2.319,12.755,12.755,0,0,1-6.876-.18c-1.487-.513-8.334-2.274-11.43-3a15.712,15.712,0,0,1-10.8-.37,10.681,10.681,0,0,0-3.078,2.137,10.571,10.571,0,0,0-2.688,4.722c-1.172,4.9,2.326,35.085-.1,47.923-.377,1.991,8,3.427,10.018,3.58a34.547,34.547,0,0,0,15.607-2.862c1.322-.588,6.712-3.461,6.352-4.862C-765.588,108.294-767.014,80.774-766.651,80.185Z"
            transform="translate(803.154 -34.323)"
            fill="url(#linear-gradient-98)"
          />
        </g>
        <g
          id="Group_150"
          data-name="Group 150"
          transform="translate(287.73 175.079)"
        >
          <g
            id="Group_145"
            data-name="Group 145"
            transform="translate(6.987 7.316)"
          >
            <path
              id="Path_140"
              data-name="Path 140"
              d="M-564.737,157.29a15.094,15.094,0,0,0-4.508-4.618,1.819,1.819,0,0,1-.742-.277c-8.44-5.322-17.567-12.3-27.951-12.717-5.525-.224-10.12,1.443-14.952,4-4.472,2.366-9.13,4.575-12.867,8.052-5.677,5.281-12.3,12.122-12.779,20.259a60.862,60.862,0,0,0,3.152,22.723c2.635,7.54,7.419,14.927,14.837,18.487,7.361,3.53,15.691,3.59,23.688,3.452,6.784-.118,13.6-.674,19.232-4.882,5.986-4.473,9.985-11.743,12.668-18.579a52.211,52.211,0,0,0,3.332-24.238C-561.978,165.029-562.713,160.733-564.737,157.29Z"
              transform="translate(638.627 -139.658)"
              fill="url(#linear-gradient-99)"
            />
            <path
              id="Path_141"
              data-name="Path 141"
              d="M-609.644,175.917c-9.863,1.723-19.314,3.63-28.09,5.563a57.118,57.118,0,0,0,2.208,8.433c.081.232.171.464.256.7a560.713,560.713,0,0,1,72.814-11.333,51.986,51.986,0,0,0,.919-9.579Q-583.949,171.438-609.644,175.917Z"
              transform="translate(638.769 -134.86)"
              fill="url(#linear-gradient-100)"
            />
          </g>
          <g id="Group_148" data-name="Group 148">
            <path
              id="Path_142"
              data-name="Path 142"
              d="M-561.858,215.308a1.772,1.772,0,0,1,1.7.169,2.085,2.085,0,0,1,.538,1.847,6.278,6.278,0,0,0-.092,1.992c.259,1.145,1.4,1.833,2.432,2.384s2.178,1.22,2.467,2.358a2.565,2.565,0,0,1-1.683,2.861,3.954,3.954,0,0,1-3.461-.564,9.124,9.124,0,0,1-2.441-2.664,9.022,9.022,0,0,1-1.758-4.468A3.938,3.938,0,0,1-561.858,215.308Z"
              transform="translate(657.507 -120.281)"
              fill="url(#linear-gradient-101)"
            />
            <rect
              id="Rectangle_104"
              data-name="Rectangle 104"
              width="11.059"
              height="31.584"
              transform="matrix(0.587, -0.81, 0.81, 0.587, 72.413, 71.901)"
              fill="url(#linear-gradient-102)"
            />
            <rect
              id="Rectangle_105"
              data-name="Rectangle 105"
              width="26.15"
              height="67.647"
              rx="2.142"
              transform="matrix(0.587, -0.81, 0.81, 0.587, 93.551, 96.551)"
              fill="url(#linear-gradient-103)"
            />
            <path
              id="Path_143"
              data-name="Path 143"
              d="M-572.128,142.055a45.7,45.7,0,0,0-63.818,10.167A45.7,45.7,0,0,0-625.78,216.04a45.7,45.7,0,0,0,63.818-10.167A45.7,45.7,0,0,0-572.128,142.055Zm1.6,57.605a35.112,35.112,0,0,1-49.038,7.812,35.111,35.111,0,0,1-7.812-49.037,35.113,35.113,0,0,1,49.038-7.813A35.112,35.112,0,0,1-570.529,199.66Z"
              transform="translate(644.652 -133.349)"
              fill="url(#linear-gradient-104)"
            />
            <g
              id="Group_146"
              data-name="Group 146"
              transform="translate(35.524 16.148)"
            >
              <path
                id="Path_144"
                data-name="Path 144"
                d="M-610.758,154.729a3.265,3.265,0,0,1-3.148-2.409,3.263,3.263,0,0,1,2.294-4.006,29.5,29.5,0,0,1,8.771-1.024,3.265,3.265,0,0,1,3.155,3.37,3.262,3.262,0,0,1-3.371,3.154,23.186,23.186,0,0,0-6.842.8A3.224,3.224,0,0,1-610.758,154.729Z"
                transform="translate(614.02 -147.273)"
                fill="#fff"
              />
            </g>
            <g
              id="Group_147"
              data-name="Group 147"
              transform="translate(16.433 21.315)"
            >
              <path
                id="Path_145"
                data-name="Path 145"
                d="M-627.214,175.494a3.424,3.424,0,0,1-.484-.035,3.264,3.264,0,0,1-2.748-3.709A29.909,29.909,0,0,1-618.8,152.38a3.263,3.263,0,0,1,4.568.658,3.265,3.265,0,0,1-.658,4.569,23.328,23.328,0,0,0-9.1,15.1A3.265,3.265,0,0,1-627.214,175.494Z"
                transform="translate(630.482 -151.729)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_149"
            data-name="Group 149"
            transform="translate(94.923 50.156)"
          >
            <path
              id="Path_146"
              data-name="Path 146"
              d="M-545.063,308.144a.871.871,0,0,1,.284-.571.732.732,0,0,1,.485-.064l5.944.584c-.187,1.183-.327,3.51-.327,3.51a3.215,3.215,0,0,1-1.077,2.633,3.531,3.531,0,0,1-2.409.509,6.186,6.186,0,0,1-1.489-.173,2.231,2.231,0,0,1-1.251-.847,3.49,3.49,0,0,1-.461-1.827A15.1,15.1,0,0,1-545.063,308.144Z"
              transform="translate(565.584 -155.691)"
              fill="url(#linear-gradient-95)"
            />
            <path
              id="Path_147"
              data-name="Path 147"
              d="M-536.3,319.126a13.209,13.209,0,0,0-.732-8.047,4.846,4.846,0,0,1-3.416,1.342,4.883,4.883,0,0,1-3.356-1.514c-.359-.372-.913.194-1.211.626s-.463.96-.757,1.4c-.624.925-3.006,1.3-4.054,1.372s-2.106-.083-3.15.049a3.13,3.13,0,0,0-1.5.543,3.427,3.427,0,0,0-1.187,2.365,10.867,10.867,0,0,0,.153,2.766c3.571.917,12.323,1.13,16.255.459C-537.2,320.133-536.7,319.956-536.3,319.126Z"
              transform="translate(563.935 -155.166)"
              fill="url(#linear-gradient-106)"
            />
            <path
              id="Path_148"
              data-name="Path 148"
              d="M-523.568,303.563c-.034-.2,4.5-.556,6.341-.375.066,1.146.462,4.655.462,4.655a2.984,2.984,0,0,1-1,2.448,3.279,3.279,0,0,1-2.239.473,5.782,5.782,0,0,1-1.385-.16,2.078,2.078,0,0,1-1.163-.787,3.253,3.253,0,0,1-.43-1.7C-523.037,306.948-523.338,304.925-523.568,303.563Z"
              transform="translate(569.067 -156.387)"
              fill="url(#linear-gradient-95)"
            />
            <path
              id="Path_149"
              data-name="Path 149"
              d="M-514.793,314.715a12.279,12.279,0,0,0-.681-7.482,4.5,4.5,0,0,1-3.176,1.248,4.541,4.541,0,0,1-3.121-1.407c-.334-.347-.849.18-1.126.582s-5.25,1.223-6.219,1.346a2.91,2.91,0,0,0-1.4.5,3.19,3.19,0,0,0-1.1,2.2,10.036,10.036,0,0,0,.143,2.572C-526.7,315.71-516.668,315.212-514.793,314.715Z"
              transform="translate(567.776 -155.777)"
              fill="url(#linear-gradient-108)"
            />
            <path
              id="Path_150"
              data-name="Path 150"
              d="M-512.407,242.44c1.764,4.961,1.832,10.47,1.186,15.748s-1.972,10.421-2.932,15.641a113.972,113.972,0,0,0-.2,39.934,18.619,18.619,0,0,1-10.836-.05c-.981-6.976-1.917-14.016-2.9-20.993a96.008,96.008,0,0,1-1.341-15.847c.172-6.563,1.685-13.029,1.706-19.6.013-4.2-.582-8.465.263-12.561a4.267,4.267,0,0,1,1.114-2.387c1.255-1.106,3.084-.362,4.608.212C-518.589,243.724-515.662,243.182-512.407,242.44Z"
              transform="translate(568.129 -166.19)"
              fill="url(#linear-gradient-109)"
            />
            <path
              id="Path_151"
              data-name="Path 151"
              d="M-512.442,245.951c.166,5.543-2.671,10.59-5.349,15.3-4.987,8.77-15.442,36.327-18,57.638a15.929,15.929,0,0,1-11.594-.85c2.328-25.529,8.734-71.761,12.169-76.326a17.831,17.831,0,0,0,9.333,5.794,26.68,26.68,0,0,0,10.625.421,4.73,4.73,0,0,0,1.83-.533A1.689,1.689,0,0,0-512.442,245.951Z"
              transform="translate(565.263 -166.198)"
              fill="url(#linear-gradient-110)"
            />
            <path
              id="Path_152"
              data-name="Path 152"
              d="M-515.034,204.853a22.436,22.436,0,0,1-.617-5.679c.15-3.222,1.854-6.453,1.64-9.671a17.689,17.689,0,0,0-1.525-6.7,8.219,8.219,0,0,0-5.02-4.445,5.416,5.416,0,0,0-6.015,2.257c-.784,1.384-.776,3.06-1.178,4.6a8.77,8.77,0,0,1-2.46,4.146c-.575.542-1.294,1.268-.961,1.984.279.6,1.105.678,1.612,1.108a2.128,2.128,0,0,1,.391,2.283c-.257.778-.733,1.475-.952,2.266a1.973,1.973,0,0,0,.605,2.21c.909.564,2.171-.135,3.142.317s1.451,3.985,1.195,5.041a7.909,7.909,0,0,0,.186,3.326c.3,1.48.818.019,2.289.355a10.916,10.916,0,0,0,4.884-.013,4.227,4.227,0,0,0,2.438-1.324A2.4,2.4,0,0,0-515.034,204.853Z"
              transform="translate(567.84 -176.345)"
              fill="url(#linear-gradient-95)"
            />
            <path
              id="Path_153"
              data-name="Path 153"
              d="M-519.58,192.73a3.83,3.83,0,0,0,2.4-2.956,3.829,3.829,0,0,0-1.424-3.529,1.7,1.7,0,0,0-1.109-.419.888.888,0,0,0-.784.939,3.471,3.471,0,0,1-1.067,2.425.867.867,0,0,1-1.07.176l-.014-.007a.842.842,0,0,1-.363-1.08,5.3,5.3,0,0,0,.576-2.852,2.584,2.584,0,0,0-.385-1.087c-1.5-2.314-4.96-.22-6.616-2.3a2.534,2.534,0,0,1-.53-1.617,3.8,3.8,0,0,1,2.577-3.313,8.956,8.956,0,0,1,3.491-.511,21.632,21.632,0,0,1,6.739,1.19,11.808,11.808,0,0,1,4.761,2.691c2.184,2.259,2.6,5.7,2.321,8.831s-2.745,5.641-3.143,8.757c-.247,1.934-1.936,3.093-3.058,2.824a11.473,11.473,0,0,1-2.817-.975,1.77,1.77,0,0,1-.718-1.8C-519.627,197.468-520.192,192.957-519.58,192.73Z"
              transform="translate(568.045 -176.597)"
            />
            <path
              id="Path_154"
              data-name="Path 154"
              d="M-561.749,223.15a6.146,6.146,0,0,0,1.022,2.23,4.073,4.073,0,0,0,3.4,1.256,2.051,2.051,0,0,0,1.827-.981,1.828,1.828,0,0,0-.587-1.945c-1.159-1.117-3.055-1.356-3.952-2.692a13.663,13.663,0,0,1-.633-1.256,1.829,1.829,0,0,0-.987-.952.885.885,0,0,0-1.128.542,1.364,1.364,0,0,0,.085.674Q-562.223,221.588-561.749,223.15Z"
              transform="translate(562.801 -169.862)"
              fill="url(#linear-gradient-112)"
            />
            <path
              id="Path_155"
              data-name="Path 155"
              d="M-504.025,206.842c-1.771-4.776-5.607-5.786-6.715-6.33-3.728,1.349-6.152,1.206-9.928,0-2.719.274-6.48,1.889-8.215,3.793a73.05,73.05,0,0,0-8.327,9.919c-7,9.454-15.515,11.08-19.6,10.711,0,0-2.809,2.206-2.216,4.769a31.2,31.2,0,0,0,25.865-5.1c-1.564,9.272-3.262,18.423-4.779,29.82,9.2,5.158,28.034,6.782,33.422,1.859C-506.952,243.446-502.273,211.566-504.025,206.842Z"
              transform="translate(563.39 -172.778)"
              fill="url(#linear-gradient-113)"
            />
          </g>
        </g>
        <g
          id="Group_151"
          data-name="Group 151"
          transform="translate(223.533 254.036)"
        >
          <path
            id="Path_156"
            data-name="Path 156"
            d="M-680.217,301.409l.937,2.65-2.148,38.581-4.587-.134s-1.762-20.439-1.792-28.087l-2.652-11.6Z"
            transform="translate(701.534 -185.464)"
            fill="url(#linear-gradient-114)"
          />
          <path
            id="Path_157"
            data-name="Path 157"
            d="M-686.443,335.952s1.193,1.33,4.727.486a14.736,14.736,0,0,0,8.908,7.037,4.532,4.532,0,0,1,1.73.721,1.377,1.377,0,0,1,.4,1.718c-.356.534-1.1.556-1.722.523l-6.485-.337c-.912-.048-5.639-.2-7.979-.3a1.1,1.1,0,0,1-1.067-1.17c.124-1.669-.629-3.473-.456-5.179A5.128,5.128,0,0,1-686.443,335.952Z"
            transform="translate(701.861 -179.947)"
            fill="url(#linear-gradient-92)"
          />
          <path
            id="Path_158"
            data-name="Path 158"
            d="M-668.884,301.739l1,2.813-2.281,40.963-4.87-.143s-2.987-22.9-3.019-31.019l-1.7-11.115Z"
            transform="translate(703.242 -185.411)"
            fill="url(#linear-gradient-116)"
          />
          <path
            id="Path_159"
            data-name="Path 159"
            d="M-693.066,274.357s-3.291,18.156.992,35.718c0,0,14.865,3.561,27.534-1.424,0,0-.013-24.6-2.92-34.976Z"
            transform="translate(700.924 -189.894)"
            fill="url(#linear-gradient-117)"
          />
          <path
            id="Path_160"
            data-name="Path 160"
            d="M-675.5,338.415s1.268,1.411,5.019.515a15.644,15.644,0,0,0,9.457,7.472,4.811,4.811,0,0,1,1.838.767,1.461,1.461,0,0,1,.426,1.823c-.378.566-1.163.59-1.828.556l-6.886-.358c-.931-.048-5.615-.2-8.182-.3a1.483,1.483,0,0,1-1.408-1.545c.034-1.69-.672-3.5-.5-5.208A5.437,5.437,0,0,1-675.5,338.415Z"
            transform="translate(703.589 -179.554)"
            fill="url(#linear-gradient-92)"
          />
          <path
            id="Path_161"
            data-name="Path 161"
            d="M-693.347,256.725c.426-4.734,2.842-8.493,5.772-12.155l.311-10.581s-13.694,9.6-12.692,23.206c.973,13.225,11.3,24.028,11.3,24.028l.2-6.015C-691.026,270.251-693.783,261.586-693.347,256.725Z"
            transform="translate(700.008 -196.232)"
            fill="url(#linear-gradient-119)"
          />
          <path
            id="Path_162"
            data-name="Path 162"
            d="M-671.974,210.85c-.365,1.99-1.367,5.049-.608,6.915a6.461,6.461,0,0,1,.605,2.052c-.031.542-1.09.5-1.552.241a9.008,9.008,0,0,0-.161,2.428,8.408,8.408,0,0,1-1.023,3.378c-.862.419-1.749.031-2.653-.227a21.25,21.25,0,0,0,.289,7.869c-1.6-.529-3.284-.094-4.965.019s-3.565-.1-4.672-1.411a6.294,6.294,0,0,1-1.141-2.805,39.561,39.561,0,0,1-.877-8.3,22.456,22.456,0,0,1,1.186-7.777,11.708,11.708,0,0,1,4.715-6.05,7.6,7.6,0,0,1,7.331-.422C-673.935,207.581-671.648,209.065-671.974,210.85Z"
            transform="translate(701.809 -200.701)"
            fill="url(#linear-gradient-120)"
          />
          <path
            id="Path_163"
            data-name="Path 163"
            d="M-669.306,268.923a17.312,17.312,0,0,1-.323-3.444,30.527,30.527,0,0,1,1.3-9.377c1.621-4.389,7.125-8.284,2.607-17.291-1.618-3.226-2.421-5.963-4.073-7.447a9.782,9.782,0,0,0-6.425-2.5s-7.8,1.672-11.773-.347A21.933,21.933,0,0,0-693.034,246c1.525,10.695-.32,33.9-1.23,39.629,3.015,1.737,28.858,1.806,27.789-3.551C-667.181,278.534-668.949,270.738-669.306,268.923Z"
            transform="translate(700.926 -197.106)"
            fill="url(#linear-gradient-121)"
          />
          <path
            id="Path_164"
            data-name="Path 164"
            d="M-674.114,232.22c-2.29.259-5.383,3.1-1.95,8.575s10.925,8.912,15.857,9.635,20.533-13.606,21.53-14.9c1.39-1.8,3.25-1.941,4.995-3.379a4.89,4.89,0,0,0,1.333-2.554c-.048-.953-1.261-1.59-1.449-1.875-.515-.786.983-1.789,2.046-3.153.4-.513.794-1.288.35-1.758s-1.2-.082-1.692.325c-2.914,2.395-3.333,2.466-5.436,5.642-1.094,1.653-2.114,3.447-3.733,4.544,0,0-12.031,9.706-15.874,8.472C-664.033,239.9-670.824,231.848-674.114,232.22Z"
            transform="translate(703.588 -198.051)"
            fill="url(#linear-gradient-122)"
          />
          <path
            id="Path_165"
            data-name="Path 165"
            d="M-681.53,246.307c-2.318,2.738-6.165,3.4-9.68,3.435-2.368.021-4.945-.255-6.666-1.933-1.97-1.922-2.2-5.158-1.419-7.845s2.373-5.027,3.66-7.5c2.3-4.409-1.061-19.233,6.254-26.738,3.114-3.194,7.644-4.989,11.928-4.046a22.016,22.016,0,0,1,4.561,1.706,9.024,9.024,0,0,1,3.393,2.322,5.481,5.481,0,0,1,1.533,5.1,2.718,2.718,0,0,1-2.584,2.027c-1.219.129-4.3-1.532-5.321-.834a5.775,5.775,0,0,0-1.584,1.888,24.718,24.718,0,0,0-3.325,14.849c.286,3.02,1.13,5.962,1.378,8.987S-679.6,244.021-681.53,246.307Z"
            transform="translate(700.056 -201.432)"
          />
        </g>
        <g
          id="Group_152"
          data-name="Group 152"
          transform="translate(293.89 314.081)"
        >
          <path
            id="Rectangle_106"
            data-name="Rectangle 106"
            d="M0,0H78.825a0,0,0,0,1,0,0V52.521A3.623,3.623,0,0,1,75.2,56.143H3.622A3.622,3.622,0,0,1,0,52.521V0A0,0,0,0,1,0,0Z"
            transform="translate(0 26.07) rotate(-14.331)"
            fill="url(#linear-gradient-123)"
          />
          <path
            id="Path_166"
            data-name="Path 166"
            d="M-639.341,278.375l6.531,25.566a6.615,6.615,0,0,0,8.047,4.772l63.552-16.236a6.616,6.616,0,0,0,4.772-8.048l-6.532-25.565Z"
            transform="translate(639.341 -252.305)"
            fill="url(#linear-gradient-124)"
          />
          <path
            id="Rectangle_107"
            data-name="Rectangle 107"
            d="M0,0H13.709a0,0,0,0,1,0,0V10.2a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,10.2V0A0,0,0,0,1,0,0Z"
            transform="translate(38.017 43.345) rotate(-14.331)"
            fill="url(#linear-gradient-125)"
          />
          <rect
            id="Rectangle_108"
            data-name="Rectangle 108"
            width="8.538"
            height="9.602"
            transform="translate(40.522 42.705) rotate(-14.332)"
            fill="url(#linear-gradient-126)"
          />
          <path
            id="Path_167"
            data-name="Path 167"
            d="M-593.776,253.475l-26.351,6.732-.622.16a3.439,3.439,0,0,0-2.482,4.183l2.389,9.35,3.954-1.011-2.212-8.66,26.35-6.732,2.213,8.66,3.955-1.01-2.388-9.35a3.441,3.441,0,0,0-4.184-2.481Z"
            transform="translate(641.897 -253.208)"
            fill="url(#linear-gradient-127)"
          />
        </g>
        <g
          id="Group_155"
          data-name="Group 155"
          transform="translate(160.003 131.575)"
        >
          <path
            id="Path_168"
            data-name="Path 168"
            d="M-677.536,149.483a27.671,27.671,0,0,0-29.63-27.6,27.764,27.764,0,0,0-25.643,25.748,27.574,27.574,0,0,0,3.951,16.2,52.11,52.11,0,0,1,7.312,27.119v2.541h32.687v-3.321a49.441,49.441,0,0,1,7.18-26.121A27.523,27.523,0,0,0-677.536,149.483Z"
            transform="translate(758.29 -91.687)"
            fill="url(#linear-gradient-128)"
          />
          <path
            id="Path_169"
            data-name="Path 169"
            d="M-723.1,183.622v13.956a11.642,11.642,0,0,0,11.642,11.642h9.4a11.642,11.642,0,0,0,11.642-11.642V183.622Z"
            transform="translate(759.85 -81.815)"
            fill="url(#linear-gradient-8)"
          />
          <g
            id="Group_153"
            data-name="Group 153"
            transform="translate(36.745 101.806)"
          >
            <path
              id="Path_170"
              data-name="Path 170"
              d="M-713.229,208.038l22-12.967v-4.347l-26.832,15.81A11.56,11.56,0,0,0-713.229,208.038Z"
              transform="translate(723.911 -182.488)"
              fill="url(#linear-gradient-130)"
            />
            <path
              id="Path_171"
              data-name="Path 171"
              d="M-720.678,204.8l30.163-17.773v-3.408h-1.592L-722.5,201.53A11.593,11.593,0,0,0-720.678,204.8Z"
              transform="translate(723.202 -183.622)"
              fill="url(#linear-gradient-131)"
            />
            <path
              id="Path_172"
              data-name="Path 172"
              d="M-707.584,183.622l-15.521,9.146v4.346l22.9-13.492Z"
              transform="translate(723.105 -183.622)"
              fill="url(#linear-gradient-132)"
            />
          </g>
          <path
            id="Path_173"
            data-name="Path 173"
            d="M-696.193,142.627a6.667,6.667,0,0,0-6.659,6.66v7.066h-7.6v-7.066a6.667,6.667,0,0,0-6.659-6.66,6.667,6.667,0,0,0-6.66,6.66v1.963a6.667,6.667,0,0,0,6.66,6.659h5.1v32.26h1.556V157.91h7.6v32.26h1.556V157.91h5.1a6.667,6.667,0,0,0,6.66-6.659v-1.963A6.667,6.667,0,0,0-696.193,142.627Zm-15.82,13.726h-5.1a5.109,5.109,0,0,1-5.1-5.1v-1.963a5.108,5.108,0,0,1,5.1-5.1,5.108,5.108,0,0,1,5.1,5.1Zm20.924-5.1a5.109,5.109,0,0,1-5.1,5.1h-5.1v-7.066a5.108,5.108,0,0,1,5.1-5.1,5.108,5.108,0,0,1,5.1,5.1Z"
            transform="translate(759.743 -88.363)"
            fill="#fff"
          />
          <g id="Group_154" data-name="Group 154" transform="translate(0 0)">
            <path
              id="Path_174"
              data-name="Path 174"
              d="M-708.69,95.836a2.336,2.336,0,0,0-2.336,2.336v18.608a2.335,2.335,0,0,0,2.336,2.334,2.334,2.334,0,0,0,2.334-2.334V98.172A2.335,2.335,0,0,0-708.69,95.836Z"
              transform="translate(761.779 -95.836)"
              fill="url(#linear-gradient-133)"
            />
            <path
              id="Path_175"
              data-name="Path 175"
              d="M-678.563,105.262a2.336,2.336,0,0,0-3.266.489l-11.065,14.96a2.335,2.335,0,0,0,.489,3.266,2.328,2.328,0,0,0,1.387.458,2.335,2.335,0,0,0,1.879-.946l11.065-14.96A2.337,2.337,0,0,0-678.563,105.262Z"
              transform="translate(764.602 -94.404)"
              fill="url(#linear-gradient-134)"
            />
            <path
              id="Path_176"
              data-name="Path 176"
              d="M-682.5,136.665a2.335,2.335,0,0,0,2.232,1.653,2.339,2.339,0,0,0,.684-.1l17.791-5.447a2.336,2.336,0,0,0,1.549-2.917,2.334,2.334,0,0,0-2.917-1.548l-17.791,5.447A2.335,2.335,0,0,0-682.5,136.665Z"
              transform="translate(766.318 -90.667)"
              fill="url(#linear-gradient-135)"
            />
            <path
              id="Path_177"
              data-name="Path 177"
              d="M-662.341,157.835l-17.543-6.2a2.334,2.334,0,0,0-2.98,1.423,2.336,2.336,0,0,0,1.424,2.98l17.543,6.2a2.347,2.347,0,0,0,.778.135,2.337,2.337,0,0,0,2.2-1.558A2.337,2.337,0,0,0-662.341,157.835Z"
              transform="translate(766.255 -86.946)"
              fill="url(#linear-gradient-136)"
            />
            <path
              id="Path_178"
              data-name="Path 178"
              d="M-734.029,105.751a2.337,2.337,0,0,0-3.266-.489,2.337,2.337,0,0,0-.489,3.267l11.065,14.96a2.335,2.335,0,0,0,1.879.946,2.328,2.328,0,0,0,1.387-.458,2.335,2.335,0,0,0,.489-3.266Z"
              transform="translate(757.432 -94.404)"
              fill="url(#linear-gradient-134)"
            />
            <path
              id="Path_179"
              data-name="Path 179"
              d="M-753.137,132.767l17.791,5.447a2.343,2.343,0,0,0,.684.1,2.335,2.335,0,0,0,2.232-1.653,2.334,2.334,0,0,0-1.549-2.916L-751.77,128.3a2.332,2.332,0,0,0-2.917,1.548A2.336,2.336,0,0,0-753.137,132.767Z"
              transform="translate(754.789 -90.667)"
              fill="url(#linear-gradient-135)"
            />
            <path
              id="Path_180"
              data-name="Path 180"
              d="M-735.08,151.636l-17.544,6.2a2.335,2.335,0,0,0-1.423,2.98,2.337,2.337,0,0,0,2.2,1.557,2.343,2.343,0,0,0,.778-.135l17.543-6.2a2.335,2.335,0,0,0,1.424-2.98A2.333,2.333,0,0,0-735.08,151.636Z"
              transform="translate(754.887 -86.946)"
              fill="url(#linear-gradient-139)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SliderVector = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="498.937"
    viewBox="0 0 675.466 498.937"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-0.264"
        y1="-0.25"
        x2="1.201"
        y2="1.181"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f9a51b" />
        <stop offset="0.494" stopColor="#c39c40" />
        <stop offset="1" stopColor="#007ec5" />
      </linearGradient>
    </defs>
    <g id="Group_852" data-name="Group 852" transform="translate(0)">
      <g
        id="Group_579"
        data-name="Group 579"
        transform="translate(428.637 465.77)"
      >
        <path
          id="Path_898"
          data-name="Path 898"
          d="M4593.212,2053.759c-.223-.227-5.429-5.594-3.54-8.783.3-.5.655-.74,1.067-.689,1.384.158,2.9,3.76,3.685,5.906-.26-2.68-.556-7.979.912-9.771a1.591,1.591,0,0,1,1.158-.629,1.214,1.214,0,0,1,1.068.466c1.323,1.637.127,7.423-.53,10.131.49-.794,1.186-1.679,1.9-1.781a.689.689,0,0,1,.754.353c.43.787-.162,2.793-1.9,4.789l-.26-.226c1.7-1.962,2.171-3.814,1.853-4.4a.349.349,0,0,0-.4-.177c-.916.131-1.981,2.114-2.316,2.866l-.324-.116c.026-.092,2.554-9.248.956-11.225a.874.874,0,0,0-.778-.338,1.244,1.244,0,0,0-.912.5c-1.858,2.269-.669,11-.656,11.083l-.336.077c-.616-1.941-2.521-7.01-3.911-7.17-.266-.028-.5.14-.73.522-1.755,2.962,3.436,8.31,3.489,8.365Z"
          transform="translate(-4589.26 -2039.79)"
          fill="#42428e"
        />
      </g>
      <g
        id="Group_582"
        data-name="Group 582"
        transform="translate(303.991 451.689)"
      >
        <g
          id="Group_580"
          data-name="Group 580"
          transform="translate(0.262 2.646)"
        >
          <path
            id="Path_899"
            data-name="Path 899"
            d="M4334.048,2033.677s-6.98-7.116-4.578-11.169,6.454,8.706,6.454,8.706-2.1-15.088,2.253-15.35,0,15.35,0,15.35,1.613-3.64,3.189-3.865,1.125,3.086-1.688,6.328Z"
            transform="translate(-4328.967 -2015.861)"
            fill="#bed5dc"
          />
        </g>
        <g id="Group_581" data-name="Group 581">
          <path
            id="Path_900"
            data-name="Path 900"
            d="M4334.261,2030.965a31.671,31.671,0,0,1-3.651-4.653c-2.23-3.528-2.762-6.329-1.579-8.325.441-.745.969-1.088,1.576-1.018,1.2.137,2.618,2.038,4.2,5.651.483,1.1.911,2.2,1.246,3.11-.385-3.95-.831-11.817,1.343-14.473a2.353,2.353,0,0,1,1.712-.929,1.8,1.8,0,0,1,1.579.689c1.955,2.419.188,10.97-.783,14.97.724-1.173,1.752-2.481,2.8-2.631a1.023,1.023,0,0,1,1.115.523c.634,1.163-.241,4.127-2.8,7.076l-.386-.335c2.516-2.9,3.208-5.635,2.739-6.5a.517.517,0,0,0-.594-.261c-1.351.193-2.927,3.122-3.421,4.236l-.479-.172c.038-.136,3.773-13.665,1.413-16.587a1.283,1.283,0,0,0-1.152-.5,1.835,1.835,0,0,0-1.347.743c-2.747,3.354-.987,16.248-.969,16.378l-.5.112c-.911-2.867-3.725-10.359-5.779-10.594-.394-.047-.745.208-1.079.772-2.595,4.377,5.078,12.28,5.156,12.359Z"
            transform="translate(-4328.418 -2010.324)"
            fill="#42428e"
          />
        </g>
      </g>
      <g
        id="Group_638"
        data-name="Group 638"
        transform="translate(37.607 8.737)"
      >
        <g
          id="Group_583"
          data-name="Group 583"
          transform="translate(97.447 89.535)"
        >
          <path
            id="Path_901"
            data-name="Path 901"
            d="M3980.889,1298.9s12.341-.569,14.307-19.5c2.02-19.463-20.3,0-20.3,0Z"
            transform="translate(-3974.891 -1270.742)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_584"
          data-name="Group 584"
          transform="translate(90.852 55.605)"
        >
          <path
            id="Path_902"
            data-name="Path 902"
            d="M3961.092,1199.739c.752.3,13.243,3.682,18.775,22.522s-13.038,3.986-13.038,3.986Z"
            transform="translate(-3961.092 -1199.739)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_585"
          data-name="Group 585"
          transform="translate(60.466 315.176)"
        >
          <path
            id="Path_903"
            data-name="Path 903"
            d="M3911.437,1742.932s.171,6.771,4.752,8.681,17.857,3.634,12.9,7.987c-2.847,2.5-16.262.885-20.048.884s-7.344,1.651-10.159-.884c-3.1-2.789,0-16.668,0-16.668a15.132,15.132,0,0,0,6.208,1.627A12.054,12.054,0,0,0,3911.437,1742.932Z"
            transform="translate(-3897.504 -1742.932)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_586"
          data-name="Group 586"
          transform="translate(7.848 315.174)"
        >
          <path
            id="Path_904"
            data-name="Path 904"
            d="M3801.326,1742.93s.17,6.772,4.751,8.682,17.858,3.634,12.9,7.986c-2.846,2.5-16.261.885-20.047.884s-7.343,1.651-10.158-.885c-3.1-2.788,0-16.668,0-16.668a15.125,15.125,0,0,0,6.208,1.627A12.056,12.056,0,0,0,3801.326,1742.93Z"
            transform="translate(-3787.392 -1742.929)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_587"
          data-name="Group 587"
          transform="translate(52.035 129.553)"
        >
          <path
            id="Path_905"
            data-name="Path 905"
            d="M3936.28,1354.486s.847,28.343-8.2,82.716c-6.531,39.253-14.24,78.807-18.023,97.77a14.794,14.794,0,0,1-13.82,12.014l-3.632.145a12.417,12.417,0,0,1-12.71-13.452l9.178-128.293,2.263-42.225Z"
            transform="translate(-3879.861 -1354.486)"
            fill="#007ec5"
          />
        </g>
        <g
          id="Group_588"
          data-name="Group 588"
          transform="translate(25.695 139.983)"
        >
          <path
            id="Path_906"
            data-name="Path 906"
            d="M3824.746,1390.826c.417,3.45,19.924,3.031,43.806.007s42.473-8.2,42.055-11.646-19.683-3.869-43.565-.846S3824.329,1387.376,3824.746,1390.826Z"
            transform="translate(-3824.739 -1376.313)"
            fill="#935b00"
          />
        </g>
        <g id="Group_589" data-name="Group 589" transform="translate(0 147.14)">
          <path
            id="Path_907"
            data-name="Path 907"
            d="M3852,1393.948l-50.989,163.791a16.434,16.434,0,0,1-15.337,11.674l-3.065.052a11.633,11.633,0,0,1-11.448-13.848l27.892-153.9a5.066,5.066,0,0,1,3.52-3.793C3812.14,1395.163,3852.941,1387.174,3852,1393.948Z"
            transform="translate(-3770.969 -1391.29)"
            fill="#007ec5"
          />
        </g>
        <g
          id="Group_590"
          data-name="Group 590"
          transform="translate(25.357 41.946)"
        >
          <path
            id="Path_908"
            data-name="Path 908"
            d="M3832.352,1189.4s6.784-19.087,30.951-18.219,42.416,32.626,46.937,100.882c0,0-39.574-8.678-85.932,11.567C3824.307,1283.633,3821.737,1217.224,3832.352,1189.4Z"
            transform="translate(-3824.033 -1171.156)"
            fill="#ffba41"
          />
        </g>
        <g
          id="Group_591"
          data-name="Group 591"
          transform="translate(16.703 41.839)"
        >
          <path
            id="Path_909"
            data-name="Path 909"
            d="M3805.924,1188.137l17.121,10.958s44.009,4.4,55.692,2.283,13.5-10.709,13.5-10.709a54.264,54.264,0,0,0-32.263-19.03,57.935,57.935,0,0,0-6.125-.571,60.132,60.132,0,0,0-10.691.234C3822.262,1173.558,3805.924,1188.137,3805.924,1188.137Z"
            transform="translate(-3805.924 -1170.931)"
            fill="#ffba41"
          />
        </g>
        <g
          id="Group_592"
          data-name="Group 592"
          transform="translate(57.431 171.311)"
        >
          <path
            id="Path_910"
            data-name="Path 910"
            d="M3891.968,1493.566l-.815-.268,10.965-33.335a32.463,32.463,0,0,1,14.669-18.091l.426.744a31.605,31.605,0,0,0-14.28,17.616Z"
            transform="translate(-3891.153 -1441.872)"
            fill="#fff"
          />
        </g>
        <g id="Group_617" data-name="Group 617" transform="translate(41.581)">
          <g
            id="Group_593"
            data-name="Group 593"
            transform="translate(38.908 20.585)"
          >
            <path
              id="Path_911"
              data-name="Path 911"
              d="M3940.116,1143.593l-.064-.273c.031-.007,3.111-.76,3.981-2.859a4.117,4.117,0,0,0-.64-3.789c-.413-.67-.792-1.276-1.14-1.831-2.4-3.825-3.3-5.255-2.639-8.386l.275.058c-.634,3.02.182,4.32,2.6,8.179.348.555.729,1.162,1.142,1.833a4.379,4.379,0,0,1,.66,4.045C3943.366,1142.8,3940.249,1143.562,3940.116,1143.593Z"
              transform="translate(-3939.405 -1126.454)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_594"
            data-name="Group 594"
            transform="translate(24.198 20.614)"
          >
            <path
              id="Path_912"
              data-name="Path 912"
              d="M3923.678,1126.514a14.262,14.262,0,0,0,2.141,9.921c3.21,5.073-.284,8.78-4.635,6.829s-6.008,3.595-11.57.53S3923.678,1126.514,3923.678,1126.514Z"
              transform="translate(-3908.623 -1126.514)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_595"
            data-name="Group 595"
            transform="translate(13.513 17.216)"
          >
            <path
              id="Path_913"
              data-name="Path 913"
              d="M3886.3,1144.234a6.683,6.683,0,0,0,5.446-3.985l3-6.955.869-2.019,5.11-11.87-.885,12.3-.533,7.383-.152,2.105a3.359,3.359,0,0,0,2.771,3.541l.03.006c.91,1.478,2.166,4.485-1.587,5.679-4.41,1.4-11.542-2.068-14.1-6.182Z"
              transform="translate(-3886.262 -1119.404)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_596"
            data-name="Group 596"
            transform="translate(21.978 28.855)"
          >
            <path
              id="Path_914"
              data-name="Path 914"
              d="M3903.978,1145.806c0,.068.007.136.013.2.293,3.965,4.535,5.818,4.535,5.818l.025-.022.533-7.384c-1.3-.475-3.144-.963-4.225-.431A1.733,1.733,0,0,0,3903.978,1145.806Z"
              transform="translate(-3903.975 -1143.759)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_597"
            data-name="Group 597"
            transform="translate(16.973 4.925)"
          >
            <path
              id="Path_915"
              data-name="Path 915"
              d="M3916.618,1107.941c0-7.875-5.174-14.259-11.557-14.259s-11.558,6.384-11.558,14.259,5.174,14.259,11.558,14.259S3916.618,1115.816,3916.618,1107.941Z"
              transform="translate(-3893.503 -1093.682)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_598"
            data-name="Group 598"
            transform="translate(17.31 16.351)"
          >
            <path
              id="Path_916"
              data-name="Path 916"
              d="M3895.416,1122.577c1.309,1.25,3.139,1.378,4.088.286s.657-2.99-.652-4.24-3.139-1.378-4.088-.286S3894.107,1121.327,3895.416,1122.577Z"
              transform="translate(-3894.206 -1117.594)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_599"
            data-name="Group 599"
            transform="translate(26.853 27.115)"
          >
            <path
              id="Path_917"
              data-name="Path 917"
              d="M3922.081,1141.414a25.085,25.085,0,0,1-7.9-1.3,3.849,3.849,0,0,0,3.949,4.152C3920.619,1144.422,3922.081,1141.414,3922.081,1141.414Z"
              transform="translate(-3914.178 -1140.118)"
              fill="#db6b49"
            />
          </g>
          <g
            id="Group_600"
            data-name="Group 600"
            transform="translate(26.859 27.115)"
          >
            <path
              id="Path_918"
              data-name="Path 918"
              d="M3922.087,1141.414a5.166,5.166,0,0,1-3.184.783c-2.229-.01-4.712-2.079-4.712-2.079A37.728,37.728,0,0,0,3922.087,1141.414Z"
              transform="translate(-3914.19 -1140.118)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_602"
            data-name="Group 602"
            transform="translate(26.204 18.552)"
          >
            <g id="Group_601" data-name="Group 601">
              <path
                id="Path_919"
                data-name="Path 919"
                d="M3916.264,1122.969a.263.263,0,0,0,.03-.5,2.847,2.847,0,0,0-3.4.758.266.266,0,0,0,.01.372.289.289,0,0,0,.372-.01,4.5,4.5,0,0,1,2.826-.633A.261.261,0,0,0,3916.264,1122.969Z"
                transform="translate(-3912.819 -1122.2)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_603"
            data-name="Group 603"
            transform="translate(27.568 21.101)"
          >
            <path
              id="Path_920"
              data-name="Path 920"
              d="M3915.675,1128.643c-.02.627.333,1.147.788,1.161s.84-.482.86-1.109-.333-1.147-.788-1.161S3915.7,1128.015,3915.675,1128.643Z"
              transform="translate(-3915.675 -1127.533)"
              fill="#060633"
            />
          </g>
          <g
            id="Group_605"
            data-name="Group 605"
            transform="translate(35.658 19.319)"
          >
            <g id="Group_604" data-name="Group 604">
              <path
                id="Path_921"
                data-name="Path 921"
                d="M3935.979,1125.177a.263.263,0,0,0,.217-.449,2.848,2.848,0,0,0-3.436-.594.265.265,0,0,0-.133.347.289.289,0,0,0,.348.133,4.5,4.5,0,0,1,2.854.491A.264.264,0,0,0,3935.979,1125.177Z"
                transform="translate(-3932.604 -1123.804)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_606"
            data-name="Group 606"
            transform="translate(35.876 21.818)"
          >
            <path
              id="Path_922"
              data-name="Path 922"
              d="M3933.192,1129.787a1.019,1.019,0,0,0,.287,1.374c.415.187.96-.126,1.218-.7a1.018,1.018,0,0,0-.286-1.374C3933.995,1128.9,3933.45,1129.215,3933.192,1129.787Z"
              transform="translate(-3933.06 -1129.035)"
              fill="#060633"
            />
          </g>
          <g id="Group_607" data-name="Group 607">
            <path
              id="Path_923"
              data-name="Path 923"
              d="M3891.422,1090.886s-.25,4.947-6.428,5.791-10.439,3.457-9.609,7.908,10.789,13.684,3.469,17.261-5.761-3.093-13.7-1.546-9.366-9.05-3.9-15.2,3.25-22.422,23.739-21.7,12.982,17.068,12.982,17.068S3891.41,1096.349,3891.422,1090.886Z"
              transform="translate(-3857.984 -1083.377)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_608"
            data-name="Group 608"
            transform="translate(10.857 5.381)"
          >
            <path
              id="Path_924"
              data-name="Path 924"
              d="M3888.611,1128.642l-.062-.155a4.092,4.092,0,0,0,2.718-2.644c.878-3.238-2.962-8.216-6.35-12.607-.9-1.165-1.748-2.265-2.5-3.316a7.88,7.88,0,0,1-.483-9.113c2-2.993,5.794-4.26,9.909-3.3a11.581,11.581,0,0,0,8.3-.762,7.553,7.553,0,0,0,2.526-2.1l.138.093c-.032.048-3.278,4.723-11,2.932-4.046-.938-7.775.3-9.734,3.236-1.786,2.675-1.605,6.011.48,8.923.751,1.049,1.6,2.148,2.5,3.312,3.412,4.422,7.279,9.434,6.378,12.753A4.3,4.3,0,0,1,3888.611,1128.642Z"
              transform="translate(-3880.704 -1094.638)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_610"
            data-name="Group 610"
            transform="translate(33.764 13.768)"
          >
            <g id="Group_609" data-name="Group 609">
              <path
                id="Path_925"
                data-name="Path 925"
                d="M3933.1,1128.456a3.3,3.3,0,0,1-1.769-.52c-2.486-1.546-3.423-6.214-2.088-10.4h0c.817-2.565,2.344-4.482,4.08-5.121a3.3,3.3,0,0,1,3,.3c2.486,1.538,3.423,6.2,2.089,10.391-.817,2.572-2.345,4.49-4.081,5.129A3.624,3.624,0,0,1,3933.1,1128.456Zm-3.367-10.755c-1.267,3.969-.427,8.369,1.874,9.8a2.877,2.877,0,0,0,2.549.246c1.59-.587,3-2.386,3.769-4.8,1.267-3.976.431-8.377-1.87-9.8a2.859,2.859,0,0,0-2.553-.246c-1.591.587-3,2.379-3.769,4.8Z"
                transform="translate(-3928.639 -1112.188)"
                fill="#786ab5"
              />
            </g>
          </g>
          <g
            id="Group_611"
            data-name="Group 611"
            transform="translate(20.724 11.036)"
            opacity="0.3"
          >
            <path
              id="Path_926"
              data-name="Path 926"
              d="M3901.629,1111.832c-.927,4.071.555,8.443,3.311,9.764s5.74-.909,6.667-4.98-.554-8.442-3.31-9.764S3902.556,1107.76,3901.629,1111.832Z"
              transform="translate(-3901.352 -1106.471)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_612"
            data-name="Group 612"
            transform="translate(33.952 14.141)"
            opacity="0.3"
          >
            <path
              id="Path_927"
              data-name="Path 927"
              d="M3929.342,1118.287c-.93,4.09.308,8.525,2.765,9.906s5.2-.815,6.132-4.905-.309-8.525-2.765-9.906S3930.271,1114.2,3929.342,1118.287Z"
              transform="translate(-3929.033 -1112.969)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_614"
            data-name="Group 614"
            transform="translate(20.443 9.976)"
          >
            <g id="Group_613" data-name="Group 613">
              <path
                id="Path_928"
                data-name="Path 928"
                d="M3906.579,1120.946a4.217,4.217,0,0,1-1.843-.431c-3.04-1.42-4.687-6.117-3.672-10.473h0a7.63,7.63,0,0,1,3.708-5.337,4.247,4.247,0,0,1,3.754-.03c3.036,1.427,4.683,6.125,3.671,10.473a7.638,7.638,0,0,1-3.72,5.344A4.412,4.412,0,0,1,3906.579,1120.946Zm.109-16.181a3.839,3.839,0,0,0-1.688.4,7.251,7.251,0,0,0-3.438,4.995h0c-.958,4.125.562,8.563,3.39,9.893a3.833,3.833,0,0,0,3.3-.022,7.246,7.246,0,0,0,3.449-5c.956-4.125-.564-8.562-3.389-9.893A3.867,3.867,0,0,0,3906.688,1104.765Z"
                transform="translate(-3900.764 -1104.252)"
                fill="#786ab5"
              />
            </g>
          </g>
          <g
            id="Group_616"
            data-name="Group 616"
            transform="translate(30.482 19.193)"
          >
            <g id="Group_615" data-name="Group 615">
              <path
                id="Path_929"
                data-name="Path 929"
                d="M3926.1,1125.331a.474.474,0,0,1-.3-.112h0a4.336,4.336,0,0,0-3.445-.609.5.5,0,0,1-.564-.379.47.47,0,0,1,.378-.558,5.179,5.179,0,0,1,4.244.8.482.482,0,0,1,.06.677A.464.464,0,0,1,3926.1,1125.331Z"
                transform="translate(-3921.772 -1123.541)"
                fill="#786ab5"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_618"
          data-name="Group 618"
          transform="translate(16.407 58.367)"
        >
          <path
            id="Path_930"
            data-name="Path 930"
            d="M3805.355,1206.575c-.594,1.724,3.884,4.951,10,7.207s11.558,2.689,12.152.965-3.884-4.951-10-7.208S3805.949,1204.852,3805.355,1206.575Z"
            transform="translate(-3805.303 -1205.519)"
            fill="#935b00"
          />
        </g>
        <g
          id="Group_620"
          data-name="Group 620"
          transform="translate(14.925 60.327)"
        >
          <g id="Group_619" data-name="Group 619">
            <path
              id="Path_931"
              data-name="Path 931"
              d="M3845.73,1268.352l.2-12.512a3.223,3.223,0,0,0-2.993-3.265c-8.673-.62-14.744-3.31-18.07-8.014-4.618-6.532-4.131-16.676-2.844-23.711.658-3.6-.53-6.008-4.879-8.316a29.747,29.747,0,0,0-8.354-2.844c-1.849-.315-3.682.428-4.16,2.241a76.27,76.27,0,0,0-2.173,12.446c-1.094,12.338,1.286,23.069,6.882,31.035,6.869,9.777,17.942,15.186,32.944,16.105A3.234,3.234,0,0,0,3845.73,1268.352Z"
              transform="translate(-3802.202 -1209.621)"
              fill="#fdad9b"
            />
          </g>
        </g>
        <g
          id="Group_621"
          data-name="Group 621"
          transform="translate(21.621 94.832)"
          opacity="0.5"
        >
          <path
            id="Path_932"
            data-name="Path 932"
            d="M3821.153,1289.855a3.6,3.6,0,0,1-1.046-.165,5.543,5.543,0,0,1-2.629-1.862c-1.545-1.919-1.693-4.374-.329-5.473s3.73-.43,5.275,1.489,1.693,4.374.329,5.472A2.5,2.5,0,0,1,3821.153,1289.855Zm-2.41-7.191a1.675,1.675,0,0,0-1.069.346c-.986.795-.777,2.761.457,4.293h0a4.7,4.7,0,0,0,2.22,1.586,2,2,0,0,0,1.876-.224c.986-.794.777-2.759-.457-4.292A4.22,4.22,0,0,0,3818.744,1282.663Z"
            transform="translate(-3816.215 -1281.827)"
            fill="#e08b65"
          />
        </g>
        <g
          id="Group_622"
          data-name="Group 622"
          transform="translate(44.904 144.795)"
        >
          <path
            id="Path_933"
            data-name="Path 933"
            d="M3864.938,1392.345s47.666-9.106,63.12-4.811l-7.411,10.983-27.551,1.559Z"
            transform="translate(-3864.938 -1386.383)"
            fill="#007ec5"
          />
        </g>
        <g
          id="Group_628"
          data-name="Group 628"
          transform="translate(62.153 74.891)"
        >
          <g
            id="Group_623"
            data-name="Group 623"
            transform="translate(0 0.128)"
          >
            <path
              id="Path_934"
              data-name="Path 934"
              d="M3903.032,1269.827l41.087.016a2,2,0,0,0,1.9-1.357l8.547-25.5a1.985,1.985,0,0,0-1.894-2.61l-39.512-.015a4.179,4.179,0,0,0-3.968,2.84l-8.05,24.011A1.986,1.986,0,0,0,3903.032,1269.827Z"
              transform="translate(-3901.034 -1240.365)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_624"
            data-name="Group 624"
            transform="translate(2.579 0.129)"
          >
            <path
              id="Path_935"
              data-name="Path 935"
              d="M3908.428,1269.829l41.087.015a2,2,0,0,0,1.9-1.357l8.549-25.5a1.986,1.986,0,0,0-1.9-2.61l-39.511-.015a4.179,4.179,0,0,0-3.968,2.84l-8.05,24.011A1.986,1.986,0,0,0,3908.428,1269.829Z"
              transform="translate(-3906.43 -1240.367)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_625"
            data-name="Group 625"
            transform="translate(28.722 12.671)"
          >
            <path
              id="Path_936"
              data-name="Path 936"
              d="M3965.789,1267.977c0-.968-1.117-1.363-2.091-1.363a2.792,2.792,0,0,0-2.558,2.417c.011,1.464,1.155,1.655,2.13,1.655A3.225,3.225,0,0,0,3965.789,1267.977Z"
              transform="translate(-3961.14 -1266.614)"
              fill="#fecaff"
            />
          </g>
          <g id="Group_626" data-name="Group 626" transform="translate(7.246)">
            <path
              id="Path_937"
              data-name="Path 937"
              d="M3916.456,1253.115l-.258-.069c.083-.312,2.048-7.667,3.37-10.045a5.7,5.7,0,0,1,3.051-2.9l.071.257a5.563,5.563,0,0,0-2.889,2.776C3918.494,1245.48,3916.476,1253.039,3916.456,1253.115Z"
              transform="translate(-3916.198 -1240.098)"
              fill="#553e93"
            />
          </g>
          <g
            id="Group_627"
            data-name="Group 627"
            transform="translate(3.101 15.75)"
          >
            <path
              id="Path_938"
              data-name="Path 938"
              d="M3907.777,1282.7l-.253-.084,3.206-9.562.253.084Z"
              transform="translate(-3907.524 -1273.058)"
              fill="#553e93"
            />
          </g>
        </g>
        <g
          id="Group_630"
          data-name="Group 630"
          transform="translate(101.088 80.261)"
        >
          <g id="Group_629" data-name="Group 629">
            <path
              id="Path_939"
              data-name="Path 939"
              d="M3999.421,1266.45s.681,5.351-3.848,5.86c-5.77.647-13.678-4.519-10.028-4.983a39.662,39.662,0,0,1,7.99.192s-12.073-1.716-10.951-4.284,11.49-.941,11.49-.941-11.636-2.861-9.836-4.9,11.22-.621,11.22-.621-11.5-2.14-9.353-4.451,7.911.088,10.556.51c2.5.4,3.112,2.254,2.645,4.813,0,0,.918,2.876.219,4.454-.268.6.224,1.326.222,1.8A17.878,17.878,0,0,1,3999.421,1266.45Z"
              transform="translate(-3982.51 -1251.336)"
              fill="#fdad9b"
            />
          </g>
        </g>
        <g
          id="Group_636"
          data-name="Group 636"
          transform="translate(52.48 89.75)"
        >
          <g id="Group_632" data-name="Group 632">
            <g id="Group_631" data-name="Group 631">
              <path
                id="Path_940"
                data-name="Path 940"
                d="M3880.792,1283.931s3.045-9.218,8.261-11.475,13.584-1.2,10.648.889a37.467,37.467,0,0,1-7.423,3.714s9.75-3.029,9.471-.442-8.785,5.14-8.785,5.14,11.89-2.68,10.835-.321-10.1,4.329-10.1,4.329,11.162-2.05,9.872.66-9.234,1.635-9.514,4.222-2.264,12.77-9.55,12.871Z"
                transform="translate(-3880.792 -1271.193)"
                fill="#fdad9b"
              />
            </g>
          </g>
          <g
            id="Group_633"
            data-name="Group 633"
            transform="translate(7.631 5.658)"
          >
            <path
              id="Path_941"
              data-name="Path 941"
              d="M3897.14,1285.384l-.379-.18c.8-1.688,3.7-2.151,3.824-2.17l.063.415C3900.621,1283.454,3897.848,1283.9,3897.14,1285.384Z"
              transform="translate(-3896.761 -1283.034)"
              fill="#dd8964"
            />
          </g>
          <g
            id="Group_634"
            data-name="Group 634"
            transform="translate(7.628 10.356)"
          >
            <path
              id="Path_942"
              data-name="Path 942"
              d="M3897.09,1295.649l-.336-.251a7.376,7.376,0,0,1,4.518-2.533l.056.415A7.082,7.082,0,0,0,3897.09,1295.649Z"
              transform="translate(-3896.754 -1292.865)"
              fill="#dd8964"
            />
          </g>
          <g
            id="Group_635"
            data-name="Group 635"
            transform="translate(8.598 14.366)"
          >
            <path
              id="Path_943"
              data-name="Path 943"
              d="M3899.178,1303.838l-.393-.148c.7-1.872,4.13-2.413,4.276-2.435l.063.415C3903.091,1301.675,3899.795,1302.2,3899.178,1303.838Z"
              transform="translate(-3898.785 -1301.255)"
              fill="#dd8964"
            />
          </g>
        </g>
        <g
          id="Group_637"
          data-name="Group 637"
          transform="translate(109.675 100.614)"
        >
          <path
            id="Path_944"
            data-name="Path 944"
            d="M4004.3,1294.845a18.235,18.235,0,0,1-3.813-.51l.1-.407c1.24.31,4.687.92,5.744.042l.267.323A3.76,3.76,0,0,1,4004.3,1294.845Z"
            transform="translate(-4000.482 -1293.928)"
            fill="#dd8964"
          />
        </g>
      </g>
      <g
        id="Group_665"
        data-name="Group 665"
        transform="translate(345.461 246.555)"
      >
        <g
          id="Group_648"
          data-name="Group 648"
          transform="translate(33.669 68.628)"
        >
          <g id="Group_639" data-name="Group 639">
            <path
              id="Path_945"
              data-name="Path 945"
              d="M4750.66,1724.664l-122.929.281-139.944,3.486-.363,10.538,135.363-5.882c13.6.469,24.214,12.93,23.7,27.833h0c-.515,14.9-11.959,26.6-25.562,26.133l-135-4.656-.269,7.784,263.131,11.6c17.659.609,32.514-17.106,33.182-36.451h0C4782.638,1745.989,4768.319,1725.273,4750.66,1724.664Z"
              transform="translate(-4485.658 -1724.664)"
              fill="#ffba41"
            />
          </g>
          <g
            id="Group_640"
            data-name="Group 640"
            transform="translate(0 0.282)"
          >
            <path
              id="Path_946"
              data-name="Path 946"
              d="M4658.5,1761.382h0c.667-19.344-13.107-35.519-30.766-36.128l-139.944,3.486-.363,10.538,135.363-5.883c13.6.469,24.214,12.93,23.7,27.833h0c-.515,14.9-11.959,26.6-25.562,26.133l-135-4.656-.269,12.617,139.658-.017C4642.975,1795.914,4657.83,1780.726,4658.5,1761.382Z"
              transform="translate(-4485.658 -1725.253)"
              fill="#ffba41"
            />
          </g>
          <g
            id="Group_641"
            data-name="Group 641"
            transform="translate(0.267 8.452)"
          >
            <path
              id="Path_947"
              data-name="Path 947"
              d="M4623.079,1742.351l-135.362,5.854h0c12.21,1.967,15.885,14.378,17.572,21,3.421,13.427-6.748,21.15-19.065,22.271l-.006.184,135,4.655c13.6.469,25.048-11.231,25.562-26.133h0C4647.294,1755.281,4636.682,1742.821,4623.079,1742.351Z"
              transform="translate(-4486.218 -1742.351)"
              fill="#e1dcf7"
            />
          </g>
          <g
            id="Group_642"
            data-name="Group 642"
            transform="translate(53.22 41.466)"
          >
            <rect
              id="Rectangle_168"
              data-name="Rectangle 168"
              width="0.737"
              height="81.007"
              transform="translate(0 0.736) rotate(-88.671)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_644"
            data-name="Group 644"
            transform="translate(162.706 14.007)"
          >
            <g id="Group_643" data-name="Group 643">
              <path
                id="Path_948"
                data-name="Path 948"
                d="M4827.709,1798.093a1.271,1.271,0,0,1-1.006-.468.969.969,0,0,1,.334-1.453c.089-.051,8.825-5.294,10.246-19.974a20.121,20.121,0,0,0-10.494-20.247.983.983,0,0,1-.5-1.418,1.31,1.31,0,0,1,1.634-.434,21.91,21.91,0,0,1,11.769,22.275c-1.524,15.767-10.917,21.318-11.317,21.547A1.345,1.345,0,0,1,4827.709,1798.093Z"
                transform="translate(-4826.146 -1753.977)"
                fill="#e1dcf7"
              />
            </g>
          </g>
          <g
            id="Group_645"
            data-name="Group 645"
            transform="translate(42.688 33.635)"
          >
            <rect
              id="Rectangle_169"
              data-name="Rectangle 169"
              width="60.362"
              height="0.737"
              transform="translate(0 0.939) rotate(-0.892)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_647"
            data-name="Group 647"
            transform="translate(177.15 18.685)"
          >
            <g id="Group_646" data-name="Group 646">
              <path
                id="Path_949"
                data-name="Path 949"
                d="M4879.239,1800.629l-.041,0-21.659-.746a1.209,1.209,0,0,1-1-1.817,65.048,65.048,0,0,0,5.967-15.234c2.1-8.377-3.6-17.1-3.655-17.189a1.2,1.2,0,0,1-.048-1.257,1.234,1.234,0,0,1,1.1-.619l20.549.708a1.208,1.208,0,0,1,.949.516c12.838,18.389-1.094,35.05-1.236,35.216A1.211,1.211,0,0,1,4879.239,1800.629Zm-19.673-3.1,19.112.658c2.036-2.631,11.305-16.1,1.074-31.322l-17.762-.612c1.72,3.279,4.607,10.2,2.86,17.161A68.757,68.757,0,0,1,4859.565,1797.532Z"
                transform="translate(-4856.372 -1763.765)"
                fill="#e1dcf7"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_654"
          data-name="Group 654"
          transform="translate(3.099 137.407)"
        >
          <g id="Group_649" data-name="Group 649">
            <path
              id="Path_950"
              data-name="Path 950"
              d="M4453.716,1868.594l123.547.132,139.731,8.456-.011,10.544-135.069-10.688c-13.612-.014-24.66,12.062-24.676,26.973h0c-.016,14.911,11.006,27.01,24.618,27.025l135.08.144-.007,7.788-263.289-.281c-17.669-.019-31.976-15.725-31.955-35.08h0C4421.707,1884.251,4436.047,1868.575,4453.716,1868.594Z"
              transform="translate(-4421.686 -1868.594)"
              fill="#553e93"
            />
          </g>
          <g
            id="Group_650"
            data-name="Group 650"
            transform="translate(123.547 0.132)"
          >
            <path
              id="Path_951"
              data-name="Path 951"
              d="M4680.227,1903.882h0c.021-19.356,14.361-35.031,32.03-35.012l139.731,8.456-.01,10.544-135.069-10.688c-13.612-.014-24.66,12.062-24.675,26.973h0c-.016,14.911,11.006,27.01,24.618,27.025l135.08.144-.007,7.788-139.741-.149C4694.514,1938.944,4680.207,1923.238,4680.227,1903.882Z"
              transform="translate(-4680.227 -1868.87)"
              fill="#553e93"
            />
          </g>
          <g
            id="Group_651"
            data-name="Group 651"
            transform="translate(135.553 8.472)"
          >
            <path
              id="Path_952"
              data-name="Path 952"
              d="M4730.029,1886.324l135.068,10.659h0c-12.272,1.533-16.387,13.8-18.307,20.362-3.9,13.3,5.992,21.378,18.261,22.936v.185l-135.08-.144c-13.612-.014-24.633-12.114-24.618-27.025h0C4705.369,1898.386,4716.417,1886.31,4730.029,1886.324Z"
              transform="translate(-4705.354 -1886.324)"
              fill="#e1dcf7"
            />
          </g>
          <g
            id="Group_652"
            data-name="Group 652"
            transform="translate(167.214 45.516)"
          >
            <rect
              id="Rectangle_170"
              data-name="Rectangle 170"
              width="0.736"
              height="109.03"
              transform="matrix(0.001, -1, 1, 0.001, 0, 0.736)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_653"
            data-name="Group 653"
            transform="translate(193.36 34.85)"
          >
            <rect
              id="Rectangle_171"
              data-name="Rectangle 171"
              width="0.737"
              height="83.72"
              transform="matrix(0.051, -0.999, 0.999, 0.051, 0, 0.736)"
              fill="#66192d"
            />
          </g>
        </g>
        <g id="Group_664" data-name="Group 664" transform="translate(0 0)">
          <g id="Group_655" data-name="Group 655">
            <path
              id="Path_953"
              data-name="Path 953"
              d="M4447.08,1583.576l125.849-2.528,142.515,5.6.215,10.74-137.812-7.976c-13.865.279-24.858,12.818-24.552,28.006h0c.3,15.188,11.792,27.275,25.657,27l137.6-2.765.16,7.933-268.193,5.389c-18,.362-32.91-15.328-33.306-35.045h0C4414.813,1600.215,4429.082,1583.939,4447.08,1583.576Z"
              transform="translate(-4415.201 -1581.048)"
              fill="#ff8548"
            />
          </g>
          <g
            id="Group_656"
            data-name="Group 656"
            transform="translate(125.849)"
          >
            <path
              id="Path_954"
              data-name="Path 954"
              d="M4678.567,1617.4h0c-.4-19.717,13.874-35.993,31.871-36.354l142.514,5.6.216,10.74-137.812-7.976c-13.866.279-24.859,12.818-24.553,28.006h0c.305,15.188,11.792,27.275,25.658,27l137.6-2.765.16,7.933-142.344,2.86C4693.875,1652.808,4678.962,1637.119,4678.567,1617.4Z"
              transform="translate(-4678.559 -1581.048)"
              fill="#ff8548"
            />
          </g>
          <g
            id="Group_657"
            data-name="Group 657"
            transform="translate(138.087 8.396)"
          >
            <path
              id="Path_955"
              data-name="Path 955"
              d="M4728.729,1598.618l137.812,7.946h0c-12.468,1.826-16.394,14.416-18.21,21.136-3.682,13.629,6.565,21.646,19.1,22.969l0,.188-137.6,2.765c-13.864.279-25.352-11.809-25.657-27h0C4703.871,1611.436,4714.863,1598.9,4728.729,1598.618Z"
              transform="translate(-4704.17 -1598.618)"
              fill="#e1dcf7"
            />
          </g>
          <g
            id="Group_658"
            data-name="Group 658"
            transform="translate(170.56 43.787)"
          >
            <rect
              id="Rectangle_172"
              data-name="Rectangle 172"
              width="111.083"
              height="0.751"
              transform="translate(0 2.15) rotate(-1.109)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_660"
            data-name="Group 660"
            transform="translate(118.443 13.236)"
          >
            <g id="Group_659" data-name="Group 659">
              <path
                id="Path_956"
                data-name="Path 956"
                d="M4673.717,1654.937a1.092,1.092,0,0,1-.617-.191c-.359-.245-8.807-6.181-9.956-22.71-1.163-16.719,10.522-22.913,11.02-23.168a1.1,1.1,0,0,1,1,1.953c-.446.232-10.893,5.837-9.833,21.063,1.077,15.477,8.923,20.994,9,21.047a1.1,1.1,0,0,1-.62,2.006Z"
                transform="translate(-4663.063 -1608.746)"
                fill="#e1dcf7"
              />
            </g>
          </g>
          <g
            id="Group_661"
            data-name="Group 661"
            transform="translate(196.978 34.55)"
          >
            <rect
              id="Rectangle_173"
              data-name="Rectangle 173"
              width="0.751"
              height="85.297"
              transform="translate(0 0.75) rotate(-88.285)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_663"
            data-name="Group 663"
            transform="translate(90.03 20.54)"
          >
            <g id="Group_662" data-name="Group 662">
              <path
                id="Path_957"
                data-name="Path 957"
                d="M4613.982,1656.971a7.939,7.939,0,0,1-7.057-4.235c-2.482-4.687-5.834-14.1-.4-24.46a7.881,7.881,0,0,1,6.856-4.154h0l4.439-.089a7.978,7.978,0,0,1,6.367,2.941,7.887,7.887,0,0,1,1.6,6.754,16.42,16.42,0,0,0-.1,7.373c.407,1.724.914,3.487,1.505,5.24a7.984,7.984,0,0,1-7.429,10.516l-5.625.113C4614.093,1656.971,4614.037,1656.971,4613.982,1656.971Zm-.555-30.652a5.681,5.681,0,0,0-4.955,2.978c-4.968,9.471-1.886,18.107.394,22.411a5.757,5.757,0,0,0,5.237,3.066l5.625-.113a5.762,5.762,0,0,0,4.645-2.474,5.688,5.688,0,0,0,.746-5.143c-.614-1.819-1.139-3.648-1.562-5.439a18.6,18.6,0,0,1,.093-8.358,5.7,5.7,0,0,0-1.161-4.883,5.789,5.789,0,0,0-4.623-2.135Z"
                transform="translate(-4603.603 -1624.032)"
                fill="#e1dcf7"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_671"
        data-name="Group 671"
        transform="translate(26.596 339.164)"
      >
        <g id="Group_666" data-name="Group 666" transform="translate(0 0)">
          <path
            id="Path_958"
            data-name="Path 958"
            d="M3996.643,1778.322l-115.68-3.474-131.1,3.06-.33,8.872,126.809-5.073a22.715,22.715,0,0,1,22.232,23.41h0a23.111,23.111,0,0,1-23.922,22.024l-126.479-3.8-.243,6.553,246.522,7.4c16.545.5,30.447-12.3,31.052-28.588h0A29.485,29.485,0,0,0,3996.643,1778.322Z"
            transform="translate(-3747.926 -1774.847)"
            fill="#ff8548"
          />
        </g>
        <g id="Group_667" data-name="Group 667" transform="translate(0 0)">
          <path
            id="Path_959"
            data-name="Path 959"
            d="M3909.821,1805.235h0a29.487,29.487,0,0,0-28.859-30.388l-131.1,3.06-.33,8.872,126.809-5.073a22.715,22.715,0,0,1,22.232,23.41h0a23.111,23.111,0,0,1-23.922,22.024l-126.479-3.8-.243,6.553,130.843,3.93C3895.313,1834.321,3909.215,1821.522,3909.821,1805.235Z"
            transform="translate(-3747.926 -1774.847)"
            fill="#ff8548"
          />
        </g>
        <g
          id="Group_668"
          data-name="Group 668"
          transform="translate(0.243 6.883)"
        >
          <path
            id="Path_960"
            data-name="Path 960"
            d="M3876.6,1789.251l-126.808,5.049h0c11.441,1.646,14.9,12.091,16.484,17.664,3.219,11.3-6.3,17.812-17.839,18.768l0,.155,126.479,3.8a23.11,23.11,0,0,0,23.921-22.024h0A22.715,22.715,0,0,0,3876.6,1789.251Z"
            transform="translate(-3748.434 -1789.251)"
            fill="#e1dcf7"
          />
        </g>
        <g
          id="Group_669"
          data-name="Group 669"
          transform="translate(18.564 34.765)"
        >
          <rect
            id="Rectangle_174"
            data-name="Rectangle 174"
            width="0.655"
            height="102.134"
            transform="translate(0 0.654) rotate(-88.28)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_670"
          data-name="Group 670"
          transform="translate(18.082 28.087)"
        >
          <rect
            id="Rectangle_175"
            data-name="Rectangle 175"
            width="78.431"
            height="0.654"
            transform="translate(0 1.191) rotate(-0.87)"
            fill="#66192d"
          />
        </g>
      </g>
      <g id="Group_681" data-name="Group 681" transform="translate(0 394.803)">
        <g id="Group_672" data-name="Group 672" transform="translate(0 0)">
          <path
            id="Path_961"
            data-name="Path 961"
            d="M3719.2,1891.281h104.011l117.645,6.994v8.877l-113.721-8.877c-11.459,0-20.749,10.177-20.749,22.729h0c0,12.554,9.29,22.73,20.749,22.73H3940.86v6.557H3719.2c-14.875,0-26.934-13.21-26.934-29.506h0C3692.269,1904.492,3704.328,1891.281,3719.2,1891.281Z"
            transform="translate(-3692.269 -1891.281)"
            fill="#786ab5"
          />
        </g>
        <g
          id="Group_673"
          data-name="Group 673"
          transform="translate(104.012 0)"
        >
          <path
            id="Path_962"
            data-name="Path 962"
            d="M3909.93,1920.786h0c0-16.294,12.059-29.505,26.934-29.505l120.676,6.167-3.031,9.7-113.722-8.877c-11.459,0-20.749,10.177-20.749,22.729h0c0,12.554,9.29,22.73,20.749,22.73H4054.51v6.557H3936.865C3921.989,1950.292,3909.93,1937.082,3909.93,1920.786Z"
            transform="translate(-3909.93 -1891.281)"
            fill="#786ab5"
          />
        </g>
        <g
          id="Group_674"
          data-name="Group 674"
          transform="translate(114.12 7.018)"
        >
          <path
            id="Path_963"
            data-name="Path 963"
            d="M3951.833,1905.968l113.722,8.852h0c-10.331,1.3-13.783,11.637-15.395,17.159-3.268,11.2,5.063,17.992,15.395,19.292v.155H3951.833c-11.459,0-20.749-10.176-20.749-22.729h0C3931.084,1916.145,3940.374,1905.968,3951.833,1905.968Z"
            transform="translate(-3931.084 -1905.968)"
            fill="#e1dcf7"
          />
        </g>
        <g
          id="Group_675"
          data-name="Group 675"
          transform="translate(140.785 38.198)"
        >
          <rect
            id="Rectangle_176"
            data-name="Rectangle 176"
            width="91.79"
            height="0.621"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_677"
          data-name="Group 677"
          transform="translate(45.17 9.394)"
        >
          <g id="Group_676" data-name="Group 676">
            <path
              id="Path_964"
              data-name="Path 964"
              d="M3794.764,1949.239c-.3-.21-7.251-5.309-7.934-19.134-.69-13.984,9.172-18.961,9.593-19.166l.8,1.649-.4-.825.4.824c-.376.186-9.2,4.691-8.567,17.427.64,12.946,7.1,17.684,7.165,17.73Z"
              transform="translate(-3786.795 -1910.939)"
              fill="#e1dcf7"
            />
          </g>
        </g>
        <g
          id="Group_678"
          data-name="Group 678"
          transform="translate(162.787 29.196)"
        >
          <rect
            id="Rectangle_177"
            data-name="Rectangle 177"
            width="0.62"
            height="70.482"
            transform="matrix(0.05, -0.999, 0.999, 0.05, 0, 0.619)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_680"
          data-name="Group 680"
          transform="translate(15.01 11.431)"
        >
          <g id="Group_679" data-name="Group 679">
            <path
              id="Path_965"
              data-name="Path 965"
              d="M3733.674,1915.2h5.6a7.49,7.49,0,0,1,7.237,9.369,19.985,19.985,0,0,0-.342,9.1c.5,2.317,1.1,4.483,1.727,6.429a7.471,7.471,0,0,1-7.135,9.728h-6.977a7.465,7.465,0,0,1-6.646-4.045c-2.776-5.406-6.119-15.6-.038-26.669A7.515,7.515,0,0,1,3733.674,1915.2Z"
              transform="translate(-3723.68 -1915.203)"
              fill="#e1dcf7"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_741"
        data-name="Group 741"
        transform="translate(292.517 38.919)"
      >
        <g id="Group_682" data-name="Group 682" transform="translate(0 0)">
          <path
            id="Path_966"
            data-name="Path 966"
            d="M4651.994,1360.3l-334.657-3.43c-7.165-.074-12.929-5.282-12.929-11.682V1162.738c0-6.382,5.735-11.583,12.878-11.681l330.341-4.519c7.2-.1,13.13,5.016,13.277,11.445l4.316,190.4C4665.368,1354.975,4659.378,1360.373,4651.994,1360.3Z"
            transform="translate(-4304.408 -1146.537)"
            fill="#786ab5"
          />
        </g>
        <g
          id="Group_683"
          data-name="Group 683"
          transform="translate(12.97 13.976)"
        >
          <path
            id="Rectangle_178"
            data-name="Rectangle 178"
            d="M13.841,0H320.9a13.841,13.841,0,0,1,13.841,13.841V170.984A13.842,13.842,0,0,1,320.9,184.826H13.841A13.841,13.841,0,0,1,0,170.985V13.841A13.841,13.841,0,0,1,13.841,0Z"
            fill="url(#linear-gradient)"
          />
        </g>
        <g
          id="Group_710"
          data-name="Group 710"
          transform="translate(147.856 35.534)"
        >
          <g
            id="Group_684"
            data-name="Group 684"
            transform="translate(0 109.61)"
          >
            <path
              id="Path_967"
              data-name="Path 967"
              d="M4813.675,1482.811v5.428a6.062,6.062,0,0,1-6.062,6.062H4615.076a12.561,12.561,0,0,1-1.256-.387c13.7-13.312,40.077-34.2,76.125-41.25q3.971-.786,8.1-1.333,3.644-.476,7.407-.751c.133-.012.27-.023.4-.031a131.616,131.616,0,0,1,22.877.54q2.295.241,4.572.54,3.372.446,6.59.958C4775.952,1458.33,4799.014,1470.465,4813.675,1482.811Z"
              transform="translate(-4613.82 -1450.274)"
              fill="#552748"
            />
          </g>
          <g
            id="Group_685"
            data-name="Group 685"
            transform="translate(84.408 47.303)"
          >
            <path
              id="Path_968"
              data-name="Path 968"
              d="M4822.465,1392.039c4.083-6.28-1.784-6.824-3.639-13.171l-5.744-19.641-1.67-5.718-9.828-33.621-1.9,33.256-1.136,19.962-.36,6.318c-.244,4.289-1.422,4.879-4.666,8.558-1.314,1.49-6.221,7.519.055,10.548C4799.207,1401.248,4817.259,1400.045,4822.465,1392.039Z"
              transform="translate(-4790.458 -1319.888)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_686"
            data-name="Group 686"
            transform="translate(111.21 36.222)"
          >
            <path
              id="Path_969"
              data-name="Path 969"
              d="M4849.857,1339.558s32.742-11.428,27.859-35.789-29.853,22.042-29.853,22.042l-1.32,12.742Z"
              transform="translate(-4846.544 -1296.699)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_687"
            data-name="Group 687"
            transform="translate(92.502 79.757)"
          >
            <path
              id="Path_970"
              data-name="Path 970"
              d="M4822.033,1394.147q-.045.271-.106.541c-2.2,10.512-14.469,13.946-14.469,13.946l-.063-.068,1.136-19.962c3.72-.8,8.952-1.448,11.725.365C4821.705,1389.918,4822.483,1391.534,4822.033,1394.147Z"
              transform="translate(-4807.394 -1387.803)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_688"
            data-name="Group 688"
            transform="translate(63.677 20.899)"
          >
            <path
              id="Path_971"
              data-name="Path 971"
              d="M4747.44,1296.079c2.542-19.36,17.617-33.344,33.672-31.236s27.01,19.51,24.468,38.869-17.617,33.344-33.672,31.236S4744.9,1315.438,4747.44,1296.079Z"
              transform="translate(-4747.074 -1264.632)"
              fill="#fdad9b"
            />
          </g>
          <g id="Group_689" data-name="Group 689" transform="translate(51.744)">
            <path
              id="Path_972"
              data-name="Path 972"
              d="M4788.819,1276.228s-9.663,1.953-13.764-5.248-6.52-13.361-23.283-12.732-30.351-9.208-29.644-18.136,9.885-11.859,18.3-7.591.121-5.106,9.75-10.472,21.026,9.807,30.352,9.208,37.669,8.038,32.207,32.934Z"
              transform="translate(-4722.102 -1220.898)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_690"
            data-name="Group 690"
            transform="translate(112.868 51.754)"
          >
            <path
              id="Path_973"
              data-name="Path 973"
              d="M4859.267,1339.914c-2.974,1.891-6.753,1.271-8.442-1.385s-.651-6.342,2.321-8.234,6.752-1.271,8.442,1.385S4862.239,1338.024,4859.267,1339.914Z"
              transform="translate(-4850.014 -1329.201)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_691"
            data-name="Group 691"
            transform="translate(64.537 16.354)"
          >
            <path
              id="Path_974"
              data-name="Path 974"
              d="M4757.917,1262.79c-2.982,0-5.218-.581-6.676-1.736-2.54-2.017-2.709-3.815-2.021-4.824.815-1.2,3.059-1.623,5.4-.262,4.014,2.329,6.958,1.952,6.988,1.949l.055.4c-.125.017-3.1.405-7.245-2-2.142-1.243-4.159-.9-4.873.142-.587.861-.386,2.439,1.94,4.285,4.813,3.82,17.757-.225,17.887-.266l.121.381a50.155,50.155,0,0,1-7.211,1.6A31.5,31.5,0,0,1,4757.917,1262.79Z"
              transform="translate(-4748.873 -1255.122)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_692"
            data-name="Group 692"
            transform="translate(90.98 7.011)"
          >
            <path
              id="Path_975"
              data-name="Path 975"
              d="M4840.03,1246.635c-3.43-2.424-7.63-2.183-12.5-1.9a67.343,67.343,0,0,1-7.79.136c-8.354-.458-15.293-8.377-15.536-9.181l.383-.117c.17.495,6.789,8.438,15.175,8.9a67.223,67.223,0,0,0,7.745-.136c4.939-.284,9.205-.53,12.748,1.976Z"
              transform="translate(-4804.209 -1235.57)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_693"
            data-name="Group 693"
            transform="translate(133.124 31.819)"
          >
            <path
              id="Path_976"
              data-name="Path 976"
              d="M4895.761,1294.414h-.049c-1.255-.081-3.243-3.806-3.307-5.7-.034-.969.447-1.179.736-1.217,2.345-.315,3.467,5.4,3.513,5.641l.006.035,0,.035C4896.493,1294.193,4896.09,1294.414,4895.761,1294.414Zm-2.438-6.529a.973.973,0,0,0-.129.009c-.1.013-.415.055-.39.807.061,1.776,1.98,5.252,2.934,5.313.286.019.449-.435.517-.836C4895.954,1291.642,4894.877,1287.884,4893.323,1287.884Z"
              transform="translate(-4892.403 -1287.484)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_695"
            data-name="Group 695"
            transform="translate(87.327 53.526)"
          >
            <g id="Group_694" data-name="Group 694">
              <path
                id="Path_977"
                data-name="Path 977"
                d="M4796.843,1334.065a.594.594,0,0,1,.267-1.095,6.434,6.434,0,0,1,6.851,3.9.6.6,0,0,1-.268.8.652.652,0,0,1-.8-.267,10.184,10.184,0,0,0-5.687-3.245A.592.592,0,0,1,4796.843,1334.065Z"
                transform="translate(-4796.565 -1332.91)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_696"
            data-name="Group 696"
            transform="translate(86.462 59.288)"
          >
            <path
              id="Path_978"
              data-name="Path 978"
              d="M4798.474,1347.985c-.373,1.368-1.48,2.257-2.473,1.987s-1.495-1.6-1.122-2.968,1.48-2.258,2.474-1.987S4798.848,1346.617,4798.474,1347.985Z"
              transform="translate(-4794.756 -1344.968)"
              fill="#060633"
            />
          </g>
          <g
            id="Group_698"
            data-name="Group 698"
            transform="translate(65.254 50.796)"
          >
            <g id="Group_697" data-name="Group 697">
              <path
                id="Path_979"
                data-name="Path 979"
                d="M4750.737,1329.316a.433.433,0,0,1-.322-.3.716.716,0,0,1,.23-.8,4.172,4.172,0,0,1,6.062.688.708.708,0,0,1,.011.831.439.439,0,0,1-.656.088,6.228,6.228,0,0,0-5.033-.577A.394.394,0,0,1,4750.737,1329.316Z"
                transform="translate(-4750.374 -1327.196)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_699"
            data-name="Group 699"
            transform="translate(67.311 56.68)"
          >
            <path
              id="Path_980"
              data-name="Path 980"
              d="M4758.4,1341.837c.176,1.407-.509,2.651-1.53,2.779s-1.992-.909-2.168-2.316.509-2.652,1.531-2.78S4758.227,1340.43,4758.4,1341.837Z"
              transform="translate(-4754.677 -1339.51)"
              fill="#060633"
            />
          </g>
          <g
            id="Group_702"
            data-name="Group 702"
            transform="translate(77.107 75.926)"
          >
            <g
              id="Group_700"
              data-name="Group 700"
              transform="translate(0 1.102)"
            >
              <path
                id="Path_981"
                data-name="Path 981"
                d="M4784.793,1386.1a16.917,16.917,0,0,1-9.459-3.27.415.415,0,0,1,.517-.649c.331.264,8.18,6.382,15.951.754a.414.414,0,1,1,.485.672A12.537,12.537,0,0,1,4784.793,1386.1Z"
                transform="translate(-4775.178 -1382.09)"
                fill="#020202"
              />
            </g>
            <g
              id="Group_701"
              data-name="Group 701"
              transform="translate(15.722)"
            >
              <path
                id="Path_982"
                data-name="Path 982"
                d="M4809.814,1384.114l-.042,0a.414.414,0,0,1-.371-.455,4.689,4.689,0,0,0-1.179-3.123c-.04-.056-.067-.095-.08-.114a.415.415,0,0,1,.7-.442c.008.013.025.037.048.068a5.55,5.55,0,0,1,1.333,3.7A.414.414,0,0,1,4809.814,1384.114Z"
                transform="translate(-4808.079 -1379.784)"
                fill="#020202"
              />
            </g>
          </g>
          <g
            id="Group_703"
            data-name="Group 703"
            transform="translate(51.418 37.375)"
          >
            <path
              id="Path_983"
              data-name="Path 983"
              d="M4733.007,1338.334a8.143,8.143,0,0,1-4.347-1.3c-3.765-2.352-6.411-7.6-7.078-14.033-1.083-10.473,3.4-20.958,10-23.374a7.961,7.961,0,0,1,7.179.782c3.765,2.352,6.411,7.6,7.077,14.033h0c1.084,10.472-3.4,20.958-10,23.373A8.184,8.184,0,0,1,4733.007,1338.334Zm1.4-37.985a6.946,6.946,0,0,0-2.4.438c-6.1,2.233-10.225,12.142-9.2,22.089.628,6.06,3.06,10.962,6.5,13.114a6.767,6.767,0,0,0,6.1.669c6.1-2.233,10.225-12.142,9.194-22.088h0c-.627-6.06-3.059-10.963-6.5-13.115A6.934,6.934,0,0,0,4734.4,1300.349Z"
              transform="translate(-4721.42 -1299.112)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_704"
            data-name="Group 704"
            transform="translate(82.782 37.619)"
            opacity="0.3"
          >
            <path
              id="Path_984"
              data-name="Path 984"
              d="M4812.82,1314.852c.184,10.009-5.433,19.544-12.547,21.3s-13.03-4.942-13.215-14.952,5.432-19.545,12.546-21.3S4812.635,1304.842,4812.82,1314.852Z"
              transform="translate(-4787.054 -1299.622)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_705"
            data-name="Group 705"
            transform="translate(52.592 38.663)"
            opacity="0.3"
          >
            <path
              id="Path_985"
              data-name="Path 985"
              d="M4747.21,1316.693c.182,10.055-4.894,19.859-11.336,21.9s-11.81-4.459-11.991-14.513,4.894-19.859,11.336-21.9S4747.029,1306.638,4747.21,1316.693Z"
              transform="translate(-4723.877 -1301.806)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_706"
            data-name="Group 706"
            transform="translate(80.818 34.942)"
          >
            <path
              id="Path_986"
              data-name="Path 986"
              d="M4795.337,1333.664a10.125,10.125,0,0,1-6.269-2.2c-3.746-2.891-5.976-8.12-6.118-14.348-.246-10.708,5.921-20.933,13.746-22.792a10.113,10.113,0,0,1,8.778,1.9c3.746,2.89,5.976,8.12,6.118,14.348.246,10.708-5.92,20.933-13.745,22.793A10.82,10.82,0,0,1,4795.337,1333.664Zm3.855-38.408a9.53,9.53,0,0,0-2.211.266c-7.289,1.733-13.03,11.406-12.8,21.564.132,5.768,2.24,10.777,5.638,13.4a8.919,8.919,0,0,0,7.74,1.678c7.29-1.732,13.03-11.406,12.8-21.564-.132-5.768-2.24-10.777-5.638-13.4A8.932,8.932,0,0,0,4799.193,1295.255Z"
              transform="translate(-4782.943 -1294.019)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_707"
            data-name="Group 707"
            transform="translate(72.98 53.712)"
          >
            <path
              id="Path_987"
              data-name="Path 987"
              d="M4777.06,1336.688a1.141,1.141,0,0,1-.448-.092c-4.884-2.067-8.342-.282-8.377-.264a1.152,1.152,0,0,1-1.081-2.034c.18-.095,4.473-2.312,10.355.177a1.152,1.152,0,0,1-.449,2.213Z"
              transform="translate(-4766.542 -1333.298)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_708"
            data-name="Group 708"
            transform="translate(142.236 35.207)"
          >
            <path
              id="Path_988"
              data-name="Path 988"
              d="M4911.764,1302.951l-.292-.58c4.729-2.38,3.52-7.591,3.507-7.643l.631-.154C4915.624,1294.631,4916.949,1300.341,4911.764,1302.951Z"
              transform="translate(-4911.472 -1294.574)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_709"
            data-name="Group 709"
            transform="translate(140.634 45.354)"
          >
            <path
              id="Path_989"
              data-name="Path 989"
              d="M4913.434,1320.976c-.026-.039-2.56-3.921-5.315-4.534l.141-.634c3.017.671,5.609,4.647,5.719,4.815Z"
              transform="translate(-4908.119 -1315.809)"
              fill="#66192d"
            />
          </g>
        </g>
        <g
          id="Group_711"
          data-name="Group 711"
          transform="translate(62.589 50.985)"
          opacity="0.5"
        >
          <path
            id="Rectangle_179"
            data-name="Rectangle 179"
            d="M9.828,0H89.584a9.828,9.828,0,0,1,9.828,9.828V51.539a9.828,9.828,0,0,1-9.828,9.828H9.829A9.829,9.829,0,0,1,0,51.538V9.828A9.828,9.828,0,0,1,9.828,0Z"
            fill="#ff443c"
          />
        </g>
        <g
          id="Group_712"
          data-name="Group 712"
          transform="translate(42.628 34.092)"
        >
          <path
            id="Rectangle_180"
            data-name="Rectangle 180"
            d="M9.828,0H89.583a9.829,9.829,0,0,1,9.829,9.829v41.71a9.829,9.829,0,0,1-9.829,9.829H9.828A9.828,9.828,0,0,1,0,51.539V9.828A9.828,9.828,0,0,1,9.828,0Z"
            fill="#ff443c"
          />
        </g>
        <g
          id="Group_722"
          data-name="Group 722"
          transform="translate(82.648 88.472)"
        >
          <g
            id="Group_717"
            data-name="Group 717"
            transform="translate(2.254 34.25)"
          >
            <g
              id="Group_714"
              data-name="Group 714"
              transform="translate(0 39.853)"
            >
              <g id="Group_713" data-name="Group 713">
                <path
                  id="Path_990"
                  data-name="Path 990"
                  d="M4533.651,1492.91c0,.017,0,.034-.009.051-.222,1.554-.435,3.354-.627,5.372l0,0c-.388,4.067-.692,9-.78,14.505h-50.154a223.378,223.378,0,0,1,1.878-26.086v0l4.117.516,19.946,2.493.78.1.973.12,22.377,2.748,1.5.184Z"
                  transform="translate(-4482.077 -1486.752)"
                  fill="#f18c7e"
                />
              </g>
            </g>
            <g
              id="Group_715"
              data-name="Group 715"
              transform="translate(1.772)"
            >
              <path
                id="Path_991"
                data-name="Path 991"
                d="M4485.786,1443.2s.683-31.99,5.616-37.4,42.31-1.222,45.1,6.491-.916,37.075-.916,37.075Z"
                transform="translate(-4485.786 -1403.353)"
                fill="#f18c7e"
              />
            </g>
            <g
              id="Group_716"
              data-name="Group 716"
              transform="translate(23.228 29.942)"
            >
              <path
                id="Path_992"
                data-name="Path 992"
                d="M4531.182,1491.57a.475.475,0,0,1-.475-.452,34.747,34.747,0,0,1,.473-6.983c.871-4.912,2.739-7.828,5.555-8.667a39.118,39.118,0,0,0,16.266-9.3.476.476,0,0,1,.7.647,39.376,39.376,0,0,1-16.691,9.569c-5.915,1.763-5.357,14.562-5.351,14.692a.476.476,0,0,1-.452.5Z"
                transform="translate(-4530.686 -1466.012)"
                fill="#e08b65"
              />
            </g>
          </g>
          <g id="Group_718" data-name="Group 718" transform="translate(40.344)">
            <path
              id="Path_993"
              data-name="Path 993"
              d="M4577.173,1382.117s1.535-49.535-3.58-50.385c-7.482-1.243-10.455,19.283-11.735,34.2S4577.173,1382.117,4577.173,1382.117Z"
              transform="translate(-4561.788 -1331.679)"
              fill="#f18c7e"
            />
          </g>
          <g
            id="Group_720"
            data-name="Group 720"
            transform="translate(0 22.478)"
          >
            <g id="Group_719" data-name="Group 719">
              <path
                id="Path_994"
                data-name="Path 994"
                d="M4515,1378.84c2.7.462,5.417-.367,6.686,14.758.635,7.564,1.157,31.376-5.98,30.177s-9.576-28.188-9.576-28.188.146,30.943-6.084,27.713-8.605-27.713-8.605-27.713,1.864,30.14-5.271,26.223-9.314-19.97-8.715-26.859c.749-8.61,7.047-9.113,13.75-9.55,0,0,6.627-4.108,11.018-3.348C4503.906,1382.344,4509.7,1377.934,4515,1378.84Z"
                transform="translate(-4477.361 -1378.717)"
                fill="#fdad9b"
              />
            </g>
          </g>
          <g
            id="Group_721"
            data-name="Group 721"
            transform="translate(51.778 40.596)"
          >
            <path
              id="Path_995"
              data-name="Path 995"
              d="M4587.125,1461.723s7.068-5.808,11.763-17.111,15.148-22.4,12.16-26.879-17.921,5.517-22.829,15.56S4587.125,1461.723,4587.125,1461.723Z"
              transform="translate(-4585.713 -1416.632)"
              fill="#f18c7e"
            />
          </g>
        </g>
        <g
          id="Group_723"
          data-name="Group 723"
          transform="translate(12.969 182.356)"
        >
          <path
            id="Path_996"
            data-name="Path 996"
            d="M4666.29,1528.146v9.832a6.614,6.614,0,0,1-6.613,6.614H4338.163a6.614,6.614,0,0,1-6.615-6.614v-6.559C4370.512,1530.83,4590.27,1528.834,4666.29,1528.146Z"
            transform="translate(-4331.548 -1528.146)"
            fill="#ff443c"
          />
        </g>
        <g
          id="Group_725"
          data-name="Group 725"
          transform="translate(33.999 189.309)"
        >
          <g id="Group_724" data-name="Group 724" transform="translate(0 0)">
            <rect
              id="Rectangle_181"
              data-name="Rectangle 181"
              width="289.349"
              height="2.56"
              transform="matrix(1, -0.006, 0.006, 1, 0, 1.599)"
              fill="#c8c1e1"
            />
          </g>
        </g>
        <g
          id="Group_727"
          data-name="Group 727"
          transform="translate(33.999 190.106)"
        >
          <g id="Group_726" data-name="Group 726">
            <path
              id="Path_997"
              data-name="Path 997"
              d="M4520.9,1544.364l-.35,2.56-144.978.811-.013-2.56Z"
              transform="translate(-4375.555 -1544.364)"
              fill="#ff8548"
            />
          </g>
        </g>
        <g
          id="Group_728"
          data-name="Group 728"
          transform="translate(174.367 187.077)"
        >
          <path
            id="Path_998"
            data-name="Path 998"
            d="M4669.3,1542.336a4.312,4.312,0,1,1,4.311,4.311A4.311,4.311,0,0,1,4669.3,1542.336Z"
            transform="translate(-4669.299 -1538.025)"
            fill="#ff8548"
          />
        </g>
        <g
          id="Group_732"
          data-name="Group 732"
          transform="translate(303.811 22.504)"
        >
          <g id="Group_729" data-name="Group 729">
            <path
              id="Path_999"
              data-name="Path 999"
              d="M4940.182,1196.82a3.189,3.189,0,1,1,3.189,3.19A3.189,3.189,0,0,1,4940.182,1196.82Z"
              transform="translate(-4940.182 -1193.63)"
              fill="#ffba41"
            />
          </g>
          <g id="Group_730" data-name="Group 730" transform="translate(11.531)">
            <path
              id="Path_1000"
              data-name="Path 1000"
              d="M4964.313,1196.82a3.189,3.189,0,1,1,3.189,3.19A3.189,3.189,0,0,1,4964.313,1196.82Z"
              transform="translate(-4964.313 -1193.63)"
              fill="#f22525"
            />
          </g>
          <g id="Group_731" data-name="Group 731" transform="translate(23.349)">
            <path
              id="Path_1001"
              data-name="Path 1001"
              d="M4989.043,1196.82a3.189,3.189,0,1,1,3.189,3.19A3.189,3.189,0,0,1,4989.043,1196.82Z"
              transform="translate(-4989.043 -1193.63)"
              fill="#553e93"
            />
          </g>
        </g>
        <g
          id="Group_740"
          data-name="Group 740"
          transform="translate(51.847 41.065)"
        >
          <g
            id="Group_733"
            data-name="Group 733"
            transform="translate(44.673 17.312)"
          >
            <path
              id="Path_1002"
              data-name="Path 1002"
              d="M4506.535,1285.291l3.287-7.737a1.808,1.808,0,0,1,2.725-.756l4.392,3.185a1.806,1.806,0,0,0,2.786-.928l2.53-8.145a1.808,1.808,0,0,1,2.323-1.17l2.421.846a1.807,1.807,0,0,0,2.049-.63l.384-.52a1.808,1.808,0,0,1,3.261,1.075V1286a1.809,1.809,0,0,1-1.808,1.807H4508.2A1.807,1.807,0,0,1,4506.535,1285.291Z"
              transform="translate(-4506.389 -1268.7)"
              fill="#ff8548"
            />
          </g>
          <g
            id="Group_734"
            data-name="Group 734"
            transform="translate(0 12.673)"
          >
            <path
              id="Path_1003"
              data-name="Path 1003"
              d="M4485.311,1287.84h-.057l-67.639-1.11a3.374,3.374,0,0,1-3.329-3.157l-1.373-19.646a3.394,3.394,0,0,1,3.321-3.63l69.011-1.306a3.392,3.392,0,0,1,3.457,3.393v22.062a3.393,3.393,0,0,1-3.392,3.393Zm0-28.191h-.053l-69.011,1.306a2.737,2.737,0,0,0-2.678,2.926l1.372,19.646a2.72,2.72,0,0,0,2.686,2.544l67.637,1.11a2.679,2.679,0,0,0,1.963-.785,2.711,2.711,0,0,0,.818-1.95v-22.062a2.735,2.735,0,0,0-2.734-2.735Z"
              transform="translate(-4412.905 -1258.991)"
              fill="#fecaff"
            />
          </g>
          <g
            id="Group_735"
            data-name="Group 735"
            transform="translate(7.679 26.533)"
          >
            <path
              id="Path_1004"
              data-name="Path 1004"
              d="M4454.869,1290.5h-24.444a1.669,1.669,0,0,1-1.437-1.094c-.147-1.094.9-1.406,1.436-1.406l24.445.544a.981.981,0,0,1,.979.978h0A.982.982,0,0,1,4454.869,1290.5Z"
              transform="translate(-4428.974 -1287.997)"
              fill="#fecaff"
            />
          </g>
          <g
            id="Group_736"
            data-name="Group 736"
            transform="translate(7.679 22.432)"
          >
            <path
              id="Path_1005"
              data-name="Path 1005"
              d="M4448.52,1281.423h-18.45a1.274,1.274,0,0,1-1.085-.879,1.008,1.008,0,0,1,1.083-1.13l18.452.438a.765.765,0,0,1,.738.785h0A.765.765,0,0,1,4448.52,1281.423Z"
              transform="translate(-4428.975 -1279.414)"
              fill="#fecaff"
            />
          </g>
          <g
            id="Group_737"
            data-name="Group 737"
            transform="translate(7.679 31.652)"
          >
            <path
              id="Path_1006"
              data-name="Path 1006"
              d="M4448.52,1300.718h-18.45a1.274,1.274,0,0,1-1.085-.879,1.007,1.007,0,0,1,1.083-1.13l18.452.437a.765.765,0,0,1,.738.786h0A.765.765,0,0,1,4448.52,1300.718Z"
              transform="translate(-4428.975 -1298.709)"
              fill="#fecaff"
            />
          </g>
          <g id="Group_738" data-name="Group 738" transform="translate(1.094)">
            <path
              id="Path_1007"
              data-name="Path 1007"
              d="M4441.088,1234.972h-24.443a1.669,1.669,0,0,1-1.437-1.094c-.146-1.094.9-1.406,1.436-1.406l24.445.544a.982.982,0,0,1,.979.978h0A.982.982,0,0,1,4441.088,1234.972Z"
              transform="translate(-4415.194 -1232.472)"
              fill="#fecaff"
            />
          </g>
          <g
            id="Group_739"
            data-name="Group 739"
            transform="translate(1.094 5.118)"
          >
            <path
              id="Path_1008"
              data-name="Path 1008"
              d="M4434.739,1245.192h-18.45a1.275,1.275,0,0,1-1.084-.878,1.007,1.007,0,0,1,1.083-1.13l18.451.437a.765.765,0,0,1,.739.785h0A.765.765,0,0,1,4434.739,1245.192Z"
              transform="translate(-4415.194 -1243.183)"
              fill="#fecaff"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_785"
        data-name="Group 785"
        transform="translate(283.208 298.673)"
      >
        <g
          id="Group_742"
          data-name="Group 742"
          transform="translate(121.244 73.152)"
        >
          <path
            id="Path_1009"
            data-name="Path 1009"
            d="M4550.793,1843.2c1.739,3.1,19.2,30.148,15.546,56.6l-19.006-6.872-8.685-35.661Z"
            transform="translate(-4538.649 -1843.195)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_743"
          data-name="Group 743"
          transform="translate(29.952 45.902)"
        >
          <path
            id="Path_1010"
            data-name="Path 1010"
            d="M4375.15,1802.334s11.678-20.943,40.1-15.153,43.937,46.474,36.228,127.056c0,0-45.157-17.953-103.873-3.48C4347.606,1910.757,4357.271,1832.717,4375.15,1802.334Z"
            transform="translate(-4347.606 -1786.169)"
            fill="#ff8548"
          />
        </g>
        <g
          id="Group_744"
          data-name="Group 744"
          transform="translate(35.816 44.923)"
        >
          <path
            id="Path_1011"
            data-name="Path 1011"
            d="M4359.877,1792.8l19.98,19.945s51.221,13.839,65.448,13.673,19.826-10.564,19.826-10.564a75.857,75.857,0,0,0-36.3-27.616,71.037,71.037,0,0,0-7.168-2.128,73.182,73.182,0,0,0-12.692-1.84C4383.817,1782.777,4359.877,1792.8,4359.877,1792.8Z"
            transform="translate(-4359.877 -1784.121)"
            fill="#ff8548"
          />
        </g>
        <g
          id="Group_745"
          data-name="Group 745"
          transform="translate(34.808 53.2)"
        >
          <path
            id="Path_1012"
            data-name="Path 1012"
            d="M4358.776,1801.846c-3.194,3.471,1.732,7.263,7.249,12.771s11.216,8.72,12.729,7.174-1.731-7.264-7.248-12.772S4360.242,1800.253,4358.776,1801.846Z"
            transform="translate(-4357.767 -1801.441)"
            fill="#9b2f00"
          />
        </g>
        <g
          id="Group_746"
          data-name="Group 746"
          transform="translate(33.551 58.147)"
        >
          <path
            id="Path_1013"
            data-name="Path 1013"
            d="M4374.724,1827.278s.3-3.984-4.709-9.07a30.413,30.413,0,0,0-9.393-6.174,2.92,2.92,0,0,0-3.884,1.639l-1.6,4.13,7.458,4.8Z"
            transform="translate(-4355.136 -1811.794)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_747"
          data-name="Group 747"
          transform="translate(0 133.524)"
        >
          <path
            id="Path_1014"
            data-name="Path 1014"
            d="M4373.37,1983.518s-61.638-33.4-83.73,3.035c-16.354,26.973,8.229,52.093,99.417,49.541,0,0,9.012-4.221,6.816-26.115C4395,2001.3,4373.37,1983.518,4373.37,1983.518Z"
            transform="translate(-4284.926 -1969.532)"
            fill="#007ec5"
          />
        </g>
        <g
          id="Group_748"
          data-name="Group 748"
          transform="translate(88.443 127.99)"
        >
          <path
            id="Path_1015"
            data-name="Path 1015"
            d="M4470.008,1977.471s82.093-33.628,98.336-12.605c18.668,24.16.766,65.182-85.768,50.518l-10.545-11.452Z"
            transform="translate(-4470.008 -1957.953)"
            fill="#007ec5"
          />
        </g>
        <g
          id="Group_749"
          data-name="Group 749"
          transform="translate(48.57 151.128)"
        >
          <path
            id="Path_1016"
            data-name="Path 1016"
            d="M4442.227,2055.469l-.2-.281c9.574-6.831,7-24.373,6.764-25.847l-62.222-22.646.119-.324,62.411,22.714.018.1C4449.147,2029.374,4452.35,2048.246,4442.227,2055.469Z"
            transform="translate(-4386.567 -2006.371)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_750"
          data-name="Group 750"
          transform="translate(109.235 179.993)"
        >
          <path
            id="Path_1017"
            data-name="Path 1017"
            d="M4515.754,2066.777s15.129.615,22.236,4.1,3.312,11.993-11.118,11.766-13.334-2.374-13.334-2.374S4516.278,2072.8,4515.754,2066.777Z"
            transform="translate(-4513.519 -2066.777)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_752"
          data-name="Group 752"
          transform="translate(28.167 60.927)"
        >
          <g id="Group_751" data-name="Group 751">
            <path
              id="Path_1018"
              data-name="Path 1018"
              d="M4407.807,1905.8a20.027,20.027,0,0,0,12.464-3.785c6.3-4.754,7.473-12.333,6.56-17.379l-6.031-3.506c-.3-1.655-11.937,4.494-11.917,4.488-.053.017-5.554,1.608-24.625-8.138-13.276-6.784-19.968-16.414-20.457-29.437-.368-9.818,4.008-15.333,5.041-17.722.138-.318-6.417-6.889-6.229-7.557l-4.913-2.5c.159-.563-4.724-2.489-4.655-2.65l-3.792,3.23a63.253,63.253,0,0,0-5.338,27.958c.534,14.224,6.407,33.854,31.355,46.6C4388.877,1902.356,4399.555,1905.8,4407.807,1905.8Z"
              transform="translate(-4343.871 -1817.611)"
              fill="#fdad9b"
            />
          </g>
        </g>
        <g
          id="Group_759"
          data-name="Group 759"
          transform="translate(76.84 111.594)"
        >
          <g
            id="Group_753"
            data-name="Group 753"
            transform="translate(16.722 0.166)"
          >
            <path
              id="Path_1019"
              data-name="Path 1019"
              d="M4483.311,1962.139H4536.5a2.584,2.584,0,0,0,2.454-1.758l11.055-33.013a2.571,2.571,0,0,0-2.455-3.378H4496.4a5.41,5.41,0,0,0-5.136,3.679l-10.411,31.092A2.571,2.571,0,0,0,4483.311,1962.139Z"
              transform="translate(-4480.722 -1923.989)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_754"
            data-name="Group 754"
            transform="translate(20.061 0.166)"
          >
            <path
              id="Path_1020"
              data-name="Path 1020"
              d="M4490.3,1962.139h53.194a2.585,2.585,0,0,0,2.454-1.758L4557,1927.368a2.571,2.571,0,0,0-2.454-3.378h-51.155a5.41,5.41,0,0,0-5.136,3.679l-10.411,31.092A2.57,2.57,0,0,0,4490.3,1962.139Z"
              transform="translate(-4487.709 -1923.989)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_755"
            data-name="Group 755"
            transform="translate(53.904 16.396)"
          >
            <path
              id="Path_1021"
              data-name="Path 1021"
              d="M4564.548,1959.717c0-1.253-1.447-1.764-2.709-1.764s-3.322,1.376-3.309,3.132c.014,1.9,1.495,2.141,2.757,2.141S4564.548,1961.313,4564.548,1959.717Z"
              transform="translate(-4558.53 -1957.953)"
              fill="#fecaff"
            />
          </g>
          <g
            id="Group_756"
            data-name="Group 756"
            transform="translate(0 33.65)"
          >
            <path
              id="Rectangle_182"
              data-name="Rectangle 182"
              d="M0,0H74.3a1.572,1.572,0,0,1,1.572,1.572v0a3.093,3.093,0,0,1-3.093,3.093H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
              transform="translate(75.871 4.665) rotate(180)"
              fill="#66192d"
            />
          </g>
          <g id="Group_757" data-name="Group 757" transform="translate(26.098)">
            <path
              id="Path_1022"
              data-name="Path 1022"
              d="M4500.674,1940.5l-.333-.089c.107-.4,2.646-9.927,4.357-13.005,1.736-3.123,3.86-3.738,3.949-3.762l.091.333c-.02.006-2.079.61-3.738,3.6C4503.311,1930.612,4500.7,1940.4,4500.674,1940.5Z"
              transform="translate(-4500.341 -1923.641)"
              fill="#553e93"
            />
          </g>
          <g
            id="Group_758"
            data-name="Group 758"
            transform="translate(18.57 20.397)"
          >
            <path
              id="Path_1023"
              data-name="Path 1023"
              d="M4484.587,1979.751v-.345c1.743,0,2.176-.722,2.18-.729l4.134-12.352.327.109-4.146,12.382C4487.051,1978.878,4486.56,1979.751,4484.587,1979.751Z"
              transform="translate(-4484.587 -1966.324)"
              fill="#553e93"
            />
          </g>
        </g>
        <g
          id="Group_760"
          data-name="Group 760"
          transform="translate(39.371 111.637)"
        >
          <path
            id="Path_1024"
            data-name="Path 1024"
            d="M4372.228,1932.583a2.253,2.253,0,0,1-.591-.082,6.174,6.174,0,0,1-3.284-2.992h0c-1.348-2.358-1.385-4.8-.083-5.557a1.87,1.87,0,0,1,1.449-.14A6.178,6.178,0,0,1,4373,1926.8c1.349,2.357,1.384,4.8.083,5.557A1.684,1.684,0,0,1,4372.228,1932.583Zm-3.276-3.417a5.582,5.582,0,0,0,2.864,2.668,1.18,1.18,0,0,0,.922-.07c.937-.546.783-2.661-.335-4.616a5.576,5.576,0,0,0-2.862-2.668,1.181,1.181,0,0,0-.922.07c-.937.546-.784,2.661.334,4.617Z"
            transform="translate(-4367.317 -1923.73)"
            fill="#e08b65"
          />
        </g>
        <g
          id="Group_761"
          data-name="Group 761"
          transform="translate(84.376 20.223)"
        >
          <path
            id="Path_1025"
            data-name="Path 1025"
            d="M4461.588,1757.235c3.5-.1,6.146-.462,8.156-2.827l6.222-7.319,1.811-2.13,10.649-12.527-5.592,14.648-3.357,8.792-1.063,2.784c-.722,1.889.231,2.769.9,4.979.273.9,2.108,6.405-2.673,6.44-5.957.043-13.491-6.88-15.146-12.858A.45.45,0,0,0,4461.588,1757.235Z"
            transform="translate(-4461.497 -1732.432)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_762"
          data-name="Group 762"
          transform="translate(90.052 7.224)"
        >
          <path
            id="Path_1026"
            data-name="Path 1026"
            d="M4480.437,1728.184s-11.726-11.269-4.96-20.781,8.453,15.266,8.453,15.266l-1.89,5.727Z"
            transform="translate(-4473.374 -1705.229)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_763"
          data-name="Group 763"
          transform="translate(98.677 32.721)"
        >
          <path
            id="Path_1027"
            data-name="Path 1027"
            d="M4491.651,1760.493c-.022.083-.042.167-.059.252-1.088,4.941,3.469,8.8,3.469,8.8l.04-.017,3.358-8.793c-1.427-1.068-3.528-2.361-5.057-2.121A2.182,2.182,0,0,0,4491.651,1760.493Z"
            transform="translate(-4491.424 -1758.586)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_764"
          data-name="Group 764"
          transform="translate(96.682 8.05)"
        >
          <path
            id="Path_1028"
            data-name="Path 1028"
            d="M4514.294,1727.415c2.644-8.806-1.076-17.741-8.31-19.955s-15.24,3.13-17.884,11.936,1.076,17.742,8.31,19.955S4511.649,1736.222,4514.294,1727.415Z"
            transform="translate(-4487.248 -1706.959)"
            fill="#fdad9b"
          />
        </g>
        <g id="Group_765" data-name="Group 765" transform="translate(92.154 0)">
          <path
            id="Path_1029"
            data-name="Path 1029"
            d="M4485.629,1709.327s3.734,2.716,6.864.421,5.343-4.471,12.352-.943,14.679,1.943,16.1-2.028-1.921-7.013-6.323-6.816.932-2.216-2.131-6.39-10.83.126-14.679-1.944-17.566-3.867-20.035,7.885Z"
            transform="translate(-4477.773 -1690.112)"
            fill="#66192d"
          />
        </g>
        <g
          id="Group_766"
          data-name="Group 766"
          transform="translate(96.548 17.183)"
        >
          <path
            id="Path_1030"
            data-name="Path 1030"
            d="M4487.5,1730.456a2.817,2.817,0,0,0,3.856,1.046,2.878,2.878,0,0,0,.6-3.987,2.818,2.818,0,0,0-3.857-1.045A2.878,2.878,0,0,0,4487.5,1730.456Z"
            transform="translate(-4486.968 -1726.07)"
            fill="#fdad9b"
          />
        </g>
        <g
          id="Group_767"
          data-name="Group 767"
          transform="translate(120.458 11.149)"
        >
          <path
            id="Path_1031"
            data-name="Path 1031"
            d="M4544.126,1717.583c-2.944,0-6.939-3.593-7.123-3.758l.345-.38c.047.043,4.708,4.239,7.405,3.552,1.036-.263,1.622-.753,1.528-1.28-.088-.5-.829-.978-1.866-.872a5.843,5.843,0,0,1-3.571-.574l.277-.432a5.454,5.454,0,0,0,3.242.5c1.4-.144,2.294.559,2.424,1.292.113.64-.329,1.466-1.908,1.867A3.083,3.083,0,0,1,4544.126,1717.583Z"
            transform="translate(-4537.004 -1713.444)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_768"
          data-name="Group 768"
          transform="translate(103.566 1.161)"
        >
          <path
            id="Path_1032"
            data-name="Path 1032"
            d="M4513.564,1696.83a8.022,8.022,0,0,1-2.935-.519,31.474,31.474,0,0,1-3.3-1.579c-1.987-1.051-3.707-1.958-5.572-1.595l-.1-.5c2.047-.4,3.836.548,5.911,1.644a31.026,31.026,0,0,0,3.25,1.555c3.564,1.4,7.733-.621,7.995-.84l.375.349A11.228,11.228,0,0,1,4513.564,1696.83Z"
            transform="translate(-4501.655 -1692.541)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_769"
          data-name="Group 769"
          transform="translate(95.179 5.868)"
        >
          <path
            id="Path_1033"
            data-name="Path 1033"
            d="M4484.634,1705.313a.6.6,0,0,1-.208-.035c-.172-.065-.437-.268-.27-.894l.038-.081c.287-.411,1.771-2.43,2.8-1.784.131.083.407.334.135.882A4.2,4.2,0,0,1,4484.634,1705.313Zm.007-.754c-.047.195-.018.254-.018.254a3.176,3.176,0,0,0,1.583-1.033c.5-.513.539-.782.523-.821C4486.273,1702.672,4485.228,1703.732,4484.641,1704.559Z"
            transform="translate(-4484.104 -1702.392)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_771"
          data-name="Group 771"
          transform="translate(109.59 23.932)"
        >
          <g id="Group_770" data-name="Group 770">
            <path
              id="Path_1034"
              data-name="Path 1034"
              d="M4517.929,1741.194a.276.276,0,0,0,.2-.142.282.282,0,0,0-.106-.381,2.98,2.98,0,0,0-3.664.343.283.283,0,0,0-.04.395.3.3,0,0,0,.39.04,4.7,4.7,0,0,1,3.042-.288A.278.278,0,0,0,4517.929,1741.194Z"
              transform="translate(-4514.261 -1740.195)"
              fill="#060633"
            />
          </g>
        </g>
        <g
          id="Group_772"
          data-name="Group 772"
          transform="translate(110.674 26.64)"
        >
          <path
            id="Path_1035"
            data-name="Path 1035"
            d="M4516.55,1746.925c-.1.66.2,1.257.67,1.333s.944-.4,1.049-1.056-.2-1.257-.669-1.334S4516.656,1746.265,4516.55,1746.925Z"
            transform="translate(-4516.53 -1745.861)"
            fill="#060633"
          />
        </g>
        <g
          id="Group_774"
          data-name="Group 774"
          transform="translate(120.318 26.859)"
        >
          <g id="Group_773" data-name="Group 773">
            <path
              id="Path_1036"
              data-name="Path 1036"
              d="M4539.342,1747.905a.2.2,0,0,0,.193-.064.34.34,0,0,0,.057-.39,1.94,1.94,0,0,0-2.71-.881.333.333,0,0,0-.164.354.2.2,0,0,0,.261.165,2.89,2.89,0,0,1,2.251.73A.181.181,0,0,0,4539.342,1747.905Z"
              transform="translate(-4536.712 -1746.32)"
              fill="#060633"
            />
          </g>
        </g>
        <g
          id="Group_775"
          data-name="Group 775"
          transform="translate(119.242 29.337)"
        >
          <path
            id="Path_1037"
            data-name="Path 1037"
            d="M4534.688,1752.176a1.094,1.094,0,0,0,.117,1.491c.41.253,1.022,0,1.367-.574a1.091,1.091,0,0,0-.115-1.49C4535.647,1751.349,4535.035,1751.606,4534.688,1752.176Z"
            transform="translate(-4534.46 -1751.505)"
            fill="#060633"
          />
        </g>
        <g
          id="Group_778"
          data-name="Group 778"
          transform="translate(105.262 32.943)"
        >
          <g
            id="Group_776"
            data-name="Group 776"
            transform="translate(0.565 0.658)"
          >
            <path
              id="Path_1038"
              data-name="Path 1038"
              d="M4512.094,1763.853a6.221,6.221,0,0,1-5.682-3.133.195.195,0,0,1,.339-.192c2.225,3.93,6.879,2.787,6.926,2.775a.195.195,0,0,1,.1.377A7.623,7.623,0,0,1,4512.094,1763.853Z"
              transform="translate(-4506.388 -1760.429)"
              fill="#020202"
            />
          </g>
          <g id="Group_777" data-name="Group 777">
            <path
              id="Path_1039"
              data-name="Path 1039"
              d="M4505.4,1760.686a.207.207,0,0,1-.061-.01.194.194,0,0,1-.124-.246,2.6,2.6,0,0,1,1.277-1.328l.035-.021a.195.195,0,0,1,.215.325l-.056.033a2.2,2.2,0,0,0-1.1,1.113A.2.2,0,0,1,4505.4,1760.686Z"
              transform="translate(-4505.205 -1759.051)"
              fill="#020202"
            />
          </g>
        </g>
        <g
          id="Group_779"
          data-name="Group 779"
          transform="translate(126.637 75.884)"
        >
          <rect
            id="Rectangle_183"
            data-name="Rectangle 183"
            width="0.853"
            height="10.048"
            transform="translate(0 0.267) rotate(-18.209)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_780"
          data-name="Group 780"
          transform="translate(116.71 21.682)"
        >
          <path
            id="Path_1040"
            data-name="Path 1040"
            d="M4533.482,1753.25a3.661,3.661,0,0,1-2.636-1.1c-2.34-2.319-2.23-7.69.245-11.976,1.524-2.638,3.663-4.377,5.719-4.652a3.689,3.689,0,0,1,3.2,1.06c2.34,2.318,2.23,7.691-.246,11.976-1.524,2.637-3.662,4.376-5.718,4.652A4.225,4.225,0,0,1,4533.482,1753.25Zm3.885-17.186a3.638,3.638,0,0,0-.48.033c-1.881.252-3.859,1.885-5.294,4.369-2.353,4.072-2.505,9.13-.34,11.275a3.135,3.135,0,0,0,2.714.9c1.88-.252,3.859-1.885,5.294-4.368,2.353-4.072,2.5-9.13.34-11.275A3.1,3.1,0,0,0,4537.368,1736.064Z"
            transform="translate(-4529.161 -1735.485)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_781"
          data-name="Group 781"
          transform="translate(103.499 15.307)"
          opacity="0.3"
        >
          <path
            id="Path_1041"
            data-name="Path 1041"
            d="M4502.72,1726.913c-2,4.262-1.45,9.448,1.238,11.583s6.492.41,8.5-3.853,1.45-9.448-1.238-11.583S4504.724,1722.651,4502.72,1726.913Z"
            transform="translate(-4501.515 -1722.145)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_782"
          data-name="Group 782"
          transform="translate(117.095 21.907)"
          opacity="0.3"
        >
          <path
            id="Path_1042"
            data-name="Path 1042"
            d="M4531.314,1740.764c-2.012,4.282-1.741,9.478.606,11.605s5.881.381,7.893-3.9,1.739-9.478-.608-11.606S4533.326,1736.481,4531.314,1740.764Z"
            transform="translate(-4529.966 -1735.956)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_783"
          data-name="Group 783"
          transform="translate(103.296 14.2)"
        >
          <path
            id="Path_1043"
            data-name="Path 1043"
            d="M4506.745,1738.432a4.676,4.676,0,0,1-2.92-1.033c-2.969-2.318-3.624-7.905-1.459-12.454,1.263-2.654,3.222-4.467,5.377-4.976a4.688,4.688,0,0,1,4.089.893c2.969,2.318,3.623,7.905,1.459,12.454-1.262,2.654-3.222,4.467-5.377,4.976A5.08,5.08,0,0,1,4506.745,1738.432Zm2.16-18.023a4.438,4.438,0,0,0-1.029.123,8.108,8.108,0,0,0-4.987,4.661c-2.055,4.318-1.475,9.589,1.292,11.75a4.129,4.129,0,0,0,3.6.786,8.108,8.108,0,0,0,4.988-4.661c2.054-4.319,1.475-9.589-1.292-11.75A4.118,4.118,0,0,0,4508.9,1720.409Z"
            transform="translate(-4501.089 -1719.829)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_784"
          data-name="Group 784"
          transform="translate(114.22 25.734)"
        >
          <path
            id="Path_1044"
            data-name="Path 1044"
            d="M4528.516,1746.77a.536.536,0,0,1-.427-.21,4.78,4.78,0,0,0-3.612-1.514.541.541,0,0,1-.526-.554.552.552,0,0,1,.553-.527,5.84,5.84,0,0,1,4.441,1.936.54.54,0,0,1-.429.868Z"
            transform="translate(-4523.95 -1743.965)"
            fill="#fff"
          />
        </g>
      </g>
      <g
        id="Group_837"
        data-name="Group 837"
        transform="translate(105.08 173.722)"
      >
        <g id="Group_836" data-name="Group 836" transform="translate(0)">
          <g
            id="Group_786"
            data-name="Group 786"
            transform="translate(65.883 77.966)"
          >
            <path
              id="Path_1045"
              data-name="Path 1045"
              d="M4070.593,1591.79s-21.976,28.4-20.482,50.348,27.523,4.406,27.523,4.406l2.242-51.98Z"
              transform="translate(-4050.038 -1591.79)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_787"
            data-name="Group 787"
            transform="translate(39.262 273.857)"
          >
            <path
              id="Path_1046"
              data-name="Path 1046"
              d="M4011.395,2002.375s.19,6.453-4.071,8.508-16.806,4.385-11.858,8.27c2.838,2.229,15.52,0,19.125-.2s7.074,1.189,9.621-1.369c2.8-2.815-.865-15.862-.865-15.862a14.416,14.416,0,0,1-5.823,1.869A11.493,11.493,0,0,1,4011.395,2002.375Z"
              transform="translate(-3994.328 -2001.723)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_788"
            data-name="Group 788"
            transform="translate(0 273.815)"
          >
            <path
              id="Path_1047"
              data-name="Path 1047"
              d="M3929.232,2002.289s.19,6.452-4.071,8.508-16.805,4.386-11.858,8.271c2.838,2.229,15.521,0,19.125-.2s7.074,1.189,9.621-1.37c2.8-2.814-.866-15.862-.866-15.862a14.423,14.423,0,0,1-5.823,1.87A11.5,11.5,0,0,1,3929.232,2002.289Z"
              transform="translate(-3912.167 -2001.636)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_790"
            data-name="Group 790"
            transform="translate(12.339 139.222)"
          >
            <g id="Group_789" data-name="Group 789">
              <path
                id="Path_1048"
                data-name="Path 1048"
                d="M3938.041,1844.564c3.191-42.274,3.943-70.8,17.32-89.123,14.641-20.057,53.892-34.242,69.027-35.127,24.682-1.443,36.312,2.231,36.312,2.231,18.526,38.525-10.493,43.637-31.958,44.69q-1.064.052-2.136.119c-13,.819-36.1,4.12-44.848,17.033-6.925,10.225-11.731,24.581-13.758,61.61a13.13,13.13,0,0,1-13.463,12.422S3936.893,1859.768,3938.041,1844.564Z"
                transform="translate(-3937.987 -1719.978)"
                fill="#007ec5"
              />
            </g>
          </g>
          <g
            id="Group_792"
            data-name="Group 792"
            transform="translate(47.172 143.986)"
          >
            <g id="Group_791" data-name="Group 791">
              <path
                id="Path_1049"
                data-name="Path 1049"
                d="M4011.133,1851.946c-.42-42.392-1.736-64.033,10.987-82.819,8.174-12.069,20.467-21.179,36.537-27.078,11.469-4.209,24.958-6.793,40.093-7.678,24.682-1.443,35.772-4.424,35.772-4.424,19.975,49.316-53.093,38.846-72.114,59.857-8.289,9.156-18.559,26.567-20.586,63.6a13.129,13.129,0,0,1-13.462,12.422S4011.287,1867.447,4011.133,1851.946Z"
                transform="translate(-4010.882 -1729.947)"
                fill="#007ec5"
              />
            </g>
          </g>
          <g
            id="Group_793"
            data-name="Group 793"
            transform="translate(47.262 162.298)"
          >
            <path
              id="Path_1050"
              data-name="Path 1050"
              d="M4011.352,1830.319l-.283-.013c.013-.254,1.269-25.458,5.508-34.293,5.362-11.18,20.911-22.848,36.971-27.744l.083.271c-15.991,4.876-31.467,16.482-36.8,27.6C4012.618,1804.919,4011.365,1830.066,4011.352,1830.319Z"
              transform="translate(-4011.069 -1768.268)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_794"
            data-name="Group 794"
            transform="translate(82.798 52.196)"
          >
            <path
              id="Path_1051"
              data-name="Path 1051"
              d="M4155.941,1551.353s-10.5-17.948-34.705-12.44-34.068,30.23-35.8,89.6c0,0,22.409-.823,81.285,18.705,6.974,2.313,17.2.479,13.622-11.4C4173.686,1613.733,4167.24,1569.685,4155.941,1551.353Z"
              transform="translate(-4085.435 -1537.862)"
              fill="#ff443c"
            />
          </g>
          <g
            id="Group_795"
            data-name="Group 795"
            transform="translate(82.077 51.426)"
          >
            <path
              id="Path_1052"
              data-name="Path 1052"
              d="M4181.78,1555.754l-24.9,4.68s-43.537,12.876-55.725,12.978-17.232-8.813-17.232-8.813a64.479,64.479,0,0,1,30.432-24.558,59.936,59.936,0,0,1,6.089-1.967,44.775,44.775,0,0,1,10.826-1.813C4172.371,1535.788,4181.78,1555.754,4181.78,1555.754Z"
              transform="translate(-4083.926 -1536.252)"
              fill="#ff443c"
            />
          </g>
          <g
            id="Group_796"
            data-name="Group 796"
            transform="translate(154.629 69.514)"
          >
            <path
              id="Path_1053"
              data-name="Path 1053"
              d="M4261.054,1575.518c.539,1.962-4.68,5.238-11.656,7.315s-13.069,2.17-13.607.207,4.68-5.237,11.657-7.315S4260.516,1573.555,4261.054,1575.518Z"
              transform="translate(-4235.752 -1574.104)"
              fill="#c40000"
            />
          </g>
          <g
            id="Group_797"
            data-name="Group 797"
            transform="translate(108.62 27.48)"
          >
            <path
              id="Path_1054"
              data-name="Path 1054"
              d="M4160.232,1510.1a7.378,7.378,0,0,1-6.729-3.2l-4.683-6.927-1.358-2.01-7.991-11.821,3.5,13.16,2.1,7.9.6,2.252a3.708,3.708,0,0,1-2.276,4.413l-.031.012c-.682,1.792-1.424,5.311,2.893,5.833,5.072.613,12.094-4.622,14.022-9.613Z"
              transform="translate(-4139.47 -1486.14)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_798"
            data-name="Group 798"
            transform="translate(117.035 22.417)"
          >
            <path
              id="Path_1055"
              data-name="Path 1055"
              d="M4163.329,1475.545s-.1,2.288,2.012,6.537c3.09,6.216-3.894,10.383-6.17,8.875-.281-.187-2.09-11.416-2.09-11.416l2.128-1.881Z"
              transform="translate(-4157.081 -1475.545)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_799"
            data-name="Group 799"
            transform="translate(112.117 39.222)"
          >
            <path
              id="Path_1056"
              data-name="Path 1056"
              d="M4152.612,1512.579c.012.075.021.149.028.224.5,4.361-3.719,7.246-3.719,7.246l-.032-.019-2.1-7.9c1.309-.782,3.212-1.693,4.494-1.339A1.911,1.911,0,0,1,4152.612,1512.579Z"
              transform="translate(-4146.788 -1510.713)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_800"
            data-name="Group 800"
            transform="translate(95.54 14.078)"
          >
            <path
              id="Path_1057"
              data-name="Path 1057"
              d="M4112.443,1476.128c-1.625-8.541,2.672-16.533,9.6-17.849s13.852,4.542,15.476,13.083-2.673,16.533-9.6,17.85S4114.067,1484.67,4112.443,1476.128Z"
              transform="translate(-4112.1 -1458.094)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_801"
            data-name="Group 801"
            transform="translate(93.305 11.08)"
          >
            <path
              id="Path_1058"
              data-name="Path 1058"
              d="M4115.315,1459.542c.438.409-.022,8.741,15.08,7.838s2.847-17.1-8.107-15.439c-15.815,2.4-16.094,13.4-13.862,16.908S4115.315,1459.542,4115.315,1459.542Z"
              transform="translate(-4107.422 -1451.822)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_802"
            data-name="Group 802"
            transform="translate(119.519)"
          >
            <path
              id="Path_1059"
              data-name="Path 1059"
              d="M4164.816,1433.335s-5.779,5.688.107,8.889,20.238,2.362,17.755,10.124-3.811,18.812,3.312,18.808,11.354-1.348,15.2,3.591,15.676-.821,13.419-7.141,7.349-14.086,1.626-19.207-17.919-8.2-22.206-15.486S4169.521,1428.932,4164.816,1433.335Z"
              transform="translate(-4162.279 -1428.634)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_803"
            data-name="Group 803"
            transform="translate(114.17 24.179)"
          >
            <path
              id="Path_1060"
              data-name="Path 1060"
              d="M4151.085,1479.468s7.231,5.206,6.376,12.094c0,0,2.768-4.482,2.574-5.921a55.939,55.939,0,0,0-2.1-6.409Z"
              transform="translate(-4151.085 -1479.232)"
              fill="#66192d"
            />
          </g>
          <g
            id="Group_804"
            data-name="Group 804"
            transform="translate(114.355 24.761)"
          >
            <path
              id="Path_1061"
              data-name="Path 1061"
              d="M4156.84,1485.686c-1.162,1.625-3.121,2.142-4.376,1.153s-1.329-3.108-.167-4.734,3.121-2.143,4.376-1.154S4158,1484.061,4156.84,1485.686Z"
              transform="translate(-4151.472 -1480.45)"
              fill="#fdad9b"
            />
          </g>
          <g
            id="Group_805"
            data-name="Group 805"
            transform="translate(123.353 3.499)"
          >
            <path
              id="Path_1062"
              data-name="Path 1062"
              d="M4205.122,1478.637c-1.855-6.978-5.969-7.293-10.323-7.626a22.6,22.6,0,0,1-5.215-.814,2.68,2.68,0,0,1-1.687-1.339c-.772-1.572.124-4.05,1.161-6.919,1.512-4.179,3.225-8.916.668-12.593a17.225,17.225,0,0,0-12.52-7.434c-4.363.276-6.62-.214-6.88-1.5s1.78-2.735,2.532-3c6.1-2.134,13.16-3,23.384,5.084,8.064,6.372,7.511,9.959,6.809,14.5-.155,1-.315,2.038-.389,3.164a2.543,2.543,0,0,0,.571,2.018c.985,1.04,3.182,1.014,5.31.989,2.75-.032,5.592-.066,6.357,2.119.6,1.709-.083,3.741-.741,5.706-.675,2.013-1.313,3.915-.528,5.245.512.869,1.606,1.447,3.343,1.764l-.1.516c-1.895-.346-3.1-1-3.7-2.013-.908-1.536-.232-3.548.481-5.679.631-1.881,1.284-3.826.745-5.366-.642-1.83-3.173-1.8-5.858-1.768-2.238.027-4.553.053-5.7-1.153a3.076,3.076,0,0,1-.712-2.413c.075-1.149.236-2.2.393-3.21.675-4.372,1.208-7.824-6.615-14.008-10.025-7.924-16.925-7.086-22.887-5-.724.253-2.366,1.535-2.191,2.4.089.439.886,1.42,6.358,1.077a17.786,17.786,0,0,1,12.959,7.658c2.711,3.9.872,8.985-.606,13.071-1,2.757-1.858,5.137-1.185,6.509a2.153,2.153,0,0,0,1.377,1.071,22.222,22.222,0,0,0,5.1.791c4.545.347,8.838.676,10.789,8.014Z"
              transform="translate(-4170.302 -1435.956)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_806"
            data-name="Group 806"
            transform="translate(106.032 18.425)"
          >
            <path
              id="Path_1063"
              data-name="Path 1063"
              d="M4141.782,1470.778a11.331,11.331,0,0,1-7.728-3.263l.352-.323a10.643,10.643,0,0,0,9.021,2.968l.079.472A10.4,10.4,0,0,1,4141.782,1470.778Z"
              transform="translate(-4134.054 -1467.192)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_808"
            data-name="Group 808"
            transform="translate(93.28 29.313)"
          >
            <g id="Group_807" data-name="Group 807">
              <path
                id="Path_1064"
                data-name="Path 1064"
                d="M4114.149,1502.093a4.86,4.86,0,0,1-2.875-.36,6.661,6.661,0,0,1-3.425-3.751c-1.221-3.193-.03-6.648,2.655-7.7a4.741,4.741,0,0,1,3.663.133,6.663,6.663,0,0,1,3.426,3.751c1.221,3.193.03,6.647-2.657,7.7A4.4,4.4,0,0,1,4114.149,1502.093Zm-2.8-11.735a4.093,4.093,0,0,0-.733.211c-2.53.991-3.642,4.267-2.481,7.3a6.353,6.353,0,0,0,3.264,3.58,4.43,4.43,0,0,0,3.427.128c2.53-.991,3.643-4.267,2.481-7.3a6.355,6.355,0,0,0-3.264-3.58A4.543,4.543,0,0,0,4111.349,1490.358Z"
                transform="translate(-4107.369 -1489.976)"
                fill="#786ab5"
              />
            </g>
          </g>
          <g
            id="Group_810"
            data-name="Group 810"
            transform="translate(105.086 25.3)"
          >
            <g id="Group_809" data-name="Group 809">
              <path
                id="Path_1065"
                data-name="Path 1065"
                d="M4138.857,1493.694a4.854,4.854,0,0,1-2.875-.359,6.659,6.659,0,0,1-3.426-3.751c-1.222-3.193-.03-6.647,2.655-7.7a4.74,4.74,0,0,1,3.663.133,6.659,6.659,0,0,1,3.425,3.751h0c1.222,3.193.03,6.646-2.655,7.7A4.493,4.493,0,0,1,4138.857,1493.694Zm-2.8-11.735a4.108,4.108,0,0,0-.732.211c-2.53.992-3.642,4.268-2.481,7.3a6.358,6.358,0,0,0,3.263,3.58,4.437,4.437,0,0,0,3.427.128c2.529-.991,3.642-4.267,2.481-7.3h0a6.349,6.349,0,0,0-3.263-3.58A4.554,4.554,0,0,0,4136.056,1481.959Z"
                transform="translate(-4132.076 -1481.578)"
                fill="#786ab5"
              />
            </g>
          </g>
          <g
            id="Group_812"
            data-name="Group 812"
            transform="translate(101.945 32.496)"
          >
            <g id="Group_811" data-name="Group 811">
              <path
                id="Path_1066"
                data-name="Path 1066"
                d="M4125.685,1498.5a.151.151,0,0,1-.135-.04.153.153,0,0,1,0-.216,6.518,6.518,0,0,1,4.36-1.607.154.154,0,0,1,.013.307,6.338,6.338,0,0,0-4.152,1.512A.147.147,0,0,1,4125.685,1498.5Z"
                transform="translate(-4125.503 -1496.637)"
                fill="#786ab5"
              />
            </g>
          </g>
          <g
            id="Group_813"
            data-name="Group 813"
            transform="translate(103.4 38.019)"
          >
            <path
              id="Path_1067"
              data-name="Path 1067"
              d="M4128.547,1511.229a27.7,27.7,0,0,0,8.3-3.034,4.249,4.249,0,0,1-3.426,5.318C4130.752,1514.19,4128.547,1511.229,4128.547,1511.229Z"
              transform="translate(-4128.547 -1508.195)"
              fill="#db6b49"
            />
          </g>
          <g
            id="Group_814"
            data-name="Group 814"
            transform="translate(103.4 38.019)"
          >
            <path
              id="Path_1068"
              data-name="Path 1068"
              d="M4128.547,1511.229a5.7,5.7,0,0,0,3.615.193c2.416-.47,4.682-3.227,4.682-3.227A41.672,41.672,0,0,1,4128.547,1511.229Z"
              transform="translate(-4128.547 -1508.195)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_816"
            data-name="Group 816"
            transform="translate(106.967 28.974)"
          >
            <g id="Group_815" data-name="Group 815">
              <path
                id="Path_1069"
                data-name="Path 1069"
                d="M4136.28,1490.4a.291.291,0,0,1-.135-.534,3.144,3.144,0,0,1,3.848.121.293.293,0,0,1,.067.405.32.32,0,0,1-.405.067,4.97,4.97,0,0,0-3.2-.1A.293.293,0,0,1,4136.28,1490.4Z"
                transform="translate(-4136.012 -1489.266)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_817"
            data-name="Group 817"
            transform="translate(108.269 31.772)"
          >
            <path
              id="Path_1070"
              data-name="Path 1070"
              d="M4140.555,1496.163a1.124,1.124,0,0,1-.615,1.422c-.49.11-1.011-.35-1.161-1.026a1.123,1.123,0,0,1,.615-1.422C4139.884,1495.028,4140.4,1495.487,4140.555,1496.163Z"
              transform="translate(-4138.737 -1495.121)"
              fill="#060633"
            />
          </g>
          <g
            id="Group_819"
            data-name="Group 819"
            transform="translate(96.96 31.606)"
          >
            <g id="Group_818" data-name="Group 818">
              <path
                id="Path_1071"
                data-name="Path 1071"
                d="M4115.45,1496.7a.291.291,0,0,1-.328-.443,3.144,3.144,0,0,1,3.6-1.354.292.292,0,0,1,.215.349.319.319,0,0,1-.349.216,4.971,4.971,0,0,0-2.994,1.121A.292.292,0,0,1,4115.45,1496.7Z"
                transform="translate(-4115.07 -1494.774)"
                fill="#060633"
              />
            </g>
          </g>
          <g
            id="Group_820"
            data-name="Group 820"
            transform="translate(99.225 34.313)"
          >
            <path
              id="Path_1072"
              data-name="Path 1072"
              d="M4121.592,1501.072a.957.957,0,1,1-1.465-.506A1.124,1.124,0,0,1,4121.592,1501.072Z"
              transform="translate(-4119.811 -1500.44)"
              fill="#060633"
            />
          </g>
          <g
            id="Group_822"
            data-name="Group 822"
            transform="translate(127.044 71.872)"
          >
            <g id="Group_821" data-name="Group 821">
              <path
                id="Path_1073"
                data-name="Path 1073"
                d="M4222.553,1630.72c-8.961,12.944-20.05,20.058-43.624,24.355l-.9-15.094c28.736-10.541,31.906-44.827,30.435-53.489a2.237,2.237,0,0,1,.846-2.155c2.194-1.682,7.881-5.1,18.041-5.3a1.967,1.967,0,0,1,1.937,1.462C4233.537,1596.51,4233.125,1615.449,4222.553,1630.72Z"
                transform="translate(-4178.027 -1579.037)"
                fill="#fdad9b"
              />
            </g>
          </g>
          <g
            id="Group_823"
            data-name="Group 823"
            transform="translate(159.337 118.563)"
            opacity="0.3"
          >
            <path
              id="Path_1074"
              data-name="Path 1074"
              d="M4248.692,1686.285a2.846,2.846,0,0,1-1.123-.228c-1.862-.795-2.516-3.479-1.459-5.983a5.651,5.651,0,0,1,2.9-3.067,3.078,3.078,0,0,1,2.4-.033c1.862.8,2.517,3.479,1.459,5.983h0a5.65,5.65,0,0,1-2.9,3.067A3.415,3.415,0,0,1,4248.692,1686.285Zm1.594-8.721a2.588,2.588,0,0,0-.971.2,4.818,4.818,0,0,0-2.451,2.627c-.883,2.09-.422,4.294,1.026,4.912a2.279,2.279,0,0,0,1.773-.039,4.822,4.822,0,0,0,2.452-2.628h0c.882-2.09.421-4.294-1.028-4.912A2.024,2.024,0,0,0,4250.286,1677.564Z"
              transform="translate(-4245.604 -1676.745)"
              fill="#e08b65"
            />
          </g>
          <g
            id="Group_828"
            data-name="Group 828"
            transform="translate(104.991 132.72)"
          >
            <g id="Group_824" data-name="Group 824">
              <path
                id="Path_1075"
                data-name="Path 1075"
                d="M4153.93,1706.467s-13.99-.8-17.6,2.2-5.958,9.563-3.324,8.179a30.946,30.946,0,0,0,5.511-4.082s-5.876,6.054-3.865,6.815,7.071-4.554,7.071-4.554-6.418,7.761-4.285,7.865,6.966-5.827,6.966-5.827-5.683,7.46-3.2,7.523,4.656-6.2,6.667-5.434,7.769,5.147,10.566-.184Z"
                transform="translate(-4131.877 -1706.371)"
                fill="#fdad9b"
              />
            </g>
            <g
              id="Group_825"
              data-name="Group 825"
              transform="translate(6.497 4.288)"
            >
              <path
                id="Path_1076"
                data-name="Path 1076"
                d="M4145.754,1717.554l-.282-.2c.061-.084,1.476-2.039,3.029-2.008l-.009.346h-.037C4147.106,1715.691,4145.768,1717.535,4145.754,1717.554Z"
                transform="translate(-4145.472 -1715.344)"
                fill="#dd8964"
              />
            </g>
            <g
              id="Group_826"
              data-name="Group 826"
              transform="translate(9.701 6.177)"
            >
              <path
                id="Path_1077"
                data-name="Path 1077"
                d="M4152.463,1721.877l-.286-.2a6.1,6.1,0,0,1,3.555-2.383l.06.341A5.839,5.839,0,0,0,4152.463,1721.877Z"
                transform="translate(-4152.177 -1719.298)"
                fill="#dd8964"
              />
            </g>
            <g
              id="Group_827"
              data-name="Group 827"
              transform="translate(12.383 8.344)"
            >
              <path
                id="Path_1078"
                data-name="Path 1078"
                d="M4158.073,1726.285l-.283-.2c.071-.1,1.751-2.429,3.393-2.241l-.037.345C4159.7,1724.032,4158.089,1726.262,4158.073,1726.285Z"
                transform="translate(-4157.79 -1723.831)"
                fill="#dd8964"
              />
            </g>
          </g>
          <g
            id="Group_835"
            data-name="Group 835"
            transform="translate(50.745 120.906)"
          >
            <g
              id="Group_829"
              data-name="Group 829"
              transform="translate(2.913 0.146)"
            >
              <path
                id="Path_1079"
                data-name="Path 1079"
                d="M4082.767,1715.239h-46.41a2.254,2.254,0,0,1-2.14-1.534l-9.645-28.8a2.243,2.243,0,0,1,2.14-2.948h44.631a4.72,4.72,0,0,1,4.481,3.21l9.083,27.126A2.243,2.243,0,0,1,4082.767,1715.239Z"
                transform="translate(-4024.455 -1681.955)"
                fill="#66192d"
              />
            </g>
            <g
              id="Group_830"
              data-name="Group 830"
              transform="translate(0 0.146)"
            >
              <path
                id="Path_1080"
                data-name="Path 1080"
                d="M4076.67,1715.239h-46.409a2.255,2.255,0,0,1-2.141-1.534l-9.644-28.8a2.243,2.243,0,0,1,2.141-2.948h44.631a4.72,4.72,0,0,1,4.481,3.21l9.083,27.126A2.243,2.243,0,0,1,4076.67,1715.239Z"
                transform="translate(-4018.359 -1681.955)"
                fill="#66192d"
              />
            </g>
            <g
              id="Group_831"
              data-name="Group 831"
              transform="translate(25.794 14.305)"
            >
              <path
                id="Path_1081"
                data-name="Path 1081"
                d="M4072.336,1713.126c0-1.093,1.262-1.54,2.363-1.54s2.9,1.2,2.888,2.732c-.013,1.654-1.3,1.869-2.406,1.869A3.642,3.642,0,0,1,4072.336,1713.126Z"
                transform="translate(-4072.336 -1711.586)"
                fill="#fecaff"
              />
            </g>
            <g
              id="Group_832"
              data-name="Group 832"
              transform="translate(11.879 29.359)"
            >
              <path
                id="Rectangle_184"
                data-name="Rectangle 184"
                d="M0,0H63.495a2.7,2.7,0,0,1,2.7,2.7v0A1.372,1.372,0,0,1,64.822,4.07H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                fill="#66192d"
              />
            </g>
            <g
              id="Group_833"
              data-name="Group 833"
              transform="translate(47.976)"
            >
              <path
                id="Path_1082"
                data-name="Path 1082"
                d="M4125.793,1696.357c-.023-.086-2.3-8.625-3.774-11.278a6.255,6.255,0,0,0-3.262-3.138l.081-.291c.078.021,1.931.558,3.444,3.282,1.493,2.686,3.709,11,3.8,11.348Z"
                transform="translate(-4118.757 -1681.65)"
                fill="#553e93"
              />
            </g>
            <g
              id="Group_834"
              data-name="Group 834"
              transform="translate(56.077 17.795)"
            >
              <path
                id="Path_1083"
                data-name="Path 1083"
                d="M4141.5,1730.6c-1.721,0-2.149-.761-2.167-.794l-3.627-10.825.286-.1,3.618,10.8h0s.392.611,1.89.611Z"
                transform="translate(-4135.71 -1718.889)"
                fill="#553e93"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Group_844" data-name="Group 844" transform="translate(244.027)">
        <g
          id="Group_838"
          data-name="Group 838"
          transform="translate(44.462 28.409)"
        >
          <path
            id="Path_1084"
            data-name="Path 1084"
            d="M4296.439,1153.077s-1.855,16.34,1.883,16.2,11.32-7.133,26.156-5.522,32.932,5.4,44.5-13.738-13.329-25.477-13.329-25.477l-59.213,28.535Z"
            transform="translate(-4295.979 -1124.542)"
            fill="#553e93"
          />
        </g>
        <g id="Group_839" data-name="Group 839" transform="translate(0)">
          <path
            id="Path_1085"
            data-name="Path 1085"
            d="M4279.5,1121.767l-75.108,3.956-1.459-3.379,73.394-50.91,72.811-6.341,1.307,3.024-70.945,53.651Z"
            transform="translate(-4202.935 -1065.092)"
            fill="#786ab5"
          />
        </g>
        <g
          id="Group_842"
          data-name="Group 842"
          transform="translate(26.883 113.471)"
        >
          <g id="Group_840" data-name="Group 840" transform="translate(0.728)">
            <path
              id="Path_1086"
              data-name="Path 1086"
              d="M4260.809,1304.573a2.591,2.591,0,0,1,3.216-1.922,2.8,2.8,0,0,1,1.828,3.411,2.591,2.591,0,0,1-3.214,1.922,2.805,2.805,0,0,1-1.83-3.411Z"
              transform="translate(-4260.715 -1302.548)"
              fill="#ff8548"
            />
          </g>
          <g
            id="Group_841"
            data-name="Group 841"
            transform="translate(0 2.776)"
          >
            <path
              id="Path_1087"
              data-name="Path 1087"
              d="M4262.271,1308.36c-2.708-.2-3.078,25.858-3.078,25.858a5.744,5.744,0,0,0,2.638.653c.865-.212,1.273-3.734,1.273-3.734l.121,3.689s2.968.089,3.99-.683c0,0-.659-25.459-4.943-25.783Z"
              transform="translate(-4259.193 -1308.358)"
              fill="#ff8548"
            />
          </g>
        </g>
        <g
          id="Group_843"
          data-name="Group 843"
          transform="translate(28.502 25.884)"
        >
          <path
            id="Path_1088"
            data-name="Path 1088"
            d="M4263.068,1207.71l-.4-53.159a13.2,13.2,0,0,1,5.58-12.334l32.616-22.675a1.577,1.577,0,0,1,2.2.4h0a.787.787,0,0,1-.2,1.1l-34.62,24.066a8.809,8.809,0,0,0-3.722,8.239l.454,54.993Z"
            transform="translate(-4262.58 -1119.259)"
            fill="#ff8548"
          />
        </g>
      </g>
      <g
        id="Group_845"
        data-name="Group 845"
        transform="translate(315.344 161.642)"
        opacity="0.5"
      >
        <path
          id="Rectangle_185"
          data-name="Rectangle 185"
          d="M12.193,0H27.568A12.193,12.193,0,0,1,39.761,12.193V38.025A12.194,12.194,0,0,1,27.568,50.219H12.194A12.194,12.194,0,0,1,0,38.025V12.193A12.193,12.193,0,0,1,12.193,0Z"
          transform="translate(0 0)"
          fill="#ff443c"
        />
      </g>
      <g
        id="Group_846"
        data-name="Group 846"
        transform="translate(321.708 176.161)"
      >
        <path
          id="Path_1089"
          data-name="Path 1089"
          d="M4391.39,1436.237h-24.443a1.671,1.671,0,0,1-1.438-1.094c-.146-1.094.9-1.406,1.436-1.406l24.446.544a.981.981,0,0,1,.979.978h0A.981.981,0,0,1,4391.39,1436.237Z"
          transform="translate(-4365.495 -1433.737)"
          fill="#fecaff"
        />
      </g>
      <g
        id="Group_847"
        data-name="Group 847"
        transform="translate(321.708 172.06)"
      >
        <path
          id="Path_1090"
          data-name="Path 1090"
          d="M4385.041,1427.163h-18.45a1.275,1.275,0,0,1-1.085-.879,1.007,1.007,0,0,1,1.083-1.13l18.452.437a.765.765,0,0,1,.738.786h0A.765.765,0,0,1,4385.041,1427.163Z"
          transform="translate(-4365.496 -1425.154)"
          fill="#fecaff"
        />
      </g>
      <g
        id="Group_848"
        data-name="Group 848"
        transform="translate(321.708 181.279)"
      >
        <path
          id="Path_1091"
          data-name="Path 1091"
          d="M4385.041,1446.457h-18.45a1.275,1.275,0,0,1-1.085-.879,1.007,1.007,0,0,1,1.083-1.13l18.452.437a.765.765,0,0,1,.738.786h0A.765.765,0,0,1,4385.041,1446.457Z"
          transform="translate(-4365.496 -1444.448)"
          fill="#fecaff"
        />
      </g>
      <g
        id="Group_849"
        data-name="Group 849"
        transform="translate(321.708 194.601)"
      >
        <path
          id="Path_1092"
          data-name="Path 1092"
          d="M4391.39,1474.825h-24.443a1.669,1.669,0,0,1-1.438-1.094c-.146-1.093.9-1.406,1.436-1.406l24.446.544a.981.981,0,0,1,.979.978h0A.981.981,0,0,1,4391.39,1474.825Z"
          transform="translate(-4365.495 -1472.325)"
          fill="#fecaff"
        />
      </g>
      <g
        id="Group_850"
        data-name="Group 850"
        transform="translate(321.708 190.499)"
      >
        <path
          id="Path_1093"
          data-name="Path 1093"
          d="M4385.041,1465.75h-18.45a1.275,1.275,0,0,1-1.085-.879,1.007,1.007,0,0,1,1.083-1.13l18.452.437a.765.765,0,0,1,.738.786h0A.765.765,0,0,1,4385.041,1465.75Z"
          transform="translate(-4365.496 -1463.742)"
          fill="#fecaff"
        />
      </g>
      <g
        id="Group_851"
        data-name="Group 851"
        transform="translate(321.708 199.718)"
      >
        <path
          id="Path_1094"
          data-name="Path 1094"
          d="M4385.041,1485.044h-18.45a1.275,1.275,0,0,1-1.085-.879,1.007,1.007,0,0,1,1.083-1.13l18.452.437a.765.765,0,0,1,.738.786h0A.766.766,0,0,1,4385.041,1485.044Z"
          transform="translate(-4365.496 -1483.035)"
          fill="#fecaff"
        />
      </g>
    </g>
  </svg>
);

export const BookWithHat = () => (
  <svg
    width="95%"
    height="95%"
    viewBox="0 0 563 448"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7" filter="url(#filter0_d_46_435)" className="animatedUPs">
      <path
        d="M539 259.512C539 350.031 418.496 423.334 269.855 423.334C121.191 423.334 0.72261 350.02 0.72261 259.512C0.72261 168.993 121.191 95.6674 269.855 95.6674C418.496 95.6674 539 168.993 539 259.512Z"
        fill="#F7F7F7"
      />
    </g>
    <path
      opacity="0.2"
      d="M269.02 187.034L386.664 251.298"
      stroke="#202C2C"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M307.419 167.84L425.051 232.105"
      stroke="#202C2C"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M399.797 129.547L345.606 158.367L451.235 215.536L505.556 187.128L399.797 129.547Z"
      fill="#202C2C"
    />
    <path
      d="M505.073 176.913V186.892L475.994 184.445L505.073 176.913Z"
      fill="white"
    />
    <path
      d="M403.798 131.571L360.692 154.295L461.967 209.616L505.073 186.892L403.798 131.571Z"
      fill="white"
    />
    <path
      d="M461.967 209.616V182.903L360.692 144.316V154.295L461.967 209.616Z"
      fill="#E2E2E2"
    />
    <path
      d="M403.798 121.592L360.692 144.316L461.967 199.637L505.073 176.913L403.798 121.592Z"
      fill="#BFC0C2"
    />
    <path
      d="M505.073 176.913V186.892L475.994 184.445L505.073 176.913Z"
      fill="white"
    />
    <path
      d="M403.798 131.571L360.692 154.295L461.967 209.616L505.073 186.892L403.798 131.571Z"
      fill="white"
    />
    <path
      d="M461.967 209.616V182.903L360.692 144.316V154.295L461.967 209.616Z"
      fill="#E2E2E2"
    />
    <path
      d="M403.798 121.592L360.692 144.316L461.967 199.637L505.073 176.913L403.798 121.592Z"
      fill="#BFC0C2"
    />
    <path
      d="M389.888 51.0315C382.828 51.0315 377.108 54.2441 377.108 58.2099V126.123V134.172V138.985C377.108 142.951 382.828 146.164 389.888 146.164C396.949 146.164 402.668 142.951 402.668 138.985V134.172V126.123V58.2099C402.657 54.2441 396.937 51.0315 389.888 51.0315Z"
      fill="#FFFDFE"
    />
    <path
      d="M381.557 58.916V138.373"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M388.088 63.1407V142.598"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M396.149 60.0693V139.526"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M473.182 92.8783C466.121 92.8783 460.402 96.0909 460.402 100.057V167.97V176.019V180.832C460.402 184.798 466.121 188.01 473.182 188.01C480.243 188.01 485.962 184.798 485.962 180.832V176.019V167.97V100.057C485.962 96.0909 480.243 92.8783 473.182 92.8783Z"
      fill="#FFFDFE"
    />
    <path
      d="M464.862 100.763V180.22"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M471.393 104.976V184.445"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M479.454 101.904V181.373"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M430.194 72.9199C423.133 72.9199 417.414 76.1326 417.414 80.0984V148.011V156.06V160.873C417.414 164.839 423.133 168.052 430.194 168.052C437.254 168.052 442.974 164.839 442.974 160.873V156.06V148.011V80.0984C442.962 76.1326 437.243 72.9199 430.194 72.9199Z"
      fill="#FFFDFE"
    />
    <path
      d="M421.874 80.7926V160.262"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M428.393 85.0173V164.486"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M436.454 81.9459V161.415"
      stroke="#E2E2E2"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M505.073 82.8638V92.843L475.994 90.3953L505.073 82.8638Z"
      fill="white"
    />
    <path
      d="M403.798 37.5219L360.692 60.2457L461.967 115.567L505.073 92.8429L403.798 37.5219Z"
      fill="white"
    />
    <path
      d="M461.967 115.567V88.8654L360.692 50.2666V60.2458L461.967 115.567Z"
      fill="#E2E2E2"
    />
    <path
      d="M403.798 27.5426L360.692 50.2665L461.967 105.588L505.073 82.8637L403.798 27.5426Z"
      fill="#BFC0C2"
    />
    <path
      d="M450.682 13.9154L407.187 30.0257L370.46 48.7367L436.454 63.9056L450.682 13.9154Z"
      fill="#BFC0C2"
    />
    <path
      d="M450.682 13.9154L419.85 29.3549L462.049 97.762L496 79.8394L450.682 13.9154Z"
      fill="#E2E2E2"
    />
    <path
      d="M462.179 97.6914L420.332 28.9784L370.46 48.7368L462.179 97.6914Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M451.811 88.7125L418.096 33.3444L378.297 49.1252L451.811 88.7125Z"
      fill="#E2E2E2"
    />
    <path
      opacity="0.2"
      d="M129.464 155.531L8.32471 218.525L182.949 314.151L304.088 251.157L129.464 155.531Z"
      fill="#202C2C"
    />
    <path
      d="M137.878 147.258L16.9977 211.676L10.0075 212.288V215.406L182.796 311.621L310.667 243.473L137.878 147.258Z"
      fill="#BFC0C2"
    />
    <path
      d="M137.878 144.14L10.0075 212.288L182.796 308.502L310.667 240.354L137.878 144.14Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M147.739 160.238L39.6157 217.477"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M162.379 168.464L54.255 225.703"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M177.006 176.69L68.8825 233.929"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M191.646 184.915L83.5218 242.155"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M206.273 193.141L98.1494 250.38"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M220.913 201.367L112.789 258.606"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M235.54 209.593L127.416 266.832"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M250.179 217.819L142.056 275.058"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M264.807 226.044L156.683 283.284"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M279.435 234.27L171.311 291.51"
      stroke="#A8A8A8"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M266.172 147.282L90.5238 240.896L214.84 308.808L390.489 215.194L266.172 147.282Z"
      fill="#202C2C"
    />
    <path
      d="M276.316 148.882L110.106 237.471L227.738 301.724L393.948 213.147L276.316 148.882Z"
      fill="#61251D"
    />
    <path
      d="M276.316 104.976L110.106 193.565L227.738 257.83L393.948 169.241L276.316 104.976Z"
      fill="#00679D"
    />
    <path
      d="M110.106 193.565V237.471L227.738 301.724V257.123L110.106 193.565Z"
      fill="#00679D"
    />
    <path
      d="M115.719 205.78V235.859L229.691 297.817V267.279L115.719 205.78Z"
      fill="#16A4EF"
    />
    <path
      d="M118.496 207.474V233.176L227.479 292.239V266.126L118.496 207.474Z"
      fill="#A8A8A8"
    />
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M104.657 202.344L234.528 273.422"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 205.65L234.528 276.729"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 208.957L234.528 280.036"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 212.264L234.528 283.354"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 215.583L234.528 286.661"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 218.889L234.528 289.968"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M104.657 222.196L234.528 293.286"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
    </g>
    <path
      d="M388.712 205.439V179.737L393.807 176.996L393.854 177.054V169.24L227.714 257.088V301.7L393.854 213.147V206.368L388.712 205.439Z"
      fill="#00679D"
    />
    <path
      d="M388.712 179.479V209.569L227.844 296.734V266.196L388.712 179.479Z"
      fill="#16A4EF"
    />
    <path
      d="M388.712 179.737V205.439L227.985 292.204V266.091L388.712 179.737Z"
      fill="#EBEBEB"
    />
    <path
      opacity="0.2"
      d="M402.562 174.595L220.924 273.387"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 177.913L220.924 276.694"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 181.22L220.924 280"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 184.527L220.924 283.319"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 187.845L220.924 286.626"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 191.152L220.924 289.932"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M402.562 194.459L220.924 293.251"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      d="M278.152 105.599L136.43 181.126L236.729 235.929L378.462 160.391L278.152 105.599Z"
      fill="#61251D"
    />
    <path
      d="M278.152 68.1539L136.43 143.692L236.729 198.484L378.462 122.957L278.152 68.1539Z"
      fill="#D88C1F"
    />
    <path
      d="M136.43 143.692V181.126L236.729 235.929V197.895L136.43 143.692Z"
      fill="#945A05"
    />
    <path
      d="M141.208 154.107V179.761L238.4 232.587V206.545L141.208 154.107Z"
      fill="#FAA831"
    />
    <path
      d="M143.585 155.554V177.466L236.505 227.821V205.568L143.585 155.554Z"
      fill="#A8A8A8"
    />
    <g opacity="0.3">
      <path
        opacity="0.3"
        d="M131.782 151.177L242.53 211.782"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 154.001L242.53 214.606"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 156.825L242.53 217.43"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 159.638L242.53 220.255"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 162.462L242.53 223.079"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 165.286L242.53 225.891"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M131.782 168.111L242.53 228.716"
        stroke="#A8A8A8"
        strokeMiterlimit="10"
      />
    </g>
    <path
      d="M374.002 153.813V131.901L378.344 129.559L378.379 129.606V122.945L236.717 197.86V235.894L378.379 160.391V154.601L374.002 153.813Z"
      fill="#945A05"
    />
    <path
      d="M374.002 131.689V157.343L236.835 231.658V205.627L374.002 131.689Z"
      fill="#FAA831"
    />
    <path
      d="M374.002 131.901V153.813L236.94 227.798V205.533L374.002 131.901Z"
      fill="#EBEBEB"
    />
    <path
      opacity="0.2"
      d="M385.805 127.523L230.927 211.758"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 130.348L230.927 214.582"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 133.172L230.927 217.395"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 135.984L230.927 220.219"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 138.809L230.927 223.044"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 141.633L230.927 225.868"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M385.805 144.457L230.927 228.692"
      stroke="#A8A8A8"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M248.779 172.689C292.454 172.689 327.86 152.399 327.86 127.37C327.86 102.342 292.454 82.0519 248.779 82.0519C205.104 82.0519 169.698 102.342 169.698 127.37C169.698 152.399 205.104 172.689 248.779 172.689Z"
      fill="#202C2C"
    />
    <path
      d="M171.758 46.0537V123.875C171.758 147.858 206.367 167.311 249.061 167.311C291.756 167.311 326.365 147.858 326.365 123.875V46.0537H171.758Z"
      fill="#202C2C"
    />
    <path
      d="M369.365 75.4383V66.0239H350.972L246.708 10.3969L142.444 66.0239H124.051V75.4383V75.8384L246.708 141.28L369.365 75.8384V75.4383Z"
      fill="#171443"
    />
    <path
      d="M369.365 75.4383V66.0239H350.972L246.708 10.3969L142.444 66.0239H124.051V75.4383V75.8384L246.708 141.28L369.365 75.8384V75.4383Z"
      fill="#324446"
    />
    <path
      d="M246.708 0.582397L124.051 66.0239L246.708 131.465L369.365 66.0239L246.708 0.582397Z"
      fill="#4D6565"
    />
    <path
      d="M248.638 60.8225L182.22 96.6912V158.026"
      stroke="#FAA831"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M188.304 189.364C188.304 192.576 185.691 195.189 182.478 195.189C179.266 195.189 176.653 192.576 176.653 189.364V169.605C176.653 166.393 179.266 163.78 182.478 163.78C185.691 163.78 188.304 166.393 188.304 169.605V189.364Z"
      fill="#FAA831"
    />
    <path
      d="M164.497 163.98C176.3 171.218 191.128 170.994 202.06 162.298C205.179 159.815 200.778 155.472 197.694 157.932C188.951 164.898 176.842 164.298 167.627 158.649C164.203 156.543 161.108 161.897 164.497 163.98Z"
      fill="#EAAF39"
    />
    <path
      d="M190.01 158.591C190.01 162.745 186.633 166.122 182.478 166.122C178.324 166.122 174.947 162.756 174.947 158.591C174.947 154.436 178.313 151.059 182.478 151.059C186.644 151.059 190.01 154.436 190.01 158.591Z"
      fill="#FFCB58"
    />
    <path
      d="M256.405 62.7172C256.405 66.7183 251.074 69.9663 244.495 69.9663C237.917 69.9663 232.586 66.7183 232.586 62.7172C232.586 58.7161 237.917 55.4682 244.495 55.4682C251.074 55.4564 256.405 58.7044 256.405 62.7172Z"
      fill="#EAAF39"
    />
    <path
      d="M253.945 60.7754C253.945 63.9527 249.709 66.5299 244.484 66.5299C239.259 66.5299 235.022 63.9527 235.022 60.7754C235.022 57.5981 239.259 55.0209 244.484 55.0209C249.709 55.0209 253.945 57.5981 253.945 60.7754Z"
      fill="#FFCB58"
    />
    <path
      d="M199.448 251.898L198.918 272.281L206.179 269.339L215.511 282.142V260.713L199.448 251.898Z"
      fill="#B25900"
    />
    <path
      d="M370.448 286.132C369.565 286.602 368.741 287.155 367.988 287.791L373.201 285.037C372.248 285.308 371.33 285.673 370.448 286.132Z"
      fill="#FF481E"
    />
    <path
      d="M462.404 226.436L217.046 356.333L243.553 406.401L488.911 276.504L462.404 226.436Z"
      fill="white"
    />
    <path
      d="M213.946 390.007C221.289 403.87 234.81 411.095 244.142 406.153C253.486 401.21 255.11 385.959 247.767 372.096C240.424 358.234 226.902 351.008 217.57 355.951C208.238 360.905 206.614 376.144 213.946 390.007Z"
      fill="#BFC0C2"
    />
    <path
      d="M214.005 389.984C221.324 403.799 234.799 411.001 244.107 406.07C253.416 401.14 255.028 385.947 247.72 372.132C240.412 358.316 226.926 351.114 217.617 356.045C208.309 360.976 206.697 376.168 214.005 389.984Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.4"
      d="M213.877 390.104C221.221 403.967 234.742 411.192 244.074 406.25C253.418 401.307 255.042 386.056 247.699 372.193C240.355 358.331 226.834 351.105 217.502 356.048C208.158 360.99 206.534 376.242 213.877 390.104Z"
      fill="#BFC0C2"
    />
    <path
      d="M224.714 376.533L224.725 376.556C224.772 376.533 224.831 376.509 224.878 376.486L224.714 376.533Z"
      fill="white"
    />
    <path
      d="M458.849 260.348C466.192 274.211 479.713 281.436 489.045 276.494C498.389 271.551 500.013 256.3 492.67 242.437C485.326 228.575 471.805 221.349 462.473 226.292C453.129 231.234 451.505 246.485 458.849 260.348Z"
      fill="white"
    />
    <path
      d="M397.949 292.792C390.606 278.93 377.085 271.704 367.753 276.647C367.082 277 366.47 277.412 365.882 277.859L311.855 306.467C321.034 303.501 333.143 310.644 339.945 323.495C347.288 337.357 345.664 352.609 336.321 357.551C336.144 357.645 335.968 357.716 335.791 357.81L335.826 357.881L395.937 326.06L395.855 325.907C403.822 320.153 404.869 305.855 397.949 292.792Z"
      fill="#E13F2A"
    />
    <path
      d="M408.882 317.999L409.235 307.737L427.016 303.76L385.711 288.25L367.247 302.371L408.882 317.999Z"
      fill="#AB0E09"
    />
    <path
      d="M427.016 301.159V303.76L418.673 301.548L427.016 301.159Z"
      fill="#AB0E09"
    />
    <path
      d="M408.882 315.398L409.235 305.137L427.016 301.159L385.711 285.649L367.247 299.77L408.882 315.398Z"
      fill="#FF481E"
    />
    <path
      d="M348.477 307.102L334.191 335.239L353.537 330.72L368.235 341.029L377.273 307.42L348.477 307.102Z"
      fill="#AB0E09"
    />
    <path
      d="M334.226 332.179L334.191 335.239L343.511 331.991L344.994 325.778L334.226 332.179Z"
      fill="#AB0E09"
    />
    <path
      d="M348.477 304.066L334.191 332.203L353.537 327.684L368.235 337.993L377.273 304.372L348.477 304.066Z"
      fill="#FF481E"
    />
    <path
      d="M393.536 289.415C393.536 301.324 377.685 310.974 358.115 310.974C338.545 310.974 322.693 301.324 322.693 289.415C322.693 277.506 338.545 267.856 358.115 267.856C377.685 267.856 393.536 277.506 393.536 289.415Z"
      fill="#AB0E09"
    />
    <path
      d="M393.536 286.673C393.536 298.582 377.685 308.232 358.115 308.232C338.545 308.232 322.693 298.582 322.693 286.673C322.693 274.764 338.545 265.114 358.115 265.114C377.685 265.114 393.536 274.764 393.536 286.673Z"
      fill="#FF481E"
    />
    <path
      d="M380.733 284.837C380.733 292.439 370.601 298.605 358.115 298.605C345.617 298.605 335.497 292.439 335.497 284.837C335.497 277.235 345.617 271.068 358.115 271.068C370.612 271.068 380.733 277.235 380.733 284.837Z"
      fill="#FFBE00"
    />
    <path
      opacity="0.4"
      d="M387.841 285.755C387.841 295.746 374.531 303.854 358.115 303.854C341.699 303.854 328.389 295.758 328.389 285.755C328.389 275.764 341.699 267.656 358.115 267.656C374.531 267.656 387.841 275.764 387.841 285.755Z"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M376.214 284.837C376.214 290.921 368.118 295.852 358.127 295.852C348.136 295.852 340.039 290.921 340.039 284.837C340.039 278.753 348.136 273.822 358.127 273.822C368.106 273.822 376.214 278.753 376.214 284.837Z"
      fill="#FFB001"
    />
    <path
      d="M384.981 99.9862L426.369 232.564"
      stroke="#A7734E"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M351.854 138.703C350.701 138.726 349.583 138.102 349.03 136.996C348.242 135.431 348.877 133.489 350.431 132.654L387.099 113.084C388.676 112.236 390.606 112.837 391.406 114.402C392.207 115.979 391.571 117.932 389.994 118.779L353.267 138.338C352.819 138.573 352.325 138.703 351.854 138.703Z"
      fill="#805434"
    />
    <path
      d="M358.138 159.402C356.973 159.426 355.855 158.791 355.302 157.684C354.514 156.119 355.138 154.166 356.703 153.342L393.466 133.866C395.043 133.03 396.984 133.631 397.785 135.208C398.585 136.784 397.949 138.75 396.361 139.585L359.551 159.038C359.103 159.273 358.609 159.402 358.138 159.402Z"
      fill="#805434"
    />
    <path
      d="M364.681 180.514C363.516 180.549 362.387 179.937 361.81 178.831C360.986 177.266 361.586 175.313 363.14 174.454L399.644 154.401C401.221 153.542 403.174 154.107 403.998 155.684C404.834 157.261 404.234 159.226 402.657 160.097L366.117 180.126C365.646 180.373 365.164 180.502 364.681 180.514Z"
      fill="#805434"
    />
    <path
      d="M370.989 201.343C369.824 201.379 368.683 200.755 368.106 199.649C367.282 198.072 367.882 196.118 369.436 195.271L406.058 175.348C407.634 174.489 409.588 175.077 410.423 176.654C411.259 178.231 410.659 180.208 409.07 181.067L372.401 200.967C371.966 201.202 371.472 201.32 370.989 201.343Z"
      fill="#805434"
    />
    <path
      d="M377.108 221.926C375.932 221.926 374.814 221.29 374.249 220.16C373.449 218.572 374.084 216.618 375.661 215.806L412.742 196.636C414.342 195.812 416.296 196.436 417.096 198.037C417.908 199.637 417.272 201.602 415.672 202.426L378.556 221.572C378.085 221.808 377.591 221.914 377.108 221.926Z"
      fill="#805434"
    />
    <path
      d="M383.475 242.896C382.298 242.896 381.168 242.249 380.603 241.119C379.803 239.519 380.439 237.565 382.016 236.753L419.191 217.677C420.791 216.853 422.744 217.489 423.556 219.101C424.368 220.702 423.721 222.679 422.121 223.491L384.899 242.543C384.452 242.79 383.957 242.896 383.475 242.896Z"
      fill="#805434"
    />
    <path
      d="M345.406 116.92L387.029 251.651"
      stroke="#A7734E"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M184.267 348.066C184.126 347.996 183.985 347.937 183.844 347.878L176.053 343.794C175.935 343.724 175.818 343.641 175.7 343.571C175.312 343.371 174.9 343.23 174.488 343.112L174.523 343.029L70.3418 287.861C70.2123 287.779 70.0946 287.697 69.9652 287.626C69.4474 287.355 68.8943 287.167 68.3294 287.061L68.3765 286.967L43.1814 285.955L55.7848 305.619C56.1496 306.572 56.6674 307.396 57.3499 308.055L57.5735 308.408L57.6324 308.302C57.9383 308.561 58.2678 308.808 58.6327 308.997C58.7621 309.067 58.8915 309.114 59.021 309.161L163.226 364.329L163.308 364.176C163.638 364.459 163.979 364.718 164.368 364.918C164.544 365.012 164.721 365.094 164.897 365.165L172.617 369.213C172.723 369.284 172.829 369.354 172.935 369.413C176.912 371.52 182.679 368.436 185.797 362.541C188.939 356.668 188.245 350.173 184.267 348.066Z"
      fill="#202C2C"
    />
    <path
      d="M51.819 280.506L66.2112 302.96L77.0024 281.518L51.819 280.506Z"
      fill="#FFCC81"
    />
    <path
      d="M117.802 307.667C118.178 307.867 118.531 308.102 118.849 308.373L116.625 307.196C117.037 307.314 117.425 307.467 117.802 307.667Z"
      fill="#FF481E"
    />
    <path
      d="M80.1444 296.676C77.0142 302.583 71.2597 305.654 67.2821 303.548C63.3045 301.442 62.6102 294.946 65.7405 289.038C68.8708 283.131 74.6253 280.047 78.6029 282.154C82.5804 284.272 83.263 290.768 80.1444 296.676Z"
      fill="#00679D"
    />
    <path
      d="M78.6328 282.204L67.3396 303.536L171.865 358.873L183.158 337.542L78.6328 282.204Z"
      fill="#004C74"
    />
    <path
      d="M184.479 351.914C181.349 357.822 175.594 360.893 171.617 358.799C167.639 356.692 166.945 350.196 170.075 344.289C173.205 338.381 178.96 335.31 182.937 337.416C186.903 339.511 187.597 346.007 184.479 351.914Z"
      fill="#BFC0C2"
    />
    <path
      d="M193.128 342.747L184.679 338.322C184.561 338.252 184.444 338.169 184.326 338.099C180.348 335.992 174.582 339.076 171.464 344.971C168.333 350.879 169.028 357.375 173.005 359.481C173.182 359.575 173.358 359.646 173.535 359.728L181.831 364.082L193.128 342.747Z"
      fill="#B67210"
    />
    <path
      d="M194.446 357.104C191.316 363.011 185.562 366.083 181.584 363.976C177.607 361.87 176.912 355.374 180.042 349.466C183.173 343.559 188.927 340.488 192.905 342.594C196.882 344.7 197.577 351.196 194.446 357.104Z"
      fill="#D88C1F"
    />
    <defs>
      <filter
        id="filter0_d_46_435"
        x="0.72261"
        y="95.6674"
        width="562.277"
        height="351.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="20" dy="20" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_46_435"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_46_435"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const ImapeLaptop = () =>(
  <img src={SliderImg1} alt="" className='img-fluid' />
)

export const FooterWave = () => (
  <svg
    width="100%"
    height="360"
    viewBox="0 0 1512 360"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.310059L31.5 40.1971C63 80.0841 126 159.858 189 179.802C252 199.745 315 159.858 378 153.21C441 146.562 504 173.154 567 153.21C630 133.267 693 66.7884 756 53.4927C819 40.1971 882 80.0841 945 139.915C1008 199.745 1071 279.519 1134 299.463C1197 319.406 1260 279.519 1323 219.689C1386 159.858 1449 80.0841 1480.5 40.1971L1512 0.310059V359.293H1480.5C1449 359.293 1386 359.293 1323 359.293C1260 359.293 1197 359.293 1134 359.293C1071 359.293 1008 359.293 945 359.293C882 359.293 819 359.293 756 359.293C693 359.293 630 359.293 567 359.293C504 359.293 441 359.293 378 359.293C315 359.293 252 359.293 189 359.293C126 359.293 63 359.293 31.5 359.293H0V0.310059Z"
      fill="#00679D"
    />
  </svg>
);

export const WaveBorder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="51px"
    viewBox="0 0 180 51"
    style={{ position: "absolute" }}
  >
    <path
      id="Path_4390"
      data-name="Path 4390"
      d="M-1300.54,239.408c56.415,0,56.415,48,112.83,48s56.416-48,112.831-48,56.415,48,112.83,48,56.416-48,112.833-48,56.419,48,112.838,48,56.419-48,112.838-48"
      transform="translate(1302.04 -237.908)"
      fill="none"
      stroke="#9d9d9c"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="3"
      strokeDasharray="7"
    />
  </svg>
);

export const TopArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.835"
    height="20.552"
    viewBox="0 0 23.835 20.552"
    style={{ position: "absolute", top: "-20px", zIndex: "2" }}
  >
    <path
      id="Path_4393"
      data-name="Path 4393"
      d="M-1063.251,314.854l-11.918-.064-11.917-.064,6.014-10.212,6.014-10.212,5.9,10.276Z"
      transform="translate(1087.086 -294.302)"
      fill="#0D69B7"
      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
    />
  </svg>
);

export const BottomArrow = ({ color }: { color: any }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="23.835"
    height="20.552"
    viewBox="0 0 23.835 20.552"
  >
    <path
      id="Path_4410"
      data-name="Path 4410"
      d="M-974.432,212.47l11.917.064,11.917.064-6.014,10.212-6.014,10.212-5.9-10.276Z"
      transform="translate(974.432 -212.47)"
      fill={color ? "#0D69B7" : "#F49317"}
      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
    />
  </svg>
);

// export const CurveBorder = () => (
//   <svg
//     width="100%"
//     height="20px"
//     viewBox="0 0 699 157"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M6 6C9.96 6.18 14.48 6.47001 19.47 6.95001"
//       stroke="#F59318"
//       strokeWidth="12"
//       stroke-miterlimit="10"
//       stroke-linecap="round"
//     />
//     <path
//       d="M46.39 10.93C54.49 12.56 63.17 14.7 72.24 17.52C122.82 33.23 139.08 55.48 204.72 95.28C256.08 126.42 281.76 141.99 316.32 147.84C370.56 157.02 416.75 141.51 436.56 133.44C504.31 105.83 510.81 64.47 576.24 31.92C608.04 16.1 639.2 9.61003 665.09 7.22003"
//       stroke="#F59318"
//       strokeWidth="12"
//       stroke-miterlimit="10"
//       stroke-linecap="round"
//       strokeDasharray="27.22 27.22"
//     />
//     <path
//       d="M678.66 6.29999C683.42 6.08999 687.93 6 692.16 6"
//       stroke="#F59318"
//       strokeWidth="12"
//       stroke-miterlimit="10"
//       stroke-linecap="round"
//     />
//   </svg>
// );

export const CurveBorder = () => (
  <svg
    width="100%"
    height="20px"
    viewBox="0 0 785 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6C10.4554 6.14018 15.5409 6.36604 21.1551 6.73986"
      stroke="#F59318"
      strokeWidth="12"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.4429 11.8394C53.5562 13.1088 63.3221 14.7755 73.5268 16.9716C130.434 29.2064 148.729 46.5344 222.58 77.5301C280.366 101.781 309.258 113.907 348.142 118.463C409.167 125.612 461.136 113.533 483.424 107.249C559.65 85.7463 566.963 53.5357 640.578 28.1862C676.356 15.8658 711.415 10.8114 740.543 8.95015"
      stroke="#F59318"
      strokeWidth="12"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeDasharray="27.22 27.22"
    />
    <path
      d="M763 7.23363C768.355 7.07008 773.43 7 778.189 7"
      stroke="#F59318"
      strokeWidth="12"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
