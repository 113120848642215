import { Col, Container, Row } from "react-bootstrap"
import { useContext } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

// import required modules
import { EffectFade,Pagination, Navigation } from 'swiper';
import { useNavigate } from "react-router-dom";

import { Fade } from "react-reveal";
import { ButtonGroup } from "react-bootstrap";
import { Button, EllipsisText, ParagraphText, TitleText } from "../../Styles/StyleComponent";
import { NavLink } from "react-router-dom";
import { GlobalData } from "../../contextapi/ContextProvider";

const HeaderSlider = () => {
  let {
    sliderCollection
    } = useContext(GlobalData);

  return (
  <Container fluid className="slider-wrapper-main" >
      <Container className="homeSwiperSlider" >
      <>
      <Swiper
spaceBetween={0}
pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[EffectFade , Pagination]}
        className="mySwiper"
      >
        {sliderCollection?.map((val:any,index:number)=>{
          return <SwiperSlide key={index} className="" >
          
          <Row  className="sliderRowmy-md-auto w-100 my-0 d-flex flex-column-reverse flex-md-row">
            <Col md={5} className="content-col  my-lg-auto my-2 d-flex flex-column">
            <Fade up delay={0}>
            <div className="box">
            <p className="title-tag">{val.title}</p>
<EllipsisText lineClamp="4" className="text-white mb-3">
{val.description}
</EllipsisText>

<div className="btn-grp">

<NavLink to={val.button_link}>
<Button className="white-btn me-4">Read More</Button>
</NavLink>

<NavLink to={'/contact-us'}>
<Button className="outline-btn me-2">Contact Us</Button>
</NavLink>
</div>
            </div>

</Fade>

            </Col>

            <Col md={7}  className="img-col  my-lg-auto my-2   d-flex justify-content-center align-items-center">
                <img src={val.image} style={{height : '400px'}}  className="img-fluid homelsiderimg animatedUp" alt="" />
            </Col>
          </Row>

      </SwiperSlide>

        })
        }

      </Swiper>
    </>

    </Container>
  </Container>
  )
}

export default HeaderSlider



export const Heading = ({ content,active,total }: { content: any,active:number,total:number }) => {
  const navigateTo = useNavigate();

  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center w-100">
        <Row className="align-items-center w-100 g-4">
          <Col md={6} className="d-flex flex-column order-md-1 order-2">
            <Fade up delay={0}>
              <ParagraphText className="mb-3">{content.sub_title}</ParagraphText>
            </Fade>
            <Fade up delay={100}>
              <TitleText color="white">{content.title}</TitleText>
            </Fade>
            <Fade up delay={200}>
              <ParagraphText>{content.description}</ParagraphText>
             {content.button_link ?  <ButtonGroup className="mt-4">
                <a href={content.button_link}>
                <Button
                  className="me-4 rounded-2"
                  background="white"
                  color="#1f5d9e"
                  style={{border : '2px solid white'}}
                >
                  Read More
                </Button>
                </a>

                <NavLink to={'/contact-us'}>
                <Button
                  className="me-4 rounded-2"
                  style={{border : '2px solid white'}}
                  background="transparent"
                  color="white" >
                  Contact Us
                </Button>
                </NavLink>

              </ButtonGroup> : null}
              {/* <p className="mt-1" style={{color:"white", fontSize : '20px'}}><span>0{active+1}</span>/<span className="text-light" style={{color : '#ACACAC'}}>0{total}</span></p> */}
            </Fade>
          </Col>
          <Col md={6} className="order-md-2 order-1">
            <Fade right delay={200}>
              <div className="d-flex">
                {!content.secondImage ? (
                
                  <img
                      className="animatedUp ms-md-auto slider-image"
                      src={content.image}
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                ) : (
                  <div className="d-flex w-100 justify-content-center align-items-center">
                    <img
                      className="animatedUp"
                      src={content.image}
                      alt=""
                      width="37%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                    <img
                      className="animatedDown"
                      src={content.image}
                      alt=""
                      width="37%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </div>
    </Container>
  );
};


