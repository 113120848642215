import SliderBG from '../../Assets/new/sliderbg.png'
import { Nav as Navs, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface CustomProps {
  readonly transform?: any;
  readonly opacity?: any;
  readonly color?: string;
  readonly fontWeight?: string;
  readonly background?: string;
}

export const CustomLink = styled(NavLink)<CustomProps>`
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 800;
  color: ${(props) => props.color ?? "black"};
  padding: 16px 0px;
  position: relative;
  font-weight: ${(props) => props.fontWeight ?? "450"};
  border-radius : 6px;
  &:after {
    content: "";
    width: 100%;
    background-color: #004c74;
    border-radius: 4px;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s ease;
  }
 
  &:hover {
    color: ${(props) => props.color ?? "black"};
  }
`;
export const DropDownLink = styled.p<CustomProps>`
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 18px;
  color: ${(props) => props.color ?? "black"};
  display: flex;
  padding: 12px 0px;
  margin: 0px;
  height: 35px;
  cursor: pointer;
  font-weight: ${(props) => props.fontWeight ?? "450"};
`;

export const Nav = styled(Navs)`
  width: 80%;
  align-items: center;
  z-index: 10;
  justify-content: space-between;

  @media (max-width: 1172px) {
    width: 95%;
  }
  @media (max-width: 992px) {
    position: absolute;
    top: 67px;
    background: #e0eeec;
    z-index: 10;
    width: 96%;
    right: 6px;
    border-radius: 0px 0px 12px 12px;
    padding-bottom: 16px;
    ${CustomLink} {
      color: #000;
    }
    ${DropDownLink} {
      color: #000;
    }
  }
`;
export const Line = styled.span<CustomProps>`
  display: block;
  width: 30px;
  height: 3px;
  background: ${(props) => props.background ?? "black"};
  margin-block: 10px;
  border-radius: 4px;
  transition: transform 0.25s;
  opacity: ${(props: any) => props.opacity ?? "0.25"};
  transform: ${(props: any) =>
    props.transform ?? "translateY(0px) rotate(0deg)"};
`;
export const Toggled = styled(Navbar.Toggle)``;

export const FadeAwayBackground = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -2;
  background : url(${SliderBG});
  background-size : 100% 100%;
  margin-top : -80px;
`;
