export const CollegeErp = ({
  activeHover,
}: {
  activeHover?: string | null;
}) => (
  <svg
    id="Group_1421"
    data-name="Group 1421"
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="35px"
    viewBox="0 0 62.353 62.353"
  >
    <g
      id="_2175878_accounting_finance_analytics_chart_graph_icon"
      data-name="2175878_accounting_finance_analytics_chart_graph_icon"
    >
      <path
        id="Path_3523"
        data-name="Path 3523"
        d="M52.991,6.028,50.322,7.808A18.069,18.069,0,0,0,21.158,9.046H1V63.353H45.251v-28.6A18.028,18.028,0,0,0,51.487,9.449L53.6,8.04h9.752V6.028Zm-17.8-2.966V18.807L26.682,32.048A16.057,16.057,0,0,1,35.194,3.063ZM36.2,37.205a18.085,18.085,0,0,0,3.017-.273v2.284H7.034V15.08H18.563A18.048,18.048,0,0,0,36.2,37.205Zm7.04,24.137H3.011V11.057h17a17.827,17.827,0,0,0-.9,2.149v-.138H5.023V41.228H41.228V36.48a18.067,18.067,0,0,0,2.011-.7ZM36.2,35.194a15.98,15.98,0,0,1-7.832-2.048l8.24-12.817L50.87,25.679A16.1,16.1,0,0,1,36.2,35.194ZM52.29,19.1a16.041,16.041,0,0,1-.7,4.7L37.205,18.406V3.063A16.061,16.061,0,0,1,48.649,8.924L40.67,14.243l1.115,1.673,8.028-5.352A15.976,15.976,0,0,1,52.29,19.1Z"
        transform="translate(-1 -1)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <rect
        id="Rectangle_186"
        data-name="Rectangle 186"
        width="2.077"
        height="2.077"
        transform="translate(60.251 9.025)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <rect
        id="Rectangle_187"
        data-name="Rectangle 187"
        width="4.846"
        height="2.077"
        transform="translate(53.329 9.025)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <rect
        id="Rectangle_188"
        data-name="Rectangle 188"
        width="35.997"
        height="2.077"
        transform="translate(4.179 42.253)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <rect
        id="Rectangle_192"
        data-name="Rectangle 192"
        width="2.077"
        height="2.077"
        transform="translate(38.099 56.097)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <rect
        id="Rectangle_193"
        data-name="Rectangle 193"
        width="31.843"
        height="2.077"
        transform="translate(4.179 56.097)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <path
        id="Path_3524"
        data-name="Path 3524"
        d="M51,41.046h8.046V33H51Zm2.011-6.034h4.023v4.023H53.011Z"
        transform="translate(-0.715 -0.818)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <path
        id="Path_3525"
        data-name="Path 3525"
        d="M55.028,53a5.028,5.028,0,1,0,5.028,5.028A5.034,5.034,0,0,0,55.028,53Zm0,8.046a3.017,3.017,0,1,1,3.017-3.017A3.021,3.021,0,0,1,55.028,61.046Z"
        transform="translate(-0.721 -0.704)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
      <path
        id="Path_3526"
        data-name="Path 3526"
        d="M59.82,51.045l-4.793-7.989-4.793,7.989Zm-3.552-2.011H53.786l1.241-2.068Z"
        transform="translate(-0.72 -0.76)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
    </g>
    <g
      id="_2175891_accounting_finance_analytics_business_chart_icon"
      data-name="2175891_accounting_finance_analytics_business_chart_icon"
      transform="translate(10.649 45.41)"
    >
      <path
        id="Path_3528"
        data-name="Path 3528"
        d="M11.161,41.323A1.981,1.981,0,0,0,13.322,39.6a1.535,1.535,0,0,0-.56-1.145l3.1-4.117a2.5,2.5,0,0,0,1.418-.005l3.387,3.082a1.462,1.462,0,0,0-.324.9,1.981,1.981,0,0,0,2.161,1.721,1.981,1.981,0,0,0,2.161-1.721,1.469,1.469,0,0,0-.345-.925l2.794-2.967c.084.008.165.02.251.02a1.981,1.981,0,0,0,2.161-1.721A1.981,1.981,0,0,0,27.367,31a1.981,1.981,0,0,0-2.161,1.721,1.633,1.633,0,0,0,.869,1.371L23.521,36.8a2.548,2.548,0,0,0-2.072.017l-3.267-2.973a1.529,1.529,0,0,0,.541-1.127A1.981,1.981,0,0,0,16.563,31,1.981,1.981,0,0,0,14.4,32.721a1.535,1.535,0,0,0,.56,1.145l-3.1,4.117a2.641,2.641,0,0,0-.7-.1A1.981,1.981,0,0,0,9,39.6,1.981,1.981,0,0,0,11.161,41.323ZM27.367,31.86a.883.883,0,1,1-1.08.86A.99.99,0,0,1,27.367,31.86Zm-3.781,6.452a1.109,1.109,0,1,1-1.08-.86A.99.99,0,0,1,23.586,38.312ZM16.563,31.86a.883.883,0,1,1,0,1.721.883.883,0,1,1,0-1.721Zm-5.4,6.882a.883.883,0,1,1,0,1.721.883.883,0,1,1,0-1.721Z"
        transform="translate(-9 -31)"
        fill={activeHover === "1" ? "#fff" : "#333"}
      />
    </g>
  </svg>
);

export const AccountingErp = ({
  activeHover,
}: {
  activeHover?: string | null;
}) => (
  <svg
    id="_2175876_accounting_finance_business_calculator_marketing_icon"
    data-name="2175876_accounting_finance_business_calculator_marketing_icon"
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="35px"
    viewBox="0 0 62 62.014"
  >
    <path
      id="Path_3529"
      data-name="Path 3529"
      d="M54,9H47V1H2.026l-1-.014C1.009,2.312,1,15.87,1,16V49c0,3.309,2.243,6,5,6H17v8H63V18A9.01,9.01,0,0,0,54,9ZM3,49V16c0-.1.006-8.884.017-13H45V9H14a5.006,5.006,0,0,0-5,5V49c0,2.206-1.346,4-3,4S3,51.206,3,49ZM17,14V53H9.716A6.682,6.682,0,0,0,11,49V14a3,3,0,0,1,6,0ZM61,61H19V14a4.948,4.948,0,0,0-1.026-3H54a7.008,7.008,0,0,1,7,7Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3530"
      data-name="Path 3530"
      d="M41,41H57V25H41Zm2-14H55V39H43Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3531"
      data-name="Path 3531"
      d="M23,41H39V25H23Zm2-14H37V39H25Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3532"
      data-name="Path 3532"
      d="M41,59H57V43H41Zm2-14H55V57H43Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <rect
      id="Rectangle_194"
      data-name="Rectangle 194"
      width="8"
      height="2"
      transform="translate(44 31.014)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <rect
      id="Rectangle_195"
      data-name="Rectangle 195"
      width="8"
      height="2"
      transform="translate(44 49.014)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3533"
      data-name="Path 3533"
      d="M54,13H30.7l-9.924,5L30.7,23H54a5,5,0,0,0,0-10Zm-6,4H32V15H48ZM32,19H48v2H32Zm18-4h2v6H50Zm-20,.593v4.815L25.223,18ZM54,21V15a3,3,0,0,1,0,6Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3534"
      data-name="Path 3534"
      d="M32,29H30v3H27v2h3v3h2V34h3V32H32Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3535"
      data-name="Path 3535"
      d="M23,59H39V43H23Zm2-14H37V57H25Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <path
      id="Path_3536"
      data-name="Path 3536"
      d="M33.121,47.465,31,49.586l-2.121-2.121-1.414,1.414L29.586,51l-2.121,2.121,1.414,1.414L31,52.414l2.121,2.121,1.414-1.414L32.414,51l2.121-2.121Z"
      transform="translate(-1 -0.986)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <rect
      id="Rectangle_196"
      data-name="Rectangle 196"
      width="2"
      height="2"
      transform="translate(47 52.014)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
    <rect
      id="Rectangle_197"
      data-name="Rectangle 197"
      width="2"
      height="2"
      transform="translate(47 46.014)"
      fill={activeHover === "2" ? "#fff" : "#333"}
    />
  </svg>
);

export const HospitalErp = ({
  activeHover,
}: {
  activeHover?: string | null;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="35px"
    viewBox="0 0 60.297 62.639"
  >
    <g id="Group_1450" data-name="Group 1450" transform="translate(0.75 0.75)">
      <g id="Group_1449" data-name="Group 1449">
        <g id="Group_1447" data-name="Group 1447">
          <path
            id="Path_3549"
            data-name="Path 3549"
            d="M260.279,74.569a4.537,4.537,0,1,1-4.538-4.537,4.536,4.536,0,0,1,4.538,4.537"
            transform="translate(-226.441 -70.032)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3550"
            data-name="Path 3550"
            d="M420.671,254.667a4.538,4.538,0,1,1-4.538-4.536,4.538,4.538,0,0,1,4.538,4.536"
            transform="translate(-361.874 -224.353)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3558"
            data-name="Path 3558"
            d="M420.671,254.667a4.538,4.538,0,1,1-4.538-4.536,4.538,4.538,0,0,1,4.538,4.536"
            transform="translate(-411.159 -224.353)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3551"
            data-name="Path 3551"
            d="M408.983,125.845a3.047,3.047,0,1,1-3.044-3.047,3.044,3.044,0,0,1,3.044,3.047"
            transform="translate(-356.414 -115.245)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3552"
            data-name="Path 3552"
            d="M84.278,120.949a3.045,3.045,0,1,1-3.046-3.047,3.047,3.047,0,0,1,3.046,3.047"
            transform="translate(-78.188 -111.05)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            y1="4.636"
            x2="4.79"
            transform="translate(42.728 12.945)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_9"
            data-name="Line 9"
            y1="2.396"
            transform="translate(29.3 9.185)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3553"
            data-name="Path 3553"
            d="M328.492,281.819h2.2"
            transform="translate(-280.974 -251.505)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3559"
            data-name="Path 3559"
            d="M328.492,281.819H331.3"
            transform="translate(-318.739 -251.505)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_10"
            data-name="Line 10"
            x2="10.385"
            y2="7.615"
            transform="translate(5.343 11.966)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
        </g>
        <g
          id="Group_1448"
          data-name="Group 1448"
          transform="translate(0 40.581)"
        >
          <path
            id="Path_3549-2"
            data-name="Path 3549"
            d="M260.279,74.568a4.537,4.537,0,1,0-4.538,4.537,4.536,4.536,0,0,0,4.538-4.537"
            transform="translate(-226.441 -58.546)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3551-2"
            data-name="Path 3551"
            d="M408.983,125.842a3.047,3.047,0,1,0-3.044,3.047,3.044,3.044,0,0,0,3.044-3.047"
            transform="translate(-356.414 -115.883)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <path
            id="Path_3552-2"
            data-name="Path 3552"
            d="M84.278,120.948A3.045,3.045,0,1,0,81.232,124a3.047,3.047,0,0,0,3.046-3.047"
            transform="translate(-78.188 -110.289)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_8-2"
            data-name="Line 8"
            x2="5.79"
            y2="5.613"
            transform="translate(41.728 2)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_9-2"
            data-name="Line 9"
            y2="4.373"
            transform="translate(29.3 7)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
          <line
            id="Line_10-2"
            data-name="Line 10"
            y1="8.592"
            x2="11.385"
            transform="translate(5.343)"
            fill="none"
            stroke={activeHover === "3" ? "#fff" : "#333"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="2.613"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <g
        id="_6673745_health_healthcare_hospital_medic_medical_icon"
        data-name="6673745_health_healthcare_hospital_medic_medical_icon"
        transform="translate(12.887 12.409)"
      >
        <circle
          id="Ellipse_150"
          data-name="Ellipse 150"
          cx="17.291"
          cy="17.291"
          r="17.291"
          transform="translate(0 0)"
          fill="none"
          stroke={activeHover === "3" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_3557"
          data-name="Path 3557"
          d="M34.532,35.532h5.5A1.271,1.271,0,0,0,41.3,34.266V30.037a1.271,1.271,0,0,0-1.267-1.271h-5.5v-5.5A1.271,1.271,0,0,0,33.266,22H29.037a1.271,1.271,0,0,0-1.271,1.271v5.5h-5.5A1.271,1.271,0,0,0,21,30.037v4.229a1.271,1.271,0,0,0,1.267,1.267h5.5v5.5A1.271,1.271,0,0,0,29.033,42.3h4.229a1.271,1.271,0,0,0,1.271-1.267Z"
          transform="translate(-13.858 -14.482)"
          fill="none"
          stroke={activeHover === "3" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);

export const BulkSms = ({ activeHover }: { activeHover?: string | null }) => (
  <svg
    width="26px"
    height="35px"
    viewBox="0 0 26 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.4819 6.20694C25.4819 5.66511 25.2182 5.12327 24.9544 4.7169C24.4269 3.63323 23.5038 3.0914 22.317 3.0914C18.3608 3.0914 14.5365 3.0914 10.5803 3.0914C9.78911 3.0914 8.99787 3.49777 8.47038 4.03961C7.81102 4.7169 7.41541 5.80056 7.41541 6.74877C7.41541 9.32248 7.41541 12.0316 7.41541 14.6053C7.41541 15.0117 7.41541 15.4181 7.54728 15.689C7.94289 17.0436 8.60226 17.8563 10.0529 18.2627C10.1847 18.2627 10.1847 18.3982 10.1847 18.3982V19.2109C10.1847 19.6173 10.1847 20.0237 10.1847 20.43C10.1847 20.8364 10.4485 21.1073 10.8441 21.2428C11.2397 21.3783 11.5034 21.2428 11.7672 20.9719C12.4266 20.1591 13.2178 19.3464 13.8772 18.5336C14.009 18.3982 14.009 18.3982 14.1409 18.3982C16.7783 18.3982 19.5477 18.3982 22.317 18.3982C22.5807 18.3982 22.7126 18.3982 22.9763 18.3982C24.1632 18.1273 24.9544 17.3145 25.3501 16.2308C25.4819 15.8245 25.4819 15.5536 25.6138 15.1472L25.4819 6.20694C25.4819 6.3424 25.4819 6.3424 25.4819 6.20694ZM23.7676 16.7727C23.372 17.179 22.8445 17.3145 22.317 17.3145H13.8772C13.6134 17.3145 13.4815 17.45 13.3497 17.5854C12.6903 18.3982 11.8991 19.2109 11.2397 20.1591L11.1078 20.2946C11.1078 20.1591 11.1078 20.1591 11.1078 20.0237V17.9918C11.1078 17.5854 10.976 17.45 10.5803 17.3145C9.65724 17.179 9.12975 16.7727 8.73413 15.9599C8.47038 15.5536 8.47038 15.1472 8.47038 14.6053V6.74877C8.47038 5.93602 8.73413 5.25873 9.26162 4.7169C9.65724 4.31052 10.1847 4.03961 10.7122 4.03961H22.317C23.2401 4.03961 24.0313 4.7169 24.4269 5.66511C24.5588 6.07148 24.6907 6.47785 24.5588 6.88423C24.5588 9.45793 24.5588 12.0316 24.5588 14.6053C24.5588 15.4181 24.2951 16.2308 23.7676 16.7727Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M17.7015 19.7528C17.5696 19.6173 17.4377 19.4818 17.3059 19.4818C17.0421 19.4818 16.9102 19.6173 16.9102 19.7528C16.9102 19.8882 16.9102 20.0237 16.9102 20.0237V29.5057H1.61305V6.20694H6.49233C6.75607 6.20694 6.88795 6.20694 7.01982 5.93602C7.15169 5.52965 7.01982 5.12328 6.49233 5.12328C4.90986 5.12328 3.45926 5.12328 1.87679 5.12328H1.61305C1.61305 4.85236 1.61305 4.58144 1.61305 4.31053C1.61305 4.17507 1.61305 3.90415 1.74492 3.76869C2.00867 2.95594 2.66803 2.00774 3.85488 2.00774H14.5365C14.8003 2.00774 14.9322 1.87228 15.064 1.60136C15.1959 1.19499 14.9322 0.924072 14.5365 0.924072H3.85488C3.32739 0.924072 2.7999 1.05953 2.40428 1.33045C1.21743 2.00774 0.689941 3.22686 0.689941 4.58144C0.689941 13.3862 0.689941 22.3265 0.689941 31.2667C0.689941 31.944 0.821814 32.6213 1.08556 33.1631C1.74492 34.2468 2.53616 34.9241 3.85488 34.9241C7.41543 34.9241 10.976 34.9241 14.6684 34.9241C15.1959 34.9241 15.7234 34.7886 16.119 34.5177C17.3059 33.7049 17.8334 32.6213 17.8334 31.2667C17.8334 27.6093 17.8334 23.8165 17.8334 20.1591C17.8334 20.0237 17.8334 19.8882 17.7015 19.7528ZM15.1959 33.7049C14.9322 33.8404 14.8003 33.8404 14.5365 33.8404C10.976 33.8404 7.41543 33.8404 3.85488 33.8404C2.7999 33.8404 2.14054 33.2986 1.74492 32.4858C1.48118 31.944 1.48118 31.2667 1.48118 30.7249H16.7784C17.0421 32.0794 16.3828 33.2986 15.1959 33.7049Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M7.01979 3.63323C7.01979 3.90415 6.88791 4.17507 6.62417 4.17507C6.22855 4.17507 6.09668 3.90415 6.09668 3.63323C6.09668 3.36232 6.36042 3.0914 6.4923 3.0914C6.75604 3.0914 7.01979 3.22686 7.01979 3.63323Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M22.7126 6.8842C22.5807 7.15512 22.4489 7.29057 22.1851 7.29057H16.3827C14.4046 7.29057 12.5584 7.29057 10.5803 7.29057C10.1847 7.29057 9.92099 6.8842 10.0529 6.61328C10.0529 6.34237 10.3166 6.20691 10.4485 6.20691H22.0532C22.5807 6.20691 22.8445 6.61328 22.7126 6.8842Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M22.7126 14.8763C22.5807 15.1472 22.4489 15.2826 22.1851 15.2826H16.3827C14.4046 15.2826 12.5584 15.2826 10.5803 15.2826C10.1847 15.2826 9.92099 14.8763 10.0529 14.4699C10.1847 14.3344 10.3166 14.199 10.4485 14.199H11.5035C15.064 14.199 18.4927 14.199 21.9214 14.199C22.5807 14.199 22.8445 14.6053 22.7126 14.8763Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M22.5807 9.86428C22.4489 9.99974 22.317 9.99974 22.1851 9.99974C20.9983 9.99974 19.8114 9.99974 18.6246 9.99974H15.1959C14.8003 9.99974 14.6684 9.72883 14.6684 9.45791C14.6684 9.05153 14.8003 8.91608 15.1959 8.91608H22.317C22.5807 8.91608 22.7126 9.05153 22.8445 9.18699C22.8445 9.45791 22.7126 9.72883 22.5807 9.86428Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M18.0971 12.438C17.9652 12.5735 17.8334 12.7089 17.7015 12.7089H10.7122C10.3166 12.7089 10.1848 12.438 10.1848 12.1671C10.1848 11.7607 10.4485 11.6252 10.7122 11.6252H17.7015C17.9652 11.6252 18.0971 11.7607 18.229 11.8962C18.229 12.0316 18.229 12.1671 18.0971 12.438Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M13.7453 9.45791C13.7453 9.72883 13.4815 9.99974 13.2178 9.99974H10.5803C10.3166 9.99974 10.0529 9.72883 10.0529 9.45791C10.0529 9.18699 10.3166 8.91608 10.5803 8.91608H13.2178C13.6134 8.91608 13.7453 9.05153 13.7453 9.45791Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M22.7127 12.0316C22.7127 12.3026 22.4489 12.5735 22.1852 12.5735H19.5478C19.284 12.5735 19.0203 12.3026 19.0203 12.0316C19.0203 11.7607 19.1521 11.4898 19.5478 11.4898H22.1852C22.5808 11.4898 22.7127 11.7607 22.7127 12.0316Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
    <path
      d="M10.5804 32.2149C10.5804 32.4858 10.4485 32.7568 10.0529 32.7568H8.33855C8.0748 32.7568 7.94293 32.4858 7.94293 32.2149C7.94293 31.944 8.0748 31.6731 8.47042 31.6731H10.1848C10.4485 31.6731 10.5804 31.944 10.5804 32.2149Z"
      fill={activeHover === "5" ? "#fff" : "#333"}
    />
  </svg>
);

export const HrErp = ({ activeHover }: { activeHover?: string | null }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35px"
    height="35px"
    viewBox="0 0 64.244 65.165"
  >
    <g id="Group_1453" data-name="Group 1453" transform="translate(1.348)">
      <g
        id="Rectangle_212"
        data-name="Rectangle 212"
        transform="translate(30.896 35.165)"
        fill="none"
        stroke={activeHover === "4" ? "#fff" : "#333"}
        strokeWidth="1.5"
      >
        <rect width="32" height="30" rx="6" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="30.5"
          height="28.5"
          rx="5.25"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_213"
        data-name="Rectangle 213"
        transform="translate(30.896 35.165)"
        fill="none"
        stroke={activeHover === "4" ? "#fff" : "#333"}
        strokeWidth="2"
      >
        <rect width="32" height="13" rx="6" stroke="none" />
        <rect x="1" y="1" width="30" height="11" rx="5" fill="none" />
      </g>
      <g id="about" transform="translate(3.536)">
        <path
          id="Path_3560"
          data-name="Path 3560"
          d="M16.931,17.862A7.931,7.931,0,1,0,9,9.931a7.931,7.931,0,0,0,7.931,7.931Zm0-13.6a5.665,5.665,0,1,1-5.665,5.665A5.665,5.665,0,0,1,16.931,4.266Z"
          transform="translate(-3.335 -2)"
          fill={activeHover === "4" ? "#fff" : "#333"}
        />
        <path
          id="Path_3561"
          data-name="Path 3561"
          d="M18.729,18H16.463A12.463,12.463,0,0,0,4,30.463,1.133,1.133,0,0,0,5.133,31.6H30.059a1.133,1.133,0,0,0,1.133-1.133A12.463,12.463,0,0,0,18.729,18ZM6.334,29.33a10.2,10.2,0,0,1,10.129-9.064h2.266A10.2,10.2,0,0,1,28.858,29.33Z"
          transform="translate(-4 0.128)"
          fill={activeHover === "4" ? "#fff" : "#333"}
        />
      </g>
      <path
        id="Path_3562"
        data-name="Path 3562"
        d="M-2111.11,1497.575s16.167,2.37,19.194,16.782"
        transform="translate(2143.815 -1486.748)"
        fill="none"
        stroke={activeHover === "4" ? "#fff" : "#333"}
        strokeWidth="1.5"
      />
      <path
        id="Path_3563"
        data-name="Path 3563"
        d="M0,16.782S16.167,14.412,19.194,0"
        transform="matrix(0.174, 0.985, -0.985, 0.174, 23.729, 36.578)"
        fill="none"
        stroke={activeHover === "4" ? "#fff" : "#333"}
        strokeWidth="1.5"
      />
      <g
        id="Group_1451"
        data-name="Group 1451"
        transform="matrix(0.309, 0.951, -0.951, 0.309, 56.412, 18.934)"
      >
        <path
          id="path995-1"
          d="M7.167,0,0,7.012"
          transform="translate(0 7.013)"
          fill="none"
          stroke={activeHover === "4" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="path997-1"
          d="M7.167,7.012,0,0"
          fill="none"
          stroke={activeHover === "4" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <g
        id="Group_1452"
        data-name="Group 1452"
        transform="matrix(-0.326, -0.946, 0.946, -0.326, 2.333, 47.92)"
      >
        <path
          id="path995-1-2"
          data-name="path995-1"
          d="M7.167,7.012,0,0"
          fill="none"
          stroke={activeHover === "4" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="path997-1-2"
          data-name="path997-1"
          d="M7.167,0,0,7.012"
          transform="translate(0 7.013)"
          fill="none"
          stroke={activeHover === "4" ? "#fff" : "#333"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);
