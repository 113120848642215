import { useContext } from "react";
import development from "../../Assets/coverImage/development.png";
import { SoftwareDevelopmentVector } from "../../Assets/DevelopmentVector";
import { BR, ParagraphText, Title } from "../../Styles/StyleComponent";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { CoverImage } from "../helper/CoverImage";
import { ServiceContainer } from "./ServiceContainer";
import { GlobalData } from "../../contextapi/ContextProvider";
import { Col, Row } from "react-bootstrap";
import { Fade, Rotate } from "react-reveal";

export const SoftwareDevelopment = () => {
  
  let {
    allpagesCollection
    } = useContext(GlobalData);

  const swDevelopment = {
    title: allpagesCollection.service_1_title,
    image: allpagesCollection.service_1_image,

    description: (
      <ParagraphText color="#000">

<p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_1_description }} ></p>
        </ParagraphText>
    ),
    bottom_description : "",
    feature_title : "We offer customizable system flexibility and tailored features to align precisely with your unique business needs and objectives.",
    features:
      "Customized Solutions for Your Needs,Cutting-Edge Technology,Agile Development Methodology,Robust Security Measures,Dedicated Customer Support",
  };

  return (
    <>
      {/* <CoverImage
        image={development}
        path="software_development"
        type="services"
      /> */}

<BackgroundContainer title={'Software Development'} description={'You can contact us at any time for support. We value our customers more than anything & put our best to provide you with the best quality support.'} > 


      <ServiceContainer services={swDevelopment} title={'Software Development'} subdescription={`Personalized solutions to fulfill your company's requirements. Our skilled development team produces scalable, high-quality software that easily fits your goals and boosts output and efficiency. `}  />
      </BackgroundContainer>
    </>
  );
};
