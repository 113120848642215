import development from "../../Assets/coverImage/development.png";
import { BR, ParagraphText } from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { HostingWithDollarVector } from "../../Assets/ServicesIcon";
import { ServiceContainer } from "./ServiceContainer";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const DomainWebHosting = () => {
  let {
    allpagesCollection
    } = useContext(GlobalData);

  const dwHosting = {
    title: allpagesCollection.service_5_title,
    image: allpagesCollection.service_5_image,
    // svg: <HostingWithDollarVector />,

    description: (
      <ParagraphText color="#000">
 <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_5_description }} ></p>

      </ParagraphText>
    ),
    type: "We Provides",
    feature_title : "Key Attributes of Our Domain Registration Services for Enhanced Online Visibility",
    features:
      "Domain Availability,Domain Extensions,Domain Management,Privacy Protection,Auto-Renewal",
  };
  return (
    <>
      {/* <CoverImage
        image={development}
        path="domain_registration_web_hosting"
        type="services"
      /> */}

<BackgroundContainer title={''} description={''} > 

      <ServiceContainer services={dwHosting} title={'Domain registration & Web hosting'}  subdescription={`Easily establish your online persona. We assist you in obtaining a memorable web address by providing domain registration services.`} />

      </BackgroundContainer>
    </>
  );
};
