import development from "../../Assets/coverImage/development.png";
import { BR, ParagraphText } from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { ManAppDevVector } from "../../Assets/ServicesIcon";
import { ServiceContainer } from "./ServiceContainer";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const AppDevelopment = () => {
  let { allpagesCollection } = useContext(GlobalData);

  const mAppDevelopment = {
    title:  allpagesCollection.service_2_title,
    image: allpagesCollection.service_2_image,

    description: (
      <ParagraphText color="#000">
        <p dangerouslySetInnerHTML={{ __html: allpagesCollection.service_2_description }} ></p>

      </ParagraphText>
    ),
    type: "Features",
    feature_title : "Essential Features for Effective Mobile App Development",
    features:
      "Custom Mobile App Development,Cross-Platform Compatibility,User-Centric Design,Scalability and Performance,Security and Data Protection,Integration and Third-Party APIs,Agile Development Methodology",
  };
  return (
    <>
            <BackgroundContainer title={''} description={''} > 

      <ServiceContainer services={mAppDevelopment} title={'Mobile app development'} subdescription={`Turn your ideas into effective mobile applications with mobile app development. Our specialty is creating cutting-edge, intuitive apps for the iOS and Android operating systems. `} />

      </BackgroundContainer>
    </>
  );
};
