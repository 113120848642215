import { Col, Container, Row } from "react-bootstrap";
import { SocialIcons } from "../../Styles/StyleComponent";
import { Instagram, Twitter, Facebook } from "../../Assets/SocialMediaIcon";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import { Logo } from "../Navbar/Icon/Icon";
import {
  ContactInfoIcon,
  FooterLink,
  FooterTitle,
  LocationIcon,
} from "./Style";
import { GlobalData } from "../../contextapi/ContextProvider";
import { useContext } from "react";

export default function Footer() {
  let { settingsCollection, mobile } = useContext(GlobalData);

  return (
    <>
      <footer style={{ background: "#0082C6" }}>
        <div className="pt-md-5 pt-4 pb-4">
          <Container>
            <div className="footer-info  ">
              <div className="row align-items-center">
                <Col xxl={6} md={4} className="mb-md-auto mb-3">
                  <div className="footer-logo">
                    <Logo />
                  </div>
                </Col>
                <Col xxl={3} md={4} sm={6} className="mb-md-auto mb-3">
                  <div className="d-flex  align-items-center">
                    <div className="me-2">
                      <a href={`mailto:${settingsCollection.email}`}>
                        <ContactInfoIcon />
                      </a>
                    </div>
                    <div className="icon-content">
                      <p className="m-0">
                        {settingsCollection.contact_number1},{" "}
                        {settingsCollection.contact_number2}
                        <br />
                        {settingsCollection.email || "info@dynamic.net.np"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} md={4} sm={6} className="mb-md-auto mb-3">
                  <div className="d-flex">
                    <div className="me-2">
                      <a
                        href="https://maps.app.goo.gl/xNVMyFMJJAFusS4K7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LocationIcon />
                      </a>
                    </div>
                    <div className="icon-content">
                      <p className="m-0">
                        {settingsCollection.address ||
                          "B & S Complex, Shankhamul Marga, Kathmandu"}
                      </p>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <Row className="gy-4">
              <Col xl={3} lg={4} sm={6}>
                <div>
                  <FooterTitle>About Us</FooterTitle>

                  <p>
                    Dynamic Technosoft is a recognized leader in the field of IT
                    service providers, providing comprehensive software
                    development and web products for more than a decade. Our
                    years of experience help us develop digital innovations to
                    deliver business outcomes, new levels of performance and
                    competitiveness for our customers and their stakeholders.
                  </p>
                  <div className="d-flex mt-4">
                    <SocialIcons
                      background="#fff"
                      onClick={() =>
                        window.open(
                          `${
                            settingsCollection?.instagram_link ||
                            "https://www.instagram.com/dynamic_technosoft/?hl=en"
                          }`,
                          "_blank"
                        )
                      }
                    >
                      <Instagram color="#0082C6" />
                    </SocialIcons>
                    <SocialIcons
                      background="#fff"
                      className="mx-3"
                      onClick={() =>
                        window.open(
                          `${
                            settingsCollection?.twitter_link ||
                            "https://twitter.com/mydynamicerp"
                          }`,
                          "_blank"
                        )
                      }
                    >
                      <Twitter color="#0082C6" />
                    </SocialIcons>
                    <SocialIcons
                      background="#fff"
                      onClick={() =>
                        window.open(
                          `${
                            settingsCollection?.facebook_link ||
                            `https://www.facebook.com/mydynamicerp`
                          }`,
                          "_blank"
                        )
                      }
                    >
                      <Facebook color="#0082C6" />
                    </SocialIcons>
                  </div>
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6} className="ps-lg-5 ps-sm-4">
                <div>
                  <FooterTitle>Our links</FooterTitle>

                  <ul>
                    <FooterLinks path="/" title="Home" />
                    <FooterLinks
                      path="/about-us/our-company"
                      title="About Us"
                    />
                    <FooterLinks path="/our-client" title="Our Client" />
                    <FooterLinks path="/about-us/our-team" title="Team" />
                    <FooterLinks path="/blogs" title="Blogs" />
                  </ul>
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6}>
                <div>
                  <FooterTitle>Our Services</FooterTitle>
                  <ul>
                    <FooterLinks
                      path="/services/software-development"
                      title="Software Development"
                    />
                    <FooterLinks
                      path="/services/mobile-app-development"
                      title="Mobile App Development"
                    />
                    <FooterLinks
                      path="/services/web-application-development"
                      title="Web Application Development"
                    />
                    <FooterLinks
                      path="/services/bulk-sms-service"
                      title="Bulk SMS Service"
                    />
                    <FooterLinks
                      path="/services/domain-registration-web-hosting"
                      title="Domain Registration & Web Hosting"
                    />
                  </ul>
                </div>
              </Col>
              <Col xl={3} lg={3} sm={6} className="ps-xl-5 ps-md-4">
                <div>
                  <FooterTitle>Other links</FooterTitle>

                  <ul>
                    <FooterLinks path="/contact-us" title="Contact Us" />
                    <FooterLinks path="/careers" title="Careers" />

                    <FooterLinks path="/portfolio" title="Portfolio" />
                    <FooterLinks
                      path="/privacy-policy"
                      title="Privacy Policy"
                    />
                    <FooterLinks
                      path="/terms-of-service"
                      title="Terms & Conditions"
                    />
                    <FooterLinks
                      path="/delete-account"
                      title="Delete Account"
                    />
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="footer-bottom">
          <Container>
            <Row>
              <Col sm className="text-center">
                <span className="copyright-text">
                  {settingsCollection.copyright ||
                    `Copyright © <strong>Dynamic Technosoft</strong>. All rights
                  reserved.`}
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}

export const FooterLinks = ({
  path,
  title,
}: {
  path: string;
  title: string;
}) => {
  const navTo = useNavigate();
  const navigateTo = (path: string) => {
    navTo(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <li onClick={() => navigateTo(`${path}`)}>
      <FooterLink>{title}</FooterLink>
    </li>
  );
};
