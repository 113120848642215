import { createContext, useEffect, useState } from "react";
import {
  achievementGet,
  allpagesGet,
  blogsGet,
  clientGet,
  mediaCoverageGet,
  mediaGet,
  settingsGet,
  sliderGet,
  testmonialGet,
} from "../Api/serviceapi";

export const GlobalData = createContext();
const ContextProvider = ({ children }) => {
  const [sliderCollection, setsliderCollection] = useState([]);
  const [blogsCollection, setblogsCollection] = useState([]);
  const [mediaCollection, setmediaCollection] = useState([]);
  const [mediaCoverageCollection, setmediaCoverageCollection] = useState([]);
  const [allpagesCollection, setallpagesCollection] = useState([]);
  const [clientCollection, setclientCollection] = useState([]);
  const [settingsCollection, setsettingsCollection] = useState([]);

  const [testmonialCollection, settestmonialCollection] = useState([]);
  const [achievementCollection, setachievementCollection] = useState([]);

  let getApi = async () => {
    let res = await sliderGet();
    res?.status && setsliderCollection(res.data);

    let res1 = await blogsGet();
    res1?.status && setblogsCollection(res1.data);

    let res2 = await mediaGet();
    res2?.status && setmediaCollection(res2.data);

    let res3 = await allpagesGet();
    res3?.status && setallpagesCollection(res3.data);

    let res4 = await clientGet();
    res4?.status && setclientCollection(res4.data);

    let res5 = await settingsGet();
    res5?.status && setsettingsCollection(res5.data);

    let res6 = await testmonialGet();
    res6?.status && settestmonialCollection(res6.data);

    let res7 = await achievementGet();
    res7?.status && setachievementCollection(res7.data);

    let res8 = await mediaCoverageGet();
    res8?.status && setmediaCoverageCollection(res8.data);
  };

  useEffect(() => {
    getApi();
  }, []);

  const [mobile, setmobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setmobile(window.innerWidth >= 768 ? false : true);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [mobilelg, setmobilelg] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setmobilelg(window.innerWidth >= 1000 ? false : true);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [mobilexl, setmobilexl] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setmobilexl(window.innerWidth >= 1400 ? false : true);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GlobalData.Provider
      value={{
        sliderCollection,
        blogsCollection,
        mediaCollection,
        allpagesCollection,
        clientCollection,
        settingsCollection,
        testmonialCollection,
        achievementCollection,
        mediaCoverageCollection,
        mobile,
        mobilelg,
        mobilexl,
      }}
    >
      {children}
    </GlobalData.Provider>
  );
};

export default ContextProvider;
