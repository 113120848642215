import { CoverImage } from "../helper/CoverImage";
import constactUs from "../../Assets/coverImage/contactUs.png";
import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import { useForm } from "react-hook-form";
import { ManWithMail } from "../../Assets/VectorImage";
import { Fade } from "react-reveal";
import Shake from "react-reveal/Shake";
import {
  ErrorText,
  Input,
  TextArea,
  SubTitle,
  Title,
  Button,
  ParagraphText,
  LocationDetailBox,
  SubTitleText,
  TitleText,
} from "../../Styles/StyleComponent";
import { send, init } from "emailjs-com";
import { useEffect, useState } from "react";
import { MailIcon, PhoneIcon } from "../Navbar/Icon/Icon";
import { LocationIcon } from "../../Assets/SocialMediaIcon";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { leadsPost } from "../../Api/serviceapi";
import { falseNotify, trueNotify } from "../../App";
//email sender
const serviceId = "service_2eiw1gq";
const templateId = "template_ug3koic";
const userID = "vy4zdjZNS-DSRvFEI";

export const sendEmail = ({ content, setLoading, setSent }: any) => {
  init(userID);
  setLoading(true);
  const toSend = {
    sender: content.email,
    senderName: content.organizationName,
    receiver: "Dynamic Academic ERP",
    subject: content.subject,
    email: "info@dynamic.net.np",
    message: content.message,
    phoneNumber: content.phoneNumber,
  };
  send(serviceId, templateId, toSend)
    .then((res: any) => {
      setSent(true);
      setLoading(false);
    })
    .catch((err: any) => {
      setLoading(false);
    });
};

export const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (sent) {
      reset({
        email: "",
        organizationName: "",
        subject: "",
        message: "",
        phoneNumber: "",
      });
      let timer = setTimeout(() => setSent(false), 30 * 100);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [sent, reset]);

  const onSubmit = (content: any) => {
    sendEmail({ content, setLoading, setSent });
  };





  /* api by rahul sharma */
  const [postData,setpostData] = useState({
    full_name : "",
    email : "",
    contact_number : "",
    subject : "",
    description : ""
  });
  const handelChange = (e:any)=>{
      let name = e.target.name;
      let value = e.target.value;
      setpostData({...postData , [name]:value})
  }

  let postApi = async (e:any) =>{
    e.preventDefault();
    let res = await leadsPost(postData);
    if(res.status===true){
      trueNotify('contact form submitted.');
      setpostData({
        full_name : "",
        email : "",
        contact_number : "",
        subject : "",
        description : ""
      });
    }
    else{
      falseNotify('contact form not submitted.');
    }
   
  }


  return (
    <>
      <BackgroundContainer title={'Contact Us'} description={'You can contact us at any time for support. We value our customers more than anything & put our best to provide you with the best quality support.'} > 

      {sent && (
        <div
          style={{
            background: "#097A57",
            position: "fixed",
            top: "70px",
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: "0px",
            zIndex: "999",
          }}
        >
          <ParagraphText color="#fff">
            Contact message sent to info@dynamic.net.np team
          </ParagraphText>
        </div>
      )}
      <Container className="my-5">
   
      <Col xs={12}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Contact Us</span></Title>
           <p>You can contact us at any time for support. We
value our customers more than anything & put
our best to provide you with the best quality support.</p>
      </Fade>
      </Col>
      </Row>
    </Col>
        <Row className="m-0 mt-5 g-3">
          <Col>
            <ManWithMail />
          </Col>
          <Col lg={6}>
          <Fade up delay={100}>
            <Title> {strings.getString("ready_to_get_started")}</Title>
          </Fade>
            <form onSubmit={postApi} className="mt-3">
              <Row className="g-3">
                <Col>
                  <Fade up delay={100}>
                    <Input
                    name="full_name"
                    value={postData.full_name}
                    onChange={handelChange}
                      placeholder="Organization Name"
                    />
                  </Fade>
                  {errors?.organizationName &&
                    errors?.organizationName?.message && (
                      <Shake>
                        <ErrorText>
                          {errors?.organizationName?.message}
                        </ErrorText>
                      </Shake>
                    )}
                </Col>
                <Col>
                  <Fade up delay={100}>
                    <Input
                      placeholder="Email"
                      name="email"
                    value={postData.email}
                      onChange={handelChange}
                    />
                  </Fade>

                  {errors?.email && errors?.email?.message && (
                    <Shake>
                      <ErrorText>{errors?.email?.message}</ErrorText>
                    </Shake>
                  )}
                </Col>
              </Row>

              <Row className="g-3 mt-0">
                <Col>
                  <Fade up delay={200}>
                    <Input
                      placeholder="Phone Number"
                      name="contact_number"
                      value={postData.contact_number}
                      onChange={handelChange}
                     
                    />
                  </Fade>
                  {errors?.phoneNumber && errors?.phoneNumber?.message && (
                    <Shake>
                      {" "}
                      <ErrorText>{errors?.phoneNumber?.message}</ErrorText>
                    </Shake>
                  )}
                </Col>
                <Col>
                  <Fade up delay={200}>
                    <Input
                      placeholder="Subject"
                      name="subject"
                      value={postData.subject}
                      onChange={handelChange}
                    />
                  </Fade>

                  {errors?.subject && errors?.subject?.message && (
                    <Shake>
                      <ErrorText>{errors?.subject?.message}</ErrorText>
                    </Shake>
                  )}
                </Col>
              </Row>
              <Fade up delay={300}>
                <TextArea
                  className="mt-3 mb-2"
                  placeholder="Message"
                  name="description"
                  value={postData.description}
                      onChange={handelChange}
                  rows={5}
                />
              </Fade>
              <Fade up delay={350}>
                <Button
                  className="m-t10"
                  background="#0082C6"
                  padding="6px 0px"
                  style={{
                    width: "140px",
                    boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                    borderRadius: "16px",
                  }}
                  type="submit"
                >
                  {loading ? (
                    <div className="loading">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <ParagraphText color="#fff">Send</ParagraphText>
                  )}
                </Button>
              </Fade>
            </form>
          </Col>
        </Row>
        <div className="mt-5">
          <div className="mb-4">
            <Title>{strings.getString("our_location")}</Title>
          </div>
          <Row className="gy-4  justify-content-center align-items-center ">
            <Col
              xl={4} md={3} sm={6}
              className=" justify-content-center"
             
            >
              <LocationDetailBox>
                <SubTitleText>{strings.getString("head_office")}</SubTitleText>
                <div>
                  <p>
                    <PhoneIcon /> 051-526647/48 ,9855021231
                  </p>
                  <p>
                    <MailIcon /> info@dynamic.net.np
                  </p>
                  <p>
                    <LocationIcon color="rgb(51, 51, 51)" /> Beside Trimurti
                    Cinema Birgunj, Nepal
                  </p>
                </div>
              </LocationDetailBox>
            </Col>
            <Col
              xl={4} md={3} sm={6}
              className=" justify-content-center"
             
            >
              <LocationDetailBox>
                <SubTitleText>
                  {strings.getString("corporate_office")}
                </SubTitleText>
                <div>
                  <p>
                    <PhoneIcon /> 9801087091 ,9851163652
                  </p>
                  <p>
                    <MailIcon /> info@dynamic.net.np
                  </p>
                  <p>
                    <LocationIcon color="rgb(51, 51, 51)" /> B & S Complex, Shankhamul Marga, Kathmandu
                  </p>
                </div>
              </LocationDetailBox>
            </Col>
            <Col
              xl={4} md={3} sm={6}
              className=" justify-content-center"
             
            >
              <LocationDetailBox>
                <SubTitleText>
                  {strings.getString("butwal_branch")}
                </SubTitleText>
                <div>
                  <p>
                    <PhoneIcon /> 9857030577
                  </p>
                  <p>
                    <MailIcon /> info@dynamic.net.np
                  </p>
                  <p>
                    <LocationIcon color="rgb(51, 51, 51)" /> Butwal, Traffic
                    Chowk, Nepal
                  </p>
                </div>
              </LocationDetailBox>

              </Col>
              <Col
              xl={4} md={3} sm={6}
              className=" justify-content-center"
             
            >
              <LocationDetailBox>
                <SubTitleText>
                Itahari Branch
                </SubTitleText>
                <div>
                  <p>
                    <PhoneIcon /> +977-9801050626
                  </p>
                  <p>
                    <MailIcon /> info@dynamic.net.np
                  </p>
                  <p>
                    <LocationIcon color="rgb(51, 51, 51)" /> Dharan Line,Opposite of Nepal Army Camp Itahari-4,Sunsari,Nepal
                  </p>
                </div>
              </LocationDetailBox>

              </Col>
              <Col
              xl={4} md={3} sm={6}
              className=" justify-content-center"
             
            >
              <LocationDetailBox>
                <SubTitleText>
                India branch
                </SubTitleText>
                <div>
                  <p>
                    <PhoneIcon /> +91 85810 04142
                  </p>
                  <p>
                    <MailIcon /> info@dynamic.net.np
                  </p>
                  <p>
                    <LocationIcon color="rgb(51, 51, 51)" /> Maharaja Enclave, Kaliket Nagar, Patna, Bihar
                  </p>
                </div>
              </LocationDetailBox>


            </Col>
          </Row>
        </div>
      </Container>


      </BackgroundContainer>
    </>
  );
};
