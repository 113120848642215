import React, { useRef } from "react";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import "./App.css";
import "./Styles/new.css";

import HomePage from "./Components/HomePage";
import { Navigate, Route, Routes } from "react-router-dom";
import { AboutCompany } from "./Components/aboutUs/AboutCompany";
import { DynamicGroup } from "./Components/aboutUs/DynamicGroup";
import { BlogsPage } from "./Components/Blogs/Blogs";
import { Team } from "./Components/aboutUs/OurTeam";
import { ContactUs } from "./Components/aboutUs/ContactUs";
import { OurClient } from "./Components/Clients/OurClient";
import { AcademicErp } from "./Components/Products/AcademicErp";
import { HospitalErp } from "./Components/Products/HospitalRep";
import { AccountingSolution } from "./Components/Products/AccountingSolution";
import { HrAndPayRoll } from "./Components/Products/HrAndPayRoll";
import { SmsSystem } from "./Components/Products/SmsSystem";
import { SoftwareDevelopment } from "./Components/Services/SoftwareDevelopment";
import { AppDevelopment } from "./Components/Services/AppDevelopment";
import { WebAppDevelopment } from "./Components/Services/WebAppDevelopment";
import { BulkSmsServices } from "./Components/Services/BulkSmsService";
import { DomainWebHosting } from "./Components/Services/DomainWebHosting";
import { BlogDetail } from "./Components/Blogs/BlogDetail";
import { TermsAndCondition } from "./Components/TermsAndConditions";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";
import { PortfolioPages } from "./Components/Portfolio/PortFolioPage";
import { Careers } from "./Components/career/Career";
import { Media } from "./Components/resources/Media";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextProvider from "./contextapi/ContextProvider";
import HelmetTag from "./utils/HelmetTag";

import { MediaCoverage } from "./Components/mediacoverage/MediaCoverage";
import { Testimonial } from "./Components/testimonial/Testimonial";
import ScrollToTop from "./utils/ScrollToTop";
import { DynamicAsm } from "./Components/Products/DynamicAsm";
import Dealership from "./Components/resources/Dealership";
import AccountDelete from "./Components/DeleteAccount/AccountDelete";

export const trueNotify = (props: any) => {
  toast.dismiss();
  toast.success(props);
};
export const falseNotify = (props: any) => {
  toast.dismiss();
  toast.warning(props);
};
export const useScroll = () => {
  const elRef = useRef(null) as React.MutableRefObject<null | any>;
  const currentRef = elRef.current.scrollIntoView();
  return [currentRef, elRef];
};

function App() {
  return (
    <div>
      <ContextProvider>
        <div className="App"></div>
        <ScrollToTop />
        <HelmetTag />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us/our-team" element={<Team />} />
          <Route path="/about-us/dynamic-group" element={<DynamicGroup />} />
          <Route path="/about-us/our-company" element={<AboutCompany />} />

          <Route path="/portfolio" element={<PortfolioPages />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/our-client" element={<OurClient />} />
          <Route path="/terms-of-service" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/products/academic-erp" element={<AcademicErp />} />
          <Route path="/products/hospital-erp" element={<HospitalErp />} />
          <Route
            path="/products/accounting-solution"
            element={<AccountingSolution />}
          />
          <Route
            path="/products/hr-and-payroll-management"
            element={<HrAndPayRoll />}
          />
          <Route path="/products/web-sms" element={<SmsSystem />} />
          <Route path="/products/dynamic-ams" element={<DynamicAsm />} />

          <Route
            path="/services/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route
            path="/services/mobile-app-development"
            element={<AppDevelopment />}
          />
          <Route
            path="/services/web-application-development"
            element={<WebAppDevelopment />}
          />
          <Route
            path="/services/bulk-sms-service"
            element={<BulkSmsServices />}
          />
          <Route
            path="/services/domain-registration-web-hosting"
            element={<DomainWebHosting />}
          />

          <Route path="/resources/blogs" element={<Media />} />
          <Route path="/resources/tutorials" element={<BlogsPage />} />
          <Route
            path="/resources/blogs/:blogId/:title"
            element={<BlogDetail />}
          />

          <Route path="/resources/testimonials" element={<Testimonial />} />
          <Route path="/resources/media-coverage" element={<MediaCoverage />} />
          <Route path="/resources/dealership" element={<Dealership />} />
          <Route path="/delete-account" element={<AccountDelete />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer />

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
          className={"rounded-0"}
          style={{ minWidth: "500px", borderRadius: "2px" }}
        />
      </ContextProvider>
    </div>
  );
}

export default App;
