import { Col, Container, Row } from "react-bootstrap";
import { strings } from "../../Api/Localization";
import academicErp from "../../Assets/coverImage/academicErp.png";
import sms from "../../Assets/products/sms.png";
import { Fade } from "react-reveal";
import {
  BR,
  ContainerTitle,
  ParagraphText,
  SubTitle,
  Title,
  Title40,
} from "../../Styles/StyleComponent";
import { CoverImage } from "../helper/CoverImage";
import { ArrowBoxLeft, YellowWave } from "./AcademicErp";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { GlobalData } from "../../contextapi/ContextProvider";
import { useContext } from "react";
import { ProductOddItems } from "./ProductHelper";

export const SmsSystem = () => {

  let {
    allpagesCollection,mobile
    } = useContext(GlobalData);


    
    const academicProducts = {
      sms: {
        features: [
          "Enhanced_Customer_Service",
          "Impressive_Conversion_Rates",
          "Timely_Updates",
          "Cost_Effective",
          "Customer_Relationship_Building",
          "Competitive_Edge",
        ],
      }
    }
    const newModules = [
      {
        id: 0,
        icon : sms,
        title : "Key Benefits of Our Bulk SMS System",
        para : strings.getString("bulk_sms_service_description"),
        products: academicProducts.sms
      }

    ]


  return (
    <>
      {/* <CoverImage image={academicErp} path="web_sms_system" type="products" /> */}

      <BackgroundContainer title={allpagesCollection.product_5_title || strings.getString("web_sms_system")} description={''} > 



      <Container className="my-5 ">
      <Row className="py-4">
        
      <Col xs={12} style={{marginBottom : mobile ? 20: 60}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>Instantaneous Conversation, Limitless Opportunities </span></Title>
           <p>Use our Web SMS System to transform your communication approach.</p>
      </Fade>
      </Col>
      </Row>
    </Col>

          <Col lg={8} className="bg-grey order-2 order-sm-1">
          <Fade up delay={200}>
           <Title40 > <span style={{color:"#233d63"}}>{allpagesCollection.product_5_title}</span></Title40>
      </Fade>
          <Fade up delay={200}>   <ParagraphText color="#000">

          <div className="blogParagraph" dangerouslySetInnerHTML={{ __html: allpagesCollection.product_5_description || '' }} ></div>

          </ParagraphText>
            </Fade>
          </Col>
          <Col
            lg={4}
            className=" d-flex justify-content-lg-end justify-content-center  order-1 order-sm-2"
          >
            <img src={allpagesCollection.product_5_image} alt="" className="img-fluid animatedUp" style={{zIndex: '2' , objectFit : 'contain'}} />
          </Col>
        </Row>
      </Container>

      <Container  className="mb-5">
        <Row>
        {newModules?.map((val,index)=>{
            return(<>

<Col xl={6} className="my-lg-3 my-3" key={index}>
              <div className="cardsServices-new">
                <h4 className="mt-2 border-bottom w-100 p-3"> <img src={val.icon} style={{height : '30px' , width : '30px'}} alt="" className="me-2" /> {val.title}</h4>
                <p className="px-3">{val.para}</p>
                <ProductOddItems products={val.products} />
               {/* {index%2 === 0 ? <ProductOddItems products={val.products} /> : <ProductEvenItems products={val.products} />} */}
              </div>
            </Col>


            </>)
          }) }
        </Row>
      </Container>

      {/* <div className="position-relative">
        <YellowWave />
        <Container className="my-4 ">
          <Row className=" m-0  g-3 ">
            <Col lg={4}>
              <div className="  d-flex " style={{ height: "300px" }}>
                <img
                  src={sms}
                  alt=""
                  width="290px"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </Col>
            <Col lg={8}>
              <div
                style={{
                  marginBottom: "24px",
                }}
              >
                <ContainerTitle>
                  {strings.getString("bulk_sms_service")}
                </ContainerTitle>

               
              </div>
              <div
                className="d-flex flex-wrap  "
                style={{ marginLeft: "25px" }}
              >
                <ArrowBoxLeft
                  firstText="flexible_system"
                  secondText="improve_the_level_of_professionalism"
                />
                <ArrowBoxLeft
                  firstText="high_roi_and_low_cost"
                  secondText="scheduling_customized_sms"
                />
                <ArrowBoxLeft firstText="customer_support" />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      </BackgroundContainer>
    </>
  );
};
