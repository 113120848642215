import React, { useState } from "react";
import {
  Button,
  Input,
  ParagraphText,
  TextArea,
  Title,
} from "../../Styles/StyleComponent";
import BackgroundContainer from "../../utils/BackgroundContainer";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import Shake from "react-reveal/Shake";

// Define the type for form data
interface FormData {
  username: string;
  password: string;
  reason: string;
}

// Define the type for validation errors
interface Errors {
  username?: string;
  password?: string;
}

const AccountDelete: React.FC = () => {
  // State to store form data and errors
  const [formData, setFormData] = useState<FormData>({
    username: "",
    password: "",
    reason: "",
  });

  const [errors, setErrors] = useState<Errors>({});

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the form
  const validateForm = (): Errors => {
    const newErrors: Errors = {};
    if (!formData.username.trim()) {
      newErrors.username = "Username is required.";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required.";
    }
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
    }
  };

  return (
    <div>
      <BackgroundContainer
        title={"Delete Account"}
        description={`Deleting your account is permanent. All your data will be wiped out immediately, and you won't be able to get it back.`}
      >
        <Container className="my-5">
          <Fade up delay={200}>
            <Title>
              {" "}
              <span style={{ color: "#233d63" }}>Delete Account</span>
            </Title>
            <hr
              style={{
                backgroundColor: "#233d63",
                color: "#fff",
                opacity: "0.25",
              }}
            />
            <p>
              Deleting your account is permanent.{" "}
              <span style={{}} className="mx-1 fw-bold">
                All your data will be wiped out immediately
                <br />
              </span>
              and you won't be able to get it back.
            </p>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label style={{ fontWeight: "bold" }}>Username:</label>
                    <Input
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                    {errors.username && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.username}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label style={{ fontWeight: "bold" }}>Password:</label>
                    <Input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.password}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label style={{ fontWeight: "bold" }}>
                      Reason for deletion (optional):
                    </label>
                    <TextArea
                      rows={5}
                      name="reason"
                      value={formData.reason}
                      onChange={handleChange}
                    />
                  </div>
                  <Fade up delay={350}>
                    <Button
                      className="m-t10"
                      background="#0082C6"
                      padding="6px 0px"
                      style={{
                        width: "140px",
                        boxShadow: "0px 27px 40px 12px rgb(34 39 49 / 15%)",
                        borderRadius: "16px",
                      }}
                      type="submit"
                    >
                      <ParagraphText color="#fff">Delete</ParagraphText>
                    </Button>
                  </Fade>
                </div>
              </div>
            </form>
          </Fade>
        </Container>
      </BackgroundContainer>
    </div>
  );
};

export default AccountDelete;
