import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom';

// ReactDOM.render(

//     <>
//       <Router>
//         <App/>
//       </Router>
//     </>
    
//     ,document.getElementById('root')
// );


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>

    <Router>
    <App />
    </Router>
    
  </React.StrictMode>,
)
