import { Row } from "react-bootstrap";
import {
  ColCards,
  EllipsisText,
  SubTitleText,
  PortfolioBox,
} from "../../Styles/StyleComponent";
import navkar from "../../Assets/portfolio/navakar.png";
import nyef from "../../Assets/portfolio/nyef.png";
import sana from "../../Assets/portfolio/sana.png";
import vodka from "../../Assets/portfolio/vodka.png";
import { useEffect, useState } from "react";
import { portfolioGet } from "../../Api/serviceapi";
import { NavLink } from "react-router-dom";

const CustomCol = ({ img, name, category,url }: { img: any; name: string , category: string,url: string }) => (
  <ColCards sm={6} md={6} lg={4}>
    <img
      src={img}
      alt=""
      width="95%"
      height="400px"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"  }}
    />
    <PortfolioBox>
      <NavLink to={url} target="_blank"  >
      <div>
        <SubTitleText className="text-start" color="#0D97D6">
          {category || 'Website'}
        </SubTitleText>
        <EllipsisText className="text-start" fontSize="22px" fontWeight="700">
          {name}
        </EllipsisText>
      </div>
      </NavLink>
    </PortfolioBox>
  </ColCards>
);

export const PortFolio = () => {
  const [portfolioData , setportfolioData] = useState<any>([]);

  let getApi = async () =>{
    let res = await portfolioGet();
    res.status && setportfolioData(res.data);
  };

  useEffect(()=>{
    getApi();
  }, [])
  return (
    <div className="mt-5 mb-4">
      <Row className="g-lg-5 g-md-4 gy-4">
        {

          
        
portfolioData?.map((p:any, i:number) => (
          <CustomCol img={p.image} name={p.name} category={p.category} url={p.url} key={i} />
        ))
        
        
        }
      </Row>
    </div>
  );
};
