import { Col, Container, Row } from "react-bootstrap";
import { Fade, Rotate } from "react-reveal";
import { BottomArrow, CurveBorder } from "../../Assets/VectorImage";
import {
  FlowBorder,
  ServicesContainer,
  ServicesContainerTop,
  Title,
  TitleText,
} from "../../Styles/StyleComponent";
import { useContext } from "react";
import { GlobalData } from "../../contextapi/ContextProvider";

export const ServiceContainer = ({ services,title,subdescription }: { services: any,title:any,subdescription:any }) => {

  let {mobile} = useContext(GlobalData);
  return (
    <Container className="my-4">
      <Row className="m-0 g-3">

      <Col xs={12} style={{marginBottom : mobile ? 20: 100}}>
      <Row>
      <Col lg={5}>
        <Fade up delay={200}>
           <Title > <span style={{color:"#127DC2"}}>{title}</span></Title>
           <p>{subdescription}</p>
      </Fade>
      </Col>
      </Row>
    </Col>

        <Col lg={4} style={{ height: "300px" }}>
          {services?.svg && services?.svg}
          {services?.image && (
            <img
              src={services.image}
              alt=""
              width={mobile ? "100%" :  "85%"}
              height="100%"
              style={{ objectFit: "contain" }}
            />
          )}
        </Col>
        <Col lg={8}>
          <div
            style={{
              borderLeft: "6px solid #007EC5",
              paddingLeft: "12px",
            }}
          >
            <Fade up delay={100}>
              <TitleText fontWeight="700" color="#233d63">{services.title}</TitleText>
            </Fade>
            <Fade up delay={150}>
              {services.description}
            </Fade>
         
          </div>
        </Col>
      </Row>

      <p className="my-2">
      <Fade up delay={150}>
              {services.bottom_description}
      </Fade>
      </p>

      <h4 className="mt-5 mb-0 text-center " style={{color:'#127DC2'}}>{services.feature_title}</h4>
      <FeaturesContainer features={services.features} />
    </Container>
  );
};

export const FeaturesContainer = ({ features }: any) => {
  return (
    <div
      className="d-flex flex-wrap mt-4 justify-content-sm-center justify-content-around"
      style={{ minHeight: "352px" }}
    >
      {features
        .split(",")
        .slice(0)
        .map((fs: any, i: number) => {
          if (i % 2 === 0) {
            return (
              <div
                key={i}
                className="position-relative h-100"
                style={{ minWidth: "102px" }}
              >
                <Rotate top left>
                  <TopDiv text={fs} />
                </Rotate>
                <div
                  className="d-sm-flex  position-absolute d-none"
                  style={{ bottom: "3px", zIndex: "-2" }}
                >
                  <CurveBorder />
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="position-relative h-100 mt-md-auto"
                style={{ minWidth: "102px" }}
              >
                <Rotate bottom left>
                  <TopDiv text={fs} transform="rotate(180deg)" />
                </Rotate>
                <div
                  className="d-sm-flex  position-absolute d-none"
                  style={{ top: "-10px", zIndex: "-2" }}
                >
                  <CurveBorder />
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};

export const TopDiv = ({
  text,
  color,
  transform,
  margin,
}: {
  text: string;
  color?: string;
  transform?: string;
  margin?: string;
}) => (
  <div
    className="d-flex flex-column justify-content-center align-items-center"
    style={{
      transform: transform,
    }}
  >
    <ServicesContainer background={color} margin={margin}>
      <ServicesContainerTop background={color ? "#F49317" : "#0D69B7"} />
      <p
        style={{
          transform: transform,
          color: "white",
          margin: "0px",
          padding: "4px 8px",
          lineHeight: "18px",
          fontSize: "12px",
        }}
      >
        {text}
      </p>
    </ServicesContainer>
    <div className="d-sm-flex d-none">
      <BottomArrow color={color} />
    </div>
    <FlowBorder>
      <div
        style={{
          height: "34px",
          borderLeft: "2px dotted #9D9D9C",
        }}
      ></div>
      <div
        style={{
          height: "12px",
          width: "12px",
          borderRadius: "12px",
          background: color ? "#F49317" : "#0D69B7",
        }}
      ></div>
    </FlowBorder>
  </div>
);
